import { Typography } from "@mui/material";
import React from "react";

import { formatAppError } from "@bujus/common";

const HorizontalCenterErrorMessage = ({ error }) => (
    <Typography align="center">{formatAppError(error)} Bitte versuchen Sie es erneut.</Typography>
);

export { HorizontalCenterErrorMessage };
