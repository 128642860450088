import { Drawer, Toolbar } from "@mui/material";
import React, { useState } from "react";

import { config } from "@/config";
import { OpenInstructionsDrawerProvider } from "@/contexts";

const App__InstructionsDrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [iframeSource, setIframeSource] = useState(config.urls.instructions);

    const open = (subUrl = undefined) => {
        setIsOpen(true);
        if (subUrl === undefined) {
            return;
        }
        setIframeSource(config.urls.instructions + subUrl);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <OpenInstructionsDrawerProvider value={open}>
            {children}
            <Drawer
                anchor="right"
                keepMounted
                onClose={handleClose}
                open={isOpen}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Toolbar />
                <iframe
                    frameBorder={0}
                    src={iframeSource}
                    style={{
                        flex: 1,
                        width: 480,
                    }}
                />
            </Drawer>
        </OpenInstructionsDrawerProvider>
    );
};

export { App__InstructionsDrawerProvider };
