import { createContext, useContext } from "react";

const DashboardEventCreatedInformationDialogDisplayerContext = createContext();
const DashboardEventCreatedInformationDialogDisplayerProvider =
    DashboardEventCreatedInformationDialogDisplayerContext.Provider;
const useDashboardEventCreatedInformationDialogDisplayerContext = () =>
    useContext(DashboardEventCreatedInformationDialogDisplayerContext);

export {
    DashboardEventCreatedInformationDialogDisplayerContext,
    DashboardEventCreatedInformationDialogDisplayerProvider,
    useDashboardEventCreatedInformationDialogDisplayerContext,
};
