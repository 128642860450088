import { joiResolver } from "@hookform/resolvers/joi";
import { Print as PrintIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    ParticipantStarterlistDisciplineLevelOfDetail,
    formatParticipantStarterlistDisciplineLevelOfDetail,
    participantValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Checkbox, DialogClosingButton, Form, RadioSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const ParticipantStarterlistOfAllPrintingDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            participantValidationSchemas.starterlistOfAllPrinting.form,
            validationOptions,
        ),
        context: { event: selectedEvent },
        defaultValues: {
            disciplineLevelOfDetail: ParticipantStarterlistDisciplineLevelOfDetail.ONLY_PRESELECTED,
            isNotesFriendly: false,
        },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.participants.printStarterlistOfAllAsync(
                variables.eventId,
                variables.disciplineLevelOfDetail,
                variables.isNotesFriendly,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (data) => {
                snackbarsController.enqueueSuccessMessage("Teilnehmer Startliste gedruckt");
                snackbarsController.enqueuePopUpWarning();
                close();
                window.open(data, "_blank");
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            disciplineLevelOfDetail: data.disciplineLevelOfDetail,
            isNotesFriendly: data.isNotesFriendly,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Teilnehmer Startliste drucken</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <RadioSelect
                            fieldName="disciplineLevelOfDetail"
                            formatItem={formatParticipantStarterlistDisciplineLevelOfDetail}
                            isEnabled={!mutation.isLoading}
                            items={Object.values(ParticipantStarterlistDisciplineLevelOfDetail)}
                            label="Disziplin Detailgrad"
                        />
                        <Checkbox
                            fieldName="isNotesFriendly"
                            isEnabled={!mutation.isLoading}
                            label="Notizen freundlich (Neben jedem Teilnehmer etwas Platz für Notizen lassen)"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<PrintIcon />}
                        type="submit"
                    >
                        Drucken
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { ParticipantStarterlistOfAllPrintingDialog };
