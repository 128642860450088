import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import React, { useMemo, useState } from "react";

import {
    defaultParticipantSorting,
    delayAsync,
    formatResultValue,
    generateFullName,
    getDiscipline,
} from "@bujus/common";
import { ResultCreationHistoryState, useApiServiceContext } from "@bujus/common-frontend";

import { FullScreenDialogTransition, HorizontalCenterCircularProgress, QueryError } from "@/base";
import { config } from "@/config";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { BigResultCreationDialog__LoadedContent } from "./big-result-creation-dialog__loaded-content";

const BigResultCreationDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [history, setHistory] = useState([]);

    const query = useQuery(
        ["results-event-data"],
        async () => {
            const participants = [];
            {
                let lastResult;
                do {
                    lastResult = await apiService.participants.listAsync(selectedEvent.id, {
                        fieldSorting: defaultParticipantSorting[0],
                        pageSize: 100,
                        pageIndex: lastResult === undefined ? 0 : lastResult.pageIndex + 1,
                    });
                    participants.push(...lastResult.items);
                } while (participants.length < lastResult.count);
            }
            const groups = await apiService.groups.getAllAsync(selectedEvent.id);
            await delayAsync(config.artificialDelayForHeavyOperations);
            return {
                participants,
                groups,
            };
        },
        { cacheTime: 0 },
    );

    const mutation = useMutation(
        async (variables) => apiService.results.createAsync(variables.result),
        {
            onMutate: (variables) => {
                const tempId = nanoid();
                setHistory((oldHistory) =>
                    [
                        {
                            tempId,
                            result: variables.result,
                            inputted: new Date(),
                            state: ResultCreationHistoryState.LOADING,
                        },
                        ...oldHistory,
                    ].slice(0, 50),
                );
                return { tempId };
            },
            onError: (error, _, context) => {
                setHistory((oldHistory) =>
                    oldHistory.map((x) => {
                        if (x.tempId === context.tempId) {
                            x.state = ResultCreationHistoryState.ERROR;
                        }
                        return x;
                    }),
                );
                snackbarsController.enqueueError(error);
            },
            onSuccess: (result, _, context) => {
                setHistory((oldHistory) =>
                    oldHistory.map((x) => {
                        if (x.tempId === context.tempId) {
                            x.state = ResultCreationHistoryState.SUCCESS;
                        }
                        return x;
                    }),
                );
                snackbarsController.enqueueSuccessMessage(
                    `${generateFullName(
                        result.participant.name.first,
                        result.participant.name.last,
                    )}: ${getDiscipline(selectedEvent.format, result.disciplineId).name}: ${formatResultValue(
                        selectedEvent.format,
                        result.disciplineId,
                        result.isFailedAttempt,
                        result.value,
                    )} hinzugefügt`,
                );
            },
        },
    );

    const hydratedHistory = useMemo(
        () =>
            history.map((x) => {
                const hydratedX = {
                    ...x,
                    result: {
                        ...x.result,
                        participant: query.data.participants.find(
                            (y) => y.id === x.result.participantId,
                        ),
                        discipline: getDiscipline(selectedEvent.format, x.result.disciplineId),
                    },
                };
                delete hydratedX.result.participantId;
                delete hydratedX.result.disciplineId;
                return hydratedX;
            }),
        [history],
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        queryClient.invalidateQueries(["results"]);
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        content = <QueryError query={query} />;
    }
    if (content !== undefined) {
        content = (
            <Box
                alignItems="center"
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
            >
                {content}
            </Box>
        );
    }
    if (query.isSuccess) {
        content = (
            <BigResultCreationDialog__LoadedContent
                groups={query.data.groups}
                hydratedHistory={hydratedHistory}
                mutation={mutation}
                participants={query.data.participants}
            />
        );
    }
    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={isOpen}
            style={{
                display: "flex",
                flexDirection: "column",
            }}
            TransitionComponent={FullScreenDialogTransition}
        >
            <AppBar position="relative" sx={{ zIndex: 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        disabled={mutation.isLoading}
                        edge="start"
                        onClick={handleClosingButtonClick}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography ml={2} variant="h5">
                        Riegenweise Ergebnisse eintragen
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                {content}
            </DialogContent>
        </Dialog>
    );
};

export { BigResultCreationDialog };
