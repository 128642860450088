import { Refresh as RefreshIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import React from "react";

import { HorizontalCenterErrorMessage } from "../horizontal-center-error-message";

const QueryError = ({ query }) => {
    const handleRetryButtonClick = () => {
        query.refetch();
    };

    return (
        <Box>
            <HorizontalCenterErrorMessage error={query.error} />
            <Box display="flex" justifyContent="center" mt={2}>
                <LoadingButton
                    loading={query.isFetching}
                    onClick={handleRetryButtonClick}
                    startIcon={<RefreshIcon />}
                    variant="outlined"
                >
                    Erneut versuchen
                </LoadingButton>
            </Box>
        </Box>
    );
};

export { QueryError };
