import { Check as CheckIcon, Download as DownloadIcon } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Typography } from "@mui/material";
import Papa from "papaparse";
import React, { useMemo } from "react";

import {
    formatAppError,
    formatAppErrorType,
    generateDownloadFileName,
    helperConvert,
} from "@bujus/common";

import { CsvDataTable } from "@/base";
import { downloadCsvFile } from "@/utilities";

const HelpersImportDialog__ReviewStepContent = ({ mutation, onOk }) => {
    const notImportedHelperCsvRows = useMemo(
        () =>
            mutation.data.fails.map((x) => ({
                ...helperConvert.toCsvRow(
                    mutation.variables.helpers.find((y) => y.tempId === x.tempId),
                    { isIsActiveIncluded: false },
                ),
                Fehler: formatAppErrorType(x.error.type),
            })),
        [],
    );

    const importedHelperCsvRows = useMemo(
        () =>
            mutation.data.successes.map((x) =>
                helperConvert.toCsvRow(x.item, {
                    isPasswordIncluded: true,
                    isIsActiveIncluded: false,
                }),
            ),
        [],
    );

    const handleFailsExportingButtonClick = () => {
        downloadCsvFile(
            generateDownloadFileName("Helfer mit Fehlern", "csv"),
            Papa.unparse(notImportedHelperCsvRows),
        );
    };

    const handleOkButtonClick = () => {
        onOk();
    };

    return (
        <Box>
            {mutation.isError && (
                <Box mb={2}>
                    <Alert>
                        <AlertTitle>{formatAppError(mutation.error)}</AlertTitle>
                    </Alert>
                </Box>
            )}
            {mutation.data.fails.length > 0 && (
                <Box mb={2}>
                    <Card sx={{ borderColor: "error.main" }}>
                        <CardContent>
                            <Box sx={{ position: "relative" }}>
                                <Typography variant="h6">Nicht importierte Helfer</Typography>
                                <Button
                                    onClick={handleFailsExportingButtonClick}
                                    startIcon={<DownloadIcon />}
                                    sx={{
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                    }}
                                    variant="outlined"
                                >
                                    Exportieren
                                </Button>
                                <Typography color="text.secondary" mt={2}>
                                    Anzahl: {mutation.data.fails.length}
                                </Typography>
                                <Box mt={2}>
                                    <CsvDataTable csvRows={notImportedHelperCsvRows} />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
            <Card sx={{ borderColor: "success.main" }}>
                <CardContent>
                    <Typography variant="h6">Importierte Helfer</Typography>
                    <Typography color="text.secondary" mt={2}>
                        Anzahl: {mutation.data.successes.length}
                    </Typography>
                    <Box mt={2}>
                        <CsvDataTable csvRows={importedHelperCsvRows} />
                    </Box>
                </CardContent>
            </Card>
            <Box
                bottom={-20} // Dialog content padding
                display="flex"
                justifyContent="end"
                mt={2}
                position="sticky"
                py={2}
                sx={{
                    backgroundColor: "background.paper",
                    borderColor: "divider",
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                }}
            >
                <Button onClick={handleOkButtonClick} startIcon={<CheckIcon />}>
                    Ok
                </Button>
            </Box>
        </Box>
    );
};

export { HelpersImportDialog__ReviewStepContent };
