import { useSnackbar as useNotistackSnackbar } from "notistack";
import { useCallback, useMemo } from "react";

import { formatAppError } from "@bujus/common";

const useSnackbarsController = () => {
    const { enqueueSnackbar: enqueue } = useNotistackSnackbar();

    const enqueueErrorMessage = useCallback(
        (message) => enqueue(message, { variant: "error" }),
        [enqueue],
    );

    const enqueueError = useCallback(
        (error) => enqueue(formatAppError(error), { variant: "error" }),
        [enqueue],
    );

    const enqueueWarningMessage = useCallback(
        (message) => enqueue(message, { variant: "warning" }),
        [enqueue],
    );

    const enqueuePopUpWarning = useCallback(() => {
        setTimeout(() => {
            enqueue(
                "Wenn sich die Datei nicht öffnet, lassen Sie bitte Pop-Ups von Bujus in Ihrem Browser zu",
                {
                    autoHideDuration: 5000,
                    variant: "warning",
                },
            );
        }, 500);
    }, [enqueue]);

    const enqueueSuccessMessage = useCallback(
        (message) => enqueue(message, { variant: "success" }),
        [enqueue],
    );

    const returnValue = useMemo(
        () => ({
            enqueueErrorMessage,
            enqueueError,
            enqueueWarningMessage,
            enqueuePopUpWarning,
            enqueueSuccessMessage,
        }),
        [
            enqueueErrorMessage,
            enqueueError,
            enqueueWarningMessage,
            enqueuePopUpWarning,
            enqueueSuccessMessage,
        ],
    );

    return returnValue;
};

export { useSnackbarsController };
