import {
    Add as AddIcon,
    Delete as DeleteIcon,
    InfoOutlined as InfoOutlinedIcon,
    RestoreFromTrash as RestoreFromTrashIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    IconButton,
    Radio,
    ThemeProvider,
    Tooltip,
    Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import * as dateFns from "date-fns";
import React, { useEffect } from "react";

import { Order, checkIsEventActive, formatDate, formatEventFormat } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { ButtonHotkeyIcon, DataMaster, DataMaster__ActionsCell } from "@/base";
import {
    useAccountContext,
    useDashboardTitleStateContext,
    useOpenInstructionsDrawerContext,
    useSelectedEventStateContext,
} from "@/contexts";
import { accountTheme } from "@/styles";

import {
    EventCreationDialog,
    EventRemovalCancellationDialog,
    EventRemovalRequestDialog,
} from "../common";

const RightDataMasterToolbarComponents = ({ onCreationButtonClick }) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();

    const handleCreationButtonClick = () => {
        onCreationButtonClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/vorbereitung/veranstaltung-erstellen");
    };

    return (
        <>
            <Button
                endIcon={<ButtonHotkeyIcon>N</ButtonHotkeyIcon>}
                onClick={handleCreationButtonClick}
                startIcon={<AddIcon />}
            >
                Erstellen
            </Button>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlinedIcon />
            </IconButton>
        </>
    );
};

const EventsPage = () => {
    const account = useAccountContext();
    const apiService = useApiServiceContext();
    const [selectedEvent, setSelectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const creationDialogDisplayer = useDialogDisplayer(EventCreationDialog);
    const removalCancellationDialogDisplayer = useDialogDisplayer(EventRemovalCancellationDialog);
    const removalRequestDialogDisplayer = useDialogDisplayer(EventRemovalRequestDialog);
    const dialogDisplayers = [
        creationDialogDisplayer,
        removalCancellationDialogDisplayer,
        removalRequestDialogDisplayer,
    ];

    const handleDataMasterRemovalCancellationButtonClick = (event) => {
        removalCancellationDialogDisplayer.open({ event });
    };

    const handleDataMasterRemovalRequestButtonClick = (event) => {
        removalRequestDialogDisplayer.open({ event });
    };

    const handleDataMasterSelectionRadioChange = (event) => {
        setSelectedEvent(event);
    };

    const dataMasterColumns = [
        // {
        //     field: "format",
        //     type: "string",
        //     valueFormatter: ({ value }) => formatEventFormat(value),
        //     headerName: "Format",
        //     width: 100,
        // },
        {
            field: "n3WordId",
            type: "string",
            headerName: "3-Wort-ID",
            width: 200,
        },
        {
            field: "name",
            type: "string",
            renderCell: ({ row }) => (
                <Box alignItems="center" display="flex">
                    <Typography fontSize="inherit">{row.name}</Typography>
                    <Box ml={1}>
                        <Chip color="primary" label={formatEventFormat(row.format)} size="small" />
                    </Box>
                    {row.isTest && (
                        <Box ml={1}>
                            <Chip color="info" label="Test" size="small" />
                        </Box>
                    )}
                </Box>
            ),
            headerName: "Name",
            width: 300,
        },
        {
            field: "start",
            type: "date",
            renderCell: ({ row }) => formatDate(row.start, "P"),
            headerName: "Start",
            width: 100,
        },
        {
            field: "warmUpStart",
            type: "date",
            renderCell: ({ row }) => formatDate(row.warmUpStart, "P"),
            headerName: "Test Start",
            width: 100,
        },
        {
            field: "end",
            type: "date",
            renderCell: ({ row }) => formatDate(row.end, "P"),
            headerName: "Ende",
            width: 100,
        },
        {
            field: "limits.helper",
            type: "number",
            valueGetter: ({ row }) => row.limits.helper,
            valueFormatter: ({ value }) => value.toLocaleString("de-DE"),
            headerName: "Max. Helferanzahl",
            width: 150,
        },
        {
            field: "limits.participant",
            type: "number",
            valueGetter: ({ row }) => row.limits.participant,
            valueFormatter: ({ value }) => value.toLocaleString("de-DE"),
            headerName: "Max. Teilnehmeranzahl",
            width: 175,
        },
        {
            field: "limits.group",
            type: "number",
            valueGetter: ({ row }) => row.limits.group,
            valueFormatter: ({ value }) => value.toLocaleString("de-DE"),
            headerName: "Max. Riegenanzahl",
            width: 150,
        },
        {
            field: "limits.result",
            type: "number",
            valueGetter: ({ row }) => row.limits.result,
            valueFormatter: ({ value }) => value.toLocaleString("de-DE"),
            headerName: "Max. Ergebnisanzahl",
            width: 150,
        },
        {
            field: "isActive",
            valueGetter: ({ row }) => checkIsEventActive(row),
            type: "boolean",
            headerName: "Aktiv",
            sortable: false,
            width: 75,
        },
        {
            field: "actions",
            getActions: ({ row }) => [
                row.removalIntent !== undefined &&
                !dateFns.isBefore(row.removalIntent.expires, new Date()) ? (
                    <Tooltip title="Löschung abbrechen">
                        <GridActionsCellItem
                            icon={<RestoreFromTrashIcon />}
                            label="Löschung abbrechen"
                            onClick={() => handleDataMasterRemovalCancellationButtonClick(row)}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title="Löschen">
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Löschen"
                            onClick={() => handleDataMasterRemovalRequestButtonClick(row)}
                        />
                    </Tooltip>
                ),
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 100,
        },
        {
            field: "select",
            renderCell: ({ row }) => (
                <DataMaster__ActionsCell
                    components={[
                        <Radio
                            checked={selectedEvent !== undefined && row.id === selectedEvent.id}
                            onChange={() => handleDataMasterSelectionRadioChange(row)}
                        />,
                    ]}
                />
            ),
            headerName: "Auswählen",
            sortable: false,
            width: 100,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.events.listAsync(account.id, listingModifiers);

    const handleDataMasterCreationHotkeyFire = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterRemovalHotkeyFire = (event) => {
        removalRequestDialogDisplayer.open({ event });
    };

    const handleDataMasterUpdateHotkeyFire = (event) => {
        // updateDialogDisplayer.open({
        //     event,
        //     removalDialogDisplayer,
        // });
    };

    const handleDataMasterCreationButtonClick = () => {
        creationDialogDisplayer.open();
    };

    useEffect(() => {
        setDashboardTitle("Veranstaltungen");
    }, []);

    return (
        <>
            <ThemeProvider theme={accountTheme}>
                <Card
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                    }}
                >
                    <CardContent
                        sx={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                        }}
                    >
                        <DataMaster
                            baseQueryKey={["events", "list", account.id]}
                            columns={dataMasterColumns}
                            defaultFieldSorting={{
                                path: "start",
                                order: Order.ASCENDING,
                            }}
                            dialogDisplayers={dialogDisplayers}
                            exampleSearchDescription="Name, 3-Wort-ID, Start"
                            fullSearchDescription="Durchsucht Name, 3-Wort-ID, Start"
                            getKeyFromItem={(item) => item.id}
                            itemLimit={10_000}
                            listItemsAsync={handleDataMasterListItemsAsync}
                            noFilteredItemsMessage="Keine Veranstaltungen gefunden."
                            noItemsMessage="Noch keine Veranstaltungen erstellt."
                            onCreationHotkeyFire={handleDataMasterCreationHotkeyFire}
                            onRemovalHotkeyFire={handleDataMasterRemovalHotkeyFire}
                            onUpdateHotkeyFire={handleDataMasterUpdateHotkeyFire}
                            RightToolbarComponents={RightDataMasterToolbarComponents}
                            rightToolbarComponentsProps={{
                                onCreationButtonClick: handleDataMasterCreationButtonClick,
                            }}
                        />
                    </CardContent>
                </Card>
            </ThemeProvider>
            {creationDialogDisplayer.element}
            {removalCancellationDialogDisplayer.element}
            {removalRequestDialogDisplayer.element}
        </>
    );
};

export { EventsPage };
