import { CssBaseline, GlobalStyles } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns as DateFnsLocalizationAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import deDateFnsLocale from "date-fns/locale/de";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { theme } from "@/styles";

import { App__InstructionsDrawerProvider } from "./app__instructions-drawer-provider";
import { MessagesDisplayer } from "./messages-displayer";
import { SnackbarCloseButton } from "./snackbar-close-button";

// const styles = {
//     success: { backgroundColor: "purple" },
//     error: { backgroundColor: "blue" },
//     warning: { backgroundColor: "green" },
//     info: { backgroundColor: "yellow" },
// };
// TODO https://iamhosseindhv.com/notistack/demos

const queryClient = new QueryClient({
    defaultOptions: {
        mutations: { retry: false },
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

const App__Framework = ({ children }) => (
    <LocalizationProvider adapterLocale={deDateFnsLocale} dateAdapter={DateFnsLocalizationAdapter}>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <GlobalStyles
                        styles={(theme2) => ({
                            ".selection-container": {
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                            },
                            ".selection-area": {
                                backgroundColor: theme2.palette.selection.background,
                                borderColor: `${theme2.palette.selection.border}`,
                                borderRadius: theme2.shape.borderRadius,
                                borderStyle: "solid",
                                borderWidth: 2,
                            },
                        })}
                    />
                    <SnackbarProvider
                        action={(key) => SnackbarCloseButton({ key })}
                        autoHideDuration={3600}
                    >
                        <QueryClientProvider client={queryClient}>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                            <MessagesDisplayer />
                            <App__InstructionsDrawerProvider>
                                {children}
                            </App__InstructionsDrawerProvider>
                        </QueryClientProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    </LocalizationProvider>
);

export { App__Framework };
