import { Box, Card, CardContent, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useMemo } from "react";

import {
    CertType,
    Gender,
    certsGenerationService,
    formatCertType,
    formatCertTypeToColor,
    formatGender,
    formatGenderToColor,
} from "@bujus/common";

import { useDisciplinePreselectionContext, useSelectedEventStateContext } from "@/contexts";
import { generateHydratedCertsResult } from "@/utilities";

import { StatisticsPage__BigNumberCard } from "./statistics-dialog__big-number-card";

const StatisticsPage__GeneralTab = ({ groups, options, participants, results }) => {
    const [selectedEvent] = useSelectedEventStateContext();
    const disciplinePreselection = useDisciplinePreselectionContext();

    const participantCountByGender = useMemo(
        () =>
            Object.fromEntries(
                Object.values(Gender).map((x) => [
                    x,
                    participants.filter((y) => y.gender === x).length,
                ]),
            ),
        [],
    );

    const certsResult = useMemo(() => {
        const result = certsGenerationService.generateAll(
            selectedEvent,
            disciplinePreselection,
            groups,
            participants,
            results,
            options,
        );
        return generateHydratedCertsResult(participants, result);
    }, []);

    const certCountByType = useMemo(
        () =>
            Object.values(CertType).reduce(
                (xAccumulator, x) => ({
                    ...xAccumulator,
                    [x]: certsResult.generatedCerts.filter((y) => y.type === x).length,
                }),
                {},
            ),
        [certsResult.generatedCerts],
    );

    return (
        <Box display="flex" flexDirection="column" gap={1}>
            <Card>
                <CardContent>
                    <Typography variant="h6">Teilnehmer</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                        <StatisticsPage__BigNumberCard
                            heading="Teilnehmer"
                            value={participants.length}
                        />
                        {Object.entries(participantCountByGender).map(([xKey, xValue]) => (
                            <StatisticsPage__BigNumberCard
                                key={xKey}
                                heading={formatGender(xKey)}
                                value={xValue}
                            />
                        ))}
                    </Box>
                    {participants.length > 0 && (
                        <Box mt={4}>
                            <PieChart
                                height={320}
                                series={[
                                    {
                                        arcLabel: (item) =>
                                            `${((item.value / participants.length) * 100).toFixed(
                                                2,
                                            )} %`,
                                        data: Object.values(Gender).map((x) => ({
                                            id: x,
                                            value: participants.filter((y) => y.gender === x)
                                                .length,
                                            label: formatGender(x),
                                            color: formatGenderToColor(x),
                                        })),
                                    },
                                ]}
                                width={640}
                            />
                        </Box>
                    )}
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h6">Ergebnisse</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                        <StatisticsPage__BigNumberCard
                            heading="Ergebnisse"
                            value={results.length}
                        />
                    </Box>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h6">Urkunden</Typography>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                        <StatisticsPage__BigNumberCard
                            heading="Generierte Urkunden"
                            value={certsResult.generatedCerts.length}
                        />
                        <StatisticsPage__BigNumberCard
                            heading="Nicht generierte Urkunden"
                            value={certsResult.notGeneratedCerts.length}
                        />
                        {Object.entries(certCountByType).map(([xKey, xValue]) => (
                            <StatisticsPage__BigNumberCard
                                key={xKey}
                                heading={formatCertType(xKey)}
                                value={xValue}
                            />
                        ))}
                    </Box>
                    {certsResult.generatedCerts.length > 0 && (
                        <Box mt={4}>
                            <PieChart
                                height={320}
                                series={[
                                    {
                                        arcLabel: (item) =>
                                            `${(
                                                (item.value / certsResult.generatedCerts.length) *
                                                100
                                            ).toFixed(2)} %`,
                                        data: Object.values(CertType).map((x) => ({
                                            id: x,
                                            value: certsResult.generatedCerts.filter(
                                                (y) => y.type === x,
                                            ).length,
                                            label: formatCertType(x, { isPlural: true }),
                                            color: formatCertTypeToColor(x),
                                        })),
                                    },
                                ]}
                                width={640}
                            />
                        </Box>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export { StatisticsPage__GeneralTab };
