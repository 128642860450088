import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AllCertsExportingDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) => apiService.certs.exportAllAsync(variables.eventId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (data) => {
                snackbarsController.enqueueSuccessMessage("Alle Urkunden exportiert");
                snackbarsController.enqueuePopUpWarning();
                close();
                window.open(data, "_blank");
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ eventId: selectedEvent.id });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Urkunden exportieren</DialogTitle>
            <DialogClosingButton
                isDisabled={mutation.isLoading}
                onClick={handleClosingButtonClick}
            />
            <DialogActions>
                <LoadingButton
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                    startIcon={<FileDownloadIcon />}
                >
                    Exportieren
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { AllCertsExportingDialog };
