import { joiResolver } from "@hookform/resolvers/joi";
import { Groups3 as Groups3Icon } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { groupValidationSchemas, validationOptions } from "@bujus/common";

import { DialogClosingButton, Form, NumberInput } from "@/base";

const AllGroupsUpdateDialog__GroupsAutoDistributionDialog = ({ close, isOpen, onDone, title }) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            groupValidationSchemas.updateAll.forms.autoDistributionSettings,
            validationOptions,
        ),
        defaultValues: { participantLimitPerGroup: 20 },
    });

    const handleClose = () => {
        close();
    };

    const handleFormSubmit = (data) => {
        onDone(data.participantLimitPerGroup);
        close();
    };

    const handleClosingButtonClick = () => {
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>{title}</DialogTitle>
                <DialogClosingButton onClick={handleClosingButtonClick} />
                <DialogContent>
                    <NumberInput
                        fieldName="participantLimitPerGroup"
                        isAutofocusing
                        label="Max. Teilnehmer pro Riege"
                        validationSchema={
                            groupValidationSchemas.updateAll.forms.autoDistributionSettings
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<Groups3Icon />} type="submit">
                        Automatisch Verteilen
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AllGroupsUpdateDialog__GroupsAutoDistributionDialog };
