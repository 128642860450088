import React from "react";

const BugsnagErrorBoundaryWrapper__Fallback = () => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
        }}
    >
        <p style={{ textAlign: "center" }}>
            Es ist ein unbekannter Fehler aufgetreten. Bitte laden Sie die Seite neu.
        </p>
    </div>
);

export { BugsnagErrorBoundaryWrapper__Fallback };
