import {
    AutoGraph as AutoGraphIcon,
    CreditCard as CreditCardIcon,
    DateRange as DateRangeIcon,
    DirectionsRun as DirectionsRunIcon,
    EmojiPeople as EmojiPeopleIcon,
    FitnessCenter as FitnessCenterIcon,
    FormatListNumbered as FormatListNumberedIcon,
    Groups as GroupsIcon,
    Person as PersonIcon,
    Print as PrintIcon,
    Today as TodayIcon,
    WorkspacePremium as WorkspacePremiumIcon,
} from "@mui/icons-material";
import {
    Box,
    Divider,
    Link,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@mui/material";
import React from "react";

import { schoolAppRoutes, version } from "@bujus/common";

import { Collapse } from "@/base";
import { config } from "@/config";
import { useDashboardSideBarContext, useSelectedEventStateContext } from "@/contexts";
import { accountTheme } from "@/styles";

import { SideBar__HelpLink } from "./side-bar__help-link";
import { SideBar__Link } from "./side-bar__link";

const SideBar__Content = () => {
    const [selectedEvent] = useSelectedEventStateContext();
    const { isDashboardSideBarOpen } = useDashboardSideBarContext();

    return (
        <Box display="flex" flex={1} flexDirection="column">
            <Box flex={1}>
                <Toolbar>
                    <Typography color="main-gradient" sx={{ fontWeight: "bold" }} variant="h5">
                        Bujus - Schule
                    </Typography>
                </Toolbar>
                <Divider />
                <List>
                    <Collapse isOpen={isDashboardSideBarOpen}>
                        <ListSubheader>
                            {selectedEvent === undefined ? "Veranstaltung" : selectedEvent.name}
                        </ListSubheader>
                    </Collapse>
                    <SideBar__Link
                        icon={<TodayIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().event().build()}
                        text="Veranstaltung"
                    />
                    <SideBar__Link
                        icon={<FitnessCenterIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().disciplinePreSelection().build()}
                        text="Disziplinvorauswahl"
                    />
                    <SideBar__Link
                        cyId="SideBar__Content__helper-link"
                        icon={<EmojiPeopleIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().helpers().build()}
                        text="Helfer"
                    />
                    <SideBar__Link
                        icon={<DirectionsRunIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().participants().build()}
                        text="Teilnehmer"
                    />
                    <SideBar__Link
                        icon={<GroupsIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().groups().build()}
                        text="Riegen"
                    />
                    <SideBar__Link
                        icon={<FormatListNumberedIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().results().build()}
                        text="Ergebnisse"
                    />
                    <SideBar__Link
                        icon={<PrintIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().certTemplateSet().build()}
                        text="Urkunden ausrichten"
                    />
                    <SideBar__Link
                        icon={<WorkspacePremiumIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().certs().build()}
                        text="Urkunden"
                    />
                    {/* {getEventFormatObject(
                        undefined, */}
                    <SideBar__Link
                        icon={<AutoGraphIcon color="primary" />}
                        isEnabled={selectedEvent !== undefined}
                        route={schoolAppRoutes(true).dashboard().statistics().build()}
                        text="Statistiken"
                    />
                    {/* selectedEvent.format,
                    )} */}
                </List>
                <Divider />
                <ThemeProvider theme={accountTheme}>
                    <List>
                        <Collapse isOpen={isDashboardSideBarOpen}>
                            <ListSubheader>Account</ListSubheader>
                        </Collapse>
                        <SideBar__Link
                            icon={<DateRangeIcon color="primary" />}
                            route={schoolAppRoutes(true).dashboard().events().build()}
                            text="Veranstaltungen"
                        />
                        <SideBar__Link
                            icon={<PersonIcon color="primary" />}
                            route={schoolAppRoutes(true).dashboard().account().build()}
                            text="Account"
                        />
                        <SideBar__Link
                            icon={<CreditCardIcon color="primary" />}
                            route={schoolAppRoutes(true).dashboard().payments().build()}
                            text="Zahlungen"
                        />
                    </List>
                </ThemeProvider>
            </Box>
            <Box>
                <SideBar__HelpLink />
            </Box>
            <Collapse isOpen={isDashboardSideBarOpen}>
                <ListItem>
                    <ListItemText
                        secondary={
                            <>
                                {/* eslint-disable-next-line no-undef */}
                                <b>Version:</b> {version}
                                <br />
                                <Link href={config.urls.homePage} target="_blank">
                                    Webseite
                                </Link>{" "}
                                <Link href={config.urls.contact} target="_blank">
                                    Kontakt
                                </Link>
                                <br />
                                <Link href={config.urls.imprint} target="_blank">
                                    Impressum
                                </Link>{" "}
                                <Link href={config.urls.dataPrivacy} target="_blank">
                                    Datenschutz
                                </Link>
                            </>
                        }
                    />
                </ListItem>
            </Collapse>
        </Box>
    );
};

export { SideBar__Content };
