import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { germanStates, schoolValidationSchemas, validationOptions } from "@bujus/common";

import { Form, Select, TextField } from "@/base";

const AccountSignUpPage__AddressSettingsStepContent = ({
    defaultFormData,
    mutation,
    onCreation,
    onPrevious,
}) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            schoolValidationSchemas.signUp.forms.addressSettings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        onCreation(data);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="row" gap={2}>
                    <Box flex={3}>
                        <TextField
                            autoComplete="address-line1"
                            cyId="AccountSignUpPage__AddressSettingsStepContent__street-field"
                            fieldName="street"
                            isEnabled={!mutation.isLoading}
                            label="Straße *"
                        />
                    </Box>
                    <Box flex={2}>
                        <TextField
                            autoComplete="address-line2"
                            cyId="AccountSignUpPage__AddressSettingsStepContent__house-number-field"
                            fieldName="houseNumber"
                            isEnabled={!mutation.isLoading}
                            label="Hausnummer *"
                        />
                    </Box>
                </Box>
                <Box display="flex" gap={2}>
                    <Box flex={2}>
                        <TextField
                            autoComplete="postal-code"
                            cyId="AccountSignUpPage__AddressSettingsStepContent__postal-code-field"
                            fieldName="postalCode"
                            isEnabled={!mutation.isLoading}
                            label="Postleitzahl *"
                        />
                    </Box>
                    <Box flex={3}>
                        <TextField
                            autoComplete="address-level2"
                            cyId="AccountSignUpPage__AddressSettingsStepContent__city-field"
                            fieldName="city"
                            isEnabled={!mutation.isLoading}
                            label="Stadt *"
                        />
                    </Box>
                </Box>
                <Select
                    cyId="AccountSignUpPage__AddressSettingsStepContent__state-field"
                    fieldName="state"
                    isEnabled={!mutation.isLoading}
                    items={germanStates}
                    label="Bundesland *"
                />
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    disabled={mutation.isLoading}
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <LoadingButton
                    cyId="AccountSignUpPage__AddressSettingsStepContent__sign-up-button"
                    loading={mutation.isLoading}
                    startIcon={<AddIcon />}
                    type="submit"
                >
                    Account erstellen
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { AccountSignUpPage__AddressSettingsStepContent };
