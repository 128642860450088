import React from "react";

import { schoolAppRoutes } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

import { RestrictedRoute } from "../restricted-route";

const SelectedEventRoute = ({ children }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <RestrictedRoute
            condition={selectedEvent !== undefined}
            redirectUrl={schoolAppRoutes(true).dashboard().events().build()}
        >
            {children}
        </RestrictedRoute>
    );
};

export { SelectedEventRoute };
