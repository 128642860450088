import {
    Clear as ClearIcon,
    Refresh as RefreshIcon,
    Search as SearchIcon,
} from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Tooltip,
    debounce,
} from "@mui/material";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Order, transformToValidSearchValue, validationTemplates } from "@bujus/common";
import { checkIsDialogOpen } from "@bujus/common-frontend";

import { config } from "@/config";
import { usePageSizeCookie, useSnackbarsController } from "@/hooks";

import { DataMaster__NoRowsOverlay } from "./data-master__no-rows-overlay";
import { DataMaster__Toolbar } from "./data-master__toolbar";

const DataMaster = ({
    baseQueryKey,
    columns,
    cyId = undefined,
    defaultFieldSorting,
    defaultSearchValue = "",
    dialogDisplayers,
    exampleSearchDescription,
    fullSearchDescription,
    getKeyFromItem,
    itemLimit = undefined,
    listItemsAsync,
    noFilteredItemsMessage,
    noItemsMessage,
    onCreationHotkeyFire = undefined,
    onRemovalHotkeyFire = undefined,
    onUpdateHotkeyFire = undefined,
    RightToolbarComponents = () => null,
    rightToolbarComponentsProps = {},
}) => {
    const snackbarsController = useSnackbarsController();

    const [pageSize, setPageSize] = usePageSizeCookie();

    // The value that is displayed the search text field
    const [searchInputValue, _setSearchInputValue] = useState(defaultSearchValue);
    const setSearchInputValue = (newSearchValue, isDebouncing) => {
        if (!isDebouncing) {
            // eslint-disable-next-line no-use-before-define
            setSearchValueDebounced.clear();
            // eslint-disable-next-line no-use-before-define
            setSearchValue(newSearchValue);
        } else {
            // eslint-disable-next-line no-use-before-define
            setSearchValueDebounced(newSearchValue);
        }
        _setSearchInputValue(newSearchValue);
    };
    // The value that gets seached, often debounced
    const [searchValue, setSearchValue] = useState(searchInputValue);
    const setSearchValueDebounced = useMemo(
        () => debounce((newSearchValue) => setSearchValue(newSearchValue), 750),
        [],
    );
    const [fieldSorting, setFieldSorting] = useState(defaultFieldSorting);
    const [pageIndex, setPageIndex] = useState(0);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const validSearchValue = transformToValidSearchValue(searchValue);

    const listingModifiers = useMemo(
        () =>
            validSearchValue !== ""
                ? {
                      searchValue: validSearchValue,
                      pageSize,
                  }
                : {
                      fieldSorting,
                      pageSize,
                      pageIndex,
                  },
        [validSearchValue, fieldSorting, pageSize, pageIndex],
    );

    const query = useQuery(
        [...baseQueryKey, listingModifiers],
        async ({ queryKey: key }) => listItemsAsync(key[baseQueryKey.length]),
        {
            keepPreviousData: true,
            onError: (error) => {
                setSearchInputValue("", false);
                setFieldSorting(fieldSorting);
                setPageSize(config.dataMasterPageSizeOptions[2]);
                setPageIndex(0);
                snackbarsController.enqueueError(error);
            },
            onSuccess: (listResult) => {
                if (listResult.searchValue !== undefined) {
                    return;
                }
                setPageIndex(listResult.pageIndex);
            },
        },
    );

    useHotkeys("n", (event) => {
        event.preventDefault();
        if (
            onCreationHotkeyFire === undefined ||
            checkIsDialogOpen(dialogDisplayers) ||
            query.isFetching ||
            query.data === undefined ||
            query.data.count >= itemLimit
        ) {
            return;
        }
        onCreationHotkeyFire();
    });

    useHotkeys("e", (event) => {
        event.preventDefault();
        if (
            onUpdateHotkeyFire === undefined ||
            checkIsDialogOpen(dialogDisplayers) ||
            !query.isSuccess ||
            rowSelectionModel.length === 0
        ) {
            return;
        }
        onUpdateHotkeyFire(
            query.data.items.find((x) => getKeyFromItem(x) === rowSelectionModel[0]),
        );
    });

    useHotkeys("del, backspace", (event) => {
        event.preventDefault();
        if (
            onRemovalHotkeyFire === undefined ||
            checkIsDialogOpen(dialogDisplayers) ||
            !query.isSuccess ||
            rowSelectionModel.length === 0
        ) {
            return;
        }
        onRemovalHotkeyFire(
            rowSelectionModel.map((x) => query.data.items.find((y) => getKeyFromItem(y) === x)),
        );
    });

    useHotkeys("r", (event) => {
        event.preventDefault();
        if (query.isLoading) {
            return;
        }
        query.refetch();
    });

    // MUI does not interpret undefined sortable as default true
    const finalColumns = (() =>
        columns.map((x) => ({
            ...x,
            // eslint-disable-next-line no-nested-ternary
            sortable: searchValue !== "" ? false : x.sortable === undefined ? true : x.sortable,
        })))();

    const sortModel = [
        {
            field: fieldSorting.path,
            sort: fieldSorting.order === Order.ASCENDING ? "asc" : "desc",
        },
    ];

    const paginationModel = {
        pageSize,
        page: pageIndex,
    };

    const handleSearchClearButtonClick = () => {
        setSearchInputValue("", false);
    };

    const handleSearchTextFieldChange = (event) => {
        setSearchInputValue(event.target.value, true);
    };

    const handleReloadButtonClick = () => {
        query.refetch();
    };

    const handleSortModelChange = (newSortModel) => {
        if (
            newSortModel[0]?.field === sortModel[0].field &&
            newSortModel[0]?.sort === sortModel[0].sort
        ) {
            return;
        }
        if (newSortModel[0]?.field === undefined || newSortModel[0]?.sort === undefined) {
            setFieldSorting(defaultFieldSorting);
        } else {
            setFieldSorting({
                path: newSortModel[0]?.field,
                order: newSortModel[0]?.sort === "asc" ? Order.ASCENDING : Order.DESCENDING,
            });
        }
        setPageIndex(0);
    };

    const handlePaginationModelChange = (newPaginationModel) => {
        setPageSize(newPaginationModel.pageSize);
        setPageIndex(newPaginationModel.page);
    };

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        document.activeElement.blur();
        setRowSelectionModel(newRowSelectionModel);
    };

    if (query.isLoading || query.isSuccess || query.isError) {
        return (
            <Box flex={1} position="relative">
                <Box
                    bottom={0}
                    {...(cyId !== undefined && { cyId })}
                    left={0}
                    position="absolute"
                    right={0}
                    top={0}
                >
                    <MuiDataGrid
                        checkboxSelection
                        columns={finalColumns}
                        density="compact"
                        disableColumnMenu
                        error={query.isError}
                        getRowId={getKeyFromItem}
                        loading={query.isFetching}
                        onRowSelectionModelChange={handleRowSelectionModelChange}
                        rows={!query.isSuccess ? [] : query.data.items}
                        rowSelectionModel={rowSelectionModel}
                        showCellRightBorder
                        showColumnRightBorder
                        slotProps={{
                            toolbar: {
                                leftElements: (
                                    <Tooltip title={fullSearchDescription}>
                                        <TextField
                                            autoComplete="off"
                                            inputProps={{
                                                maxLength: validationTemplates.searchValueMaxLength,
                                            }}
                                            // eslint-disable-next-line react/jsx-no-duplicate-props
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: searchInputValue !== "" && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={handleSearchClearButtonClick}
                                                            size="small"
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={handleSearchTextFieldChange}
                                            placeholder={exampleSearchDescription}
                                            size="small"
                                            sx={{ maxWidth: 300 }}
                                            value={searchInputValue}
                                        />
                                    </Tooltip>
                                ),
                                query,
                                rightElements: (
                                    <>
                                        <RightToolbarComponents
                                            getKeyFromItem={getKeyFromItem}
                                            query={query}
                                            selectedItems={rowSelectionModel}
                                            {...rightToolbarComponentsProps}
                                        />
                                        <Tooltip title="Neu laden (R)">
                                            <Box>
                                                <IconButton
                                                    disabled={query.isFetching}
                                                    onClick={handleReloadButtonClick}
                                                >
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            noRowsOverlay: {
                                noFilteredItemsMessage,
                                noItemsMessage,
                                query,
                            },
                        }}
                        slots={{
                            toolbar: DataMaster__Toolbar,
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: DataMaster__NoRowsOverlay,
                        }}
                        sx={{ borderWidth: 0 }}
                        {...(!query.isSuccess || query.data.searchValue !== undefined
                            ? { hideFooterPagination: true }
                            : {
                                  pageSizeOptions: config.dataMasterPageSizeOptions,
                                  onPaginationModelChange: handlePaginationModelChange,
                                  hideFooterSelectedRowCount: true,
                                  onSortModelChange: handleSortModelChange,
                                  paginationModel,
                                  pagination: true,
                                  paginationMode: "server",
                                  rowCount: query.data.count,
                                  sortingMode: "server",
                                  sortModel,
                              })}
                    />
                </Box>
            </Box>
        );
    }
    return undefined;
};

export { DataMaster };
