import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

const DataTable = ({ columns, isPreview = false, isShowHeadings = true, items }) => {
    const totalColumnsWidth = columns.reduce((xAccumulator, x) => xAccumulator + x.width, 0);

    const getCellValueSafely = (column, row) => {
        try {
            return column.getValue(row);
        } catch (error) {
            return "";
        }
    };

    return (
        <Table size="small">
            <colgroup>
                {columns.map((x) => (
                    <col key={x.fieldName} width={`${(x.width / totalColumnsWidth) * 100}%`} />
                ))}
            </colgroup>
            {isShowHeadings && (
                <TableHead>
                    <TableRow>
                        {columns.map((x) => (
                            <TableCell
                                key={x.fieldName}
                                sx={{
                                    fontWeight: "bold",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {x.heading === undefined ? "" : x.heading}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {items.slice(0, !isPreview ? undefined : 3).map((x, xIndex) => (
                    <TableRow key={xIndex}>
                        {columns.map((y) => (
                            <TableCell
                                key={`${xIndex}.${y.fieldName}`}
                                sx={{
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {getCellValueSafely(y, x)}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export { DataTable };
