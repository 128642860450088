import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useMemo } from "react";

const checkIsFieldSimplifiable = (firstItem, secondItem, headerColumnCount) => {
    // Check if the first n (headerColumnCount) properties of firstItem and secondItem are equal
    if (firstItem === undefined || secondItem === undefined) {
        return false;
    }
    // Get the keys of both objects
    const firstItemKeys = Object.keys(firstItem);
    const secondItemKeys = Object.keys(secondItem);
    // Compare the first 'headerColumnCount' properties
    for (let i = 0; i < headerColumnCount; i++) {
        if (firstItem[firstItemKeys[i]] !== secondItem[secondItemKeys[i]]) {
            return false;
        }
    }
    return true;
};

const CsvDataTable = ({
    csvRows,
    headerColumnCount = 0,
    isSimplified = false,
    visibleColumns = undefined,
}) => {
    const strippedCsvRows = useMemo(() => {
        if (!visibleColumns) {
            return csvRows;
        }
        return csvRows.map((x) => {
            const strippedCsvRow = {};
            visibleColumns.forEach((y) => {
                if (y in x) {
                    strippedCsvRow[y] = x[y];
                }
            });
            return strippedCsvRow;
        });
    }, [csvRows, visibleColumns]);

    return (
        // const totalColumnsWidth = columns.reduce((xAccumulator, x) => xAccumulator + x.width, 0);
        // const getCellValueSafely = (column, row) => {
        //     try {
        //         return column.getValue(row);
        //     } catch (error) {
        //         return "";
        //     }
        // };
        // color: "text.secondary",
        <Table size="small">
            {/* <colgroup>
                {columns.map((x) => (
                    <col key={x.fieldName} width={`${(x.width / totalColumnsWidth) * 100}%`} />
                ))}
            </colgroup> */}
            {/* {isShowHeadings && ( */}
            <TableHead>
                <TableRow>
                    {Object.keys(strippedCsvRows[0]).map((x) => (
                        <TableCell
                            key={x}
                            sx={{
                                fontWeight: "bold",
                                overflowX: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {x}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            {/* )} */}
            <TableBody>
                {strippedCsvRows.map((x, xIndex) => (
                    <TableRow key={xIndex}>
                        {Object.entries(x).map(([yKey, yValue], yIndex) => (
                            <TableCell
                                key={`${xIndex}.${yKey}`}
                                sx={{
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    ...(yIndex >= headerColumnCount
                                        ? undefined
                                        : {
                                              ...(!isSimplified ||
                                              !checkIsFieldSimplifiable(
                                                  strippedCsvRows[xIndex - 1],
                                                  x,
                                                  headerColumnCount,
                                              )
                                                  ? undefined
                                                  : { color: "text.secondary" }),
                                              fontWeight: "bold",
                                          }),
                                }}
                            >
                                {yValue}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
export { CsvDataTable };
