import React from "react";

import { Order } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { AutocompleteSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const HelperSelect = ({ fieldName, isEnabled = true, label }) => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const handleAutocompleteSelectListItemsAsync = async (listingModifiers) =>
        apiService.helpers.listAsync(selectedEvent.id, listingModifiers);

    return (
        <AutocompleteSelect
            baseQueryKey={["helpers", "list", selectedEvent.id]}
            fieldName={fieldName}
            fieldSorting={{
                path: "name",
                order: Order.ASCENDING,
            }}
            formatItem={(helper) =>
                `${helper.name === undefined ? "" : `${helper.name} (`}${helper.username}${
                    helper.name === undefined ? "" : ")"
                }`
            }
            getKeyFromItem={(helper) => helper.id}
            isEnabled={isEnabled}
            label={label}
            listItemsAsync={handleAutocompleteSelectListItemsAsync}
        />
    );
};

export { HelperSelect };
