import { DirectionsRun as DirectionsRunIcon, Security as SecurityIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import howToOpenInstructionsImageSource from "@/assets/how-to-open-instructions.webp";
import { config } from "@/config";
import { useAccountContext, useOpenInstructionsDrawerContext } from "@/contexts";

const OnboardingDialog = ({ close, isOpen }) => {
    const queryClient = useQueryClient();

    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.updateFlowAsync(variables.accountId, { isOnboarded: true }),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
            },
        },
    );

    const handleDataPrivacyOpenButtonClick = () => {
        window.open(config.urls.dataPrivacy, "_blank");
    };

    const handleStartButtonClick = () => {
        mutation.mutate({ accountId: account.id });
        openInstructionsDrawer("/vorbereitung/veranstaltung-erstellen");
        close();
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Willkommen bei Bujus!</DialogTitle>
            {/* <DialogClosingButton onClick={handleClosingButtonClick} /> */}
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        Wenn Sie Bujus vorher noch nicht verwendet haben, werfen Sie gerne einen
                        Blick in die Anleitung. Die Anleitung zeigt Ihnen, wie eine Veranstaltung
                        mit Bujus abläuft und wie alle Funktionen zu benutzen sind. Sie finden die
                        Anleitung auch immer unten links im Dashboard.
                    </Typography>
                    <br />
                    <Typography>
                        Um Bujus nach der DSGVO datenschutzkonform nutzen zu können, folgen Sie
                        bitte zusätzlich den Schritten auf der Webseite.
                    </Typography>
                </DialogContentText>
                <Box mt={2}>
                    <Card>
                        <img
                            alt=""
                            src={howToOpenInstructionsImageSource}
                            style={{
                                display: "block",
                                width: "100%",
                            }}
                        />
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleDataPrivacyOpenButtonClick}
                    startIcon={<SecurityIcon />}
                >
                    Datenschutz-Schritte öffnen
                </Button>
                <Button onClick={handleStartButtonClick} startIcon={<DirectionsRunIcon />}>
                    Los gehts!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { OnboardingDialog };
