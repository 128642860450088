const StatisticsType = {
    DISCIPLINE_CATEGORY: "DISCIPLINE_CATEGORY",
    DISCIPLINE: "DISCIPLINE",
    CERT: "CERT",
};

const formatStatisticsType = (statisticsType) => {
    switch (statisticsType) {
        case StatisticsType.DISCIPLINE_CATEGORY:
            return "Disziplins-Kategorie";
        case StatisticsType.DISCIPLINE:
            return "Disziplin";
        case StatisticsType.CERT:
            return "Urkunde";
        default:
            throw new Error(`Invalid statistics type: ${statisticsType}`);
    }
};

export { StatisticsType, formatStatisticsType };
