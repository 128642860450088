import React from "react";

import { Order, generateFullName } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { AutocompleteSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const ParticipantSelect = ({ fieldName, isEnabled = true, label }) => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const handleAutocompleteSelectListItemsAsync = async (listingModifiers) =>
        apiService.participants.listAsync(selectedEvent.id, listingModifiers);

    return (
        <AutocompleteSelect
            baseQueryKey={["participants", "list", selectedEvent.id]}
            fieldName={fieldName}
            fieldSorting={{
                path: "name.last",
                order: Order.ASCENDING,
            }}
            formatItem={(participant) =>
                `${generateFullName(participant.name.first, participant.name.last)}${
                    participant.schoolInternalId === undefined
                        ? ""
                        : ` (${participant.schoolInternalId})`
                }`
            }
            getKeyFromItem={(participant) => participant.id}
            isEnabled={isEnabled}
            label={label}
            listItemsAsync={handleAutocompleteSelectListItemsAsync}
        />
    );
};

export { ParticipantSelect };
