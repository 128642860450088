import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ThemeProvider,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, TextField } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { accountTheme } from "@/styles";

const AccountMailAddressChangeRequestDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            schoolValidationSchemas.mailAddressChangeRequest.body,
            validationOptions,
        ),
        defaultValues: { verifiableMailAddress: "" },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.requestMailAddressChangeAsync(
                variables.accountId,
                variables.verifiableMailAddress,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage(
                    "Account Email-Adressen Änderung angefragt",
                );
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            accountId: account.id,
            verifiableMailAddress: data.verifiableMailAddress,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <ThemeProvider theme={accountTheme}>
            <Dialog onClose={handleClose} open={isOpen}>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <DialogTitle>Account Email-Adresse ändern</DialogTitle>
                    <DialogClosingButton
                        isDisabled={mutation.isLoading}
                        onClick={handleClosingButtonClick}
                    />
                    <DialogContent>
                        <DialogContentText>
                            Um Ihre Email-Adresse zu ändern, schicken wir Ihnen eine Email an die
                            neue Email-Adresse, mit einem Link zur Bestätigung.
                        </DialogContentText>
                        <Box mt={2}>
                            <TextField
                                autoComplete="email"
                                fieldName="verifiableMailAddress"
                                isAutofocusing
                                isEnabled={!mutation.isLoading}
                                label="Neue Email-Adresse *"
                                type="email"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={mutation.isLoading}
                            startIcon={<EditIcon />}
                            type="submit"
                        >
                            Änderung anfragen
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Dialog>
        </ThemeProvider>
    );
};

export { AccountMailAddressChangeRequestDialog };
