import { Alert, AlertTitle, Box } from "@mui/material";
import React from "react";

import { Collapse } from "../collapse";

const TooLessCsvFileRowsRowsErrorAlert = ({ containerProps = undefined, realFileDatas }) => (
    <Collapse isOpen={realFileDatas.length === 0}>
        <Box {...containerProps}>
            <Alert severity="error">
                <AlertTitle>Nicht genügend Zeilen</AlertTitle>
                Die ausgewählte Datei enthält nicht genügend Zeilen. Es müssen mindestens zwei
                Zeilen vorhanden sein, wenn die erste Zeile Überschriften enthält.
            </Alert>
        </Box>
    </Collapse>
);

export { TooLessCsvFileRowsRowsErrorAlert };
