import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Form, LoginButton, TextField } from "@/base";
import {
    useAccountDashboardMailAddressStateContext,
    useAccountDashboardTitleStateContext,
} from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountPasswordForgottenPage = () => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [, setAccountDashboardTitle] = useAccountDashboardTitleStateContext();
    const [accountDashboardMailAddress, setAccountDashboardMailAddress] =
        useAccountDashboardMailAddressStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.passwordForgotten.body, validationOptions),
        defaultValues: { mailAddress: accountDashboardMailAddress },
    });

    const mailAddressFieldValue = useWatch({
        control: formController.control,
        name: "mailAddress",
    });

    const mutation = useMutation(
        async (variables) => apiService.schools.forgotPasswordAsync(variables.mailAddress),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
        },
    );

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({ mailAddress: data.mailAddress });
    };

    useEffect(() => {
        setAccountDashboardTitle("Passwort vergessen");
    }, []);

    useEffect(() => {
        setAccountDashboardMailAddress(mailAddressFieldValue);
    }, [mailAddressFieldValue]);

    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <Box>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            autoComplete="email"
                            fieldName="mailAddress"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Email-Adresse *"
                            type="email"
                        />
                        <LoadingButton
                            endIcon={<ArrowForwardIcon />}
                            fullWidth
                            loading={mutation.isLoading}
                            type="submit"
                            variant="contained"
                        >
                            Passwort vergessen
                        </LoadingButton>
                    </Box>
                </Form>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </Box>
        );
    }
    if (mutation.isSuccess) {
        return (
            <Box>
                <Typography align="center">
                    Bitte klicken Sie auf den Link, den wir an {mutation.data.mailAddress} geschickt
                    haben.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </Box>
        );
    }
    return undefined;
};

export { AccountPasswordForgottenPage };
