import { Alert, Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { DpaState, schoolAppRoutes } from "@bujus/common";

import { useAccountContext } from "@/contexts";

const checkIsDone = (account) =>
    account.dataPrivacy.dpa.state === DpaState.SIGNED &&
    account.dataPrivacy.isConsentFormCollected &&
    account.dataPrivacy.isDataPrivacyPolicyUpdated &&
    account.dataPrivacy.isGuideRead;

const DataPrivacyAlert = ({ containerProps }) => {
    const navigate = useNavigate();

    const account = useAccountContext();

    const handleDataPrivacyOpenButtonClick = () => {
        navigate(schoolAppRoutes(true).dashboard().account().build());
    };

    if (checkIsDone(account)) {
        return undefined;
    }
    return (
        <Box {...containerProps}>
            <Alert
                action={
                    <Button color="inherit" onClick={handleDataPrivacyOpenButtonClick} size="small">
                        Schritte anzeigen
                    </Button>
                }
                severity="warning"
                // variant="outlined"
            >
                Um Bujus nach der DSGVO datenschutzkonform nutzen zu können, folgen Sie den
                folgenden 4 Schritten.
            </Alert>
        </Box>
    );
};

export { DataPrivacyAlert };
