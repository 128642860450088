import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from "@mui/material";
import React from "react";

import { useDashboardSideBarContext, useDashboardTitleStateContext } from "@/contexts";
import { gradients } from "@/styles";

import {
    generateClosedSideBarWidthTransitionSx,
    generateOpenedSideBarWidthTransitionSx,
    openedSideBarWidth,
} from "../side-bar/side-bar";

import { TopBar__AccountMenuButton } from "./top-bar__account-menu-button";
import { TopBar__EventSelectionButton } from "./top-bar__event-selection-button";

const TopBar = () => {
    const theme = useTheme();

    const [dashboardTitle] = useDashboardTitleStateContext();
    const { isDashboardSideBarOpen, toggleDashboardSideBar } = useDashboardSideBarContext();

    const handleSideBarToggleButtonClick = () => {
        toggleDashboardSideBar();
    };

    return (
        <AppBar
            sx={{
                background: gradients.main,
                marginLeft: !isDashboardSideBarOpen ? 0 : openedSideBarWidth,
                width: `calc(100% - ${!isDashboardSideBarOpen ? 0 : openedSideBarWidth}px)`,
                ...(isDashboardSideBarOpen
                    ? generateOpenedSideBarWidthTransitionSx(theme)
                    : generateClosedSideBarWidthTransitionSx(theme)),
            }}
        >
            <Toolbar>
                <IconButton color="inherit" edge="start" onClick={handleSideBarToggleButtonClick}>
                    {!isDashboardSideBarOpen ? <MenuIcon /> : <ChevronLeftIcon />}
                </IconButton>
                <Typography flex={1} ml={2} variant="h5">
                    {dashboardTitle}
                </Typography>
                <TopBar__EventSelectionButton />
                <Box ml={1}>
                    <TopBar__AccountMenuButton />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export { TopBar };
