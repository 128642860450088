import { Close as CloseIcon } from "@mui/icons-material";
import {
    AppBar,
    Card,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash.isequal";
import React, { useReducer, useState } from "react";

import {
    CertSvg,
    eventFormatToCertTypeToExampleCertMap,
    eventFormatToExampleEventMap,
    formatCertType,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import paperTextureImage from "@/assets/paper-texture.jpg";
import { ClosingConfirmationDialog, FullScreenDialogTransition } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { CertTemplateUpdateDialog__PresetStepContent } from "./cert-template-update-dialog__preset-step-content";
import { CertTemplateUpdateDialog__SettingsStepContent } from "./cert-template-update-dialog__settings-step-content";
import { certTemplateUpdateDialog__StateMachine } from "./cert-template-update-dialog__state-machine";

const CertTemplateUpdateDialog = ({ close, certTemplateSet, certType, isOpen }) => {
    const theme = useTheme();

    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    // const [a, setA] = useState(0);

    const [liveCertTemplate, setLiveCertTemplate] = useState(
        certTemplateSet.certTypeToCertTemplateInfoMap[certType].certTemplate,
    );
    const [state, dispatchState] = useReducer(
        certTemplateUpdateDialog__StateMachine.reducer,
        certTemplateUpdateDialog__StateMachine.generateInitialState(
            certTemplateSet.certTypeToCertTemplateInfoMap[certType],
        ),
    );

    const closingConfirmationDialogDisplayer = useDialogDisplayer(ClosingConfirmationDialog);

    const mutation = useMutation(
        async (variables) =>
            apiService.certTemplateSets.updateAsync(
                variables.eventId,
                variables.certType,
                variables.presetId,
                variables.certTemplate,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage(
                    `${formatCertType(variables.certType)} - Vorlage geändert`,
                );
                queryClient.invalidateQueries(["certTemplateSets"]);
                close();
            },
        },
    );

    const displayStep = certTemplateUpdateDialog__StateMachine.convertStepToDisplayStep(state.step);

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        if (
            !isEqual(
                certTemplateSet.certTypeToCertTemplateInfoMap[certType].certTemplate,
                liveCertTemplate,
            )
        ) {
            closingConfirmationDialogDisplayer.open({
                onConfirmation: () => {
                    close();
                },
            });
            return undefined;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handlePresetSelectionNext = (data) => {
        dispatchState({
            type: "NEXT",
            formData: data,
        });
    };

    const handleSettingsPrevious = () => {
        dispatchState({ type: "PREVIOUS" });
    };

    const handleSettingsUpdate = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            certType: state.formDatas.presetSelection.preset.certType,
            presetId: state.formDatas.presetSelection.preset.id,
            certTemplate: data.certTemplate,
        });
    };

    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                open={isOpen}
                TransitionComponent={FullScreenDialogTransition}
            >
                <AppBar position="relative" sx={{ zIndex: 1 }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            disabled={mutation.isLoading}
                            edge="start"
                            onClick={handleClosingButtonClick}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography ml={2} variant="h5">
                            {formatCertType(state.formDatas.presetSelection.preset.certType)} -
                            Vorlage bearbeiten
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={6}>
                            <Card
                                sx={{
                                    position: "sticky",
                                    top: 0,
                                    userSelect: "none",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "1fr",
                                        gridTemplateRows: "Auto",
                                    }}
                                >
                                    <Box
                                        gridColumn={1}
                                        gridRow={1}
                                        sx={{
                                            backgroundImage: `url(${paperTextureImage})`,
                                            backgroundSize: "cover",
                                            opacity: 0.5,
                                        }}
                                    />
                                    <Box gridColumn={1} gridRow={1} zIndex={1}>
                                        <CertSvg
                                            backgroundImageUrl={paperTextureImage}
                                            // cert={(() => {
                                            //     const cert = getExampleCert(
                                            //         state.formDatas.presetSelection.preset.certType
                                            //     );
                                            //     cert.participant.name.first = new Array(a / 2).join(
                                            //         "M"
                                            //     );
                                            //     cert.participant.name.last = new Array(a / 2).join("M");
                                            //     return cert;
                                            // })()}
                                            cert={
                                                eventFormatToCertTypeToExampleCertMap[
                                                    selectedEvent.format
                                                ][state.formDatas.presetSelection.preset.certType]
                                            }
                                            certTemplateSet={{
                                                ...certTemplateSet,
                                                certTypeToCertTemplateInfoMap: {
                                                    [certType]: { certTemplate: liveCertTemplate },
                                                },
                                            }}
                                            certType={certType}
                                            event={
                                                eventFormatToExampleEventMap[selectedEvent.format]
                                            }
                                            isShowAlignmentGuide
                                        />
                                        {/* <p onClick={() => setA(a + 2)}>asdf</p> */}
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Box maxWidth={theme.breakpoints.values.md}>
                                <Stepper activeStep={displayStep} orientation="vertical">
                                    <Step>
                                        <StepLabel>Urkundenpapier auwählen</StepLabel>
                                        <StepContent>
                                            <CertTemplateUpdateDialog__PresetStepContent
                                                defaultFormData={state.formDatas.presetSelection}
                                                onLiveChange={setLiveCertTemplate}
                                                onNext={handlePresetSelectionNext}
                                            />
                                        </StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>Einstellen</StepLabel>
                                        <StepContent>
                                            <CertTemplateUpdateDialog__SettingsStepContent
                                                defaultFormData={state.formDatas.settings}
                                                mutation={mutation}
                                                onLiveChange={setLiveCertTemplate}
                                                onPrevious={handleSettingsPrevious}
                                                onUpdate={handleSettingsUpdate}
                                                preset={state.formDatas.presetSelection.preset}
                                            />
                                        </StepContent>
                                    </Step>
                                </Stepper>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {closingConfirmationDialogDisplayer.element}
        </>
    );
};

export { CertTemplateUpdateDialog };
