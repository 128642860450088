import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FileDownload as FileDownloadIcon,
    InfoOutlined as InfoOutlinedIcon,
    MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";

import {
    AppErrorType,
    EventFormat,
    Order,
    checkIsEventActive,
    checkIsMovingDisciplineCategory,
    formatAppErrorType,
    formatClassLevelRange,
    formatDate,
    formatMeasuringType,
    formatResultValue,
    getDiscipline,
    getDisciplineCategoryOfDiscipline,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { ButtonHotkeyIcon, DataMaster, DoubleIconButton } from "@/base";
import {
    useDashboardTitleStateContext,
    useOpenInstructionsDrawerContext,
    useSelectedEventStateContext,
} from "@/contexts";
import { useQueryParams } from "@/hooks";

import { AllResultsExportingDialog } from "./all-results-exporting-dialog";
import { BigResultCreationDialog } from "./big-result-creation-dialog/big-result-creation-dialog";
import { ManyResultsRemovalDialog } from "./many-results-removal-dialog";
import { ResultCreationDialog } from "./result-creation-dialog";
import { ResultRemovalDialog } from "./result-removal-dialog";
import { ResultUpdateDialog } from "./result-update-dialog";

const RightDataMasterToolbarComponents = ({
    getKeyFromItem,
    onAllExportingMenuItemClick,
    onBigCreationButtonClick,
    onCreationButtonClick,
    onManyRemovalButtonClick,
    onRemovalButtonClick,
    onUpdateButtonClick,
    query,
    selectedItems,
}) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuAnchorElementRef = useRef();

    const handleUpdateButtonClick = (result) => {
        onUpdateButtonClick(result);
    };

    const handleRemovalButtonClick = (result) => {
        onRemovalButtonClick(result);
    };

    const handleManyRemovalButtonClick = (results) => {
        onManyRemovalButtonClick(results);
    };

    const handleCreationButtonClick = () => {
        onCreationButtonClick();
    };

    const handleBigCreationButtonClick = () => {
        onBigCreationButtonClick();
    };

    const handleMenuOpenButtonClick = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleAllExportingMenuItemClick = () => {
        onAllExportingMenuItemClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/durchführung/ergebnisse-eintragen");
    };

    return (
        <>
            {selectedItems.length === 1 && (
                <>
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>E</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleUpdateButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<EditIcon />}
                    />
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleRemovalButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<DeleteIcon />}
                    />
                </>
            )}
            {selectedItems.length > 1 && (
                <Button
                    disabled={query.isFetching}
                    endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                    onClick={() =>
                        handleManyRemovalButtonClick(
                            selectedItems.map((x) =>
                                query.data.items.find((y) => getKeyFromItem(y) === x),
                            ),
                        )
                    }
                    startIcon={<DeleteIcon />}
                >
                    {selectedItems.length} entfernen
                </Button>
            )}
            {selectedItems.length > 0 && <Divider flexItem orientation="vertical" />}
            <Tooltip
                title={(() => {
                    if (!checkIsEventActive(selectedEvent)) {
                        return formatAppErrorType(AppErrorType.INVALID.EVENT_IS_IN_ACTIVE);
                    }
                    if (
                        !query.isFetching &&
                        query.data !== undefined &&
                        query.data.count >= selectedEvent.limits.result
                    ) {
                        return formatAppErrorType(AppErrorType.INVALID.RESULT_LIMIT_REACHED);
                    }
                    return "";
                })()}
            >
                <Box>
                    <Button
                        disabled={
                            !checkIsEventActive(selectedEvent) ||
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.result
                        }
                        endIcon={<ButtonHotkeyIcon>N</ButtonHotkeyIcon>}
                        onClick={handleCreationButtonClick}
                        startIcon={<AddIcon />}
                    >
                        Eintragen
                    </Button>
                </Box>
            </Tooltip>
            <Tooltip
                title={(() => {
                    if (!checkIsEventActive(selectedEvent)) {
                        return formatAppErrorType(AppErrorType.INVALID.EVENT_IS_IN_ACTIVE);
                    }
                    if (
                        !query.isFetching &&
                        query.data !== undefined &&
                        query.data.count >= selectedEvent.limits.result
                    ) {
                        return formatAppErrorType(AppErrorType.INVALID.RESULT_LIMIT_REACHED);
                    }
                    return "";
                })()}
            >
                <Box>
                    <Button
                        disabled={
                            !checkIsEventActive(selectedEvent) ||
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.result
                        }
                        onClick={handleBigCreationButtonClick}
                        startIcon={<AddIcon />}
                    >
                        Riegenweise eintragen
                    </Button>
                </Box>
            </Tooltip>
            <IconButton ref={menuAnchorElementRef} onClick={handleMenuOpenButtonClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorElementRef.current}
                onClose={handleMenuClose}
                open={isMenuOpen}
            >
                <MenuItem
                    disabled={
                        query.isFetching || query.data === undefined || query.data.count === 0
                    }
                    onClick={handleAllExportingMenuItemClick}
                >
                    <ListItemIcon>
                        <FileDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>Exportieren</ListItemText>
                </MenuItem>
            </Menu>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlinedIcon />
            </IconButton>
        </>
    );
};

const ResultsPage = () => {
    // TODO Remove query params
    const queryParams = useQueryParams();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const creationDialogDisplayer = useDialogDisplayer(ResultCreationDialog);
    const bigCreationDialogDisplayer = useDialogDisplayer(BigResultCreationDialog);
    const allExportingDialogDisplayer = useDialogDisplayer(AllResultsExportingDialog);
    const updateDialogDisplayer = useDialogDisplayer(ResultUpdateDialog);
    const removalDialogDisplayer = useDialogDisplayer(ResultRemovalDialog);
    const manyRemovalDialogDisplayer = useDialogDisplayer(ManyResultsRemovalDialog);
    const dialogDisplayers = [
        creationDialogDisplayer,
        bigCreationDialogDisplayer,
        allExportingDialogDisplayer,
        updateDialogDisplayer,
        removalDialogDisplayer,
        manyRemovalDialogDisplayer,
    ];

    const handleDataMasterUpdateButtonClick = (result) => {
        updateDialogDisplayer.open({
            result,
            removalDialogDisplayer,
        });
    };

    const handleDataMasterRemovalButtonClick = (result) => {
        removalDialogDisplayer.open({ result });
    };

    const dataMasterColumns = [
        {
            field: "participant.schoolInternalId",
            valueGetter: ({ row }) => row.participant.schoolInternalId,
            type: "string",
            headerName: "T Schulinterne-ID",
            width: 150,
        },
        {
            field: "participant.name.first",
            valueGetter: ({ row }) => row.participant.name.first,
            type: "string",
            headerName: "Teilnehmer Vorname",
            width: 200,
        },
        {
            field: "participant.name.last",
            valueGetter: ({ row }) => row.participant.name.last,
            type: "string",
            headerName: "Teilnehmer Nachname",
            width: 200,
        },
        {
            field: "participant.class",
            valueGetter: ({ row }) => row.participant.class,
            type: "string",
            headerName: "T Klasse",
            width: 100,
        },
        {
            field: "participant.group.name",
            valueGetter: ({ row }) =>
                row.participant.group === undefined ? undefined : row.participant.group.name,
            type: "string",
            headerName: "T Riege",
            width: 100,
        },
        ...(selectedEvent.format !== EventFormat.CONTEST
            ? []
            : [
                  {
                      field: "participant.group.classLevelRange",
                      valueGetter: ({ row }) =>
                          row.participant.group === undefined
                              ? undefined
                              : formatClassLevelRange(row.participant.group.classLevelRange),
                      type: "string",
                      headerName: "T Klassenstufe",
                      width: 125,
                  },
              ]),
        {
            field: "discipline.name",
            valueGetter: ({ row }) => getDiscipline(selectedEvent.format, row.disciplineId).name,
            type: "string",
            headerName: "Disziplin",
            width: selectedEvent.format === EventFormat.CONTEST ? 300 : 150,
        },
        {
            field: "value",
            type: "number",
            renderCell: ({ row }) =>
                formatResultValue(
                    selectedEvent.format,
                    row.disciplineId,
                    row.isFailedAttempt,
                    row.value,
                    { isShortUnit: true },
                ),
            headerName: "Wert",
            width: 125,
        },
        {
            field: "helper.name",
            valueGetter: ({ row }) => row.helper?.name,
            type: "string",
            headerName: "Helfer Name",
            width: 200,
        },
        {
            field: "helper.username",
            valueGetter: ({ row }) => row.helper?.username,
            type: "string",
            headerName: "Helfer Benutzername",
            width: 150,
        },
        ...(selectedEvent.format !== EventFormat.COMPETITION
            ? []
            : [
                  {
                      field: "measuringType",
                      type: "string",
                      renderCell: ({ row }) =>
                          !checkIsMovingDisciplineCategory(
                              getDisciplineCategoryOfDiscipline(
                                  selectedEvent.format,
                                  row.disciplineId,
                              ).id,
                          )
                              ? undefined
                              : formatMeasuringType(row.measuringType),
                      headerName: "Messart",
                      width: 100,
                  },
              ]),
        {
            field: "inputted",
            type: "date",
            valueFormatter: ({ value }) => formatDate(value, "Pp"),
            headerName: "Eingetragen",
            width: 150,
        },
        {
            field: "actions",
            getActions: ({ row }) => [
                <Tooltip title="Bearbeiten (E)">
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleDataMasterUpdateButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Entfernen (DEL)">
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Entfernen"
                        onClick={() => handleDataMasterRemovalButtonClick(row)}
                    />
                </Tooltip>,
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 100,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.results.listOfEventAsync(selectedEvent.id, listingModifiers);

    const handleDataMasterCreationHotkeyFire = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterRemovalHotkeyFire = (results) => {
        if (results.length === 1) {
            removalDialogDisplayer.open({ result: results[0] });
        } else {
            manyRemovalDialogDisplayer.open({ results });
        }
    };

    const handleDataMasterUpdateHotkeyFire = (result) => {
        updateDialogDisplayer.open({
            result,
            removalDialogDisplayer,
        });
    };

    const handleAllExportingMenuItemClick = () => {
        allExportingDialogDisplayer.open();
    };

    const handleBigCreationButtonClick = () => {
        bigCreationDialogDisplayer.open();
    };

    const handleCreationButtonClick = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterManyRemovalButtonClick = (results) => {
        manyRemovalDialogDisplayer.open({ results });
    };

    useEffect(() => {
        setDashboardTitle("Ergebnisse");
    }, []);

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                    }}
                >
                    <DataMaster
                        baseQueryKey={["results", "list", selectedEvent.id]}
                        columns={dataMasterColumns}
                        defaultFieldSorting={{
                            path: "inputted",
                            order: Order.DESCENDING,
                        }}
                        defaultSearchValue={
                            queryParams.searchValue === undefined ? "" : queryParams.searchValue
                        }
                        dialogDisplayers={dialogDisplayers}
                        exampleSearchDescription="Helfer, Teilnehmer, Disziplin, Wert"
                        fullSearchDescription="Durchsucht Helfer (Name, Benutzername), Teilnehmer (Schulinterne-ID, Vorname, Nachname), Disziplin, Wert"
                        getKeyFromItem={(item) => item.id}
                        itemLimit={selectedEvent.limits.result}
                        listItemsAsync={handleDataMasterListItemsAsync}
                        noFilteredItemsMessage="Keine Ergebnisse gefunden."
                        noItemsMessage="Noch keine Ergebnisse eingetragen."
                        onCreationHotkeyFire={handleDataMasterCreationHotkeyFire}
                        onRemovalHotkeyFire={handleDataMasterRemovalHotkeyFire}
                        onUpdateHotkeyFire={handleDataMasterUpdateHotkeyFire}
                        RightToolbarComponents={RightDataMasterToolbarComponents}
                        rightToolbarComponentsProps={{
                            onAllExportingMenuItemClick: handleAllExportingMenuItemClick,
                            onBigCreationButtonClick: handleBigCreationButtonClick,
                            onCreationButtonClick: handleCreationButtonClick,
                            onManyRemovalButtonClick: handleDataMasterManyRemovalButtonClick,
                            onRemovalButtonClick: handleDataMasterRemovalButtonClick,
                            onUpdateButtonClick: handleDataMasterUpdateButtonClick,
                        }}
                    />
                </CardContent>
            </Card>
            {creationDialogDisplayer.element}
            {bigCreationDialogDisplayer.element}
            {allExportingDialogDisplayer.element}
            {updateDialogDisplayer.element}
            {removalDialogDisplayer.element}
            {manyRemovalDialogDisplayer.element}
        </>
    );
};

export { ResultsPage };
