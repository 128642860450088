import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon, ZoomIn as ZoomInIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Card, CardContent, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    CertTemplateElementPurpose,
    certTemplateSetValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { CardCollapse, Form } from "@/base";

import { CertTemplateUpdateDialog__ElementSettings } from "./cert-template-update-dialog__element-settings";
import { CertTemplateUpdateDialog__NumberInput } from "./cert-template-update-dialog__number-input";
import { CertTemplateUpdateDialog__PositionOffsetSettings } from "./cert-template-update-dialog__position-offset-settings";

const CertTemplateUpdateDialog__SettingsStepContent = ({
    defaultFormData,
    mutation,
    onLiveChange,
    onPrevious,
    onUpdate,
    preset,
}) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            certTemplateSetValidationSchemas.update.forms.settings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const certTemplateFieldValue = useWatch({
        control: formController.control,
        name: "certTemplate",
    });

    const handleFormSubmit = (data) => {
        onUpdate(data);
    };

    const handlePresetSelectionButtonClick = () => {
        if (mutation.isLoading) {
            return;
        }
        onPrevious();
    };

    useEffect(() => {
        onLiveChange(certTemplateFieldValue);
    }, [certTemplateFieldValue]);

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Allgemein</Typography>
                        <Box mt={2}>
                            <Typography display="inline">
                                <b>Urkundenpapier-Jahr:</b> {preset.year}
                            </Typography>
                            <Link href="#" ml={1} onClick={handlePresetSelectionButtonClick}>
                                Anderes Urkundenpapier auswählen
                            </Link>
                        </Box>
                        <Typography fontWeight="bold" mt={2}>
                            Alle Elemente
                        </Typography>
                        <Box mt={1}>
                            <CertTemplateUpdateDialog__NumberInput
                                fieldName="certTemplate.scale.value"
                                icon={<ZoomInIcon color="secondary" />}
                                isEnabled={!mutation.isLoading}
                                isFlipped={false}
                                isHorizontal={false}
                                max={certTemplateFieldValue.scale.max}
                                step={certTemplateFieldValue.scale.step}
                            />
                        </Box>
                        <Box mt={1}>
                            <CertTemplateUpdateDialog__PositionOffsetSettings
                                fieldName="certTemplate.positionOffset"
                                isEnabled={!mutation.isLoading}
                            />
                        </Box>
                    </CardContent>
                </Card>
                <CardCollapse isBigTitle title="Standard Elemente">
                    <Box display="flex" flexDirection="column" gap={2}>
                        {certTemplateFieldValue.elements.map((x, xIndex) => {
                            if (x.purpose !== CertTemplateElementPurpose.STANDARD) {
                                return undefined;
                            }
                            return (
                                <CertTemplateUpdateDialog__ElementSettings
                                    key={x.id}
                                    fieldName={`certTemplate.elements.${xIndex}`}
                                    isEnabled={!mutation.isLoading}
                                    isStandard
                                    label={x.name}
                                />
                            );
                        })}
                    </Box>
                </CardCollapse>
                <CardCollapse isBigTitle title="Weitere Elemente">
                    <Box display="flex" flexDirection="column" gap={2}>
                        {certTemplateFieldValue.elements.map((x, xIndex) => {
                            if (x.purpose !== CertTemplateElementPurpose.EXTRA) {
                                return undefined;
                            }
                            return (
                                <CertTemplateUpdateDialog__ElementSettings
                                    key={x.id}
                                    fieldName={`certTemplate.elements.${xIndex}`}
                                    isEnabled={!mutation.isLoading}
                                    isStandard={false}
                                    label={x.name}
                                />
                            );
                        })}
                    </Box>
                </CardCollapse>
            </Box>
            <Box display="flex" justifyContent="end" mt={2}>
                <LoadingButton loading={mutation.isLoading} startIcon={<EditIcon />} type="submit">
                    Ändern
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { CertTemplateUpdateDialog__SettingsStepContent };
