import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

import { DialogClosingButton } from "@/base";

const ErrorDialog = ({ close, description, isOpen, title }) => {
    const handleClose = () => {
        close();
    };

    const handleClosingButtonClick = () => {
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogClosingButton onClick={handleClosingButtonClick} />
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export { ErrorDialog };
