import { Box, ThemeProvider, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import { useDashboardTitleStateContext } from "@/contexts";
import { accountTheme } from "@/styles";

import { AccountPage__BasicsCard } from "./account-page__basics-card";
import { AccountPage__DataPrivacyCard } from "./account-page__data-privacy-card";
import { AccountPage__MailAddressCard } from "./account-page__mail-address-card";
import { AccountPage__PasswordCard } from "./account-page__password-card";
import { AccountPage__RemovalCard } from "./account-page__removal-card";

const AccountPage = () => {
    const theme = useTheme();

    const [, setDashboardTitle] = useDashboardTitleStateContext();

    useEffect(() => {
        setDashboardTitle("Account");
    }, []);

    return (
        <ThemeProvider theme={accountTheme}>
            <Box
                display="flex"
                flexDirection="column"
                gap={1}
                maxWidth={theme.breakpoints.values.sm}
            >
                <AccountPage__BasicsCard />
                <AccountPage__MailAddressCard />
                <AccountPage__PasswordCard />
                <AccountPage__DataPrivacyCard />
                <AccountPage__RemovalCard />
            </Box>
        </ThemeProvider>
    );
};

export { AccountPage };
