import { Close as CloseIcon } from "@mui/icons-material";
import {
    AppBar,
    Dialog,
    DialogContent,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Toolbar,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useReducer } from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { FullScreenDialogTransition } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { helpersImportDialogStateMachine } from "./helpers-import-dialog-state-machine";
import { HelpersImportDialog__DataSelectionStepContent } from "./helpers-import-dialog__data-selection-step-content";
import { HelpersImportDialog__FileSettingsStepContent } from "./helpers-import-dialog__file-settings-step-content";
import { HelpersImportDialog__FileUploadStepContent } from "./helpers-import-dialog__file-upload-step-content";
import { HelpersImportDialog__ReviewStepContent } from "./helpers-import-dialog__review-step-content";

const HelpersImportDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [state, dispatchState] = useReducer(
        helpersImportDialogStateMachine.reducer,
        helpersImportDialogStateMachine.initialState,
    );

    const mutation = useMutation(
        async (variables) =>
            apiService.helpers.importAsync(
                variables.eventId,
                variables.areNamesSpecified,
                variables.isGeneratingUsernames,
                variables.isGeneratingPasswords,
                variables.helpers,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Helfer importiert");
                queryClient.invalidateQueries(["helpers"]);
                dispatchState({ type: helpersImportDialogStateMachine.Action.NEXT });
            },
        },
    );

    const displayStep = helpersImportDialogStateMachine.convertStepToDisplayStep(state.step);

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleFileUploadStepContentNext = (data) => {
        dispatchState({
            type: helpersImportDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleFileSettingsStepContentNext = (data) => {
        dispatchState({
            type: helpersImportDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleFileSettingsStepContentPrevious = () => {
        dispatchState({ type: helpersImportDialogStateMachine.Action.PREVIOUS });
    };

    const handleDataSelectionStepContentImport = (data) => {
        mutation.mutate({
            eventId: selectedEvent.id,
            ...data,
        });
    };

    const handleDataSelectionStepContentPrevious = () => {
        dispatchState({ type: helpersImportDialogStateMachine.Action.PREVIOUS });
        // mutation.reset();
    };

    const handleReviewStepContentOk = () => {
        tryClose();
    };

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={isOpen}
            TransitionComponent={FullScreenDialogTransition}
        >
            <AppBar position="relative" sx={{ zIndex: 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        disabled={mutation.isLoading}
                        edge="start"
                        onClick={handleClosingButtonClick}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography ml={2} variant="h5">
                        Helfer importieren
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Stepper activeStep={displayStep} orientation="vertical">
                    <Step>
                        <StepLabel>CSV-Datei hochladen</StepLabel>
                        <StepContent>
                            <HelpersImportDialog__FileUploadStepContent
                                defaultFormData={state.formDatas.fileUpload}
                                onNext={handleFileUploadStepContentNext}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Datei einstellen</StepLabel>
                        <StepContent>
                            <HelpersImportDialog__FileSettingsStepContent
                                defaultFormData={state.formDatas.fileSettings}
                                fileDatas={state.formDatas.fileUpload.fileDatas}
                                onNext={handleFileSettingsStepContentNext}
                                onPrevious={handleFileSettingsStepContentPrevious}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Daten auswählen</StepLabel>
                        <StepContent>
                            <HelpersImportDialog__DataSelectionStepContent
                                defaultFormData={state.formDatas.dataSelection}
                                fileDatas={state.formDatas.fileUpload.fileDatas}
                                isFirstRowContainingHeadings={
                                    state.formDatas.fileSettings.isFirstRowContainingHeadings
                                }
                                mutation={mutation}
                                onImport={handleDataSelectionStepContentImport}
                                onPrevious={handleDataSelectionStepContentPrevious}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Import überprüfen</StepLabel>
                        <StepContent>
                            <HelpersImportDialog__ReviewStepContent
                                mutation={mutation}
                                onOk={handleReviewStepContentOk}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
            </DialogContent>
        </Dialog>
    );
};

export { HelpersImportDialog };
