import { useDroppable } from "@dnd-kit/core";
import { Add as AddIcon } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { SpecialGroupId } from "@bujus/common";

import { ButtonHotkeyIcon } from "@/base";

const AllGroupsUpdateDialog__CreationCard = ({ isDropzone, onRequest, selectedParticipantIds }) => {
    const { isOver, setNodeRef } = useDroppable({ id: SpecialGroupId.CREATION });

    useHotkeys("n", (event) => {
        event.preventDefault();
        onRequest();
    });

    const handleCardActionAreaClick = () => {
        onRequest();
    };

    return (
        <Card
            ref={setNodeRef}
            sx={{
                ...(isDropzone && { borderStyle: "dashed" }),
                borderWidth: 2,
                color: "primary.main",
                transition: "border-color 0.2s ease-in-out, transform 0.2s ease-in-out",
                ...(isOver
                    ? {
                          transform: "scale(0.95)",
                          borderColor: "primary.main",
                      }
                    : undefined),
            }}
        >
            <CardActionArea onClick={handleCardActionAreaClick}>
                <CardContent>
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                    >
                        <AddIcon />
                        <Typography mt={1}>RIEGE HINZUFÜGEN</Typography>{" "}
                        {selectedParticipantIds.length > 0 && (
                            <Typography color="secondary">
                                Mit {selectedParticipantIds.length} ausgewählten Teilnehmer
                                {selectedParticipantIds.length === 1 ? "" : "n"}
                            </Typography>
                        )}
                        <Box mt={1}>
                            <ButtonHotkeyIcon>N</ButtonHotkeyIcon>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export { AllGroupsUpdateDialog__CreationCard };
