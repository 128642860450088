import { joiResolver } from "@hookform/resolvers/joi";
import { Box, Link } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { participantValidationSchemas, validationOptions } from "@bujus/common";

import { CsvFileUpload, Form } from "@/base";
import { config } from "@/config";
import { useSelectedEventStateContext } from "@/contexts";

const ParticipantsImportDialog__FileUploadStepContent = ({ defaultFormData, onNext }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            participantValidationSchemas.import.forms.fileUpload,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const handleCsvFileUploadChange = () => {
        formController.handleSubmit(onNext)();
    };

    return (
        <Form controller={formController}>
            <Link
                href={config.urls.importHelpers.eventFormatToParticipantsMap[selectedEvent.format]}
                target="_blank"
            >
                Importhilfe herunterladen
            </Link>
            <Box mt={2}>
                <CsvFileUpload
                    fieldName="fileDatas"
                    label="CSV-Datei"
                    onChange={handleCsvFileUploadChange}
                />
            </Box>
        </Form>
    );
};

export { ParticipantsImportDialog__FileUploadStepContent };
