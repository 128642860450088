import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowForward } from "@mui/icons-material";
import { Alert, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    EventFormat,
    eventValidationSchemas,
    formatEventFormat,
    validationOptions,
} from "@bujus/common";

import { CardRadioSelect, Form } from "@/base";

const EventCreationDialog__TypeSettingsStepContent = ({ defaultFormData, onNext }) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            eventValidationSchemas.creation.forms.typeSelection,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const handleFormSubmit = (data) => {
        onNext(data);
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <CardRadioSelect
                    // eslint-disable-next-line react/no-unstable-nested-components
                    fieldName="format"
                    formatItem={(eventFormat) => {
                        switch (eventFormat) {
                            case EventFormat.CONTEST:
                                return (
                                    <>
                                        <Typography fontWeight="bold">
                                            {formatEventFormat(eventFormat)}
                                        </Typography>
                                        <Typography color="text.secondary" mt={2}>
                                            Kind- und entwicklungsgemäße Formen mit kreativen
                                            Ergänzungen und Neugestaltungen sollen den Kindern und
                                            Jugendlichen präsentiert werden - bewusst ohne Fixierung
                                            auf traditionelle Normen und Übungsformen.
                                        </Typography>
                                    </>
                                );
                            case EventFormat.COMPETITION:
                                return (
                                    <>
                                        <Typography fontWeight="bold">
                                            {formatEventFormat(eventFormat)}
                                        </Typography>
                                        <Typography color="text.secondary" mt={2}>
                                            Dieser Ausschreibungsteil ist grundsätzlich identisch
                                            mit den herkömmlichen Bundesjugendspielen, sowohl in den
                                            Ausübungsformen als auch in der Wertung.
                                        </Typography>
                                    </>
                                );
                            default:
                                throw new Error(`Invalid event format: ${eventFormat}`);
                        }
                    }}
                    items={Object.values(EventFormat)}
                    label="Format"
                />
                <CardRadioSelect
                    // eslint-disable-next-line react/no-unstable-nested-components
                    fieldName="isTest"
                    formatItem={(isTest) => {
                        if (isTest) {
                            return (
                                <>
                                    <Typography fontWeight="bold">Test-Veranstaltung</Typography>
                                    <Typography color="text.secondary" mt={2}>
                                        Mit einer Test-Veranstaltung können Sie alle Funktionen mit
                                        einer kleinen Anzahl an Helfern und Teilnehmern kostenlos
                                        ausprobieren.
                                    </Typography>
                                </>
                            );
                        }
                        return (
                            <>
                                <Typography fontWeight="bold">Echte-Veranstaltung</Typography>
                                <Typography color="text.secondary" mt={2}>
                                    Mit einer echten Veranstaltung können Sie eine Veranstaltung mit
                                    beliebig vielen Helfern und Teilnehmern durchführen.
                                </Typography>
                            </>
                        );
                    }}
                    items={[true, false]}
                    label="Typ"
                />
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button endIcon={<ArrowForward />} type="submit">
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { EventCreationDialog__TypeSettingsStepContent };
