import { Loader as GoogleMapsApiLoader } from "@googlemaps/js-api-loader";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    CircularProgress,
    TextField,
    Typography,
    debounce,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useController as useFieldController } from "react-hook-form";

import { transformToValidSearchValue } from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { config } from "@/config";

// Guide: https://mui.com/material-ui/react-autocomplete/#google-maps-place

const googleMapsApiLoader = new GoogleMapsApiLoader({
    apiKey: config.googleMaps.publicApiKey,
    version: "3.53", // Versions: https://developers.google.com/maps/documentation/javascript/versions#weekly-channel
    region: "de",
});

const AddressAutocompleteSelect = ({ fieldName, isEnabled = true, label }) => {
    const formController = useFormControllerContext();

    const [inputValue, setInputValue] = useState("");
    // The value that gets seached, often debounced
    const [searchValue, setSearchValue] = useState("");
    const setSearchValueDebounced = useMemo(() => debounce(setSearchValue, 750), []);
    const googleMapsAutocompleteService = useRef();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const validSearchValue = transformToValidSearchValue(searchValue);

    const query = useQuery(
        ["google-maps", validSearchValue],
        async ({ queryKey: key }) =>
            googleMapsAutocompleteService.current === undefined || validSearchValue === ""
                ? { predictions: [] }
                : googleMapsAutocompleteService.current.getPlacePredictions({ input: key[1] }),
        {
            keepPreviousData: true,
            onError: () => {
                setInputValue("");
            },
        },
    );

    const options = useMemo(() => {
        const list = [];
        if (fieldController.field.value !== null) {
            list.push(fieldController.field.value);
            if (query.isSuccess) {
                list.push(
                    ...query.data.predictions.filter(
                        (x) => x.description !== fieldController.field.value.description,
                    ),
                );
            }
        } else if (query.isSuccess) {
            list.push(...query.data.predictions);
        }
        return list;
    }, [fieldController.field.value, query.isSuccess, query.data]);

    const handleChange = (_event, option) => {
        fieldController.field.onChange(option);
    };

    const handleInputChange = async (_event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleOpen = () => {
        // query.refetch();
    };

    useEffect(() => {
        (async () => {
            const google = await googleMapsApiLoader.load();
            const { AutocompleteService } = await google.maps.importLibrary("places");
            googleMapsAutocompleteService.current = new AutocompleteService();
        })();
    }, []);

    useEffect(() => {
        setSearchValueDebounced(inputValue);
    }, [inputValue]);

    return (
        <Autocomplete
            autoHighlight
            autoSelect
            disabled={!isEnabled}
            filterOptions={(x) => x}
            getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
            includeInputInList
            inputValue={inputValue}
            isOptionEqualToValue={(option, value) => option.description === value.description}
            loading={query.isFetching}
            loadingText="Lade..."
            noOptionsText="Keine Optionen"
            onChange={handleChange}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            options={options}
            renderInput={(props) => (
                <TextField
                    {...props}
                    error={fieldController.fieldState.error !== undefined}
                    helperText={
                        fieldController.fieldState.error === undefined
                            ? undefined
                            : fieldController.fieldState.error.message
                    }
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <>
                                {query.isFetching && <CircularProgress size={20} />}
                                {props.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    label={label}
                />
            )}
            renderOption={(props, option) => {
                if (option.place_id === undefined) {
                    // This is the self constructed option for the currently selected option
                    return (
                        <Box {...props} key={option.description} component="li" display="flex">
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                            <Box ml={1}>
                                <Typography fontWeight="bold">{option.description}</Typography>
                            </Box>
                        </Box>
                    );
                }
                const matches = option.structured_formatting.main_text_matched_substrings || [];
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );
                return (
                    <Box {...props} key={option.place_id} component="li" display="flex">
                        <Box>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Box>
                        <Box ml={1}>
                            {parts.map((x, xIndex) => (
                                <Typography
                                    key={xIndex}
                                    display="inline"
                                    sx={{ fontWeight: x.highlight ? "bold" : "regular" }}
                                >
                                    {x.text}
                                </Typography>
                            ))}
                            <Typography color="text.secondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Box>
                    </Box>
                );
            }}
            value={fieldController.field.value}
        />
    );
};

export { AddressAutocompleteSelect };
