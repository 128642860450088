import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";

import { useApiServiceContext, useLogoutLocalContext } from "@bujus/common-frontend";

import { CenterLayout, HorizontalCenterCircularProgress } from "@/base";

const AccountLogoutPage = () => {
    const logoutLocal = useLogoutLocalContext();
    const apiService = useApiServiceContext();

    const mutation = useMutation(async () => apiService.schools.logoutAsync(), {
        onSettled: () => {
            logoutLocal();
        },
    });

    useEffect(() => {
        mutation.mutate();
    }, []);

    if (mutation.isIdle || mutation.isLoading) {
        return (
            <CenterLayout isVerticalCenter title="Abmeldung">
                <HorizontalCenterCircularProgress />
            </CenterLayout>
        );
    }
    return undefined;
};

export { AccountLogoutPage };
