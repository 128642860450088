import { Box, Card, CardContent, FormLabel, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

import {
    ClassLevelRange,
    eventFormatToDisciplineCategoriesMap,
    formatClassLevelRange,
} from "@bujus/common";

import { RadioSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const DisciplinePreselectionPage__ContestEventView = ({ FormActions, mutation }) => {
    const theme = useTheme();

    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <Box display="flex" flex={1} flexDirection="column" maxWidth={theme.breakpoints.values.md}>
            <Card
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <Box flex={1} overflow="auto" position="relative">
                    <Box
                        bottom={0}
                        display="flex"
                        flexDirection="column"
                        left={0}
                        position="absolute"
                        right={0}
                        top={0}
                    >
                        <CardContent>
                            <Box display="flex" flexDirection="column" gap={2}>
                                {Object.values(ClassLevelRange).map((x) => (
                                    <Card key={x}>
                                        <CardContent>
                                            <Box display="flex">
                                                <Box flex="none">
                                                    <FormLabel>Klassenstufe</FormLabel>
                                                    <Typography variant="h6">
                                                        {formatClassLevelRange(x)}
                                                    </Typography>
                                                </Box>
                                                <Grid container flex={1} ml={2} spacing={2}>
                                                    {Object.values(
                                                        eventFormatToDisciplineCategoriesMap[
                                                            selectedEvent.format
                                                        ],
                                                    ).map((y) => (
                                                        <Grid key={y.id} item xs={6}>
                                                            <RadioSelect
                                                                fieldName={`value.${x}.${y.id}`}
                                                                formatItem={(item) => item.name}
                                                                getKeyFromItem={(item) => item.id}
                                                                isEnabled={!mutation.isLoading}
                                                                items={y.disciplines.filter(
                                                                    (z) =>
                                                                        z.allowedClassLevelRange ===
                                                                        x,
                                                                )}
                                                                label={y.name}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Box>
                        </CardContent>
                    </Box>
                </Box>
                {FormActions}
            </Card>
        </Box>
    );
};

export { DisciplinePreselectionPage__ContestEventView };
