import { Add as AddIcon, DateRange as DateRangeIcon } from "@mui/icons-material";
import {
    Avatar,
    Dialog,
    DialogTitle,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { accountTheme } from "@/styles";

import { EventSelectionDialog__List } from "./event-selection-dialog__list";

const EventSelectionDialog = ({ close, eventCreationDialogDisplayer, isOpen }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        close();
    };

    const handleAllEventsListItemButtonClick = () => {
        close();
        navigate(schoolAppRoutes(true).dashboard().events().build());
    };

    const handleCreateNewListItemButtonClick = () => {
        close();
        eventCreationDialogDisplayer.open();
    };

    return (
        <>
            <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
                <DialogTitle>Veranstaltung auswählen</DialogTitle>
                <List>
                    <EventSelectionDialog__List closeDialog={close} />
                    <ThemeProvider theme={accountTheme}>
                        <ListSubheader>Weiteres</ListSubheader>
                        <ListItemButton onClick={handleAllEventsListItemButtonClick}>
                            <ListItemAvatar>
                                <Avatar>
                                    <DateRangeIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>Alle anzeigen</ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={handleCreateNewListItemButtonClick}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>Neue erstellen</ListItemText>
                        </ListItemButton>
                    </ThemeProvider>
                </List>
            </Dialog>
            {eventCreationDialogDisplayer.element}
        </>
    );
};

export { EventSelectionDialog };
