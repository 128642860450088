import { TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useController as useFieldController } from "react-hook-form";
import { Rifm } from "rifm";

import { useFormControllerContext } from "@bujus/common-frontend";

// TODO when we reset the form this shows validation error instantly
const MaskedInput = ({ fieldName, InputProps, isEnabled = true, label, mask }) => {
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    useEffect(() => {
        // Revalidate value when mask changes, also reformat with the mask, because the current value could be invalid for the mask. This does not happen on discipline change, because the mask does not change on every discipline change.
        formController.setValue(fieldName, mask.format(fieldController.field.value), {
            shouldValidate: !fieldController.field.value.includes("_"),
        });
    }, [mask]);

    return (
        <Rifm
            format={mask.format}
            mask
            onChange={fieldController.field.onChange}
            replace={mask.replace}
            value={fieldController.field.value}
        >
            {({ onChange, value }) => (
                <TextField
                    disabled={!isEnabled}
                    error={fieldController.fieldState.error !== undefined}
                    fullWidth
                    helperText={
                        fieldController.fieldState.error === undefined
                            ? undefined
                            : fieldController.fieldState.error.message
                    }
                    InputProps={InputProps}
                    label={label}
                    onChange={onChange}
                    value={value}
                />
            )}
        </Rifm>
    );
};

export { MaskedInput };
