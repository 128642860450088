import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useSnackbar as useNotistackSnackbar } from "notistack";
import React from "react";

const SnackbarCloseButton = ({ key }) => {
    const { closeSnackbar } = useNotistackSnackbar();

    const handleClick = () => {
        closeSnackbar(key);
    };

    return (
        <IconButton color="inherit" onClick={handleClick} size="small">
            <CloseIcon fontSize="small" />
        </IconButton>
    );
};

export { SnackbarCloseButton };
