import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ThemeProvider,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, PasswordField } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { accountTheme } from "@/styles";

const AccountRemovalRequestDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.removalRequest.body, validationOptions),
        defaultValues: { password: "" },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.requestRemovalAsync(variables.accountId, variables.password),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account Löschung angefragt");
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            accountId: account.id,
            password: data.password,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <ThemeProvider theme={accountTheme}>
            <Dialog onClose={handleClose} open={isOpen}>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <DialogTitle>Account löschen</DialogTitle>
                    <DialogClosingButton
                        isDisabled={mutation.isLoading}
                        onClick={handleClosingButtonClick}
                    />
                    <DialogContent>
                        <DialogContentText>
                            Um Ihren Account zu löschen, schicken wir Ihnen eine Email, mit einem
                            Link zur Löschung.
                        </DialogContentText>
                        <Box mt={2}>
                            <PasswordField
                                autoComplete="current-password"
                                fieldName="password"
                                isAutofocusing
                                isEnabled={!mutation.isLoading}
                                label="Passwort *"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={mutation.isLoading}
                            startIcon={<DeleteIcon />}
                            type="submit"
                        >
                            Löschung anfragen
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Dialog>
        </ThemeProvider>
    );
};

export { AccountRemovalRequestDialog };
