import { Box, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useReducer } from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { LoginButton } from "@/base";
import {
    useAccountDashboardMailAddressStateContext,
    useAccountDashboardTitleStateContext,
} from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { AccountSignUpPage__AccountSettingsStepContent } from "./account-sign-up-page__account-settings-step-content";
import { AccountSignUpPage__AddressSettingsStepContent } from "./account-sign-up-page__address-settings-step-content";
import { AccountSignUpPage__SchoolSettingsStepContent } from "./account-sign-up-page__school-settings-step-content";
import { accountSignUpPage__StateMachine } from "./account-sign-up-page__state-machine";

const AccountSignUpPage = () => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [, setAccountDashboardTitle] = useAccountDashboardTitleStateContext();
    const [accountDashboardMailAddress] = useAccountDashboardMailAddressStateContext();

    const [state, dispatchState] = useReducer(
        accountSignUpPage__StateMachine.reducer,
        accountSignUpPage__StateMachine.generateInitialState(accountDashboardMailAddress),
    );

    const mutation = useMutation(
        async (variables) => apiService.schools.signUpAsync(variables.school),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
        },
    );

    const displayStep = accountSignUpPage__StateMachine.convertStepToDisplayStep(state.step);

    const handleAccountSettingsNext = (data) => {
        dispatchState({
            type: accountSignUpPage__StateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleSchoolSettingsNext = (data) => {
        dispatchState({
            type: accountSignUpPage__StateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleSchoolSettingsPrevious = () => {
        dispatchState({ type: accountSignUpPage__StateMachine.Action.PREVIOUS });
    };

    const handleAddressSettingsCreation = (data) => {
        mutation.mutate({
            school: {
                name: state.formDatas.schoolSettings.name,
                password: state.formDatas.accountSettings.password,
                type: state.formDatas.schoolSettings.type,
                address: {
                    street: data.street,
                    houseNumber: data.houseNumber,
                    postalCode: data.postalCode,
                    city: data.city,
                    state: data.state,
                },
                verifiableMailAddress: state.formDatas.accountSettings.verifiableMailAddress,
            },
        });
    };

    const handleAddressSettingsPrevious = () => {
        dispatchState({ type: accountSignUpPage__StateMachine.Action.PREVIOUS });
    };

    useEffect(() => {
        setAccountDashboardTitle("Account erstellen");
    }, []);

    // useEffect(() => {
    //     setAccountDashboardMailAddress(formVerifiableMailAddress);
    // }, [formVerifiableMailAddress]);

    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <>
                <Stepper activeStep={displayStep} orientation="vertical">
                    <Step>
                        <StepLabel>Account</StepLabel>
                        <StepContent>
                            <AccountSignUpPage__AccountSettingsStepContent
                                defaultFormData={state.formDatas.accountSettings}
                                onNext={handleAccountSettingsNext}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Schule</StepLabel>
                        <StepContent>
                            <AccountSignUpPage__SchoolSettingsStepContent
                                defaultFormData={state.formDatas.schoolSettings}
                                onNext={handleSchoolSettingsNext}
                                onPrevious={handleSchoolSettingsPrevious}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Adresse</StepLabel>
                        <StepContent>
                            <AccountSignUpPage__AddressSettingsStepContent
                                defaultFormData={state.formDatas.addressSettings}
                                mutation={mutation}
                                onCreation={handleAddressSettingsCreation}
                                onPrevious={handleAddressSettingsPrevious}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </>
        );
    }
    if (mutation.isSuccess) {
        return (
            <>
                <Typography align="center" cyId="AccountSignUpPage__success-text">
                    Bitte bestätigen Sie Ihre Email-Adresse, indem Sie auf den Link klicken, den wir
                    an {mutation.data.verifiableMailAddress} geschickt haben.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    return undefined;
};

export { AccountSignUpPage };
