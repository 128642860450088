import { Box, InputAdornment, ToggleButton } from "@mui/material";
import React, { useEffect } from "react";
import { useController as useFieldController } from "react-hook-form";

import {
    Unit,
    checkIsResultValueInputValueEmpty,
    formatUnit,
    unitToResultValueMaskMap,
} from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { MaskedInput } from "@/base";

const ResultValueInput = ({
    discipline = undefined,
    fieldName,
    isEnabled = true,
    isFailedAttemptFieldName,
    label,
}) => {
    const formController = useFormControllerContext();

    const isFailedAttemptFieldController = useFieldController({
        control: formController.control,
        name: isFailedAttemptFieldName,
    });
    const valueFieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const handleIsFailedAttemptToggleButtonChange = () => {
        isFailedAttemptFieldController.field.onChange(!isFailedAttemptFieldController.field.value);
    };

    useEffect(() => {
        // Revalidate value when discipline changes
        // Also revalidate when isFailedAttempt changes, because then then we do not care about the value anymore
        // But do not revalidate if the value is empty, because otherwise we would see error on form mounting
        if (
            checkIsResultValueInputValueEmpty(valueFieldController.field.value) &&
            valueFieldController.fieldState.error === undefined
        ) {
            return;
        }
        formController.trigger("value");
    }, [discipline, isFailedAttemptFieldController.field.value]);

    return (
        <Box display="flex">
            <Box flex={1}>
                <MaskedInput
                    fieldName={fieldName}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* eslint-disable-next-line no-nested-ternary */}
                                {discipline === undefined ? "-" : formatUnit(discipline.inputUnit)}
                            </InputAdornment>
                        ),
                    }}
                    isEnabled={isEnabled && !isFailedAttemptFieldController.field.value}
                    label={label}
                    mask={
                        discipline === undefined
                            ? unitToResultValueMaskMap[Unit.SECONDS]
                            : unitToResultValueMaskMap[discipline.inputUnit]
                    }
                />
            </Box>
            <Box display="flex" flexDirection="column" height={56} justifyContent="center" ml={1}>
                <ToggleButton
                    color="primary"
                    onChange={handleIsFailedAttemptToggleButtonChange}
                    selected={isFailedAttemptFieldController.field.value}
                    sx={{ height: "100%" }}
                    value="check" // HACK Required by MUI
                >
                    Fehlversuch
                </ToggleButton>
            </Box>
        </Box>
    );
};
export { ResultValueInput };
