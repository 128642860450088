import { useQueryClient } from "@tanstack/react-query";

const useResetDataQueries = () => {
    const queryClient = useQueryClient();

    return () => {
        queryClient.resetQueries(["events"]);
        queryClient.resetQueries(["helpers"]);
        queryClient.resetQueries(["participants"]);
        queryClient.resetQueries(["results"]);
        queryClient.resetQueries(["certTemplateSets"]);
        queryClient.resetQueries(["certs"]);
    };
};

export { useResetDataQueries };
