import {
    Add as AddIcon,
    Check as CheckIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FileUpload as FileUploadIcon,
    FormatListNumbered as FormatListNumberedIcon,
    InfoOutlined,
    MoreVert as MoreVertIcon,
    Print as PrintIcon,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppErrorType, Order, formatAppErrorType, schoolAppRoutes } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { ButtonHotkeyIcon, DataMaster, DataMaster__PasswordCell, DoubleIconButton } from "@/base";
import {
    useDashboardTitleStateContext,
    useOpenInstructionsDrawerContext,
    useSelectedEventStateContext,
} from "@/contexts";

import { HelperCreationDialog } from "./helper-creation-dialog";
import { HelperRemovalDialog } from "./helper-removal-dialog";
import { HelperUpdateDialog } from "./helper-update-dialog";
import { HelpersImportDialog } from "./helpers-import-dialog";
import { IsActiveOfAllHelpersUpdateDialog } from "./is-active-of-all-helpers-update-dialog";
import { LoginDataOfAllHelpersPrintingDialog } from "./login-data-of-all-helpers-printing-dialog";
import { ManyHelpersRemovalDialog } from "./many-helpers-removal-dialog";

const RightDataMasterToolbarComponents = ({
    getKeyFromItem,
    onCreationButtonClick,
    onImportButtonClick,
    onIsActiveOfAllUpdateButtonClick,
    onLoginDataOfAllPrintingMenuItemClick,
    onManyRemovalButtonClick,
    onRemovalButtonClick,
    onUpdateButtonClick,
    query,
    selectedItems,
}) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuAnchorElementRef = useRef();

    const handleUpdateButtonClick = (helper) => {
        onUpdateButtonClick(helper);
    };

    const handleRemovalButtonClick = (helper) => {
        onRemovalButtonClick(helper);
    };

    const handleManyRemovalButtonClick = (helpers) => {
        onManyRemovalButtonClick(helpers);
    };

    const handleCreationButtonClick = () => {
        onCreationButtonClick();
    };

    const handleImportButtonClick = () => {
        onImportButtonClick();
    };

    const handleIsActiveOfAllUpdateButtonClick = () => {
        onIsActiveOfAllUpdateButtonClick();
    };

    const handleMenuOpenButtonClick = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleLoginDataOfAllPrintingMenuItemClick = () => {
        onLoginDataOfAllPrintingMenuItemClick();
    };

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/vorbereitung/helfer-hinzufügen");
    };

    return (
        <>
            {selectedItems.length === 1 && (
                <>
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>E</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleUpdateButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<EditIcon />}
                    />
                    <DoubleIconButton
                        disabled={query.isFetching}
                        endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                        onClick={() =>
                            handleRemovalButtonClick(
                                query.data.items.find(
                                    (x) => getKeyFromItem(x) === selectedItems[0],
                                ),
                            )
                        }
                        startIcon={<DeleteIcon />}
                    />
                </>
            )}
            {selectedItems.length > 1 && (
                <Button
                    disabled={query.isFetching}
                    endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                    onClick={() =>
                        handleManyRemovalButtonClick(
                            selectedItems.map((x) =>
                                query.data.items.find((y) => getKeyFromItem(y) === x),
                            ),
                        )
                    }
                    startIcon={<DeleteIcon />}
                >
                    {selectedItems.length} entfernen
                </Button>
            )}
            {selectedItems.length > 0 && <Divider flexItem orientation="vertical" />}
            <Tooltip
                title={
                    query.isFetching ||
                    query.data === undefined ||
                    query.data.count < selectedEvent.limits.helper
                        ? ""
                        : formatAppErrorType(AppErrorType.INVALID.HELPER_LIMIT_REACHED)
                }
            >
                <Box>
                    <Button
                        cyId="HelpersPage__helper-creation-button"
                        disabled={
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.helper
                        }
                        endIcon={<ButtonHotkeyIcon>N</ButtonHotkeyIcon>}
                        onClick={handleCreationButtonClick}
                        startIcon={<AddIcon />}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </Tooltip>
            <Tooltip
                title={
                    query.isFetching ||
                    query.data === undefined ||
                    query.data.count < selectedEvent.limits.helper
                        ? ""
                        : formatAppErrorType(AppErrorType.INVALID.HELPER_LIMIT_REACHED)
                }
            >
                <Box>
                    <Button
                        disabled={
                            query.isFetching ||
                            query.data === undefined ||
                            query.data.count >= selectedEvent.limits.helper
                        }
                        onClick={handleImportButtonClick}
                        startIcon={<FileUploadIcon />}
                    >
                        Importieren
                    </Button>
                </Box>
            </Tooltip>
            <Button
                disabled={query.isFetching || query.data === undefined || query.data.count === 0}
                onClick={handleIsActiveOfAllUpdateButtonClick}
                startIcon={<CheckIcon />}
            >
                Aktivieren
            </Button>
            <IconButton ref={menuAnchorElementRef} onClick={handleMenuOpenButtonClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorElementRef.current}
                onClose={handleMenuClose}
                open={isMenuOpen}
            >
                <MenuItem
                    disabled={
                        query.isFetching || query.data === undefined || query.data.count === 0
                    }
                    onClick={handleLoginDataOfAllPrintingMenuItemClick}
                >
                    <ListItemIcon>
                        <PrintIcon />
                    </ListItemIcon>
                    <ListItemText>Zugangsdaten drucken</ListItemText>
                </MenuItem>
            </Menu>
            <IconButton onClick={handleInstructionsButtonClick}>
                <InfoOutlined />
            </IconButton>
        </>
    );
};

const HelpersPage = () => {
    const navigate = useNavigate();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const creationDialogDisplayer = useDialogDisplayer(HelperCreationDialog);
    const importDialogDisplayer = useDialogDisplayer(HelpersImportDialog);
    const isActiveOfAllUpdateDialogDisplayer = useDialogDisplayer(IsActiveOfAllHelpersUpdateDialog);
    const loginDataOfAllPrintingDialogDisplayer = useDialogDisplayer(
        LoginDataOfAllHelpersPrintingDialog,
    );
    const updateDialogDisplayer = useDialogDisplayer(HelperUpdateDialog);
    const removalDialogDisplayer = useDialogDisplayer(HelperRemovalDialog);
    const manyRemovalDialogDisplayer = useDialogDisplayer(ManyHelpersRemovalDialog);
    const dialogDisplayers = [
        creationDialogDisplayer,
        importDialogDisplayer,
        isActiveOfAllUpdateDialogDisplayer,
        loginDataOfAllPrintingDialogDisplayer,
        updateDialogDisplayer,
        removalDialogDisplayer,
        manyRemovalDialogDisplayer,
    ];

    const handleDataMasterResultsShowButtonClick = (helper) => {
        navigate(
            schoolAppRoutes(true)
                .dashboard()
                .results()
                .build({
                    searchValue: [
                        ...(helper.name === undefined ? [] : [helper.name]),
                        helper.username,
                    ].join(" "),
                }),
        );
    };

    const handleDataMasterUpdateButtonClick = (helper) => {
        updateDialogDisplayer.open({
            helper,
            removalDialogDisplayer,
        });
    };

    const handleDataMasterRemovalButtonClick = (helper) => {
        removalDialogDisplayer.open({ helper });
    };

    const dataMasterColumns = [
        {
            field: "name",
            type: "string",
            headerName: "Name",
            width: 200,
        },
        {
            field: "username",
            type: "string",
            headerName: "Benutzername",
            width: 150,
        },
        {
            field: "password",
            type: "string",
            renderCell: ({ row }) => <DataMaster__PasswordCell password={row.password} />,
            headerName: "Passwort",
            sortable: false,
            width: 200,
        },
        {
            field: "isActive",
            type: "boolean",
            headerName: "Aktiv",
            width: 75,
        },
        {
            field: "actions",
            getActions: ({ row }) => [
                <Tooltip title="Ergebnisse anzeigen">
                    <GridActionsCellItem
                        icon={<FormatListNumberedIcon />}
                        label="Ergebnisse anzeigen"
                        onClick={() => handleDataMasterResultsShowButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Bearbeiten (E)">
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        onClick={() => handleDataMasterUpdateButtonClick(row)}
                    />
                </Tooltip>,
                <Tooltip title="Entfernen (DEL)">
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Entfernen"
                        onClick={() => handleDataMasterRemovalButtonClick(row)}
                    />
                </Tooltip>,
            ],
            type: "actions",
            headerName: "Aktionen",
            sortable: false,
            width: 125,
        },
    ];

    const handleDataMasterListItemsAsync = async (listingModifiers) =>
        apiService.helpers.listAsync(selectedEvent.id, listingModifiers);

    const handleDataMasterCreationHotkeyFire = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterRemovalHotkeyFire = (helpers) => {
        if (helpers.length === 1) {
            removalDialogDisplayer.open({ helper: helpers[0] });
        } else {
            manyRemovalDialogDisplayer.open({ helpers });
        }
    };

    const handleDataMasterUpdateHotkeyFire = (helper) => {
        updateDialogDisplayer.open({
            helper,
            removalDialogDisplayer,
        });
    };

    const handleDataMasterCreationButtonClick = () => {
        creationDialogDisplayer.open();
    };

    const handleDataMasterImportButtonClick = () => {
        importDialogDisplayer.open();
    };

    const handleDataMasterIsActiveOfAllUpdateButtonClick = () => {
        isActiveOfAllUpdateDialogDisplayer.open();
    };

    const handleDataMasterLoginDataOfAllPrintingMenuItemClick = () => {
        loginDataOfAllPrintingDialogDisplayer.open();
    };

    const handleDataMasterManyRemovalButtonClick = (helpers) => {
        manyRemovalDialogDisplayer.open({ helpers });
    };

    useEffect(() => {
        setDashboardTitle("Helfer");
    }, []);

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                    }}
                >
                    <DataMaster
                        baseQueryKey={["helpers", "list", selectedEvent.id]}
                        columns={dataMasterColumns}
                        cyId="HelpersPage__data-master"
                        defaultFieldSorting={{
                            path: "name",
                            order: Order.ASCENDING,
                        }}
                        dialogDisplayers={dialogDisplayers}
                        exampleSearchDescription="Name, Benutzername"
                        fullSearchDescription="Durchsucht Name, Benutzername"
                        getKeyFromItem={(item) => item.id}
                        itemLimit={selectedEvent.limits.helper}
                        listItemsAsync={handleDataMasterListItemsAsync}
                        noFilteredItemsMessage="Keine Helfer gefunden."
                        noItemsMessage="Noch keine Helfer hinzugefügt."
                        onCreationHotkeyFire={handleDataMasterCreationHotkeyFire}
                        onRemovalHotkeyFire={handleDataMasterRemovalHotkeyFire}
                        onUpdateHotkeyFire={handleDataMasterUpdateHotkeyFire}
                        RightToolbarComponents={RightDataMasterToolbarComponents}
                        rightToolbarComponentsProps={{
                            onCreationButtonClick: handleDataMasterCreationButtonClick,
                            onImportButtonClick: handleDataMasterImportButtonClick,
                            onIsActiveOfAllUpdateButtonClick:
                                handleDataMasterIsActiveOfAllUpdateButtonClick,
                            onLoginDataOfAllPrintingMenuItemClick:
                                handleDataMasterLoginDataOfAllPrintingMenuItemClick,
                            onManyRemovalButtonClick: handleDataMasterManyRemovalButtonClick,
                            onRemovalButtonClick: handleDataMasterRemovalButtonClick,
                            onUpdateButtonClick: handleDataMasterUpdateButtonClick,
                        }}
                    />
                </CardContent>
            </Card>
            {creationDialogDisplayer.element}
            {importDialogDisplayer.element}
            {isActiveOfAllUpdateDialogDisplayer.element}
            {loginDataOfAllPrintingDialogDisplayer.element}
            {updateDialogDisplayer.element}
            {removalDialogDisplayer.element}
            {manyRemovalDialogDisplayer.element}
        </>
    );
};

export { HelpersPage };
