import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { participantValidationSchemas, validationOptions } from "@bujus/common";

import { Checkbox, DataTable, Form, TooLessCsvFileRowsRowsErrorAlert } from "@/base";
import { generateDataTableDataProps } from "@/utilities";

const ParticipantsImportDialog__FileSettingsStepContent = ({
    defaultFormData,
    fileDatas,
    onNext,
    onPrevious,
}) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            participantValidationSchemas.import.forms.fileSettings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const isFirstRowContainingHeadingsFieldValue = useWatch({
        control: formController.control,
        name: "isFirstRowContainingHeadings",
    });

    const realFileDatas = fileDatas.slice(isFirstRowContainingHeadingsFieldValue ? 1 : 0);

    const handleFormSubmit = (formData) => {
        onNext(formData);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <TooLessCsvFileRowsRowsErrorAlert
                containerProps={{ mb: 2 }}
                realFileDatas={realFileDatas}
            />
            <Box display="flex" flexDirection="column" gap={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Datei Einstellungen</Typography>
                        <Box mt={2}>
                            <Checkbox
                                fieldName="isFirstRowContainingHeadings"
                                label="Erste Zeile enthält Überschriften"
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Vorschau der Datei</Typography>
                        <Typography color="text.secondary" mt={2}>
                            Anzahl Zeilen mit Teilnehmerdaten: {realFileDatas.length}
                        </Typography>
                        <Box mt={2}>
                            <DataTable
                                isPreview
                                {...generateDataTableDataProps(fileDatas, false)}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <Button
                    disabled={realFileDatas.length === 0}
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                >
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { ParticipantsImportDialog__FileSettingsStepContent };
