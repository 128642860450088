import { createContext, useContext } from "react";

const AccountDashboardMailAddressStateContext = createContext();
const AccountDashboardMailAddressStateProvider = AccountDashboardMailAddressStateContext.Provider;
const useAccountDashboardMailAddressStateContext = () =>
    useContext(AccountDashboardMailAddressStateContext);

export {
    AccountDashboardMailAddressStateContext,
    AccountDashboardMailAddressStateProvider,
    useAccountDashboardMailAddressStateContext,
};
