import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";

import { EventFormat, getEventFormatObject } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

import { StatisticsPage__GeneralTab } from "./statistics-dialog__general-tab";
import { StatisticsPage__LeaderboardTab } from "./statistics-dialog__leaderboard-tab";

const StatisticsDialogTabNames = {
    GENERAL: "GENERAL",
    LEADERBOARD: "LEADERBOARD",
};

const StatisticsDialog__LoadedContent = ({ groups, options, participants, results }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const [tabsValue, setTabsValue] = useState(StatisticsDialogTabNames.GENERAL);

    const handleGeneralTabClick = () => {
        setTabsValue(StatisticsDialogTabNames.GENERAL);
    };

    const handleLeaderboardTabClick = () => {
        setTabsValue(StatisticsDialogTabNames.LEADERBOARD);
    };

    return (
        <Box display="flex" flex={1} flexDirection="column">
            <TabContext value={tabsValue}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <TabList value={tabsValue} variant="fullWidth">
                        <Tab
                            label="Allgemein"
                            onClick={handleGeneralTabClick}
                            value={StatisticsDialogTabNames.GENERAL}
                        />
                        <Tab
                            label="Bestenlisten"
                            onClick={handleLeaderboardTabClick}
                            value={StatisticsDialogTabNames.LEADERBOARD}
                        />
                    </TabList>
                </Box>
                <Box flex={1} overflow="auto" position="relative">
                    <Box
                        bottom={0}
                        display="flex"
                        flexDirection="column"
                        left={0}
                        position="absolute"
                        right={0}
                        top={0}
                    >
                        <TabPanel value={StatisticsDialogTabNames.GENERAL}>
                            <StatisticsPage__GeneralTab
                                groups={groups}
                                options={options}
                                participants={participants}
                                results={results}
                            />
                        </TabPanel>
                        <TabPanel value={StatisticsDialogTabNames.LEADERBOARD}>
                            <StatisticsPage__LeaderboardTab
                                groups={groups}
                                options={options}
                                participants={participants}
                                results={results}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            </TabContext>
        </Box>
    );
};

export { StatisticsDialog__LoadedContent };
