import { Box, Typography } from "@mui/material";
import React from "react";

const DataMaster__NoRowsOverlay = ({ noFilteredItemsMessage, noItemsMessage, query }) => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
    >
        <Typography textAlign="center">
            {query.data?.count === undefined ? noFilteredItemsMessage : noItemsMessage}
        </Typography>
    </Box>
);

export { DataMaster__NoRowsOverlay };
