import { Box } from "@mui/material";
import React, { cloneElement } from "react";

const DataMaster__ActionsCell = ({ components = [] }) => (
    <Box display="flex" gap={1} justifyContent="center" width="100%">
        {components.map((x, xIndex) => cloneElement(x, { key: xIndex }))}
    </Box>
);

export { DataMaster__ActionsCell };
