import React from "react";

import { FormControllerProvider } from "@bujus/common-frontend";

const Form = ({ controller, onSubmit, children }) => {
    const handleSubmit = (event) => {
        // event.stopPropagation();
        // event.preventDefault();
        controller.handleSubmit(onSubmit)(event);
    };

    return (
        <FormControllerProvider value={controller}>
            <form
                noValidate
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                {children}
            </form>
        </FormControllerProvider>
    );
};

export { Form };
