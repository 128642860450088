import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowBack as ArrowBackIcon, FileUpload as FileUploadIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    examplePasswords,
    generateUsername,
    helperValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { Checkbox, Collapse, DataTable, Form, Select } from "@/base";
import { generateDataTableDataProps, generateImportColumnSelectDataProps } from "@/utilities";

const HelpersImportDialog__DataSelectionStepContent = ({
    defaultFormData,
    fileDatas,
    isFirstRowContainingHeadings,
    mutation,
    onImport,
    onPrevious,
}) => {
    const theme = useTheme();

    const [helpersValidationErrors, setHelpersValidationErrors] = useState();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            helperValidationSchemas.import.forms.dataSelection,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const areNamesSpecifiedFieldValue = useWatch({
        control: formController.control,
        name: "areNamesSpecified",
    });
    const nameColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "nameColumnIndex",
    });
    const isGeneratingUsernamesFieldValue = useWatch({
        control: formController.control,
        name: "isGeneratingUsernames",
    });
    const usernameColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "usernameColumnIndex",
    });
    const isGeneratingPasswordsFieldValue = useWatch({
        control: formController.control,
        name: "isGeneratingPasswords",
    });
    const passwordColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "passwordColumnIndex",
    });

    const realFileDatas = fileDatas.slice(isFirstRowContainingHeadings ? 1 : 0);

    const previewDataTableColumns = [
        {
            fieldName: "name",
            heading: "Name",
            getValue: (item) =>
                !areNamesSpecifiedFieldValue ? "" : item[nameColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "username",
            heading: `Benutzername${!isGeneratingUsernamesFieldValue ? "" : " (wird generiert)"}`,
            getValue: (item) =>
                !isGeneratingUsernamesFieldValue
                    ? item[usernameColumnIndexFieldValue]
                    : generateUsername(item[nameColumnIndexFieldValue]),
            width: 1,
        },
        {
            fieldName: "password",
            heading: `Passwort${!isGeneratingPasswordsFieldValue ? "" : " (wird generiert)"}`,
            getValue: (item, itemIndex) =>
                !isGeneratingPasswordsFieldValue
                    ? item[passwordColumnIndexFieldValue]
                    : examplePasswords[itemIndex],
            width: 1,
        },
    ];

    const helpersValidationErrorsDataTableColumns = [
        {
            fieldName: "row",
            heading: "Zeile",
            getValue: (item) => item.path[1] + 1,
            width: 1,
        },
        {
            fieldName: "fieldName",
            heading: "Spalte",
            getValue: (item) => {
                let fieldName = "";
                switch (item.path[2]) {
                    case "name":
                        fieldName = "Name";
                        break;
                    case "username":
                        fieldName = "Benutzername";
                        break;
                    case "password":
                        fieldName = "Passwort";
                        break;
                    default:
                        break;
                }
                return fieldName;
            },
            width: 1,
        },
        {
            fieldName: "value",
            heading: "Wert",
            getValue: (item) => item.context.value,
            width: 1,
        },
        {
            fieldName: "message",
            heading: "Fehler",
            getValue: (item) => item.message,
            width: 2,
        },
    ];

    useEffect(() => {
        if (areNamesSpecifiedFieldValue) {
            formController.trigger("isGeneratingUsernames");
            return;
        }
        formController.setValue("isGeneratingUsernames", false);
    }, [areNamesSpecifiedFieldValue]);

    const handleFormSubmitAsync = async (data) => {
        if (mutation.isLoading) {
            return;
        }
        let validatedData;
        try {
            validatedData = await helperValidationSchemas.import.forms.data.validateAsync(
                {
                    areNamesSpecified: data.areNamesSpecified,
                    isGeneratingUsernames: data.isGeneratingUsernames,
                    isGeneratingPasswords: data.isGeneratingPasswords,
                    helpers: fileDatas.slice(isFirstRowContainingHeadings ? 1 : 0).map((x) => ({
                        tempId: nanoid(),
                        ...(data.areNamesSpecified && { name: x[data.nameColumnIndex] }),
                        ...(!data.isGeneratingUsernames && {
                            username: x[data.usernameColumnIndex],
                        }),
                        ...(!data.isGeneratingPasswords && {
                            password: x[data.passwordColumnIndex],
                        }),
                    })),
                },
                validationOptions,
            );
        } catch (error) {
            setHelpersValidationErrors(error.details);
            return;
        }
        onImport(validatedData);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmitAsync}>
            <Box display="flex" flexDirection="column" gap={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Vorschau der Datei</Typography>
                        <Box mt={2}>
                            <DataTable
                                isPreview
                                {...generateDataTableDataProps(
                                    fileDatas,
                                    isFirstRowContainingHeadings,
                                )}
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Box maxWidth={theme.breakpoints.values.sm}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Daten Auswahl</Typography>
                            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                                <Checkbox
                                    fieldName="areNamesSpecified"
                                    isEnabled={!mutation.isLoading}
                                    label="Namen angeben"
                                />
                                <Collapse
                                    isOpen={areNamesSpecifiedFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="nameColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Name *"
                                    />
                                    <Box mt={2}>
                                        <Checkbox
                                            fieldName="isGeneratingUsernames"
                                            isEnabled={!mutation.isLoading}
                                            label="Benutzernamen generieren"
                                        />
                                    </Box>
                                </Collapse>
                                <Collapse
                                    isOpen={!isGeneratingUsernamesFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="usernameColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Benutzername *"
                                    />
                                </Collapse>
                                <Checkbox
                                    fieldName="isGeneratingPasswords"
                                    isEnabled={!mutation.isLoading}
                                    label="Passwörter generieren"
                                />
                                <Collapse
                                    isOpen={!isGeneratingPasswordsFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="passwordColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Passwort *"
                                    />
                                </Collapse>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Vorschau der Helfer</Typography>
                        <Typography color="text.secondary" mt={2}>
                            Anzahl Helfer zu importieren: {realFileDatas.length}
                        </Typography>
                        <Box mt={2}>
                            <DataTable
                                columns={previewDataTableColumns}
                                isPreview
                                items={realFileDatas}
                            />
                        </Box>
                    </CardContent>
                </Card>
                {helpersValidationErrors !== undefined && helpersValidationErrors.length > 0 && (
                    <Card sx={{ borderColor: "error.main" }}>
                        <CardContent>
                            <Typography variant="h6">Validierungsfehler</Typography>
                            <Typography color="text.secondary" mt={2}>
                                Anzahl: {helpersValidationErrors.length}
                            </Typography>
                            <Box mt={2}>
                                <DataTable
                                    columns={helpersValidationErrorsDataTableColumns}
                                    items={helpersValidationErrors}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    disabled={mutation.isLoading}
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <LoadingButton
                    loading={mutation.isLoading}
                    startIcon={<FileUploadIcon />}
                    type="submit"
                >
                    Importieren
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { HelpersImportDialog__DataSelectionStepContent };
