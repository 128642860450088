import React from "react";

import { schoolAppRoutes } from "@bujus/common";

import { useAccountContext } from "@/contexts";

import { RestrictedRoute } from "../restricted-route";

const UnauthorizedRoute = ({ children }) => {
    const account = useAccountContext();

    return (
        <RestrictedRoute
            condition={account === undefined}
            redirectUrl={schoolAppRoutes(true).dashboard().build()}
        >
            {children}
        </RestrictedRoute>
    );
};

export { UnauthorizedRoute };
