import { joiResolver } from "@hookform/resolvers/joi";
import { Calculate as CalculateIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
    EventFormat,
    certValidationSchemas,
    schoolAppRoutes,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { CardCollapse, Checkbox, DialogClosingButton, Form } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { AllCertsGenerationDialog__LastGenerationDetails } from "./all-certs-generation-dialog__last-generation-details";

const AllCertsGenerationDialog = ({ close, isOpen }) => {
    const navigate = useNavigate();

    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(certValidationSchemas.allGeneration.body, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            options: {
                isOnlyUsingAllowedDisciplines: false,
                isRescuingNotGeneratingCerts: false,
            },
        },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.certs.generateAllAsync(variables.eventId, variables.options),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (result) => {
                if (result.counts.notGenerated > 0) {
                    snackbarsController.enqueueWarningMessage(
                        "Konnte nicht alle Urkunden generieren",
                    );
                    navigate(schoolAppRoutes(true).dashboard().certs().notGenerated().build());
                } else {
                    snackbarsController.enqueueSuccessMessage("Alle Urkunden generiert");
                    navigate(schoolAppRoutes(true).dashboard().certs().generated().build());
                }
                queryClient.invalidateQueries(["certs"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            options: {
                isOnlyUsingAllowedDisciplines: data.options.isOnlyUsingAllowedDisciplines,
                isRescuingNotGeneratingCerts: data.options.isRescuingNotGeneratingCerts,
            },
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Urkunden generieren</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <AllCertsGenerationDialog__LastGenerationDetails />
                    <CardCollapse title="Erweiterte Optionen">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                            }}
                        >
                            <Checkbox
                                fieldName="options.isOnlyUsingAllowedDisciplines"
                                isEnabled={!mutation.isLoading}
                                label="Verbiete Teilnehmern das Ausführen von Disziplinen, die sie nach Altersgruppe und Geschlecht nicht ausführen dürften. Beispiel: Männlich 14 darf keine 50m laufen, sondern nur 75m."
                            />
                            <Checkbox
                                fieldName="options.isRescuingNotGeneratingCerts"
                                isEnabled={!mutation.isLoading}
                                label="Rette Urkunden, welche eigentlich nicht generiert werden können."
                            />
                            {/* <Checkbox
                                    fieldName="options.isCheckingResultCount"
                                    isEnabled={!mutation.isLoading}
                                    label="Überprüfe bei jedem Teilnehmer, dass die richtige Anzahl an Versuchen pro Disziplin durchgeführt wurde. Beispiel: Für Sprint muss exakt ein Versuch eingetragen sein, für Weitsprung drei."
                                /> */}
                        </Box>
                    </CardCollapse>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<CalculateIcon />}
                        type="submit"
                    >
                        Generieren
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AllCertsGenerationDialog };
