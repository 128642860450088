import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { formatDate } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSnackbarsController } from "@/hooks";

const EventRemovalCancellationDialog = ({ close, event, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) => apiService.events.cancelRemovalAsync(variables.eventId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Veranstaltungs Löschung abgebrochen");
                queryClient.invalidateQueries(["events"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ eventId: event.id });
    };

    // if (
    //     event.removalIntent !== undefined &&
    //     day(event.removalIntent.expires).isSameOrAfter(day())
    // ) {
    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Aktuelle Veranstaltung-Löschung</DialogTitle>
            <DialogClosingButton
                isDisabled={mutation.isLoading}
                onClick={handleClosingButtonClick}
            />
            <DialogContent>
                <DialogContentText>
                    Bis zum {formatDate(event.removalIntent.expires, "Pp")} Uhr möglich
                    <Typography variant="dialogContentTextLong">
                        <b>3-Wort-ID:</b> {event.n3WordId}
                    </Typography>
                    <Typography variant="dialogContentTextLong">
                        <b>Name:</b> {event.name}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                    startIcon={<CloseIcon />}
                >
                    Löschung abbrechen
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
    // }
    // return undefined;
};

export { EventRemovalCancellationDialog };
