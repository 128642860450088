import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const RadioSelect = ({
    fieldName,
    formatItem = undefined,
    getKeyFromItem = undefined,
    isEnabled = true,
    items,
    label,
}) => {
    const finalGetKeyFromItem = getKeyFromItem !== undefined ? getKeyFromItem : (item) => item;
    const finalFormatItem = formatItem !== undefined ? formatItem : (item) => item;

    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const handleChange = (event) => {
        // fieldController.field.onChange(
        //     event.target.value === ""
        //         ? null
        //         : items.find((x) => finalGetKeyFromItem(x) === event.target.value)
        // );
        fieldController.field.onChange(
            items.find((x) => finalGetKeyFromItem(x) === event.target.value),
        );
    };

    return (
        <FormControl
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
        >
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                onChange={handleChange}
                value={
                    fieldController.field.value === null
                        ? ""
                        : finalGetKeyFromItem(fieldController.field.value)
                }
            >
                {items.map((x) => (
                    <FormControlLabel
                        key={finalGetKeyFromItem(x)}
                        control={<Radio />}
                        label={finalFormatItem(x)}
                        value={finalGetKeyFromItem(x)}
                    />
                ))}
            </RadioGroup>
            {fieldController.fieldState.error !== undefined && (
                <FormHelperText>{fieldController.fieldState.error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

export { RadioSelect };
