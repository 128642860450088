import { Dialog } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Suspense } from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, SuspenseFallback } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { getCertTemplateSetImageOverlayUpdateDialogModeObject } from "./cert-template-set-image-overlay-update-dialog-mode";

const CertTemplateSetImageOverlayUpdateDialog__LoadedContent = React.lazy(() =>
    import("./cert-template-set-image-overlay-update-dialog__loaded-content").then((module) => ({
        default: module.CertTemplateSetImageOverlayUpdateDialog__LoadedContent,
    })),
);

const CertTemplateSetImageOverlayUpdateDialog = ({ close, mode, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) =>
            getCertTemplateSetImageOverlayUpdateDialogModeObject(
                apiService.certTemplateSets.updateRubberStampAsync,
                apiService.certTemplateSets.updateSignatureAsync,
                mode,
            )(variables.eventId, variables.imagePngDataUrl),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage(
                    getCertTemplateSetImageOverlayUpdateDialogModeObject(
                        "Stempel geändert",
                        "Unterschrift geändert",
                        mode,
                    ),
                );
                queryClient.invalidateQueries(["certTemplateSets"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleLoadedContentUpdateAsync = (imagePngDataUrl) => {
        mutation.mutate({
            eventId: selectedEvent.id,
            imagePngDataUrl,
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogClosingButton
                isDisabled={mutation.isLoading}
                onClick={handleClosingButtonClick}
            />
            <Suspense fallback={<SuspenseFallback />}>
                <CertTemplateSetImageOverlayUpdateDialog__LoadedContent
                    mode={mode}
                    mutation={mutation}
                    onUpdate={handleLoadedContentUpdateAsync}
                />
            </Suspense>
        </Dialog>
    );
};

export { CertTemplateSetImageOverlayUpdateDialog };
