import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    calculateHelperLimit,
    calculateResultLimit,
    eventValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { Collapse, DatePicker, Form, Select, TextField } from "@/base";
import { config } from "@/config";

import { EventScheduleTimeline } from "../event-schedule-timeline";

import { EventCreationDialog__Pricing } from "./event-creation-dialog__pricing";

const EventCreationDialog__RealSettingsStepContent = ({ defaultFormData, onNext, onPrevious }) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            eventValidationSchemas.creation.forms.settings.real,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const startFieldValue = useWatch({
        control: formController.control,
        name: "start",
    });
    const limitsParticipantFieldValue = useWatch({
        control: formController.control,
        name: "limits.participant.",
    });

    const helperLimit = calculateHelperLimit(limitsParticipantFieldValue);
    const resultLimit = calculateResultLimit(limitsParticipantFieldValue);

    const handleFormSubmit = (data) => {
        onNext(data);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField fieldName="name" isAutofocusing label="Name *" />
                <DatePicker
                    fieldName="start"
                    label="Start"
                    validationSchema={eventValidationSchemas.creation.forms.settings.real}
                />
                <EventScheduleTimeline
                    start={
                        startFieldValue === null || Number.isNaN(startFieldValue.getTime())
                            ? undefined
                            : startFieldValue
                    }
                />
                <Select
                    fieldName="limits.participant"
                    items={config.event.real.allowedParticipantLimits}
                    label="Max. Teilnehmeranzahl"
                />
                <Collapse isOpen={limitsParticipantFieldValue !== null} overwritingTopMargin={2}>
                    {limitsParticipantFieldValue !== null && (
                        <>
                            <Typography>
                                <b>Max. Helferanzahl:</b> {helperLimit}
                            </Typography>
                            <Typography>
                                <b>Max. Ergebnisanzahl:</b> {resultLimit}
                            </Typography>
                            <EventCreationDialog__Pricing
                                participantLimit={limitsParticipantFieldValue}
                            />
                        </>
                    )}
                </Collapse>
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <Button endIcon={<ArrowForwardIcon />} type="submit">
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { EventCreationDialog__RealSettingsStepContent };
