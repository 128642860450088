import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { SelectedEventRoute } from "@/base";

import { AccountPage } from "./account-page";
import { CertTemplateSetPage } from "./cert-template-set-page";
import { CertsPage } from "./certs-page/certs-page";
import { DisciplinePreselectionPage } from "./discipline-pre-selection-page";
import { EventPage } from "./event-page/event-page";
import { EventsPage } from "./events-page";
import { GroupsPage } from "./groups-page";
import { HelpersPage } from "./helpers-page/helpers-page";
import { ParticipantsPage } from "./participants-page/participants-page";
import { PaymentsPage } from "./payments-page";
import { ResultsPage } from "./results-page";
import { StatisticsPage } from "./statistics-page";

const Dashboard__Router = () => (
    <Routes>
        <Route
            element={
                <SelectedEventRoute>
                    <EventPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).event().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <DisciplinePreselectionPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).disciplinePreSelection().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <HelpersPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).helpers().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <ParticipantsPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).participants().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <GroupsPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).groups().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <ResultsPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).results().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <CertTemplateSetPage />
                </SelectedEventRoute>
            }
            path={schoolAppRoutes().dashboard(true).certTemplateSet().build()}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <CertsPage />
                </SelectedEventRoute>
            }
            path={`${schoolAppRoutes().dashboard(true).certs().build()}/*`}
        />
        <Route
            element={
                <SelectedEventRoute>
                    <StatisticsPage />
                </SelectedEventRoute>
            }
            path={`${schoolAppRoutes().dashboard(true).statistics().build()}/*`}
        />
        <Route element={<EventsPage />} path={schoolAppRoutes().dashboard(true).events().build()} />
        <Route
            element={<AccountPage />}
            path={schoolAppRoutes().dashboard(true).account().build()}
        />
        <Route
            element={<PaymentsPage />}
            path={schoolAppRoutes().dashboard(true).payments().build()}
        />
        <Route
            element={<Navigate to={schoolAppRoutes(true).dashboard().event().build()} />}
            path="*"
        />
    </Routes>
);

export { Dashboard__Router };
