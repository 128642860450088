const StatisticsGrouping = {
    NONE: "NONE",
    GENDER: "GENDER",
    AGE_GROUP: "AGE_GROUP",
    GENDER_AND_AGE_GROUP: "GENDER_AND_AGE_GROUP",
    GROUP: "GROUP",
};

const formatStatisticsGrouping = (statisticsGrouping) => {
    switch (statisticsGrouping) {
        case StatisticsGrouping.NONE:
            return "None";
        case StatisticsGrouping.GENDER:
            return "Geschlecht";
        case StatisticsGrouping.AGE_GROUP:
            return "Altersgruppe";
        case StatisticsGrouping.GENDER_AND_AGE_GROUP:
            return "Geschlecht und Altersgruppe";
        case StatisticsGrouping.GROUP:
            return "Riege";
        default:
            throw new Error(`Invalid statistics group by: ${statisticsGrouping}`);
    }
};

export { StatisticsGrouping, formatStatisticsGrouping };
