import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

const DataMaster__PasswordCell = ({ password }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    const handleRevealingToggleButtonClick = () => {
        setIsRevealed(!isRevealed);
    };

    return (
        <Box alignItems="center" display="flex" width="100%">
            <Typography flex={1} fontSize="inherit">
                {isRevealed ? password : "●●●●●●●●"}
            </Typography>
            <Box ml={1}>
                <IconButton color="secondary" edge="end" onClick={handleRevealingToggleButtonClick}>
                    {isRevealed ? (
                        <VisibilityIcon fontSize="small" />
                    ) : (
                        <VisibilityOffIcon fontSize="small" />
                    )}
                </IconButton>
            </Box>
        </Box>
    );
};

export { DataMaster__PasswordCell };
