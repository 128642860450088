import { Card, CardContent, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { useDashboardTitleStateContext } from "@/contexts";
import { useTabsValue } from "@/hooks";

import { CertsPage__GeneratedTab } from "./certs-page__generated-tab";
import { CertsPage__NotGeneratedTab } from "./certs-page__not-generated-tab";

const CertsPage = () => {
    const navigate = useNavigate();

    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const tabsValue = useTabsValue(
        schoolAppRoutes(true).dashboard().certs().generated().build(),
        schoolAppRoutes(true).dashboard().certs().notGenerated().build(),
    );

    const handleCertsTabClick = () => {
        navigate(schoolAppRoutes(true).dashboard().certs().generated().build());
    };

    const handleErrorTabClick = () => {
        navigate(schoolAppRoutes(true).dashboard().certs().notGenerated().build());
    };

    useEffect(() => {
        setDashboardTitle("Urkunden");
    }, []);

    return (
        <Card
            sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <Tabs value={tabsValue} variant="fullWidth">
                <Tab
                    id={schoolAppRoutes().dashboard().certs(true).generated().build()}
                    label="Generierte Urkunden"
                    onClick={handleCertsTabClick}
                />
                <Tab
                    id={schoolAppRoutes().dashboard().certs(true).notGenerated().build()}
                    label="Nicht generierte Urkunden"
                    onClick={handleErrorTabClick}
                />
            </Tabs>
            <CardContent
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <Routes>
                    <Route
                        element={<CertsPage__GeneratedTab />}
                        path={schoolAppRoutes().dashboard().certs(true).generated().build()}
                    />
                    <Route
                        element={<CertsPage__NotGeneratedTab />}
                        path={schoolAppRoutes().dashboard().certs(true).notGenerated().build()}
                    />
                    <Route
                        element={
                            <Navigate
                                to={schoolAppRoutes(true).dashboard().certs().generated().build()}
                            />
                        }
                        path="*"
                    />
                </Routes>
            </CardContent>
        </Card>
    );
};

export { CertsPage };
