import { Login as LoginIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

const LoginButton = ({ color }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(schoolAppRoutes(true).account().login().build());
    };

    return (
        <Button
            {...(color !== undefined && { color })}
            onClick={handleClick}
            startIcon={<LoginIcon />}
        >
            Anmelden
        </Button>
    );
};

export { LoginButton };
