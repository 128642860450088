import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

import { Environment, getEnvironment, version } from "@bujus/common";

import { config } from "@/config";

import { BugsnagErrorBoundaryWrapper__Fallback } from "./bugsnag-error-boundary-wrapper__fallback";

Bugsnag.start({
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: [Environment.PRODUCTION],
    apiKey: config.bugsnag.publicApiKey,
    // eslint-disable-next-line no-undef
    appVersion: version,
    releaseStage: getEnvironment(),
});

const BugsnagErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const BugsnagErrorBoundaryWrapper = ({ children }) => (
    <BugsnagErrorBoundary FallbackComponent={BugsnagErrorBoundaryWrapper__Fallback}>
        {children}
    </BugsnagErrorBoundary>
);

export { BugsnagErrorBoundaryWrapper };
