import { clamp } from "@/utilities";

const Step = {
    FILE_UPLOAD: "FILE_UPLOAD",
    FILE_SETTINGS: "FILE_SETTINGS",
    DATA_SELECTION: "DATA_SELECTION",
    REVIEW: "REVIEW",
};

const DisplayStep = {
    FILE_UPLOAD: 0,
    FILE_SETTINGS: 1,
    DATA_SELECTION: 2,
    REVIEW: 3,
};

const convertStepToDisplayStep = (step) => {
    switch (step) {
        case Step.FILE_UPLOAD:
            return DisplayStep.FILE_UPLOAD;
        case Step.FILE_SETTINGS:
            return DisplayStep.FILE_SETTINGS;
        case Step.DATA_SELECTION:
            return DisplayStep.DATA_SELECTION;
        case Step.REVIEW:
            return DisplayStep.REVIEW;
        default:
            throw new Error();
    }
};

const Action = {
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
};

const initialState = {
    step: Step.FILE_UPLOAD,
    formDatas: {
        fileUpload: { fileDatas: null },
        fileSettings: { isFirstRowContainingHeadings: true },
        dataSelection: {
            areNamesSpecified: true,
            nameColumnIndex: 0,
            isGeneratingUsernames: true,
            usernameColumnIndex: 0,
            isGeneratingPasswords: true,
            passwordColumnIndex: 0,
        },
    },
};

// Should reset steps when going back
const reducer = (state, action) => {
    switch (action.type) {
        case Action.NEXT: {
            switch (state.step) {
                case Step.FILE_UPLOAD: {
                    return {
                        ...state,
                        step: Step.FILE_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            fileUpload: action.formData,
                        },
                    };
                }
                case Step.FILE_SETTINGS: {
                    return {
                        ...state,
                        step: Step.DATA_SELECTION,
                        formDatas: {
                            ...state.formDatas,
                            fileSettings: action.formData,
                            dataSelection: {
                                ...state.formDatas.dataSelection,
                                nameColumnIndex: clamp(
                                    0,
                                    0,
                                    state.formDatas.fileUpload.fileDatas[0].length - 1,
                                ),
                                usernameColumnIndex: clamp(
                                    1,
                                    0,
                                    state.formDatas.fileUpload.fileDatas[0].length - 1,
                                ),
                                passwordColumnIndex: clamp(
                                    2,
                                    0,
                                    state.formDatas.fileUpload.fileDatas[0].length - 1,
                                ),
                            },
                        },
                    };
                }
                case Step.DATA_SELECTION: {
                    return {
                        ...state,
                        step: Step.REVIEW,
                        formDatas: {
                            ...state.formDatas,
                            dataSelection: action.formData,
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        case Action.PREVIOUS: {
            switch (state.step) {
                case Step.FILE_SETTINGS: {
                    return {
                        ...state,
                        step: Step.FILE_UPLOAD,
                        formDatas: {
                            ...state.formDatas,
                            fileSettings: initialState.formDatas.fileSettings,
                        },
                    };
                }
                case Step.DATA_SELECTION: {
                    return {
                        ...state,
                        step: Step.FILE_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            dataSelection: initialState.formDatas.dataSelection,
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        default:
            throw new Error();
    }
};

const helpersImportDialogStateMachine = {
    Step,
    DisplayStep,
    convertStepToDisplayStep,
    Action,
    initialState,
    reducer,
};

export { helpersImportDialogStateMachine };
