import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useApiServiceContext } from "@bujus/common-frontend";

import {
    HorizontalCenterCircularProgress,
    HorizontalCenterErrorMessage,
    LoginButton,
} from "@/base";
import {
    useAccountDashboardMailAddressStateContext,
    useAccountDashboardTitleStateContext,
} from "@/contexts";

import { AccountPasswordChangePage__Logic } from "./account-password-change-page__logic";

const AccountPasswordChangePage = () => {
    const params = useParams();

    const apiService = useApiServiceContext();
    const [, setAccountDashboardTitle] = useAccountDashboardTitleStateContext();
    const [, setAccountDashboardMailAddress] = useAccountDashboardMailAddressStateContext();

    const query = useQuery(
        ["_schools", "validatePasswordChangeToken", params.token],
        async ({ queryKey: key }) => apiService.schools.validatePasswordChangeTokenAsync(key[2]),
        {
            onSuccess: (data) => {
                setAccountDashboardMailAddress(data.mailAddress);
            },
        },
    );

    useEffect(() => {
        setAccountDashboardTitle("Passwort ändern");
    }, []);

    if (query.isLoading) {
        return <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        return (
            <>
                <HorizontalCenterErrorMessage error={query.error} />
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    if (query.isSuccess) {
        return (
            <>
                <Typography align="center" color="textSecondary">
                    Für {query.data.name} ({query.data.mailAddress})
                </Typography>
                <Box mt={2}>
                    <AccountPasswordChangePage__Logic token={params.token} />
                </Box>
            </>
        );
    }
    return undefined;
};

export { AccountPasswordChangePage };
