import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { snapCenterToCursor as snapCenterToCursorDndKitModifier } from "@dnd-kit/modifiers";
import { Edit as EditIcon, Group as GroupIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";

import { EventFormat, SpecialGroupId } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

import { AllGroupsUpdateDialog__Details } from "./all-groups-update-dialog__details";
import { AllGroupsUpdateDialog__GroupCreationDialog } from "./all-groups-update-dialog__group-creation-dialog";
import { AllGroupsUpdateDialog__GroupUpdateDialog } from "./all-groups-update-dialog__group-update-dialog";
import { AllGroupsUpdateDialog__Select } from "./all-groups-update-dialog__select";
import { useAllGroupsUpdateDialogController } from "./use-all-groups-update-dialog-groups-controller";

const AllGroupsUpdateDialog__LoadedContent = ({
    groups,
    isDirty,
    mutation,
    participants,
    setIsDirty,
}) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const [selectedId, seSelectedId] = useState(SpecialGroupId.UNALLOCATED);
    const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const creationDialogDisplayer = useDialogDisplayer(AllGroupsUpdateDialog__GroupCreationDialog);
    const updateDialogDisplayer = useDialogDisplayer(AllGroupsUpdateDialog__GroupUpdateDialog);

    const controller = useAllGroupsUpdateDialogController(
        selectedEvent,
        participants,
        groups,
        setIsDirty,
    );

    const selectedGroup = useMemo(
        () => controller.groups.find((x) => x.id === selectedId),
        [controller.groups, selectedId],
    );

    const dndKitSensors = useSensors(
        useSensor(PointerSensor, { activationConstraint: { distance: 10 } }),
    );

    const transferSelectedParticipants = (targetId) => {
        controller.transferParticipants(selectedId, targetId, selectedParticipantIds);
        setSelectedParticipantIds([]);
    };

    const handleDragStart = (event) => {
        if (selectedParticipantIds.length === 0) {
            setSelectedParticipantIds([event.active.id]);
        }
        setIsDragging(true);
    };

    const openCreationDialogWithSelectedParticipants = () => {
        creationDialogDisplayer.open({
            controller,
            participantIds: selectedParticipantIds,
        });
    };

    const handleDragEnd = (event) => {
        setIsDragging(false);
        if (event.over === null) {
            return;
        }
        if (event.over.id === SpecialGroupId.CREATION) {
            openCreationDialogWithSelectedParticipants();
            return;
        }
        transferSelectedParticipants(event.over.id);
    };

    const handleMasterViewCreationRequest = () => {
        openCreationDialogWithSelectedParticipants();
    };

    const handleMasterViewGroupSelection = (id) => {
        seSelectedId(id);
    };

    const handleSaveButtonClick = () => {
        mutation.mutate({
            eventId: selectedEvent.id,
            groups: controller.groups
                .filter((x) => x.id !== SpecialGroupId.UNALLOCATED)
                .map((x) => ({
                    ...(x.id.length === 24 ? { id: x.id } : { tempId: x.id }),
                    name: x.name,
                    ...(selectedEvent.format === EventFormat.CONTEST && {
                        classLevelRange: x.classLevelRange,
                    }),
                    participantIds: x.participants.map((y) => y.id),
                })),
        });
    };

    const handleDetailsViewAutoDistributeByClass = (participantLimitPerGroup) => {
        controller.autoDistributeByClass(participantLimitPerGroup);
    };

    const handleDetailsViewAutoDistributeByParticipantGroup = (participantLimitPerGroup) => {
        controller.autoDistributeByParticipantGroup(participantLimitPerGroup);
    };

    const handleDetailsViewSelectedGroupRemoval = () => {
        controller.remove(selectedGroup.id);
        seSelectedId(SpecialGroupId.UNALLOCATED);
    };

    const handleDetailsViewSelectedGroupUpdate = () => {
        updateDialogDisplayer.open({
            controller,
            group: selectedGroup,
        });
    };

    const handleDetailSelectedParticipantsChange = (modifySelectedParticipantIds) => {
        setSelectedParticipantIds(modifySelectedParticipantIds);
    };

    const handleDetailsViewSelectedParticipantsTransfer = (targetId) => {
        transferSelectedParticipants(targetId);
    };

    const handleDetailsViewSelectedParticipantsUnallocation = () => {
        controller.unallocateParticipants(selectedId, selectedParticipantIds);
        setSelectedParticipantIds([]);
    };

    return (
        <>
            <DndContext
                modifiers={[snapCenterToCursorDndKitModifier]}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                sensors={dndKitSensors}
            >
                <Box display="flex" flex={1}>
                    <Box display="flex" flex="none" flexDirection="column" width={320}>
                        <AllGroupsUpdateDialog__Select
                            groups={controller.groups}
                            isDragging={isDragging}
                            onCreationRequest={handleMasterViewCreationRequest}
                            onGroupSelection={handleMasterViewGroupSelection}
                            selectedGroup={selectedGroup}
                            selectedParticipantIds={selectedParticipantIds}
                        />
                        <Box mt={1}>
                            <LoadingButton
                                disabled={!isDirty}
                                fullWidth
                                loading={mutation.isLoading}
                                onClick={handleSaveButtonClick}
                                startIcon={<EditIcon />}
                                variant="contained"
                            >
                                Speichern
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Box display="flex" flex={1} flexDirection="column" minWidth={0} ml={1}>
                        <AllGroupsUpdateDialog__Details
                            groups={controller.groups}
                            isDragging={isDragging}
                            onAutoDistributionByClass={handleDetailsViewAutoDistributeByClass}
                            onAutoDistributionByParticipantGroup={
                                handleDetailsViewAutoDistributeByParticipantGroup
                            }
                            onSelectedGroupRemoval={handleDetailsViewSelectedGroupRemoval}
                            onSelectedGroupUpdate={handleDetailsViewSelectedGroupUpdate}
                            onSelectedParticipantsChange={handleDetailSelectedParticipantsChange}
                            onSelectedParticipantsTransfer={
                                handleDetailsViewSelectedParticipantsTransfer
                            }
                            onSelectedParticipantsUnallocation={
                                handleDetailsViewSelectedParticipantsUnallocation
                            }
                            selectedGroup={selectedGroup}
                            selectedParticipantIds={selectedParticipantIds}
                        />
                    </Box>
                    <DragOverlay dropAnimation={null} style={{ width: 200 }}>
                        {isDragging && (
                            <Card
                                sx={{
                                    borderColor: "primary.main",
                                    borderWidth: 2,
                                    borderRadius: 100,
                                    color: "primary.main",
                                    position: "relative",
                                    cursor: "grabbing",
                                    boxShadow: "0 1px 1px rgba(0,0,0,0)",
                                    transform: "scale(0.7)",
                                    transition:
                                        "box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.15s ease-in-out",
                                    opacity: 0,
                                    "&:hover": {
                                        boxShadow: "2px 8px 8px rgba(0,0,0,0.2)",
                                        transform: "scale(1)",
                                        opacity: 1,
                                    },
                                }}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="center"
                                    p={1}
                                >
                                    <GroupIcon />
                                    <Typography fontWeight="bold" ml={1}>
                                        {selectedParticipantIds.length} Teilnehmer
                                    </Typography>
                                </Box>
                            </Card>
                        )}
                    </DragOverlay>
                </Box>
            </DndContext>
            {creationDialogDisplayer.element}
            {updateDialogDisplayer.element}
        </>
    );
};

export { AllGroupsUpdateDialog__LoadedContent };
