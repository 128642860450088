import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    FormHelperText,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TextField,
    Typography,
    debounce,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useController as useFieldController, useWatch } from "react-hook-form";

import {
    defaultParticipantSorting,
    generateFullName,
    transformToValidSearchValue,
    validationTemplates,
} from "@bujus/common";
import { listItemsWithFuse, useFormControllerContext } from "@bujus/common-frontend";

import { config } from "@/config";

const BigResultCreationDialog__ParticipantSelect = () => {
    const formController = useFormControllerContext();

    // The value that is displayed the search text field
    const [searchInputValue, _setSearchInputValue] = useState("");
    const setSearchInputValue = (newSearchValue, isDebouncing) => {
        if (!isDebouncing) {
            // eslint-disable-next-line no-use-before-define
            setSearchValueDebounced.clear();
            // eslint-disable-next-line no-use-before-define
            setSearchValue(newSearchValue);
        } else {
            // eslint-disable-next-line no-use-before-define
            setSearchValueDebounced(newSearchValue);
        }
        _setSearchInputValue(newSearchValue);
    };
    // The value that gets seached, often debounced
    const [searchValue, setSearchValue] = useState(searchInputValue);
    const setSearchValueDebounced = useMemo(
        () => debounce((newSearchValue) => setSearchValue(newSearchValue), 750),
        [],
    );

    const groupsFieldValue = useWatch({
        control: formController.control,
        name: "groups",
    });

    const validSearchValue = transformToValidSearchValue(searchValue);

    const fieldController = useFieldController({
        name: "participant",
        control: formController.control,
    });

    const participants = useMemo(
        () =>
            listItemsWithFuse(
                groupsFieldValue.flatMap((x) => x.participants),
                [
                    {
                        name: "schoolInternalId",
                        getFn: (participant) => participant.schoolInternalId,
                    },
                    {
                        name: "name.first",
                        getFn: (participant) => participant.name.first,
                    },
                    {
                        name: "name.last",
                        getFn: (participant) => participant.name.last,
                    },
                    {
                        name: "class",
                        getFn: (participant) => participant.class,
                    },
                ],
                defaultParticipantSorting,
                validSearchValue !== ""
                    ? {
                          searchValue: validSearchValue,
                          pageSize: config.event.alltimeLimits.participant,
                      }
                    : {
                          fieldSorting: defaultParticipantSorting[0],
                          pageSize: config.event.alltimeLimits.participant,
                          pageIndex: 0,
                      },
            ).items,
        [groupsFieldValue, validSearchValue],
    );

    const handleSearchClearButtonClick = () => {
        setSearchInputValue("", false);
    };

    const handleSearchTextFieldChange = (event) => {
        setSearchInputValue(event.target.value, true);
    };

    const handleListItemButtonClick = (participant) => {
        fieldController.field.onChange(participant);
    };

    // useEffect(() => {
    //     if (
    //         fieldController.field.value === null ||
    //         participants.some((x) => x.id === fieldController.field.value.id)
    //     ) {
    //         return;
    //     }
    //     fieldController.field.onChange(null);
    // }, [fieldController.field, participants]);

    return (
        <Card
            sx={{
                ...(fieldController.fieldState.error !== undefined && {
                    borderColor: "error.main",
                }),
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <CardContent>
                <Typography
                    {...(fieldController.fieldState.error !== undefined && { color: "error" })}
                    variant="h6"
                >
                    2. Teilnehmer auswählen
                </Typography>
                <Box mt={2}>
                    <TextField
                        autoComplete="off"
                        fullWidth
                        inputProps={{ maxLength: validationTemplates.searchValueMaxLength }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: searchInputValue !== "" && (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchClearButtonClick} size="small">
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearchTextFieldChange}
                        placeholder="Schulinterne-ID, Vorname, Nachname, ..."
                        size="small"
                        value={searchInputValue}
                    />
                </Box>
            </CardContent>
            <Box flex={1} position="relative">
                <Box
                    bottom={0}
                    left={0}
                    position="absolute"
                    right={0}
                    sx={{ overflowY: "auto" }}
                    top={0}
                >
                    {participants.length === 0 ? (
                        <Typography textAlign="center">
                            Keine Teilnehmer in den ausgewählten Riegen gefunden.
                        </Typography>
                    ) : (
                        <List dense>
                            {participants.map((x) => (
                                <ListItem key={x.id} disablePadding>
                                    <ListItemButton
                                        onClick={() => handleListItemButtonClick(x)}
                                        selected={
                                            fieldController.field.value !== null &&
                                            x.id === fieldController.field.value.id
                                        }
                                    >
                                        <ListItemText
                                            primary={generateFullName(x.name.first, x.name.last)}
                                            secondary={x.schoolInternalId}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>
            {fieldController.fieldState.error !== undefined && (
                <Box p={1}>
                    <FormHelperText error style={{ textAlign: "center" }}>
                        {fieldController.fieldState.error.message}
                    </FormHelperText>
                </Box>
            )}
        </Card>
    );
};

export { BigResultCreationDialog__ParticipantSelect };
