import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { defaultParticipantSorting, defaultResultSorting, delayAsync } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { FullScreenDialogTransition, HorizontalCenterCircularProgress, QueryError } from "@/base";
import { config } from "@/config";
import { useSelectedEventStateContext } from "@/contexts";

import { StatisticsDialog__LoadedContent } from "./statistics-dialog__loaded-content";

const StatisticsDialog = ({ close, isOpen, options }) => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const query = useQuery(
        ["statistics-event-data"],
        async () => {
            const groups = await apiService.groups.getAllAsync(selectedEvent.id);
            const participants = [];
            {
                let lastResult;
                do {
                    lastResult = await apiService.participants.listAsync(selectedEvent.id, {
                        fieldSorting: defaultParticipantSorting[0],
                        pageSize: 100,
                        pageIndex: lastResult === undefined ? 0 : lastResult.pageIndex + 1,
                    });
                    participants.push(...lastResult.items);
                } while (participants.length < lastResult.count);
            }
            const results = [];
            {
                let lastResult;
                do {
                    lastResult = await apiService.results.listOfEventAsync(selectedEvent.id, {
                        fieldSorting: defaultResultSorting[0],
                        pageSize: 100,
                        pageIndex: lastResult === undefined ? 0 : lastResult.pageIndex + 1,
                    });
                    results.push(...lastResult.items);
                } while (results.length < lastResult.count);
            }
            await delayAsync(config.artificialDelayForHeavyOperations);
            return {
                groups,
                participants,
                results,
            };
        },
        { cacheTime: 0 },
    );

    const handleClose = () => {
        close();
    };

    const handleClosingButtonClick = () => {
        close();
    };

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        content = <QueryError query={query} />;
    }
    if (content !== undefined) {
        content = (
            <DialogContent
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <Box
                    alignItems="center"
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                >
                    {content}
                </Box>
            </DialogContent>
        );
    }
    if (query.isSuccess) {
        content = (
            <StatisticsDialog__LoadedContent
                groups={query.data.groups}
                options={options}
                participants={query.data.participants}
                results={query.data.results}
            />
        );
    }
    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={isOpen}
            TransitionComponent={FullScreenDialogTransition}
        >
            <Box display="flex" flexDirection="column" height="100%">
                <AppBar position="relative" sx={{ zIndex: 1 }}>
                    <Toolbar>
                        <IconButton color="inherit" edge="start" onClick={handleClosingButtonClick}>
                            <CloseIcon />
                        </IconButton>
                        <Typography ml={2} variant="h5">
                            Statistiken
                        </Typography>
                    </Toolbar>
                </AppBar>
                {content}
            </Box>
        </Dialog>
    );
};

export { StatisticsDialog };
