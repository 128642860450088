import { Edit as EditIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Typography,
} from "@mui/material";
import React from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import paperTextureImage from "@/assets/paper-texture.jpg";

import { CertTemplateSetImageOverlayUpdateDialog } from "./cert-template-set-image-overlay-update-dialog";
import { getCertTemplateSetImageOverlayUpdateDialogModeObject } from "./cert-template-set-image-overlay-update-dialog/cert-template-set-image-overlay-update-dialog-mode";

const CertTemplateSetPage__ImageOverlayCard = ({ mode, certTemplateSet }) => {
    const updateDialogDisplayer = useDialogDisplayer(CertTemplateSetImageOverlayUpdateDialog);

    const handleUpdateButtonClick = () => {
        updateDialogDisplayer.open({ mode });
    };

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: 300,
                }}
            >
                <CardMedia
                    sx={{
                        flex: 1,
                        userSelect: "none",
                    }}
                >
                    <Box
                        sx={{
                            aspectRatio: 1,
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gridTemplateRows: "Auto",
                        }}
                    >
                        <Box
                            gridColumn={1}
                            gridRow={1}
                            sx={{
                                backgroundImage: `url(${paperTextureImage})`,
                                backgroundSize: "cover",
                                opacity: 0.5,
                            }}
                        />
                        <Box gridColumn={1} gridRow={1} zIndex={1}>
                            <img
                                alt=""
                                src={getCertTemplateSetImageOverlayUpdateDialogModeObject(
                                    certTemplateSet.rubberStampPngDataUrl,
                                    certTemplateSet.signaturePngDataUrl,
                                    mode,
                                )}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    // height: "100%",
                                }}
                            />
                        </Box>
                    </Box>
                </CardMedia>
                <Divider />
                <CardContent>
                    <Typography variant="h6">
                        {getCertTemplateSetImageOverlayUpdateDialogModeObject(
                            "Stempel",
                            "Unterschrift",
                            mode,
                        )}
                    </Typography>
                </CardContent>
                <CardActions
                    sx={{
                        gap: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <Button onClick={handleUpdateButtonClick} size="small" startIcon={<EditIcon />}>
                        Bearbeiten
                    </Button>
                </CardActions>
            </Card>
            {updateDialogDisplayer.element}
        </>
    );
};

export { CertTemplateSetPage__ImageOverlayCard };
