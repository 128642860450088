import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    SchoolType,
    formatSchoolType,
    germanStates,
    schoolValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, Select, TextField } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountUpdateDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.update.form, validationOptions),
        defaultValues: {
            name: account.name,
            type: account.type === undefined ? null : account.type,
            address: {
                street: account.address === undefined ? "" : account.address.street,
                houseNumber: account.address === undefined ? "" : account.address.houseNumber,
                postalCode: account.address === undefined ? "" : account.address.postalCode,
                city: account.address === undefined ? "" : account.address.city,
                state: account.address === undefined ? "" : account.address.state,
            },
        },
    });

    const mutation = useMutation(
        async (variables) => apiService.schools.updateAsync(variables.accountId, variables.school),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account geändert");
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            accountId: account.id,
            school: {
                name: data.name,
                type: data.type,
                address: {
                    street: data.address.street,
                    houseNumber: data.address.houseNumber,
                    postalCode: data.address.postalCode,
                    city: data.address.city,
                    state: data.address.state,
                },
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Account bearbeiten</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            fieldName="name"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Name *"
                        />
                        <Select
                            fieldName="type"
                            formatItem={formatSchoolType}
                            isEnabled={!mutation.isLoading}
                            items={Object.values(SchoolType)}
                            label="Schulart *"
                        />
                        <Box display="flex" flexDirection="row" gap={2}>
                            <Box flex={3}>
                                <TextField
                                    autoComplete="address-line1"
                                    fieldName="address.street"
                                    isEnabled={!mutation.isLoading}
                                    label="Straße *"
                                />
                            </Box>
                            <Box flex={2}>
                                <TextField
                                    autoComplete="address-line2"
                                    fieldName="address.houseNumber"
                                    isEnabled={!mutation.isLoading}
                                    label="Hausnummer *"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" gap={2}>
                            <Box flex={2}>
                                <TextField
                                    autoComplete="postal-code"
                                    fieldName="address.postalCode"
                                    isEnabled={!mutation.isLoading}
                                    label="Postleitzahl *"
                                />
                            </Box>
                            <Box flex={3}>
                                <TextField
                                    autoComplete="address-level2"
                                    fieldName="address.city"
                                    isEnabled={!mutation.isLoading}
                                    label="Stadt *"
                                />
                            </Box>
                        </Box>
                        <Select
                            fieldName="address.state"
                            isEnabled={!mutation.isLoading}
                            items={germanStates}
                            label="Bundesland *"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<EditIcon />}
                        type="submit"
                    >
                        Ändern
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AccountUpdateDialog };
