import { Environment, config as commonConfig, getEnvironment, mergeConfigs } from "@bujus/common";

import { generateDevelopmentConfig } from "./generate-config.development";
import { generateProductionConfig } from "./generate-config.production";
import { generateStagingConfig } from "./generate-config.staging";

let config;
switch (getEnvironment()) {
    case Environment.DEVELOPMENT:
        config = mergeConfigs(commonConfig, generateDevelopmentConfig());
        break;
    case Environment.STAGING:
        config = mergeConfigs(
            commonConfig,
            mergeConfigs(generateDevelopmentConfig(), generateStagingConfig()),
        );
        break;
    case Environment.PRODUCTION:
        config = mergeConfigs(
            commonConfig,
            mergeConfigs(generateDevelopmentConfig(), generateProductionConfig()),
        );
        break;
    default:
        throw new Error(`Invalid environment: ${getEnvironment()}`);
}

export { config };
