import { useCallback, useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const useCookie = (key, defaultValue, parse = (value) => value) => {
    const [value, _setValue] = useState(
        parse(cookies.get(key) === undefined ? defaultValue : cookies.get(key)),
    );

    const setValue = useCallback(
        (newValue) => {
            cookies.set(key, newValue, { path: "/" });
            _setValue(newValue);
        },
        [key],
    );

    const removeCookie = useCallback(() => {
        cookies.remove(key, { path: "/" });
        _setValue(undefined);
    }, [key]);

    useEffect(() => {
        if (cookies.get(key) !== undefined || defaultValue === undefined) {
            return;
        }
        cookies.set(key, defaultValue, { path: "/" });
    }, [key, defaultValue]);

    return useMemo(() => [value, setValue, removeCookie], [value, setValue, removeCookie]);
};

export { useCookie };
