import { Logout as LogoutIcon, Person as PersonIcon } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { useAccountContext } from "@/contexts";

const TopBar__AccountMenuButton = () => {
    const navigate = useNavigate();

    const account = useAccountContext();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const anchorElementRef = useRef();

    const handleClick = () => {
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleAccountMenuItemClick = () => {
        setIsMenuOpen(false);
        navigate(schoolAppRoutes(true).dashboard().account().build());
    };

    const handleLogoutMenuItemClick = () => {
        setIsMenuOpen(false);
        navigate(schoolAppRoutes(true).logout().build());
    };

    return (
        <>
            <IconButton
                ref={anchorElementRef}
                color="inherit"
                cyId="top-bar__accout-menu-button-icon-button"
                onClick={handleClick}
            >
                <Avatar
                    sx={{
                        height: 24,
                        width: 24,
                    }}
                >
                    <Typography>{account.name[0].toUpperCase()}</Typography>
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorElementRef.current}
                keepMounted
                onClose={handleMenuClose}
                open={isMenuOpen}
            >
                <MenuItem onClick={handleAccountMenuItemClick}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    {account.name}
                </MenuItem>
                <MenuItem onClick={handleLogoutMenuItemClick}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Abmelden
                </MenuItem>
            </Menu>
        </>
    );
};

export { TopBar__AccountMenuButton };
