import { Add as AddIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { Order, defaultGroupSorting } from "@bujus/common";
import {
    listItemsWithFuse,
    useApiServiceContext,
    useFormControllerContext,
} from "@bujus/common-frontend";

import { AutocompleteSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const GroupSelect = ({ creationDialogDisplayer, fieldName, isEnabled = true, label }) => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const query = useQuery(
        ["groups", selectedEvent.id],
        async ({ queryKey: key }) => apiService.groups.getAllAsync(key[1]),
        { initialData: [] },
    );

    const handleAutocompleteSelectListItemsAsync = async (listingModifiers) =>
        // TODO Maybe await query fetch?
        listItemsWithFuse(
            query.data,
            [
                {
                    name: "name",
                    getFn: (group) => group.name,
                },
            ],
            defaultGroupSorting,
            listingModifiers,
        );
    const handleCreationDialogCreated = (group) => {
        fieldController.field.onChange(group);
    };

    const creationButtonClick = () => {
        creationDialogDisplayer.open({ onCreated: handleCreationDialogCreated });
    };

    return (
        <Box display="flex">
            <Box flex={1}>
                <AutocompleteSelect
                    baseQueryKey={["groups", "getAll", selectedEvent.id]}
                    fieldName={fieldName}
                    fieldSorting={{
                        path: "name",
                        order: Order.ASCENDING,
                    }}
                    formatItem={(group) => group.name}
                    getKeyFromItem={(group) => group.id}
                    isEnabled={isEnabled}
                    isLocalData
                    isShortSearchValue
                    label={label}
                    listItemsAsync={handleAutocompleteSelectListItemsAsync}
                />
            </Box>
            <Box display="flex" flexDirection="column" height={56} justifyContent="center" ml={1}>
                <IconButton disabled={!isEnabled} onClick={creationButtonClick}>
                    <AddIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export { GroupSelect };
