import { Print as PrintIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";

import { EventFormat, formatCertType, generateFullName } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const CertPrintingDialog = ({ cert, close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(async (variables) => apiService.certs.printAsync(variables.id), {
        onError: (error) => {
            snackbarsController.enqueueError(error);
        },
        onSuccess: (data) => {
            snackbarsController.enqueueSuccessMessage("Urkunde gedruckt");
            snackbarsController.enqueuePopUpWarning();
            close();
            window.open(data, "_blank");
        },
    });

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ id: cert.id });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Urkunde drucken</DialogTitle>
            <DialogClosingButton
                isDisabled={mutation.isLoading}
                onClick={handleClosingButtonClick}
            />
            <DialogContent>
                <DialogContentText>
                    <Typography variant="dialogContentTextLong">
                        <b>Teilnehmer Name:</b>{" "}
                        {generateFullName(cert.participant.name.first, cert.participant.name.last)}
                    </Typography>
                    {cert.participant.schoolInternalId !== undefined && (
                        <Typography variant="dialogContentTextLong">
                            <b>Teilnehmer Schulinterne-ID:</b> {cert.participant.schoolInternalId}
                        </Typography>
                    )}
                    <Typography variant="dialogContentTextLong">
                        {selectedEvent.format === EventFormat.CONTEST ? (
                            <>
                                <b>Rang: </b> {cert.points} von {cert.groupParticipantCount}
                            </>
                        ) : (
                            <>
                                <b>Punkte: </b> {cert.points}
                            </>
                        )}
                    </Typography>
                    <Typography variant="dialogContentTextLong">
                        <b>Typ:</b> {formatCertType(cert.type)}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                    startIcon={<PrintIcon />}
                >
                    Drucken
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { CertPrintingDialog };
