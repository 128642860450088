import { useDroppable } from "@dnd-kit/core";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";

import { EventFormat, SpecialGroupId, formatClassLevelRange } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

// TODO Memoizing the "presentational component" https://github.com/clauderic/dnd-kit/discussions/1156

const AllGroupsUpdateDialog__SelectOption = ({ group, onSelection, isSelected, isDropzone }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const { isOver, setNodeRef } = useDroppable({
        id: group.id,
        disabled: isSelected,
    });

    const handleClick = () => {
        onSelection(group.id);
    };

    return (
        <Card
            ref={setNodeRef}
            onClick={handleClick}
            sx={{
                ...(isSelected && { backgroundColor: "background.primary" }),
                ...(isDropzone && { borderStyle: "dashed" }),
                borderWidth: 2,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                textAlign: "center",
                ...(isOver
                    ? {
                          transform: "scale(0.95)",
                          borderColor: "primary.main",
                      }
                    : undefined),
                transition: "transform 0.2s ease-in-out, border-color 0.2s ease-in-out",
            }}
        >
            <CardActionArea
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        p: 1,
                    }}
                >
                    <Typography flex={1} sx={{ wordBreak: "break-word" }}>
                        {group.name}
                    </Typography>
                    <Typography color="secondary" mt={0} variant="body2">
                        {group.participants.length} Teilnehmer
                    </Typography>
                    {selectedEvent.format === EventFormat.CONTEST &&
                        group.id !== SpecialGroupId.UNALLOCATED && (
                            <Typography color="secondary" mt={0} variant="body2">
                                Klassenstufe: {formatClassLevelRange(group.classLevelRange)}
                            </Typography>
                        )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};
export { AllGroupsUpdateDialog__SelectOption };
