import queryStringUtilities from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useQueryParams, useSnackbarsController } from "@/hooks";

const MessagesDisplayer = () => {
    const queryParams = useQueryParams();
    const navigate = useNavigate();

    const snackbarsController = useSnackbarsController();

    useEffect(() => {
        if (queryParams.errorMessage !== undefined) {
            snackbarsController.enqueueErrorMessage(queryParams.errorMessage);
        }
        if (queryParams.successMessage !== undefined) {
            snackbarsController.enqueueSuccessMessage(queryParams.successMessage);
        }
        delete queryParams.errorMessage;
        delete queryParams.successMessage;
        navigate({ search: queryStringUtilities.stringify(queryParams) });
    }, []);

    return undefined;
};

export { MessagesDisplayer };
