import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as dateFns from "date-fns";
import React from "react";

import { formatDate } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const EventRemovalCancellationAlert = ({ containerProps }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) => apiService.events.cancelRemovalAsync(variables.eventId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Veranstaltungs Löschung abgebrochen");
                queryClient.invalidateQueries(["events"]);
            },
        },
    );

    const handleButtonClick = () => {
        mutation.mutate({ eventId: selectedEvent.id });
    };

    if (
        selectedEvent.removalIntent === undefined ||
        dateFns.isBefore(selectedEvent.removalIntent.expires, new Date())
    ) {
        return undefined;
    }
    return (
        <Box {...containerProps}>
            <Alert
                action={
                    <LoadingButton
                        color="inherit"
                        loading={mutation.isLoading}
                        onClick={handleButtonClick}
                        size="small"
                    >
                        Abbrechen
                    </LoadingButton>
                }
                severity="error"
            >
                <AlertTitle>Aktuelle Veranstaltung-Löschung</AlertTitle>
                Bis zum {formatDate(selectedEvent.removalIntent.expires, "Pp")} Uhr möglich
            </Alert>
        </Box>
    );
};

export { EventRemovalCancellationAlert };
