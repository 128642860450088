import React from "react";

import { colors } from "@bujus/common";

import { App__Framework } from "./app__framework";
import { App__Loader } from "./app__loader";
import { App__Router } from "./app__router";
import { BugsnagErrorBoundaryWrapper } from "./bugsnag-error-boundary-wrapper";

document.documentElement.style.setProperty("--primary-color", colors.amaranthShades[500]);

const App = () => (
    <BugsnagErrorBoundaryWrapper>
        <App__Framework>
            <App__Loader>
                <App__Router />
            </App__Loader>
        </App__Framework>
    </BugsnagErrorBoundaryWrapper>
);

export { App };
