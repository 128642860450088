import { Close as CloseIcon } from "@mui/icons-material";
import {
    AppBar,
    Dialog,
    DialogContent,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Toolbar,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useReducer } from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { FullScreenDialogTransition } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { participantsImportDialogStateMachine } from "./participants-import-dialog-state-machine";
import { ParticipantsImportDialog__DataSelectionStepContent } from "./participants-import-dialog__data-selection-step-content";
import { ParticipantsImportDialog__FileSettingsStepContent } from "./participants-import-dialog__file-settings-step-content";
import { ParticipantsImportDialog__FileUploadStepContent } from "./participants-import-dialog__file-upload-step-content";
import { ParticipantsImportDialog__ReviewStepContent } from "./participants-import-dialog__review-step-content";

const ParticipantsImportDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [state, dispatchState] = useReducer(
        participantsImportDialogStateMachine.reducer,
        participantsImportDialogStateMachine.initialState,
    );

    const mutation = useMutation(
        async (variables) =>
            apiService.participants.importAsync(
                variables.eventId,
                variables.areSchoolInternalIdsSpecified,
                variables.areClassesSpecified,
                variables.areGroupsSpecified,
                variables.participants,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Teilnehmer importiert");
                queryClient.invalidateQueries(["participants"]);
                dispatchState({
                    eventFormat: selectedEvent.format,
                    type: participantsImportDialogStateMachine.Action.NEXT,
                });
            },
        },
    );

    const displayStep = participantsImportDialogStateMachine.convertStepToDisplayStep(state.step);

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleFileUploadStepContentNext = (data) => {
        dispatchState({
            eventFormat: selectedEvent.format,
            type: participantsImportDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleFileSettingsStepContentNext = (data) => {
        dispatchState({
            eventFormat: selectedEvent.format,
            type: participantsImportDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };
    const handleFileSettingsStepContentPrevious = () => {
        dispatchState({
            eventFormat: selectedEvent.format,
            type: participantsImportDialogStateMachine.Action.PREVIOUS,
        });
    };

    const handleDataSelectionStepContentImport = async (data) => {
        mutation.mutate({
            eventId: selectedEvent.id,
            ...data,
        });
    };

    const handleDataSelectionStepContentPrevious = () => {
        dispatchState({
            eventFormat: selectedEvent.format,
            type: participantsImportDialogStateMachine.Action.PREVIOUS,
        });
        // mutation.reset();
    };

    const handleReviewStepContentOk = () => {
        tryClose();
    };

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={isOpen}
            TransitionComponent={FullScreenDialogTransition}
        >
            <AppBar position="relative" sx={{ zIndex: 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        disabled={mutation.isLoading}
                        edge="start"
                        onClick={handleClosingButtonClick}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography ml={2} variant="h5">
                        Teilnehmer importieren
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Stepper activeStep={displayStep} orientation="vertical">
                    <Step>
                        <StepLabel>CSV-Datei hochladen</StepLabel>
                        <StepContent>
                            <ParticipantsImportDialog__FileUploadStepContent
                                defaultFormData={state.formDatas.fileUpload}
                                onNext={handleFileUploadStepContentNext}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Datei einstellen</StepLabel>
                        <StepContent>
                            <ParticipantsImportDialog__FileSettingsStepContent
                                defaultFormData={state.formDatas.fileSettings}
                                fileDatas={state.formDatas.fileUpload.fileDatas}
                                onNext={handleFileSettingsStepContentNext}
                                onPrevious={handleFileSettingsStepContentPrevious}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Daten auswählen</StepLabel>
                        <StepContent>
                            <ParticipantsImportDialog__DataSelectionStepContent
                                defaultFormData={state.formDatas.dataSelection}
                                fileDatas={state.formDatas.fileUpload.fileDatas}
                                isFirstRowContainingHeadings={
                                    state.formDatas.fileSettings.isFirstRowContainingHeadings
                                }
                                mutation={mutation}
                                onImport={handleDataSelectionStepContentImport}
                                onPrevious={handleDataSelectionStepContentPrevious}
                            />
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Import überprüfen</StepLabel>
                        <StepContent>
                            <ParticipantsImportDialog__ReviewStepContent
                                mutation={mutation}
                                onOk={handleReviewStepContentOk}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
            </DialogContent>
        </Dialog>
    );
};

export { ParticipantsImportDialog };
