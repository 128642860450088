import { Check as CheckIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { AppErrorType } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { HorizontalCenterErrorMessage, LoginButton } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountMailAddressVerificationPage__Logic = ({ token }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) => apiService.schools.verifyMailAddressAsync(variables.token),
        {
            onError: (error) => {
                if (error.type === AppErrorType.INVALID.MAIL_ADDRESS_VERIFICATION_TOKEN) {
                    return;
                }
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account Email-Adresse bestätigt");
                if (variables.accountId === undefined) {
                    return;
                }
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
            },
        },
    );

    const handleButtonClick = () => {
        mutation.mutate({
            ...(account !== undefined && { accountId: account.id }),
            token,
        });
    };

    if (
        mutation.isError &&
        mutation.error.type === AppErrorType.INVALID.MAIL_ADDRESS_VERIFICATION_TOKEN
    ) {
        return (
            <>
                <HorizontalCenterErrorMessage error={mutation.error} />
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </>
        );
    }
    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <LoadingButton
                fullWidth
                loading={mutation.isLoading}
                onClick={handleButtonClick}
                startIcon={<CheckIcon />}
                variant="contained"
            >
                Email-Adresse bestätigen
            </LoadingButton>
        );
    }
    if (mutation.isSuccess) {
        return (
            <>
                <Typography align="center">
                    Sie könnnen sich jetzt mit {mutation.data.mailAddress} anmelden.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    return undefined;
};

export { AccountMailAddressVerificationPage__Logic };
