import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import React from "react";

import { formatUnit } from "@bujus/common";

import { DialogClosingButton } from "@/base";

const DisciplineInformationDialog = ({ close, discipline, isOpen }) => {
    const handleClose = () => {
        close();
    };

    const handleClosingButtonClick = () => {
        close();
    };

    return (
        <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
            <DialogTitle>{discipline.name}</DialogTitle>
            <DialogClosingButton onClick={handleClosingButtonClick} />
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        <b>Einheit:</b> {formatUnit(discipline.inputUnit)}
                    </Typography>
                    {discipline.note !== undefined && (
                        <Typography>
                            <b>Anweisung:</b> {discipline.note}
                        </Typography>
                    )}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export { DisciplineInformationDialog };
