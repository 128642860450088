import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { AuthenticatedRoute } from "@/base";

import { AccountDashboard } from "./account-dashboard";
import { AccountLogoutPage } from "./account-logout-page";
import { Dashboard } from "./dashboard";
import { PageNotFoundPage } from "./page-not-found-page";

const App__Router = () => (
    <Routes>
        <Route element={<PageNotFoundPage />} path={schoolAppRoutes(true).pageNotFound().build()} />
        <Route
            element={
                <AuthenticatedRoute>
                    <AccountLogoutPage />
                </AuthenticatedRoute>
            }
            path={schoolAppRoutes(true).logout().build()}
        />
        <Route
            element={<AccountDashboard />}
            path={`${schoolAppRoutes(true).account().build()}/*`}
        />
        <Route
            element={
                <AuthenticatedRoute>
                    <Dashboard />
                </AuthenticatedRoute>
            }
            path={`${schoolAppRoutes(true).dashboard().build()}/*`}
        />
        <Route element={<Navigate to={schoolAppRoutes(true).account().build()} />} index />
        <Route element={<Navigate to={schoolAppRoutes(true).pageNotFound().build()} />} path="*" />
    </Routes>
);

export { App__Router };
