import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowBack as ArrowBackIcon, FileUpload as FileUploadIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, Typography, useTheme } from "@mui/material";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { participantValidationSchemas, validationOptions } from "@bujus/common";

import { Checkbox, Collapse, DataTable, Form, Select } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { generateDataTableDataProps, generateImportColumnSelectDataProps } from "@/utilities";

const ParticipantsImportDialog__DataSelectionStepContent = ({
    defaultFormData,
    fileDatas,
    isFirstRowContainingHeadings,
    mutation,
    onImport,
    onPrevious,
}) => {
    const theme = useTheme();

    const [selectedEvent] = useSelectedEventStateContext();

    const [participantsValidationErrors, setParticipantsValidationErrors] = useState();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            participantValidationSchemas.import.forms.dataSelection,
            validationOptions,
        ),
        context: { event: selectedEvent },
        defaultValues: defaultFormData,
    });
    const areSchoolInternalIdsSpecifiedFieldValue = useWatch({
        control: formController.control,
        name: "areSchoolInternalIdsSpecified",
    });
    const schoolInternalIdColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "schoolInternalIdColumnIndex",
    });
    const firstNameColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "name.firstColumnIndex",
    });
    const lastNameColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "name.lastColumnIndex",
    });
    const genderColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "genderColumnIndex",
    });
    const birthYearColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "birthYearColumnIndex",
    });
    const areClassesSpecifiedFieldValue = useWatch({
        control: formController.control,
        name: "areClassesSpecified",
    });
    const classColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "classColumnIndex",
    });
    const areGroupsSpecifiedFieldValue = useWatch({
        control: formController.control,
        name: "areGroupsSpecified",
    });
    const groupColumnIndexFieldValue = useWatch({
        control: formController.control,
        name: "groupColumnIndex",
    });

    const realFileDatas = fileDatas.slice(isFirstRowContainingHeadings ? 1 : 0);

    const previewDataTableColumns = [
        {
            fieldName: "schoolInternalId",
            heading: "Schulinterne-ID",
            getValue: (item) =>
                !areSchoolInternalIdsSpecifiedFieldValue
                    ? ""
                    : item[schoolInternalIdColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "name.first",
            heading: "Vorname",
            getValue: (item) => item[firstNameColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "name.last",
            heading: "Nachname",
            getValue: (item) => item[lastNameColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "gender",
            heading: "Geschlecht",
            getValue: (item) => item[genderColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "birthYear",
            heading: "Geburtsjahr",
            getValue: (item) => item[birthYearColumnIndexFieldValue],
            width: 1,
        },
        {
            fieldName: "class",
            heading: "Klasse",
            getValue: (item) =>
                !areClassesSpecifiedFieldValue
                    ? ""
                    : item[Object.keys(item)[classColumnIndexFieldValue]],
            width: 1,
        },
        {
            fieldName: "group",
            heading: "Riege",
            getValue: (item) =>
                !areGroupsSpecifiedFieldValue
                    ? ""
                    : item[Object.keys(item)[groupColumnIndexFieldValue]],
            width: 1,
        },
    ];

    const participantsValidationErrorsDataTableColumns = [
        {
            fieldName: "row",
            heading: "Zeile",
            getValue: (item) => item.path[1] + 1,
            width: 1,
        },
        {
            fieldName: "fieldName",
            heading: "Spalte",
            getValue: (item) => {
                let fieldName = "";
                switch (item.path[2]) {
                    case "schoolInternalId":
                        fieldName = "Schulinterne-ID";
                        break;
                    case "name":
                        if (item.path[3] === "first") {
                            fieldName = "Vorname";
                        } else if (item.path[3] === "last") {
                            fieldName = "Nachname";
                        }
                        break;
                    case "gender":
                        fieldName = "Geschlecht";
                        break;
                    case "birthYear":
                        fieldName = "Geburtsjahr";
                        break;
                    case "class":
                        fieldName = "Klasse";
                        break;
                    case "group":
                        fieldName = "Riege";
                        break;
                    default:
                        break;
                }
                return fieldName;
            },
            width: 1,
        },
        {
            fieldName: "value",
            heading: "Wert",
            getValue: (item) => item.context.value,
            width: 1,
        },
        {
            fieldName: "message",
            heading: "Fehler",
            getValue: (item) => item.message,
            width: 2,
        },
    ];

    const handleFormSubmitAsync = async (data) => {
        if (mutation.isLoading) {
            return;
        }
        let validatedData;
        try {
            validatedData = await participantValidationSchemas.import.forms.data.validateAsync(
                {
                    areSchoolInternalIdsSpecified: data.areSchoolInternalIdsSpecified,
                    areClassesSpecified: data.areClassesSpecified,
                    areGroupsSpecified: data.areGroupsSpecified,
                    participants: await Promise.all(
                        fileDatas.slice(isFirstRowContainingHeadings ? 1 : 0).map(async (x) => ({
                            tempId: nanoid(),
                            ...(!data.areSchoolInternalIdsSpecified ||
                                (x[data.schoolInternalIdColumnIndex] !== "" && {
                                    schoolInternalId: x[data.schoolInternalIdColumnIndex],
                                })),
                            name: {
                                first: x[data.name.firstColumnIndex],
                                last: x[data.name.lastColumnIndex],
                            },
                            gender: x[data.genderColumnIndex],
                            birthYear: x[data.birthYearColumnIndex],
                            ...(data.areClassesSpecified &&
                                x[data.classColumnIndex] !== "" && {
                                    class: x[data.classColumnIndex],
                                }),
                            ...(data.areGroupsSpecified &&
                                x[data.groupColumnIndex] !== "" && {
                                    groupName: x[data.groupColumnIndex],
                                }),
                        })),
                    ),
                },
                {
                    ...validationOptions,
                    context: { event: selectedEvent },
                },
            );
        } catch (error) {
            setParticipantsValidationErrors(error.details);
            return;
        }
        await onImport(validatedData);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmitAsync}>
            <Box display="flex" flexDirection="column" gap={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Vorschau der Datei</Typography>
                        <Box mt={2}>
                            <DataTable
                                isPreview
                                {...generateDataTableDataProps(
                                    fileDatas,
                                    isFirstRowContainingHeadings,
                                )}
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Box maxWidth={theme.breakpoints.values.sm}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Daten Auswahl</Typography>
                            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                                <Checkbox
                                    fieldName="areSchoolInternalIdsSpecified"
                                    isEnabled={!mutation.isLoading}
                                    label="Schulinterne-IDs angeben"
                                />
                                <Collapse
                                    isOpen={areSchoolInternalIdsSpecifiedFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="schoolInternalIdColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Schulinterne-ID *"
                                    />
                                </Collapse>
                                <Select
                                    {...generateImportColumnSelectDataProps(
                                        fileDatas,
                                        isFirstRowContainingHeadings,
                                    )}
                                    fieldName="name.firstColumnIndex"
                                    isEnabled={!mutation.isLoading}
                                    label="Vorname *"
                                />
                                <Select
                                    {...generateImportColumnSelectDataProps(
                                        fileDatas,
                                        isFirstRowContainingHeadings,
                                    )}
                                    fieldName="name.lastColumnIndex"
                                    isEnabled={!mutation.isLoading}
                                    label="Nachname *"
                                />
                                <Select
                                    {...generateImportColumnSelectDataProps(
                                        fileDatas,
                                        isFirstRowContainingHeadings,
                                    )}
                                    fieldName="genderColumnIndex"
                                    isEnabled={!mutation.isLoading}
                                    label="Geschlecht *"
                                />
                                <Select
                                    {...generateImportColumnSelectDataProps(
                                        fileDatas,
                                        isFirstRowContainingHeadings,
                                    )}
                                    fieldName="birthYearColumnIndex"
                                    isEnabled={!mutation.isLoading}
                                    label="Geburtsjahr oder -datum *"
                                />
                                <Checkbox
                                    fieldName="areClassesSpecified"
                                    isEnabled={!mutation.isLoading}
                                    label="Klassen angeben"
                                />
                                <Collapse
                                    isOpen={areClassesSpecifiedFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="classColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Klasse *"
                                    />
                                </Collapse>
                                <Checkbox
                                    fieldName="areGroupsSpecified"
                                    isEnabled={!mutation.isLoading}
                                    label="Riegen angeben"
                                />
                                <Collapse
                                    isOpen={areGroupsSpecifiedFieldValue}
                                    overwritingTopMargin={2}
                                >
                                    <Select
                                        {...generateImportColumnSelectDataProps(fileDatas)}
                                        fieldName="groupColumnIndex"
                                        isEnabled={!mutation.isLoading}
                                        label="Riege *"
                                    />
                                </Collapse>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Vorschau der Teilnehmer</Typography>
                        <Typography color="text.secondary" mt={2}>
                            Anzahl Teilnehmer zu importieren: {realFileDatas.length}
                        </Typography>
                        <Box mt={2}>
                            <DataTable
                                columns={previewDataTableColumns}
                                isPreview
                                items={realFileDatas}
                            />
                        </Box>
                    </CardContent>
                </Card>
                {participantsValidationErrors !== undefined &&
                    participantsValidationErrors.length > 0 && (
                        <Card sx={{ borderColor: "error.main" }}>
                            <CardContent>
                                <Typography variant="h6">Validierungsfehler</Typography>
                                <Typography color="text.secondary" mt={2}>
                                    Anzahl: {participantsValidationErrors.length}
                                </Typography>
                                <Box mt={2}>
                                    <DataTable
                                        columns={participantsValidationErrorsDataTableColumns}
                                        items={participantsValidationErrors}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    )}
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    disabled={mutation.isLoading}
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <LoadingButton
                    loading={mutation.isLoading}
                    startIcon={<FileUploadIcon />}
                    type="submit"
                >
                    Importieren
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { ParticipantsImportDialog__DataSelectionStepContent };
