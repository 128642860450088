import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

import { useSelectedEventStateContext } from "@/contexts";

const EventPage__LimitsCard = () => {
    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Limitierungen</Typography>
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                    <Typography>
                        <b>Max. Helferanzahl:</b> {selectedEvent.limits.helper}
                    </Typography>
                    <Typography>
                        <b>Max. Teilnehmeranzahl:</b> {selectedEvent.limits.participant}
                    </Typography>
                    <Typography>
                        <b>Max. Riegenanzahl:</b> {selectedEvent.limits.group}
                    </Typography>
                    <Typography>
                        <b>Max. Ergebnisanzahl:</b> {selectedEvent.limits.result}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export { EventPage__LimitsCard };
