import { joiResolver } from "@hookform/resolvers/joi";
import { Login as LoginIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { schoolAppRoutes, schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext, useAuthorizationJwtStateContext } from "@bujus/common-frontend";

import { Form, PasswordField, TextField } from "@/base";
import {
    useAccountDashboardMailAddressStateContext,
    useAccountDashboardTitleStateContext,
} from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountLoginPage = () => {
    const navigate = useNavigate();

    const snackbarsController = useSnackbarsController();
    const [, setAuthorizationJwt] = useAuthorizationJwtStateContext();
    const apiService = useApiServiceContext();
    const [, setAccountDashboardTitle] = useAccountDashboardTitleStateContext();
    const [accountDashboardMailAddress, setAccountDashboardMailAddress] =
        useAccountDashboardMailAddressStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.login.body, validationOptions),
        defaultValues: {
            mailAddress: accountDashboardMailAddress,
            password: "",
        },
    });

    const mailAddressFieldValue = useWatch({
        control: formController.control,
        name: "mailAddress",
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.loginAsync(variables.mailAddress, variables.password),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (newAuthorizationJwt) => {
                setAuthorizationJwt(newAuthorizationJwt);
                // navigate(schoolAppRoutes(true).dashboard().build(), { replace: true });
            },
        },
    );

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate(data);
    };

    const handleForgotPasswordButtonClick = () => {
        navigate(schoolAppRoutes(true).account().passwordForgotten().build());
    };

    const handleSignUpButtonClick = () => {
        navigate(schoolAppRoutes(true).account().signUp().build());
    };

    useEffect(() => {
        setAccountDashboardTitle("Anmeldung");
    }, []);

    useEffect(() => {
        setAccountDashboardMailAddress(mailAddressFieldValue);
    }, [mailAddressFieldValue]);

    return (
        <Box>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        autoComplete="email"
                        cyId="AccountLoginPage__mail-address-field"
                        fieldName="mailAddress"
                        isAutofocusing
                        isEnabled={!mutation.isLoading}
                        label="Email-Adresse *"
                        type="email"
                    />
                    <PasswordField
                        autoComplete="current-password"
                        cyId="AccountLoginPage__password-field"
                        fieldName="password"
                        isEnabled={!mutation.isLoading}
                        label="Passwort *"
                    />
                    <LoadingButton
                        cyId="AccountLoginPage__login-button"
                        fullWidth
                        loading={mutation.isLoading}
                        startIcon={<LoginIcon />}
                        type="submit"
                        variant="contained"
                    >
                        Anmelden
                    </LoadingButton>
                </Box>
            </Form>
            <Box display="flex" gap={1} justifyContent="space-between" mt={2}>
                <Button color="secondary" onClick={handleForgotPasswordButtonClick}>
                    Passwort vergessen?
                </Button>
                <Button color="secondary" onClick={handleSignUpButtonClick}>
                    Account erstellen
                </Button>
            </Box>
        </Box>
    );
};

export { AccountLoginPage };
