import { Description as DescriptionIcon, Task as TaskIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Link,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { DpaState } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { config } from "@/config";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { AccountDpaRequestDialog } from "./account-dpa-request-dialog";

const AccountPage__DataPrivacyCard = () => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const dpaRequestDialogDisplayer = useDialogDisplayer(AccountDpaRequestDialog);

    const updateMutation = useMutation(
        async (variables) =>
            apiService.schools.updateDataPrivacyAsync(variables.accountId, variables.dataPrivacy),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Datenschutzkonforme Nutzung geändert");
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
            },
        },
    );

    const printingMutation = useMutation(
        async (variables) => apiService.schools.printDpaAsync(variables.accountId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (data) => {
                snackbarsController.enqueueSuccessMessage("Auftragsverarbeitungsvertrag gedruckt");
                snackbarsController.enqueuePopUpWarning();
                window.open(data, "_blank");
            },
        },
    );

    const handleDpaRequestButtonClick = () => {
        dpaRequestDialogDisplayer.open();
    };

    const handleDpaSigningButtonClick = () => {
        window.open(account.dataPrivacy.dpa.eSignaturesContract.signaturePageUrl, "_blank");
    };

    const handleDpaPrintingButtonClick = () => {
        printingMutation.mutate({ accountId: account.id });
    };

    const handleIsDataPrivacyPolicyUpdatedCheckboxChange = (event) => {
        if (updateMutation.isLoading) {
            return;
        }
        updateMutation.mutate({
            accountId: account.id,
            dataPrivacy: { isDataPrivacyPolicyUpdated: event.target.checked },
        });
    };

    const handleIsConsentFormCollectedCheckboxChange = (event) => {
        if (updateMutation.isLoading) {
            return;
        }
        updateMutation.mutate({
            accountId: account.id,
            dataPrivacy: { isConsentFormCollected: event.target.checked },
        });
    };

    const handleIsGuideReadCheckboxChange = (event) => {
        if (updateMutation.isLoading) {
            return;
        }
        updateMutation.mutate({
            accountId: account.id,
            dataPrivacy: { isGuideRead: event.target.checked },
        });
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6">Datenschutzkonforme Nutzung</Typography>
                    <Typography color="secondary" mt={2}>
                        Um Bujus nach der DSGVO datenschutzkonform nutzen zu können, folgen Sie den{" "}
                        <Link href={config.urls.dataPrivacyCompliance} target="_blank">
                            4 Schritten auf unserer Webseite
                        </Link>
                        .
                    </Typography>
                    <Box
                        alignItems="flex-start"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        mt={2}
                    >
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={account.dataPrivacy.dpa.state === DpaState.SIGNED}
                                        disableRipple
                                        sx={{ cursor: "default" }}
                                    />
                                }
                                label="1. Auftragsverarbeitungsvertrag abschließen"
                                sx={{ cursor: "default" }}
                            />
                            <Box mt={2}>
                                {(() => {
                                    switch (account.dataPrivacy.dpa.state) {
                                        case DpaState.NONE:
                                            return (
                                                <Button
                                                    onClick={handleDpaRequestButtonClick}
                                                    size="small"
                                                    startIcon={<DescriptionIcon />}
                                                    variant="outlined"
                                                >
                                                    Vertrag anfragen
                                                </Button>
                                            );
                                        case DpaState.SENT:
                                            return (
                                                <Button
                                                    onClick={handleDpaSigningButtonClick}
                                                    size="small"
                                                    startIcon={<DescriptionIcon />}
                                                    variant="outlined"
                                                >
                                                    Vertrag unterzeichnen
                                                </Button>
                                            );
                                        case DpaState.SIGNED:
                                            return (
                                                <LoadingButton
                                                    loading={printingMutation.isLoading}
                                                    onClick={handleDpaPrintingButtonClick}
                                                    size="small"
                                                    startIcon={<TaskIcon />}
                                                    variant="outlined"
                                                >
                                                    Abgeschlossenen Vertrag drucken
                                                </LoadingButton>
                                            );
                                        default:
                                            throw new Error(
                                                `Invalid dpa state: ${account.dataPrivacy.dpa.state}`,
                                            );
                                    }
                                })()}
                            </Box>
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={account.dataPrivacy.isDataPrivacyPolicyUpdated}
                                    disabled={updateMutation.isLoading}
                                    onChange={handleIsDataPrivacyPolicyUpdatedCheckboxChange}
                                />
                            }
                            label="2. Datenschutzerklärung updaten"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={account.dataPrivacy.isConsentFormCollected}
                                    disabled={updateMutation.isLoading}
                                    onChange={handleIsConsentFormCollectedCheckboxChange}
                                />
                            }
                            label="3. Einverständniserklärungen einholen"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={account.dataPrivacy.isGuideRead}
                                    disabled={updateMutation.isLoading}
                                    onChange={handleIsGuideReadCheckboxChange}
                                />
                            }
                            label="4. Abschnitt zur datenschutzkonformen Nutzung lesen"
                        />
                    </Box>
                </CardContent>
            </Card>
            {dpaRequestDialogDisplayer.element}
        </>
    );
};

export { AccountPage__DataPrivacyCard };
