import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";

import { EventFormat, SpecialGroupId, formatClassLevelRange } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

const BigResultCreationDialog__GroupSelectOption = ({ group, isSelected, onSelection }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const handleClick = () => {
        onSelection(group);
    };

    return (
        <Card
            onClick={handleClick}
            sx={{
                ...(isSelected && { backgroundColor: "background.primary" }),
                // borderWidth: 1,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                textAlign: "center",
                transition: "background-color 0.2s ease-in-out",
            }}
        >
            <CardActionArea
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <CardContent
                    sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        p: 1,
                    }}
                >
                    <Typography flex={1} sx={{ wordBreak: "break-word" }}>
                        {group.name}
                    </Typography>
                    <Typography color="secondary" mt={0} variant="body2">
                        {group.participants.length} Teilnehmer
                    </Typography>
                    {selectedEvent.format === EventFormat.CONTEST &&
                        group.id !== SpecialGroupId.UNALLOCATED && (
                            <Typography color="secondary" mt={0} variant="body2">
                                {formatClassLevelRange(group.classLevelRange)}
                            </Typography>
                        )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export { BigResultCreationDialog__GroupSelectOption };
