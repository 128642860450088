import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { ButtonBase, Card, CardContent, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

import { Collapse } from "../collapse";

const CardCollapse = ({ isBigTitle = false, startIcon = undefined, title, children }) => {
    const theme = useTheme();

    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Card>
            <ButtonBase
                onClick={toggleIsOpen}
                sx={{
                    display: "block",
                    textAlign: "left",
                    width: "100%",
                }}
            >
                <CardContent>
                    <Box display="flex" justifySelf="center">
                        {startIcon !== undefined && <Box mr={1}>{startIcon}</Box>}
                        <Typography flex={1} {...(isBigTitle && { variant: "h6" })}>
                            {title}
                        </Typography>
                        <Box ml={1}>
                            <ExpandMoreIcon
                                color="primary"
                                sx={{
                                    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                                    transition: theme.transitions.create("transform", {
                                        duration: theme.transitions.duration.standard,
                                    }),
                                }}
                            />
                        </Box>
                    </Box>
                </CardContent>
            </ButtonBase>
            <Collapse isOpen={isOpen}>
                <CardContent>{children}</CardContent>
            </Collapse>
        </Card>
    );
};

export { CardCollapse };
