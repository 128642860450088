import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const CertTemplateUpdateDialog__NumberInput = ({
    fieldName,
    icon,
    isEnabled = true,
    isFlipped,
    isHorizontal,
    max,
    step,
}) => {
    const formController = useFormControllerContext();

    const increaseTimerRef = React.useRef();
    const decreaseTimerRef = React.useRef();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });
    const [value, setValue] = useState(fieldController.field.value);

    const getClampedIncreaseValue = (oldValue) => {
        if (oldValue + step > max) {
            return oldValue;
        }
        return oldValue + step;
    };

    const handleIncreaseButtonMouseDown = () => {
        setValue(getClampedIncreaseValue);
        increaseTimerRef.current = setTimeout(() => {
            increaseTimerRef.current = setInterval(() => {
                setValue(getClampedIncreaseValue);
            }, 50);
        }, 300);
    };

    const handleIncreaseButtonMouseUp = () => {
        // fieldController.field.onChange(value);
        clearTimeout(increaseTimerRef.current);
        clearInterval(increaseTimerRef.current);
    };

    const getClampedDecreaseValue = (oldValue) => {
        if (oldValue - step < -max) {
            return oldValue;
        }
        return oldValue - step;
    };

    const handleDecreaseButtonMouseDown = () => {
        setValue(getClampedDecreaseValue);
        decreaseTimerRef.current = setTimeout(() => {
            decreaseTimerRef.current = setInterval(() => {
                setValue(getClampedDecreaseValue);
            }, 50);
        }, 300);
    };

    const handleDecreaseButtonMouseUp = () => {
        // fieldController.field.onChange(value);
        clearTimeout(decreaseTimerRef.current);
        clearInterval(decreaseTimerRef.current);
    };

    const handleLeftButtonMouseDown = () => {
        if (isFlipped) {
            handleIncreaseButtonMouseDown();
            return;
        }
        handleDecreaseButtonMouseDown();
    };

    const handleLeftButtonMouseUp = () => {
        if (isFlipped) {
            handleIncreaseButtonMouseUp();
            return;
        }
        handleDecreaseButtonMouseUp();
    };

    const handleRightButtonMouseDown = () => {
        if (isFlipped) {
            handleDecreaseButtonMouseDown();
            return;
        }
        handleIncreaseButtonMouseDown();
    };

    const handleRightButtonMouseUp = () => {
        if (isFlipped) {
            handleDecreaseButtonMouseUp();
            return;
        }
        handleIncreaseButtonMouseUp();
    };

    useEffect(
        () => () => {
            clearInterval(increaseTimerRef.current);
            clearInterval(decreaseTimerRef.current);
        },
        [],
    );

    useEffect(() => {
        setValue(fieldController.field.value);
    }, [fieldController.field.value]);

    useEffect(() => {
        fieldController.field.onChange(value);
    }, [value]);

    return (
        <Box alignItems="center" display="flex" flex={1} justifyContent="center">
            <Box alignItems="center" display="flex">
                {icon}
            </Box>
            <Box alignItems="center" display="flex" flex={1} ml={1}>
                <IconButton
                    disabled={!isEnabled}
                    onMouseDown={handleLeftButtonMouseDown}
                    onMouseLeave={handleLeftButtonMouseUp}
                    onMouseUp={handleLeftButtonMouseUp}
                    size="small"
                >
                    {isHorizontal ? <KeyboardArrowLeftIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                {/* Measured with -99,9 */}
                <Typography
                    align="center"
                    ml={1}
                    width={36.79}
                    {...(!isEnabled && { color: "text.disabled" })}
                >
                    {value.toLocaleString("de-DE", {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                    })}
                </Typography>
                <Box ml={1}>
                    <IconButton
                        disabled={!isEnabled}
                        onMouseDown={handleRightButtonMouseDown}
                        onMouseLeave={handleRightButtonMouseUp}
                        onMouseUp={handleRightButtonMouseUp}
                        size="small"
                    >
                        {isHorizontal ? <KeyboardArrowRightIcon /> : <KeyboardArrowUpIcon />}
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
};

export { CertTemplateUpdateDialog__NumberInput };
