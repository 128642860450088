import { joiResolver } from "@hookform/resolvers/joi";
import { FileDownload as FileDownloadIcon, Print as PrintIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    CertSortingPreset,
    Order,
    certValidationSchemas,
    formatCertSortingPreset,
    formatOrder,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, RadioSelect, Select } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AllCertsPrintingDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(certValidationSchemas.allPrinting.form, validationOptions),
        defaultValues: {
            sorting: {
                preset: CertSortingPreset.PARTICIPANT_CLASS,
                order: Order.ASCENDING,
            },
        },
    });

    const mutation = useMutation(
        async (variables) => apiService.certs.printAllAsync(variables.eventId, variables.sorting),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Urkunden gedruckt");
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (formData) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            sorting: {
                preset: formData.sorting.preset,
                order: formData.sorting.order,
            },
        });
    };

    const handleClosingButtonClick = () => {
        close();
    };

    const handleHonorButtonClick = () => {
        window.open(mutation.data.honors, "_blank");
    };

    const handleWinnerButtonClick = () => {
        window.open(mutation.data.winners, "_blank");
    };

    const handleParticipationButtonClick = () => {
        window.open(mutation.data.participations, "_blank");
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Urkunden drucken</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Select
                            fieldName="sorting.preset"
                            formatItem={formatCertSortingPreset}
                            isEnabled={!mutation.isLoading && !mutation.isSuccess}
                            items={Object.values(CertSortingPreset)}
                            label="Sortierung"
                        />
                        <RadioSelect
                            fieldName="sorting.order"
                            formatItem={formatOrder}
                            isEnabled={!mutation.isLoading && !mutation.isSuccess}
                            items={Object.values(Order)}
                            label="Reihenfolge"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    {!mutation.isSuccess ? (
                        <LoadingButton
                            loading={mutation.isLoading}
                            startIcon={<PrintIcon />}
                            type="submit"
                        >
                            Drucken
                        </LoadingButton>
                    ) : (
                        <>
                            <Button
                                onClick={handleHonorButtonClick}
                                startIcon={<FileDownloadIcon />}
                            >
                                Ehren
                            </Button>
                            <Button
                                onClick={handleWinnerButtonClick}
                                startIcon={<FileDownloadIcon />}
                            >
                                Sieger
                            </Button>
                            <Button
                                onClick={handleParticipationButtonClick}
                                startIcon={<FileDownloadIcon />}
                            >
                                Teilnahme
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AllCertsPrintingDialog };
