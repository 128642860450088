import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    FormControl,
    FormHelperText,
    FormLabel,
} from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const CardRadioSelect = ({
    fieldName,
    formatItem = undefined,
    getKeyFromItem = undefined,
    isEnabled = true,
    items,
    label,
}) => {
    const finalGetKeyFromItem = getKeyFromItem !== undefined ? getKeyFromItem : (item) => item;
    const finalFormatItem = formatItem !== undefined ? formatItem : (item) => item;

    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const handleChange = (value) => {
        fieldController.field.onChange(items.find((x) => finalGetKeyFromItem(x) === value));
    };

    return (
        <FormControl
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
        >
            <FormLabel>{label}</FormLabel>
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
                {items.map((x) => (
                    <Card
                        key={finalGetKeyFromItem(x)}
                        {...(fieldController.field.value === finalGetKeyFromItem(x) && {
                            sx: { borderColor: "primary.main" },
                        })}
                    >
                        <CardActionArea
                            disabled={!isEnabled}
                            onClick={() => handleChange(finalGetKeyFromItem(x))}
                        >
                            <CardContent>{finalFormatItem(x)}</CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
            {fieldController.fieldState.error !== undefined && (
                <FormHelperText>{fieldController.fieldState.error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

export { CardRadioSelect };
