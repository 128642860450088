const generateProductionConfig = () => ({
    dataMasterPageSizeOptions: [10, 20, 30, 50, 75, 100],
    bugsnag: { publicApiKey: "0e7cc5ad8440614637b33b7a9f264ca0" },
    stripe: {
        publicApiKey:
            "pk_live_51IR9EkLTZHLl3HxJwDQqYhSbpxCD8PRgDmb3aFIdwl3jZfCFqODvtljox7juzQ2p9vCPpVAMcBc7gARIrbNjIzy800iyy3en0t",
    },
});

export { generateProductionConfig };
