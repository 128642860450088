import { Box } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";

const DataMaster__Toolbar = ({ leftElements = [], rightElements = [] }) => (
    <Box mb={1}>
        <GridToolbarContainer
            sx={{
                flexWrap: "unset",
                WebkitFlexWrap: "unset",
            }}
        >
            <Box alignItems="center" display="flex" flex={1} gap={1}>
                {leftElements}
            </Box>
            <Box alignItems="center" display="flex" gap={1} ml={1}>
                {rightElements}
            </Box>
        </GridToolbarContainer>
    </Box>
);

export { DataMaster__Toolbar };
