import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { eventValidationSchemas, validationOptions } from "@bujus/common";

import { DatePicker, Form, TextField } from "@/base";

import { EventScheduleTimeline } from "../event-schedule-timeline";

const EventCreationDialog__TestSettingsStepContent = ({
    defaultFormData,
    mutation,
    onCreation,
    onPrevious,
}) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            eventValidationSchemas.creation.forms.settings.test,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const startFieldValue = useWatch({
        control: formController.control,
        name: "start",
    });

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        onCreation(data);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    fieldName="name"
                    isAutofocusing
                    isEnabled={!mutation.isLoading}
                    label="Name *"
                />
                <DatePicker
                    fieldName="start"
                    isEnabled={!mutation.isLoading}
                    label="Start"
                    validationSchema={eventValidationSchemas.creation.forms.settings.test}
                />
                <EventScheduleTimeline
                    start={
                        startFieldValue === null || Number.isNaN(startFieldValue.getTime())
                            ? undefined
                            : startFieldValue
                    }
                />
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    disabled={mutation.isLoading}
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <LoadingButton loading={mutation.isLoading} startIcon={<AddIcon />} type="submit">
                    Erstellen
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { EventCreationDialog__TestSettingsStepContent };
