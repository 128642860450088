import { Box, Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useFieldArray as useArrayFieldController } from "react-hook-form";

import { SpecialGroupId, generateHydratedGroups } from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { BigResultCreationDialog__GroupSelectOption } from "./big-result-creation-dialog__group-select-option";

const BigResultCreationDialog__GroupSelect = ({ groups, participants }) => {
    const formController = useFormControllerContext();

    const arrayFieldController = useArrayFieldController({
        name: "groups",
        control: formController.control,
        keyName: "uiId",
    });

    const hydratedGroups = useMemo(
        () => generateHydratedGroups(groups, participants),
        [groups, participants],
    );

    const handleAllSelectionButtonClick = () => {
        hydratedGroups.forEach((x) => {
            if (arrayFieldController.fields.some((y) => y.id === x.id)) {
                return;
            }
            arrayFieldController.append(x);
        });
    };

    const handleNoSelectionButtonClick = () => {
        arrayFieldController.remove();
    };

    const handleOptionViewSelection = (group) => {
        const index = arrayFieldController.fields.findIndex((x) => x.id === group.id);
        if (index !== -1) {
            arrayFieldController.remove(index);
            return;
        }
        arrayFieldController.append(group);
    };

    return (
        <Card
            sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography variant="h6">1. Riegen filtern</Typography>
                <Box display="flex" gap={1} mt={2}>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={handleAllSelectionButtonClick}
                        variant="outlined"
                    >
                        Alle
                    </Button>
                    <Button
                        color="secondary"
                        fullWidth
                        onClick={handleNoSelectionButtonClick}
                        variant="outlined"
                    >
                        Keine
                    </Button>
                </Box>
                <Box mt={1}>
                    <BigResultCreationDialog__GroupSelectOption
                        group={hydratedGroups.find((x) => x.id === SpecialGroupId.UNALLOCATED)}
                        isSelected={arrayFieldController.fields.some(
                            (y) => y.id === SpecialGroupId.UNALLOCATED,
                        )}
                        onSelection={() =>
                            handleOptionViewSelection(
                                hydratedGroups.find((x) => x.id === SpecialGroupId.UNALLOCATED),
                            )
                        }
                    />
                </Box>
                <Box display="flex" flex={1} flexDirection="column" mt={1}>
                    <Divider />
                    <Box flex={1} position="relative">
                        <Box
                            bottom={0}
                            left={0}
                            position="absolute"
                            right={0}
                            sx={{ overflowY: "auto" }}
                            top={0}
                        >
                            <Grid container paddingTop={1} spacing={1}>
                                {hydratedGroups
                                    .filter((x) => x.id !== SpecialGroupId.UNALLOCATED)
                                    .map((x) => (
                                        <Grid
                                            key={`${x.id}.${x.tempId}`}
                                            display="flex"
                                            flexDirection="column"
                                            item
                                            xs={6}
                                        >
                                            <BigResultCreationDialog__GroupSelectOption
                                                group={x}
                                                isSelected={arrayFieldController.fields.some(
                                                    (y) => y.id === x.id,
                                                )}
                                                onSelection={() => handleOptionViewSelection(x)}
                                                participantCount={x.participants.length}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export { BigResultCreationDialog__GroupSelect };
