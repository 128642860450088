import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";

import { Order } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { CenterLayout, HorizontalCenterCircularProgress, QueryError } from "@/base";
import {
    DashboardEventCreatedInformationDialogDisplayerProvider,
    DisciplinePreselectionProvider,
    SelectedEventStateProvider,
    useAccountContext,
} from "@/contexts";
import { useQueryParams, useSelectedEventIdCookie } from "@/hooks";

import { EventCreatedInformationDialog } from "./common/event-created-information-dialog";
import { OnboardingDialog } from "./common/onboarding-dialog";

const Dashboard__Loader = ({ children }) => {
    const queryParams = useQueryParams();

    const [selectedEventId, setSelectedEventId, removeSelectedEventIdCookie] =
        useSelectedEventIdCookie();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const [stripeSessionIdQueryParam, setStripeSessionIdQueryParam] = useState();

    const eventCreatedInformationDialogDisplayer = useDialogDisplayer(
        EventCreatedInformationDialog,
    );
    const onboardingDialogDisplayer = useDialogDisplayer(OnboardingDialog);

    const eventQuery = useQuery(
        ["events", selectedEventId, stripeSessionIdQueryParam],
        async ({ queryKey: key }) => {
            if (key[2] !== undefined) {
                try {
                    const checkoutCompletedResult =
                        await apiService.events.getCheckoutCompletedResultAsync(key[2]);
                    setSelectedEventId(checkoutCompletedResult.event.id);
                    eventCreatedInformationDialogDisplayer.open({
                        event: checkoutCompletedResult.event,
                        stripeInvoiceUrl: checkoutCompletedResult.stripeInvoiceUrl,
                    });
                    return checkoutCompletedResult.event;
                } catch {
                    // handle error
                }
            }
            if (key[1] !== undefined) {
                try {
                    return await apiService.events.getAsync(key[1]);
                } catch {
                    removeSelectedEventIdCookie();
                }
            }
            const event = (
                await apiService.events.listAsync(account.id, {
                    fieldSorting: {
                        path: "start",
                        order: Order.DESCENDING,
                    },
                    pageSize: 1,
                    pageIndex: 0,
                })
            ).items[0];
            if (event !== undefined) {
                setSelectedEventId(event.id);
            }
            return event === undefined ? null : event;
        },
    );
    const disciplinePreselectionQuery = useQuery(
        ["disciplinePreselections", eventQuery.data?.id],
        async ({ queryKey: key }) =>
            key[1] === undefined ? null : apiService.disciplinePreselections.getAsync(key[1]),
        { enabled: eventQuery.isSuccess },
    );

    const selectedEventStateProviderValue = useMemo(
        () => [
            eventQuery.data === undefined || eventQuery.data === null ? undefined : eventQuery.data,
            (event) => setSelectedEventId(event.id),
        ],
        [eventQuery.data, setSelectedEventId],
    );
    const disciplinePreselectionProviderValue = useMemo(
        () =>
            disciplinePreselectionQuery.data === undefined ||
            disciplinePreselectionQuery.data === null
                ? undefined
                : disciplinePreselectionQuery.data.value,
        [disciplinePreselectionQuery.data],
    );

    useEffect(() => {
        if (queryParams.stripeSessionId === undefined) {
            return;
        }
        setStripeSessionIdQueryParam(queryParams.stripeSessionId);
        delete queryParams.stripeSessionId;
    }, []);

    useEffect(() => {
        if (account.flow.isOnboarded) {
            return;
        }
        onboardingDialogDisplayer.open();
    }, []);

    let content;
    if (eventQuery.isLoading || disciplinePreselectionQuery.isFetching) {
        content = <HorizontalCenterCircularProgress />;
    }
    if (eventQuery.isError || disciplinePreselectionQuery.isError) {
        content = (
            <QueryError query={eventQuery.isError ? eventQuery : disciplinePreselectionQuery} />
        );
    }
    if (content !== undefined) {
        return (
            <CenterLayout isVerticalCenter title="Dashboard">
                {content}
            </CenterLayout>
        );
    }
    if (eventQuery.isSuccess && disciplinePreselectionQuery.isSuccess) {
        return (
            <>
                <SelectedEventStateProvider value={selectedEventStateProviderValue}>
                    <DashboardEventCreatedInformationDialogDisplayerProvider
                        value={eventCreatedInformationDialogDisplayer}
                    >
                        <DisciplinePreselectionProvider value={disciplinePreselectionProviderValue}>
                            {children}
                        </DisciplinePreselectionProvider>
                    </DashboardEventCreatedInformationDialogDisplayerProvider>
                </SelectedEventStateProvider>
                {onboardingDialogDisplayer.element}
                {eventCreatedInformationDialogDisplayer.element}
            </>
        );
    }
    return undefined;
};

export { Dashboard__Loader };
