import { getCertTemplatePreset } from "@bujus/common";

const Step = {
    PRESET_SELECTION: "PRESET_SELECTION",
    SETTINGS: "SETTINGS",
};

const DisplayStep = {
    PRESET_SELECTION: 0,
    SETTINGS: 1,
};

const convertStepToDisplayStep = (step) => {
    switch (step) {
        case Step.PRESET_SELECTION:
            return DisplayStep.PRESET_SELECTION;
        case Step.SETTINGS:
            return DisplayStep.SETTINGS;
        default:
            throw new Error();
    }
};

const Action = {
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
};

const generateInitialState = (certTemplateInfo) => ({
    step: Step.SETTINGS,
    formDatas: {
        presetSelection: { preset: getCertTemplatePreset(certTemplateInfo.presetId) },
        settings: { certTemplate: certTemplateInfo.certTemplate },
    },
});

const reducer = (state, action) => {
    switch (action.type) {
        case Action.NEXT: {
            switch (state.step) {
                case Step.PRESET_SELECTION: {
                    return {
                        ...state,
                        step: Step.SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            presetSelection: action.formData,
                            settings: { certTemplate: action.formData.preset.certTemplate },
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        case Action.PREVIOUS: {
            switch (state.step) {
                case Step.SETTINGS: {
                    return {
                        ...state,
                        step: Step.PRESET_SELECTION,
                        formDatas: {
                            ...state.formDatas,
                            presetSelection: state.formDatas.presetSelection,
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        default:
            throw new Error();
    }
};

const certTemplateUpdateDialog__StateMachine = {
    Step,
    DisplayStep,
    convertStepToDisplayStep,
    Action,
    generateInitialState,
    reducer,
};

export { certTemplateUpdateDialog__StateMachine };
