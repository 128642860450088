import { Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as dateFns from "date-fns";
import React from "react";

import { formatDate } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountPasswordChangeCancellationAlert = ({ containerProps }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) => apiService.schools.cancelPasswordChangeAsync(variables.accountId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account Passwort Änderung abgebrochen");
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
            },
        },
    );

    const handleButtonClick = () => {
        mutation.mutate({ accountId: account.id });
    };

    if (
        account.passwordChangeIntent === undefined ||
        dateFns.isBefore(account.passwordChangeIntent.expires, new Date())
    ) {
        return undefined;
    }
    return (
        <Box {...containerProps}>
            <Alert
                action={
                    <LoadingButton
                        color="inherit"
                        loading={mutation.isLoading}
                        onClick={handleButtonClick}
                        size="small"
                        startIcon={<CloseIcon />}
                    >
                        Abbrechen
                    </LoadingButton>
                }
                severity="info"
            >
                <AlertTitle>Aktuelle Passwort-Änderung</AlertTitle>
                Bis zum {formatDate(account.passwordChangeIntent.expires, "Pp")} Uhr möglich
            </Alert>
        </Box>
    );
};

export { AccountPasswordChangeCancellationAlert };
