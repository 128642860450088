import { nanoid } from "nanoid";
import { useState } from "react";

import {
    AppError,
    AppErrorType,
    ClassLevelRange,
    EventFormat,
    SpecialGroupId,
    calculateAgeGroup,
    extractClassLevelRange,
    formatGender,
    generateHydratedGroups,
    sortGroupsByDefaultSorting,
} from "@bujus/common";

const useAllGroupsUpdateDialogController = (selectedEvent, participants, groups, setIsDirty) => {
    const [hydratedGroups, _setHydratedGroups] = useState(
        generateHydratedGroups(groups, participants),
    );
    const setHydratedGroups = (newHydratedGroups) => {
        setIsDirty(true);
        _setHydratedGroups(newHydratedGroups);
    };

    const create = (group) => {
        if (hydratedGroups.some((x) => x.name.toLowerCase() === group.name.toLowerCase())) {
            throw new AppError(AppErrorType.INVALID.GROUP_NAME_ALREADY_USED);
        }
        setHydratedGroups((oldHydratedGroups) =>
            sortGroupsByDefaultSorting([
                group,
                ...oldHydratedGroups.map((x) => ({
                    ...x,
                    participants: x.participants.filter(
                        (y) => !group.participants.some((z) => z.id === y.id),
                    ),
                })),
            ]),
        );
    };

    const update = (id, group) => {
        if (
            hydratedGroups.some(
                (x) => x.id !== id && x.name.toLowerCase() === group.name.toLowerCase(),
            )
        ) {
            throw new AppError(AppErrorType.INVALID.GROUP_NAME_ALREADY_USED);
        }
        setHydratedGroups((oldHydratedGroups) =>
            sortGroupsByDefaultSorting(
                oldHydratedGroups.map((x) => ({
                    ...x,
                    name: x.id !== id ? x.name : group.name,
                    ...(selectedEvent.format === EventFormat.CONTEST && {
                        classLevelRange: x.id !== id ? x.classLevelRange : group.classLevelRange,
                    }),
                    participants: x.participants.map((y) => ({ ...y })), // Sorting manipulates this array, so shallow copy it
                })),
            ),
        );
    };

    const transferParticipants = (sourceId, targetId, participantIds) => {
        setHydratedGroups((oldHydratedGroups) =>
            sortGroupsByDefaultSorting([
                ...oldHydratedGroups.map((x) => {
                    if (x.id === sourceId) {
                        return {
                            ...x,
                            participants: x.participants.filter(
                                (participant) => !participantIds.includes(participant.id),
                            ),
                        };
                    }
                    if (x.id === targetId) {
                        return {
                            ...x,
                            participants: [
                                ...x.participants,
                                ...participants.filter((y) => participantIds.includes(y.id)),
                            ],
                        };
                    }
                    return x;
                }),
            ]),
        );
    };

    const unallocateParticipants = (sourceId, participantIds) => {
        transferParticipants(sourceId, SpecialGroupId.UNALLOCATED, participantIds);
    };

    const remove = (id) => {
        setHydratedGroups((oldHydratedGroups) => {
            const sourceGroup = oldHydratedGroups.find((x) => x.id === id);
            return oldHydratedGroups
                .map((x) => {
                    if (x.id === SpecialGroupId.UNALLOCATED) {
                        return {
                            ...x,
                            participants: [...x.participants, ...sourceGroup.participants],
                        };
                    }
                    if (x.id === id) {
                        return {
                            ...x,
                            participants: [],
                        };
                    }
                    return x;
                })
                .filter((x) => x.id !== id);
        });
    };

    const autoDistributeBySelector = (
        generateGroupNameFromParticipant,
        participantLimitPerGroup,
        isExtractingClassLevelRange,
    ) => {
        setHydratedGroups((oldHydratedGroups) => {
            // First distribute the participants from the unallocated group to the new groups, do not care about the participantLimitPerGroup yet
            const largeGroups = oldHydratedGroups
                .find((x) => x.id === SpecialGroupId.UNALLOCATED)
                .participants.reduce((xAccumulator, x) => {
                    const name = generateGroupNameFromParticipant(x);
                    let classLevelRange = ClassLevelRange.N_1_AND_2;
                    if (
                        isExtractingClassLevelRange &&
                        selectedEvent.format === EventFormat.CONTEST
                    ) {
                        try {
                            classLevelRange = extractClassLevelRange(name);
                        } catch {
                            // eslint-disable-next-line no-empty
                        }
                    }
                    const existingGroup = xAccumulator.find((y) => y.name === name);
                    if (existingGroup) {
                        existingGroup.participants.push(x);
                    } else {
                        xAccumulator.push({
                            name,
                            ...(selectedEvent.format === EventFormat.CONTEST && {
                                classLevelRange,
                            }),
                            participants: [x],
                        });
                    }
                    return xAccumulator;
                }, []);
            // Then distribute the participants from the bigGroups to the new groups, respecting the participantLimitPerGroup
            const smallGroups = largeGroups.reduce((xAccumulator, x) => {
                const count = Math.ceil(x.participants.length / participantLimitPerGroup);
                const participantsPerGroupCount = Math.ceil(x.participants.length / count);
                return [
                    ...xAccumulator,
                    ...Array.from({ length: count }, (_, yIndex) => ({
                        ...x,
                        id: nanoid(),
                        participants: x.participants.slice(
                            yIndex * participantsPerGroupCount,
                            (yIndex + 1) * participantsPerGroupCount,
                        ),
                    })),
                ];
            }, []);
            // Then rename the new groups to avoid name conflicts
            const newGroups = [];
            smallGroups.forEach((x, xIndex) => {
                const hasConflictingGroup = smallGroups.some(
                    (y) => y.name.toLowerCase() === x.name.toLowerCase(),
                );
                const leadingConflictingGroupCount = smallGroups
                    .slice(0, xIndex)
                    .filter((y) => y.name.toLowerCase() === x.name.toLowerCase()).length;
                const uniqueName =
                    x.name + (!hasConflictingGroup ? "" : ` (${leadingConflictingGroupCount + 1})`);
                const conflictingOldGroupCount = oldHydratedGroups.filter(
                    (y) => y.name.toLowerCase() === uniqueName.toLowerCase(),
                ).length;
                newGroups.push({
                    ...x,
                    name:
                        uniqueName +
                        (conflictingOldGroupCount === 0
                            ? ""
                            : ` (${conflictingOldGroupCount + 1})`),
                });
            });
            oldHydratedGroups.find((x) => x.id === SpecialGroupId.UNALLOCATED).participants = [];
            newGroups.push(...oldHydratedGroups);
            return sortGroupsByDefaultSorting(newGroups);
        });
    };

    const autoDistributeByClass = (participantLimitPerGroup) => {
        autoDistributeBySelector(
            (participant) => (participant.class === undefined ? "Klassenlos" : participant.class),
            participantLimitPerGroup,
            true,
        );
    };

    const autoDistributeByParticipantGroup = (participantLimitPerGroup) => {
        autoDistributeBySelector(
            (participant) =>
                `${formatGender(participant.gender)} - ${calculateAgeGroup(
                    selectedEvent.start,
                    participant.birthYear,
                )}`,
            participantLimitPerGroup,
            false,
        );
    };

    return {
        participants,
        groups: hydratedGroups,
        create,
        update,
        transferParticipants,
        unallocateParticipants,
        remove,
        autoDistributeByClass,
        autoDistributeByParticipantGroup,
    };
};

export { useAllGroupsUpdateDialogController };
