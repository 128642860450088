import {
    FormatListNumbered as FormatListNumberedIcon,
    Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, Chip, Tooltip, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";

import { formatResultValue } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { HorizontalCenterCircularProgress, QueryError } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const ResultAttempts = ({ currentResultId = undefined, discipline, participant }) => {
    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const query = useQuery(
        [
            "results",
            "getAllOfParticipantAndDiscipline",
            participant === undefined ? undefined : participant.id,
            discipline === undefined ? undefined : discipline.id,
        ],
        async ({ queryKey: key }) => {
            if (key[2] === undefined || key[3] === undefined) {
                return [];
            }
            return apiService.results.getAllOfParticipantAndDisciplineAsync(key[2], key[3]);
        },
        {
            cacheTime: 0,
            enabled: false,
        },
    );

    const filteredResults =
        !query.isSuccess || currentResultId === undefined
            ? query.data
            : query.data.filter((x) => x.id !== currentResultId);

    const handleLoadButtonClick = () => {
        query.refetch();
    };

    const handleReloadButtonClick = () => {
        query.refetch();
    };

    useEffect(() => {
        queryClient.resetQueries(["_results", "getAllOfParticipantAndDiscipline"]);
    }, [participant, discipline]);

    let content;
    if (!query.isFetching && !query.isError && !query.isSuccess) {
        content = (
            <Tooltip
                title={
                    participant !== undefined && discipline !== undefined
                        ? ""
                        : "Bitte wählen Sie einen Teilnehmer und eine Disziplin aus."
                }
            >
                <Box>
                    <Button
                        disabled={participant === undefined || discipline === undefined}
                        fullWidth
                        onClick={handleLoadButtonClick}
                        startIcon={<FormatListNumberedIcon />}
                    >
                        Vorherige Versuche laden
                    </Button>
                </Box>
            </Tooltip>
        );
    } else if (query.isFetching) {
        content = <HorizontalCenterCircularProgress />;
    } else if (query.isError) {
        content = <QueryError query={query} />;
    } else if (query.isSuccess) {
        let successContent;
        if (query.data.length === 0) {
            successContent = (
                <Typography textAlign="center">Keine vorherigen Versuche gefunden.</Typography>
            );
        } else if (filteredResults.length === 0) {
            successContent = (
                <Typography textAlign="center">
                    Keine weiteren vorherigen Versuche gefunden.
                </Typography>
            );
        } else {
            successContent = (
                <Box
                    alignContent="flex-start"
                    alignItems="center"
                    display="flex"
                    flexWrap="wrap"
                    gap={1}
                >
                    <Typography>Vorherige Versuche:</Typography>
                    {filteredResults.map((x, xIndex) => (
                        <Chip
                            key={x.id}
                            avatar={<Avatar>{xIndex + 1}</Avatar>}
                            label={formatResultValue(
                                selectedEvent.format,
                                discipline.id,
                                x.isFailedAttempt,
                                x.value,
                            )}
                            variant="outlined"
                        />
                    ))}
                </Box>
            );
        }
        if (successContent !== undefined) {
            content = (
                <>
                    {successContent}
                    <Box alignItems="center" display="flex" flexDirection="column" mt={1}>
                        <Button onClick={handleReloadButtonClick} startIcon={<RefreshIcon />}>
                            Neu laden
                        </Button>
                    </Box>
                </>
            );
        }
    }
    if (content !== undefined) {
        return (
            <Card>
                <CardContent>{content}</CardContent>
            </Card>
        );
    }
    return undefined;
};

export { ResultAttempts };
