import { Autocomplete, Box, CircularProgress, TextField, debounce } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useController as useFieldController } from "react-hook-form";

import { formatAppError, transformToValidSearchValue, validationTemplates } from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { useSnackbarsController } from "@/hooks";

const AutocompleteSelect = ({
    baseQueryKey,
    fieldName,
    fieldSorting,
    formatItem = undefined,
    getKeyFromItem = undefined,
    isEnabled = true,
    isLocalData = false,
    isShortSearchValue = false,
    label,
    listItemsAsync,
    pageSize = 10,
}) => {
    const finalGetKeyFromItem = getKeyFromItem !== undefined ? getKeyFromItem : (item) => item;
    const finalFormatItem = formatItem !== undefined ? formatItem : (item) => item;

    const snackbarsController = useSnackbarsController();

    const formController = useFormControllerContext();

    const [inputValue, setInputValue] = useState("");
    // The value that gets seached, often debounced
    const [searchValue, setSearchValue] = useState("");
    const setSearchValueDebounced = useMemo(
        () => debounce((newSearchValue) => setSearchValue(newSearchValue), isLocalData ? 100 : 750),
        [isLocalData],
    );

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const validSearchValue = transformToValidSearchValue(searchValue, isShortSearchValue);

    const listingModifiers = useMemo(
        () =>
            validSearchValue !== ""
                ? {
                      searchValue: validSearchValue,
                      pageSize,
                  }
                : {
                      fieldSorting,
                      pageSize,
                      pageIndex: 0,
                  },
        [validSearchValue, pageSize, fieldSorting],
    );

    const query = useQuery(
        [...baseQueryKey, listingModifiers],
        async ({ queryKey: key }) => listItemsAsync(key[baseQueryKey.length]),
        {
            keepPreviousData: true,
            onError: (error) => {
                setInputValue("");
                snackbarsController.enqueueError(error);
            },
        },
    );

    const options = useMemo(() => {
        const list = [];
        if (fieldController.field.value !== null) {
            list.push(fieldController.field.value);
            if (query.isSuccess) {
                list.push(
                    ...query.data.items.filter(
                        (x) =>
                            finalGetKeyFromItem(x) !==
                            finalGetKeyFromItem(fieldController.field.value),
                    ),
                );
            }
        } else if (query.isSuccess) {
            list.push(...query.data.items);
        }
        return list;
    }, [fieldController.field.value, query.data?.items, query.isSuccess]);

    const handleChange = (_event, option) => {
        fieldController.field.onChange(option);
    };

    const handleInputChange = async (_event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleOpen = () => {
        query.refetch();
    };

    useEffect(() => {
        setSearchValueDebounced(inputValue);
    }, [inputValue]);

    return (
        <Autocomplete
            autoHighlight
            disabled={!isEnabled}
            filterOptions={(option) => option}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : finalFormatItem(option)
            }
            inputValue={inputValue}
            isOptionEqualToValue={(option, value) =>
                finalGetKeyFromItem(option) === finalGetKeyFromItem(value)
            }
            loading={query.isFetching}
            loadingText="Lade..."
            noOptionsText={
                query.isError ? `Fehler: ${formatAppError(query.error)}` : "Keine Optionen"
            }
            onChange={handleChange}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            options={options}
            renderInput={(props) => (
                <TextField
                    {...props}
                    error={fieldController.fieldState.error !== undefined}
                    helperText={
                        fieldController.fieldState.error === undefined
                            ? undefined
                            : fieldController.fieldState.error.message
                    }
                    inputProps={{
                        ...props.inputProps,
                        maxLength: validationTemplates.searchValueMaxLength,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <>
                                {query.isFetching && <CircularProgress size={20} />}
                                {props.InputProps.endAdornment}
                            </>
                        ),
                    }}
                    label={label}
                />
            )}
            renderOption={(props, option) => (
                <Box
                    {...props}
                    key={finalGetKeyFromItem(option)}
                    component="li"
                    sx={{ wordBreak: "break-all" }}
                >
                    {finalFormatItem(option)}
                </Box>
            )}
            value={fieldController.field.value}
        />
    );
};

export { AutocompleteSelect };
