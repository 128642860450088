import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import React from "react";
import * as semver from "semver";

import { version } from "@bujus/common";

const OutdatedVersionSnackbar = ({ apiVersion }) => {
    const handleReloadAppButtonClick = () => {
        window.location.reload(true);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            open={semver.gt(apiVersion, version)}
        >
            <Alert
                action={
                    <Button color="inherit" onClick={handleReloadAppButtonClick} size="small">
                        Neu laden
                    </Button>
                }
                severity="error"
                variant="filled"
            >
                <AlertTitle>Version veraltet</AlertTitle>
                Bitte laden Sie die App neu, um die neueste Version zu benutzen.
            </Alert>
        </Snackbar>
    );
};

export { OutdatedVersionSnackbar };
