import { Event as EventIcon, Today as TodayIcon } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Chip,
    DialogContent,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { Order, formatEventFormat } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Collapse, HorizontalCenterCircularProgress, QueryError } from "@/base";
import { useAccountContext, useSelectedEventStateContext } from "@/contexts";

const EventSelectionDialog__List = ({ closeDialog }) => {
    const account = useAccountContext();
    const apiService = useApiServiceContext();
    const [selectedEvent, setSelectedEvent] = useSelectedEventStateContext();

    const query = useQuery(["events", "list", account.id], async ({ queryKey: key }) =>
        apiService.events.listAsync(key[2], {
            fieldSorting: {
                path: "start",
                order: Order.DESCENDING,
            },
            pageSize: 6,
            pageIndex: 0,
        }),
    );

    const handleListItemClick = (event) => {
        closeDialog();
        setSelectedEvent(event);
    };

    if (query.isLoading) {
        return (
            <DialogContent
                sx={{
                    pb: 0,
                    pt: 0,
                }}
            >
                <HorizontalCenterCircularProgress />
            </DialogContent>
        );
    }
    if (query.isError) {
        return (
            <DialogContent
                sx={{
                    pb: 0,
                    pt: 0,
                }}
            >
                <QueryError query={query} />
            </DialogContent>
        );
    }
    if (query.isSuccess) {
        if (query.data.items.length === 0) {
            return <Typography textAlign="center">Noch keine Veranstaltungen erstellt.</Typography>;
        }
        return (
            <Collapse isAnimatingOnEnter isOpen>
                {selectedEvent !== undefined && (
                    <ListItemButton selected>
                        <ListItemAvatar>
                            <Avatar>
                                <TodayIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary={selectedEvent.n3WordId}>
                            <Box display="flex">
                                <Typography>{selectedEvent.name}</Typography>
                                <Box ml={1}>
                                    <Chip
                                        color="primary"
                                        label={formatEventFormat(selectedEvent.format)}
                                        size="small"
                                    />
                                </Box>
                                {selectedEvent.isTest && (
                                    <Box ml={1}>
                                        <Chip color="info" label="Test" size="small" />
                                    </Box>
                                )}
                            </Box>
                        </ListItemText>
                    </ListItemButton>
                )}
                {query.data.items
                    .filter((x) => x.id !== selectedEvent?.id)
                    .slice(0, 4)
                    .map((x) => (
                        <ListItemButton key={x.id} onClick={() => handleListItemClick(x)}>
                            <ListItemAvatar>
                                <Avatar>
                                    <EventIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText secondary={x.n3WordId}>
                                <Box display="flex">
                                    <Typography>{x.name}</Typography>
                                    <Box ml={1}>
                                        <Chip
                                            color="primary"
                                            label={formatEventFormat(x.format)}
                                            size="small"
                                        />
                                    </Box>
                                    {x.isTest && (
                                        <Box ml={1}>
                                            <Chip color="info" label="Test" size="small" />
                                        </Box>
                                    )}
                                </Box>
                            </ListItemText>
                        </ListItemButton>
                    ))}
            </Collapse>
        );
    }
    return undefined;
};

export { EventSelectionDialog__List };
