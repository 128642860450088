import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { HorizontalCenterCircularProgress, QueryError } from "@/base";
import { formatMoneyAmount } from "@/utilities";

const EventCreationDialog__Pricing = ({ participantLimit }) => {
    const apiService = useApiServiceContext();

    const query = useQuery(["_events", "getPricing"], apiService.events.getPricingAsync);

    if (query.isLoading) {
        return <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        return <QueryError query={query} />;
    }
    if (query.isSuccess) {
        return (
            <Typography>
                {/* <b>Preis:</b> Basis: + {participantLimit} Teilnehmer = 30 € +{" "}
                {priceForParticipantsInEur} € = {priceInEur} € */}
                <b>Preis:</b>{" "}
                {formatMoneyAmount(
                    query.data.base + (participantLimit / 50) * query.data.n50Participants,
                )}
            </Typography>
        );
    }
    return undefined;
};

export { EventCreationDialog__Pricing };
