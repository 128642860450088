const CertTemplateSetImageOverlayUpdateDialogMode = {
    RUBBER_STAMP: "RUBBER_STAMP",
    SIGNATURE: "SIGNATURE",
};

const getCertTemplateSetImageOverlayUpdateDialogModeObject = (
    rubberStampObject,
    signObject,
    certTemplateSetImageOverlayUpdateDialogMode,
) => {
    switch (certTemplateSetImageOverlayUpdateDialogMode) {
        case CertTemplateSetImageOverlayUpdateDialogMode.RUBBER_STAMP:
            return rubberStampObject;
        case CertTemplateSetImageOverlayUpdateDialogMode.SIGNATURE:
            return signObject;
        default:
            throw new Error(
                `Invalid cert template set image overlay update dialog mode: ${certTemplateSetImageOverlayUpdateDialogMode}`,
            );
    }
};

export {
    CertTemplateSetImageOverlayUpdateDialogMode,
    getCertTemplateSetImageOverlayUpdateDialogModeObject,
};
