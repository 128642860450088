import { Drawer, useTheme } from "@mui/material";
import React from "react";

import { useDashboardSideBarContext } from "@/contexts";

import { SideBar__Content } from "./side-bar__content";

const openedSideBarWidth = 240;
const closedSideBarWidth = 55;

const generateOpenedSideBarWidthTransitionSx = (theme) => ({
    transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const generateClosedSideBarWidthTransitionSx = (theme) => ({
    transition: theme.transitions.create("width", {
        duration: theme.transitions.duration.leavingScreen,
    }),
});

const SideBar = () => {
    const theme = useTheme();

    const { isDashboardSideBarOpen } = useDashboardSideBarContext();

    return (
        <Drawer
            open={isDashboardSideBarOpen}
            sx={{
                display: "flex",
                flexDirection: "column",
                // display: "block",
                whiteSpace: "nowrap",
                width: !isDashboardSideBarOpen ? closedSideBarWidth : openedSideBarWidth,
                "& .MuiDrawer-paper": {
                    // boxShadow: 3,
                    width: !isDashboardSideBarOpen ? closedSideBarWidth : openedSideBarWidth,
                    overflowX: "hidden",
                    ...(isDashboardSideBarOpen
                        ? generateOpenedSideBarWidthTransitionSx(theme)
                        : generateClosedSideBarWidthTransitionSx(theme)),
                },
                ...(isDashboardSideBarOpen
                    ? generateOpenedSideBarWidthTransitionSx(theme)
                    : generateClosedSideBarWidthTransitionSx(theme)),
            }}
            variant="permanent"
        >
            <SideBar__Content />
        </Drawer>
    );
};

export {
    openedSideBarWidth,
    closedSideBarWidth,
    generateOpenedSideBarWidthTransitionSx,
    generateClosedSideBarWidthTransitionSx,
    SideBar,
};
