import { useDraggable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { GridRow } from "@mui/x-data-grid";
import React, { forwardRef } from "react";

const AllGroupsUpdateDialog__DataMasterRow = forwardRef((props, ref) => {
    const { row, selected } = props;

    const { attributes, listeners, setNodeRef } = useDraggable({
        id: row.id,
        // disabled: !selected,
    });

    return (
        <Box
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className="selectable"
            selectable-key={row.id}
            sx={{ cursor: !selected ? "pointer" : "grab" }}
        >
            <GridRow ref={ref} {...props} />
        </Box>
    );
});

export { AllGroupsUpdateDialog__DataMasterRow };
