import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    SchoolType,
    formatSchoolType,
    schoolValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { Form, Select, TextField } from "@/base";

const AccountSignUpPage__SchoolSettingsStepContent = ({ defaultFormData, onNext, onPrevious }) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            schoolValidationSchemas.signUp.forms.schoolSettings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const handleFormSubmit = (data) => {
        onNext(data);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    cyId="AccountSignUpPage__SchoolSettingsStepContent__name-field"
                    fieldName="name"
                    isAutofocusing
                    label="Schulname *"
                />
                <Select
                    cyId="AccountSignUpPage__SchoolSettingsStepContent__type-field"
                    fieldName="type"
                    formatItem={formatSchoolType}
                    items={Object.values(SchoolType)}
                    label="Schulart *"
                />
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <Button
                    cyId="AccountSignUpPage__SchoolSettingsStepContent__next-button"
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                >
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { AccountSignUpPage__SchoolSettingsStepContent };
