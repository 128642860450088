import { Error as ErrorIcon } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";

import { formatResultValue, generateFullName, getDiscipline } from "@bujus/common";
import { ResultCreationHistoryState } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

const BigResultCreationDialog__History = ({ hydratedHistory }) => {
    const theme = useTheme();

    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <Card
            sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <CardContent>
                <Typography variant="h6">Verlauf</Typography>
            </CardContent>
            <Box flex={1} position="relative">
                <Box
                    bottom={0}
                    left={0}
                    position="absolute"
                    right={0}
                    sx={{ overflowY: "scroll" }}
                    top={0}
                >
                    {hydratedHistory.length === 0 ? (
                        <Typography textAlign="center">
                            Noch keine neuen Ergebnisse eingetragen.
                        </Typography>
                    ) : (
                        <List dense>
                            {hydratedHistory.map((x, xIndex) => (
                                <ListItem
                                    key={xIndex}
                                    {...(x.state === ResultCreationHistoryState.ERROR && {
                                        sx: {
                                            backgroundColor: "error.main",
                                            color: "error.contrastText",
                                        },
                                    })}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            {(() => {
                                                switch (x.state) {
                                                    case ResultCreationHistoryState.LOADING:
                                                        return (
                                                            <CircularProgress
                                                                size={theme.spacing(3)}
                                                            />
                                                        );
                                                    case ResultCreationHistoryState.ERROR:
                                                        return <ErrorIcon fontSize="inherit" />;
                                                    case ResultCreationHistoryState.SUCCESS:
                                                        return (
                                                            <Typography>{xIndex + 1}</Typography>
                                                        );
                                                    default:
                                                        throw new Error(
                                                            `Invalid big result creation dialog history state: ${x.state}`,
                                                        );
                                                }
                                            })()}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        {...(x.state === ResultCreationHistoryState.ERROR && {
                                            sx: {
                                                "& .MuiListItemText-secondary": {
                                                    color: "error.contrastText",
                                                },
                                            },
                                        })}
                                        primary={generateFullName(
                                            x.result.participant.name.first,
                                            x.result.participant.name.last,
                                        )}
                                        secondary={`${getDiscipline(selectedEvent.format, x.result.discipline.id).name}: ${formatResultValue(selectedEvent.format, x.result.discipline.id, x.result.isFailedAttempt, x.result.value)}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>
        </Card>
    );
};

export { BigResultCreationDialog__History };
