import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import * as dateFns from "date-fns";
import React from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import { useAccountContext } from "@/contexts";

import { AccountMailAddressChangeCancellationAlert } from "./account-mail-address-change-cancellation-alert";
import { AccountMailAddressChangeRequestDialog } from "./account-mail-address-change-request-dialog";

const AccountPage__MailAddressCard = () => {
    const account = useAccountContext();

    const changeRequestDialogDisplayer = useDialogDisplayer(AccountMailAddressChangeRequestDialog);

    const handleChangeRequestButtonClick = () => {
        changeRequestDialogDisplayer.open();
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6">Email-Adresse</Typography>
                    <Typography mt={2}>
                        <b>Aktuell:</b> {account.mailAddress}
                    </Typography>
                    <AccountMailAddressChangeCancellationAlert containerProps={{ mt: 2 }} />
                    {(account.mailAddressVerificationIntent === undefined ||
                        dateFns.isBefore(
                            account.mailAddressVerificationIntent.expires,
                            new Date(),
                        )) && (
                        <Box mt={2}>
                            <Button
                                onClick={handleChangeRequestButtonClick}
                                size="small"
                                startIcon={<EditIcon />}
                                variant="outlined"
                            >
                                Email-Adresse ändern
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
            {changeRequestDialogDisplayer.element}
        </>
    );
};

export { AccountPage__MailAddressCard };
