import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

const HorizontalCenterCircularProgress = () => {
    const theme = useTheme();

    // const [isOpen, setIsOpen] = useState(false);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setIsOpen(true);
    //     }, 750);
    //     return () => {
    //         clearTimeout(timer);
    //     };
    // }, []);

    return (
        <Box display="flex" justifyContent="center">
            {/* <Collapse isOpen={isOpen}> */}
            <CircularProgress size={theme.spacing(4)} />
            {/* </Collapse> */}
        </Box>
    );
};

export { HorizontalCenterCircularProgress };
