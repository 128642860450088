import { Edit as EditIcon, Print as PrintIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Typography,
} from "@mui/material";
import React from "react";

import {
    CertSvg,
    eventFormatToCertTypeToExampleCertMap,
    eventFormatToExampleEventMap,
    formatCertType,
    getCertTemplatePreset,
} from "@bujus/common";

import paperTextureImage from "@/assets/paper-texture.jpg";
import { useSelectedEventStateContext } from "@/contexts";

const CertTemplateSetPage__CertTemplateInfoCard = ({
    certTemplateSet,
    certType,
    rubberStampPngDataUrl,
    signaturePngDataUrl,
    testPrintingDialog,
    updateDialogDisplayer,
}) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const preset = getCertTemplatePreset(
        certTemplateSet.certTypeToCertTemplateInfoMap[certType].presetId,
    );

    const handleTestPrintingButtonClick = () => {
        testPrintingDialog.open({ certType: preset.certType });
    };

    const handleUpdateButtonClick = () => {
        updateDialogDisplayer.open({
            certTemplateSet,
            certType,
        });
    };

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                width: 300,
            }}
        >
            <CardMedia
                sx={{
                    flex: 1,
                    userSelect: "none",
                }}
            >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gridTemplateRows: "Auto",
                    }}
                >
                    <Box
                        gridColumn={1}
                        gridRow={1}
                        sx={{
                            backgroundImage: `url(${paperTextureImage})`,
                            backgroundSize: "cover",
                            opacity: 0.5,
                        }}
                    />
                    <Box gridColumn={1} gridRow={1} zIndex={1}>
                        <CertSvg
                            backgroundImageUrl={paperTextureImage}
                            cert={
                                eventFormatToCertTypeToExampleCertMap[selectedEvent.format][
                                    preset.certType
                                ]
                            }
                            certTemplateSet={certTemplateSet}
                            certType={certType}
                            event={eventFormatToExampleEventMap[selectedEvent.format]}
                            rubberStampPngDataUrl={rubberStampPngDataUrl}
                            signaturePngDataUrl={signaturePngDataUrl}
                        />
                    </Box>
                </Box>
            </CardMedia>
            <Divider />
            <CardContent>
                <Typography variant="h6">{formatCertType(preset.certType)}</Typography>
                <Typography mt={2}>
                    <b>Urkundenpapier-Jahr:</b> {preset.year}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    gap: 1,
                    justifyContent: "space-between",
                }}
            >
                <Button
                    onClick={handleTestPrintingButtonClick}
                    size="small"
                    startIcon={<PrintIcon />}
                >
                    Test Drucken
                </Button>
                <Button onClick={handleUpdateButtonClick} size="small" startIcon={<EditIcon />}>
                    Bearbeiten
                </Button>
            </CardActions>
        </Card>
    );
};

export { CertTemplateSetPage__CertTemplateInfoCard };
