import { Box, Container, Divider, Link, Typography } from "@mui/material";
import React from "react";

import { version } from "@bujus/common";

import { config } from "@/config";

const CenterLayout = ({ isVerticalCenter = false, title, children }) => (
    <Box
        display="flex"
        flex={1}
        flexDirection="column"
        sx={{ backgroundColor: "background.paper" }}
    >
        <Box
            display="flex"
            flex={1}
            flexDirection="column"
            py={8}
            {...(isVerticalCenter && { justifyContent: "center" })}
        >
            <Container maxWidth="xs">
                <Typography
                    color="main-gradient"
                    sx={{ fontWeight: "bold" }}
                    textAlign="center"
                    variant="h4"
                >
                    Bujus - Schule
                </Typography>
                {title !== undefined && (
                    <Typography mt={1} textAlign="center" variant="h5">
                        {title}
                    </Typography>
                )}
                <Box mt={4}>{children}</Box>
            </Container>
        </Box>
        <Divider />
        <Box display="flex" p={3}>
            <Typography color="text.secondary" flex={1}>
                {/* eslint-disable-next-line no-undef */}
                <b>Version:</b> {version}
            </Typography>
            <Typography color="text.secondary" ml={2}>
                <Link color="inherit" href={config.urls.homePage} target="_blank">
                    Webseite
                </Link>{" "}
                <Link color="inherit" href={config.urls.contact} target="_blank">
                    Kontakt
                </Link>{" "}
                <Link color="inherit" href={config.urls.instructions} target="_blank">
                    Anleitung
                </Link>{" "}
                <Link color="inherit" href={config.urls.imprint} target="_blank">
                    Impressum
                </Link>{" "}
                <Link color="inherit" href={config.urls.dataPrivacy} target="_blank">
                    Datenschutz
                </Link>
            </Typography>
        </Box>
    </Box>
);

export { CenterLayout };
