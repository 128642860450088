import { Close as CloseIcon } from "@mui/icons-material";
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";

import { defaultParticipantSorting, delayAsync } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import {
    ClosingConfirmationDialog,
    FullScreenDialogTransition,
    HorizontalCenterCircularProgress,
    QueryError,
} from "@/base";
import { config } from "@/config";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { AllGroupsUpdateDialog__LoadedContent } from "./all-groups-update-dialog__loaded-content";

const AllGroupsUpdateDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [isDirty, setIsDirty] = useState(false);

    const closingConfirmationDialogDisplayer = useDialogDisplayer(ClosingConfirmationDialog);

    const query = useQuery(
        ["groups-event-data"],
        async () => {
            const participants = [];
            {
                let lastResult;
                do {
                    lastResult = await apiService.participants.listAsync(selectedEvent.id, {
                        fieldSorting: defaultParticipantSorting[0],
                        pageSize: 100,
                        pageIndex: lastResult === undefined ? 0 : lastResult.pageIndex + 1,
                    });
                    participants.push(...lastResult.items);
                } while (participants.length < lastResult.count);
            }
            const groups = await apiService.groups.getAllAsync(selectedEvent.id);
            await delayAsync(config.artificialDelayForHeavyOperations);
            return {
                participants,
                groups,
            };
        },
        { cacheTime: 0 },
    );

    const mutation = useMutation(
        async (variables) => apiService.groups.updateAllAsync(variables.eventId, variables.groups),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Riegen geändert");
                setIsDirty(false);
            },
        },
    );

    const finalClose = () => {
        queryClient.resetQueries(["groups-event-data"]);
        close();
    };

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        if (isDirty) {
            closingConfirmationDialogDisplayer.open({
                onConfirmation: () => {
                    finalClose();
                },
            });
            return undefined;
        }
        finalClose();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        content = <QueryError query={query} />;
    }
    if (content !== undefined) {
        content = (
            <Box
                alignItems="center"
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
            >
                {content}
            </Box>
        );
    }
    if (query.isSuccess) {
        content = (
            <AllGroupsUpdateDialog__LoadedContent
                groups={query.data.groups}
                isDirty={isDirty}
                mutation={mutation}
                participants={query.data.participants}
                setIsDirty={setIsDirty}
            />
        );
    }
    return (
        <>
            <Dialog
                fullScreen
                onClose={handleClose}
                open={isOpen}
                TransitionComponent={FullScreenDialogTransition}
            >
                <AppBar position="relative" sx={{ zIndex: 1 }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            disabled={mutation.isLoading}
                            edge="start"
                            onClick={handleClosingButtonClick}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography ml={2} variant="h5">
                            Riegen bearbeiten
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {content}
                </DialogContent>
            </Dialog>
            {closingConfirmationDialogDisplayer.element}
        </>
    );
};

export { AllGroupsUpdateDialog };
