import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const StatisticsPage__BigNumberCard = ({ heading, value }) => (
    <Box width={256}>
        <Card>
            <CardContent>
                <Typography textAlign="center" variant="h3">
                    {value}
                </Typography>
                <Typography mt={1} textAlign="center">
                    {heading}
                </Typography>
            </CardContent>
        </Card>
    </Box>
);

export { StatisticsPage__BigNumberCard };
