import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { helperValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DataTable, DialogClosingButton, Form, TextField } from "@/base";
import { useSnackbarsController } from "@/hooks";

const ManyHelpersRemovalDialog = ({ close, helpers, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const confirmation = `${helpers.length}`;
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            helperValidationSchemas.manyRemoval.generateForm(confirmation),
            validationOptions,
        ),
        defaultValues: { confirmation: "" },
    });

    const mutation = useMutation(
        async (variables) => apiService.helpers.removeManyAsync(variables.ids),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage(`${helpers.length} Helfer entfernt`);
                queryClient.invalidateQueries(["helpers"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = () => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({ ids: helpers.map((x) => x.id) });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const dataTableColumns = [
        {
            fieldName: "name",
            heading: "Name",
            getValue: (helper) => helper.name,
            width: 1,
        },
        {
            fieldName: "username",
            heading: "Benutzername",
            getValue: (helper) => helper.username,
            width: 1,
        },
    ];

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>{helpers.length} Helfer entfernen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <DataTable columns={dataTableColumns} items={helpers} />
                    <Box mt={2}>
                        <TextField
                            fieldName="confirmation"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label={`Tippen Sie "${confirmation}" ein um zu bestätigen`}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        disabled={!formController.formState.isValid}
                        icon={<DeleteIcon />}
                        loading={mutation.isLoading}
                        type="submit"
                    >
                        Entfernen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { ManyHelpersRemovalDialog };
