import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { helperValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Checkbox, Collapse, DialogClosingButton, Form, PasswordField, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const HelperCreationDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(helperValidationSchemas.creation.form, validationOptions),
        defaultValues: {
            isGeneratingUsername: true,
            isGeneratingPassword: true,
            helper: {
                name: "",
                username: "",
                password: "",
                isActive: false,
            },
        },
    });
    const isGeneratingUsernameFieldValue = useWatch({
        control: formController.control,
        name: "isGeneratingUsername",
    });
    const isGeneratingPasswordFieldValue = useWatch({
        control: formController.control,
        name: "isGeneratingPassword",
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.helpers.createAsync(
                variables.isGeneratingUsername,
                variables.isGeneratingPassword,
                variables.helper,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (helper) => {
                snackbarsController.enqueueSuccessMessage(`${helper.username} hinzugefügt`);
                queryClient.invalidateQueries(["helpers"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            isGeneratingUsername: data.isGeneratingUsername,
            isGeneratingPassword: data.isGeneratingPassword,
            helper: {
                eventId: selectedEvent.id,
                ...(data.helper.name !== "" && { name: data.helper.name }),
                ...(!data.isGeneratingUsername && { username: data.helper.username }),
                ...(!data.isGeneratingPassword && { password: data.helper.password }),
                isActive: data.helper.isActive,
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Helfer hinzufügen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            cyId="HelperCreationDialog__helper-name-field"
                            fieldName="helper.name"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label={`Name${!isGeneratingUsernameFieldValue ? "" : " *"}`}
                        />
                        <Checkbox
                            cyId="HelperCreationDialog__is-generating-username-checkbox"
                            fieldName="isGeneratingUsername"
                            isEnabled={!mutation.isLoading}
                            label="Benutzername generieren"
                        />
                        <Collapse isOpen={!isGeneratingUsernameFieldValue} overwritingTopMargin={2}>
                            <TextField
                                cyId="HelperCreationDialog__helper-username-field"
                                fieldName="helper.username"
                                label="Benutzername *"
                            />
                        </Collapse>
                        <Checkbox
                            cyId="HelperCreationDialog__is-generating-password-checkbox"
                            fieldName="isGeneratingPassword"
                            isEnabled={!mutation.isLoading}
                            label="Passwort generieren"
                        />
                        <Collapse isOpen={!isGeneratingPasswordFieldValue} overwritingTopMargin={2}>
                            <PasswordField
                                cyId="HelperCreationDialog__helper-password-field"
                                fieldName="helper.password"
                                label="Passwort *"
                            />
                        </Collapse>
                        <Checkbox
                            cyId="HelperCreationDialog__helper-is-active-checkbox"
                            fieldName="helper.isActive"
                            isEnabled={!mutation.isLoading}
                            label="Aktiv"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        cyId="HelperCreationDialog__add-button"
                        loading={mutation.isLoading}
                        startIcon={<AddIcon />}
                        type="submit"
                    >
                        Hinzufügen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { HelperCreationDialog };
