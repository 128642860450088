import { Box, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useApiServiceContext } from "@bujus/common-frontend";

import {
    HorizontalCenterCircularProgress,
    HorizontalCenterErrorMessage,
    LoginButton,
} from "@/base";
import { useAccountDashboardTitleStateContext } from "@/contexts";

import { EventRemovalPage__Logic } from "./event-removal-page__logic";

const EventRemovalPage = () => {
    const params = useParams();

    const apiService = useApiServiceContext();
    const [, setAccountDashboardTitle] = useAccountDashboardTitleStateContext();

    const query = useQuery(
        ["_events", "validateRemovalToken", params.token],
        async ({ queryKey: key }) => {
            console.log("REFRESHING");
            return apiService.events.validateRemovalTokenAsync(key[2]);
        },
    );
    console.log(query);

    useEffect(() => {
        setAccountDashboardTitle("Veranstaltung löschen");
    }, []);

    if (query.isLoading) {
        return <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        return (
            <>
                <HorizontalCenterErrorMessage error={query.error} />{" "}
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    if (query.isSuccess) {
        return (
            <>
                <Typography align="center" color="textSecondary">
                    Von {query.data.school.name} ({query.data.school.mailAddress})
                    <br />
                    Veranstaltung: {query.data.event.name} ({query.data.event.n3WordId})
                </Typography>
                <Box mt={2}>
                    <EventRemovalPage__Logic token={params.token} />
                </Box>
            </>
        );
    }
    return undefined;
};

export { EventRemovalPage };
