import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { generateFullName } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSnackbarsController } from "@/hooks";

const ParticipantRemovalDialog = ({ close, participant, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) => apiService.participants.removeAsync(variables.id),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (removedParticipant) => {
                snackbarsController.enqueueSuccessMessage(
                    `${generateFullName(
                        removedParticipant.name.first,
                        removedParticipant.name.last,
                    )} entfernt`,
                );
                queryClient.invalidateQueries(["participants"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ id: participant.id });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Teilnehmer entfernen</DialogTitle>
            <DialogClosingButton isDisabled={mutation.isLoading} onClick={handleClosingButtonClick} />
            <DialogContent>
                <DialogContentText>
                    {participant.schoolInternalId !== undefined && (
                        <Typography variant="dialogContentTextLong">
                            <b>Schulinterne-ID:</b> {participant.schoolInternalId}
                        </Typography>
                    )}
                    <Typography variant="dialogContentTextLong">
                        <b>Name:</b>{" "}
                        {generateFullName(participant.name.first, participant.name.last)}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    icon={<DeleteIcon />}
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                >
                    Entfernen
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { ParticipantRemovalDialog };
