import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Box, Button, Chip, Typography } from "@mui/material";
import React from "react";

import { formatEventFormat } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

import { EventCreationDialog } from "../common";

import { EventSelectionDialog } from "./event-selection-dialog/event-selection-dialog";

const TopBar__EventSelectionButton = () => {
    const [selectedEvent] = useSelectedEventStateContext();

    const eventCreationDialogDisplayer = useDialogDisplayer(EventCreationDialog);
    const eventSelectionDialogDisplayer = useDialogDisplayer(EventSelectionDialog);

    const handleClick = () => {
        eventSelectionDialogDisplayer.open({ eventCreationDialogDisplayer });
    };

    return (
        <>
            <Button endIcon={<ExpandMoreIcon />} onClick={handleClick} sx={{ color: "inherit" }}>
                {selectedEvent === undefined ? (
                    "Veranstaltung auswählen"
                ) : (
                    <Box alignItems="center" display="flex">
                        <Box sx={{ textAlign: "right" }}>
                            <Typography fontSize="inherit" mb={-0.5}>
                                {selectedEvent.name}
                            </Typography>
                            <Typography textTransform="none" variant="caption">
                                {selectedEvent.n3WordId}
                            </Typography>
                        </Box>
                        <Box ml={1}>
                            <Chip
                                color="primary"
                                label={formatEventFormat(selectedEvent.format)}
                                size="small"
                            />
                        </Box>
                        {selectedEvent.isTest && (
                            <Box ml={1}>
                                <Chip color="info" label="Test" size="small" />
                            </Box>
                        )}
                    </Box>
                )}
            </Button>
            {eventCreationDialogDisplayer.element}
            {eventSelectionDialogDisplayer.element}
        </>
    );
};

export { TopBar__EventSelectionButton };
