import { Button } from "@mui/material";
import React from "react";

const DoubleIconButton = ({ sx = {}, children, ...props }) => (
    <Button
        {...props}
        sx={{
            ...sx,
            "& .MuiButton-endIcon": { ml: 0 },
        }}
    />
);

export { DoubleIconButton };
