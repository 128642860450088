import { Box, DialogContentText, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import { formatDate } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { HorizontalCenterCircularProgress, QueryError } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const AllCertsGenerationDialog__LastGenerationDetails = () => {
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const query = useQuery(["events", "get", selectedEvent.id], async ({ queryKey: key }) =>
        apiService.events.getAsync(key[2]),
    );

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    }
    if (query.isError) {
        content = <QueryError query={query} />;
    }
    if (query.isSuccess) {
        if (query.data.lastAllCertsGeneration === undefined) {
            return undefined;
        }
        content = (
            <DialogContentText>
                <Typography variant="dialogContentTextLong">
                    <b>Letzte Generierung:</b> {formatDate(query.data.lastAllCertsGeneration, "Pp")}{" "}
                    Uhr
                </Typography>
            </DialogContentText>
        );
    }
    return <Box mb={2}>{content}</Box>;
};

export { AllCertsGenerationDialog__LastGenerationDetails };
