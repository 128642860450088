import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const PasswordField = ({
    cyId = undefined,
    fieldName,
    isEnabled = true,
    label,
    isAutofocusing = false,
}) => {
    const formController = useFormControllerContext();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const handlPasswordVisibilityToggleButtonClick = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <TextField
            autoFocus={isAutofocusing}
            {...(cyId !== undefined && { cyId })}
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
            {...(fieldController.fieldState.error !== undefined && {
                helperText: fieldController.fieldState.error.message,
            })}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            color="secondary"
                            disabled={!isEnabled}
                            edge="end" // TOOD Check necessity
                            onClick={handlPasswordVisibilityToggleButtonClick}
                        >
                            {isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            label={label}
            onChange={fieldController.field.onChange}
            {...(!isPasswordVisible && { type: "password" })}
            value={fieldController.field.value}
        />
    );
};

export { PasswordField };
