import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    ClassLevelRange,
    EventFormat,
    formatClassLevelRange,
    groupValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { DialogClosingButton, Form, RadioSelect, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AllGroupsUpdateDialog__GroupUpdateDialog = ({ close, controller, group, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(groupValidationSchemas.update.form, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            name: group.name,
            ...(selectedEvent.format === EventFormat.CONTEST && {
                classLevelRange: group.classLevelRange,
            }),
        },
    });

    const handleClose = () => {
        close();
    };

    const handleFormSubmit = (data) => {
        try {
            controller.update(group.id, {
                name: data.name,
                ...(selectedEvent.format === EventFormat.CONTEST && {
                    classLevelRange: data.classLevelRange,
                }),
            });
            close();
        } catch (error) {
            snackbarsController.enqueueError(error);
        }
    };

    const handleClosingButtonClick = () => {
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Riege bearbeiten</DialogTitle>
                <DialogClosingButton onClick={handleClosingButtonClick} />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField fieldName="name" isAutofocusing label="Name *" />
                        {selectedEvent.format === EventFormat.CONTEST && (
                            <RadioSelect
                                fieldName="classLevelRange"
                                formatItem={formatClassLevelRange}
                                items={Object.values(ClassLevelRange)}
                                label="Klassenstufe *"
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<EditIcon />} type="submit">
                        Ändern
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AllGroupsUpdateDialog__GroupUpdateDialog };
