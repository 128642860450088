import { createContext, useContext } from "react";

const DisciplinePreselectionContext = createContext();
const DisciplinePreselectionProvider = DisciplinePreselectionContext.Provider;
const useDisciplinePreselectionContext = () => useContext(DisciplinePreselectionContext);

export {
    DisciplinePreselectionContext,
    DisciplinePreselectionProvider,
    useDisciplinePreselectionContext,
};
