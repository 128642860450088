import { Box } from "@mui/material";
import React from "react";

import { CenterLayout, LoginButton } from "@/base";

const PageNotFoundPage = () => (
    <CenterLayout title="Seite nicht gefunden">
        <Box alignItems="center" display="flex" flexDirection="column">
            <LoginButton />
        </Box>
    </CenterLayout>
);

export { PageNotFoundPage };
