import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from "@mui/material";
import React, { useId } from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const Select = ({
    cyId = undefined,
    fieldName,
    formatItem = undefined,
    getKeyFromItem = undefined,
    isEnabled = true,
    items,
    label,
}) => {
    const finalGetKeyFromItem = getKeyFromItem !== undefined ? getKeyFromItem : (item) => item;
    const finalFormatItem = formatItem !== undefined ? formatItem : (item) => item;

    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const labelId = useId();

    const handleChange = (event) => {
        fieldController.field.onChange(
            event.target.value === ""
                ? null
                : items.find((x) => finalGetKeyFromItem(x) === event.target.value),
        );
    };

    return (
        <FormControl
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
        >
            <InputLabel id={labelId}>{label}</InputLabel>
            <MuiSelect
                {...(cyId !== undefined && { cyId })}
                label={label}
                labelId={labelId}
                onChange={handleChange}
                value={
                    fieldController.field.value === null
                        ? ""
                        : finalGetKeyFromItem(fieldController.field.value)
                }
            >
                {items.map((x) => (
                    <MenuItem
                        key={finalGetKeyFromItem(x)}
                        cyId={`Select__menu-item--${finalGetKeyFromItem(x)}`}
                        value={finalGetKeyFromItem(x)}
                    >
                        {finalFormatItem(x)}
                    </MenuItem>
                ))}
            </MuiSelect>
            {fieldController.fieldState.error !== undefined && (
                <FormHelperText>{fieldController.fieldState.error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

export { Select };
