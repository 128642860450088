import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import { useDashboardTitleStateContext } from "@/contexts";

import { AllGroupsUpdateDialog } from "./all-groups-update-dialog";

const GroupsPage = () => {
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const updateDialogDisplayer = useDialogDisplayer(AllGroupsUpdateDialog);

    const handleStartButtonClick = () => {
        updateDialogDisplayer.open();
    };

    useEffect(() => {
        setDashboardTitle("Riegen");
    }, []);

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
            >
                <Button onClick={handleStartButtonClick} startIcon={<EditIcon />}>
                    Bearbeiten
                </Button>
            </Box>
            {updateDialogDisplayer.element}
        </>
    );
};

export { GroupsPage };
