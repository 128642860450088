import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { formatEventFormat, schoolAppRoutes } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import {
    useAccountContext,
    useDashboardEventCreatedInformationDialogDisplayerContext,
    useSelectedEventStateContext,
} from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { stripeService } from "@/services";
import { accountTheme } from "@/styles";

import { eventCreationDialogStateMachine } from "./event-creation-dialog-state-machine";
import { EventCreationDialog__PaymentSettingsStepContent } from "./event-creation-dialog__payment-step-content";
import { EventCreationDialog__RealSettingsStepContent } from "./event-creation-dialog__real-settings-step-content";
import { EventCreationDialog__TestSettingsStepContent } from "./event-creation-dialog__test-settings-step-content";
import { EventCreationDialog__TypeSettingsStepContent } from "./event-creation-dialog__type-settings-step-content";

const EventCreationDialog = ({ close, isOpen }) => {
    const navigate = useNavigate();
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();
    const [, setSelectedEvent] = useSelectedEventStateContext();
    const eventCreatedInformationDialogDisplayer =
        useDashboardEventCreatedInformationDialogDisplayerContext();

    const [state, dispatchState] = useReducer(
        eventCreationDialogStateMachine.reducer,
        eventCreationDialogStateMachine.initialState,
    );

    const mutation = useMutation(
        async (variables) =>
            apiService.events.createAsync(
                variables.accountId,
                variables.event,
                variables.discountCode,
                variables.paymentType,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (result) => {
                if (!result.isPaymentRequired) {
                    snackbarsController.enqueueSuccessMessage(`${result.event.name} erstellt`);
                    queryClient.invalidateQueries(["events"]);
                    setSelectedEvent(result.event);
                    close();
                    navigate(schoolAppRoutes().dashboard().event().build());
                    eventCreatedInformationDialogDisplayer.open({
                        event: result.event,
                        ...(result.stripeInvoiceUrl !== undefined && {
                            stripeInvoiceUrl: result.stripeInvoiceUrl,
                        }),
                    });
                    return;
                }
                stripeService.redirectToCheckout({ sessionId: result.stripeSessionId });
            },
        },
    );

    const displayStep = eventCreationDialogStateMachine.convertStepToDisplayStep(state.step);

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleTypeSelectionStepContentNext = (data) => {
        dispatchState({
            type: eventCreationDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleTestSettingsStepContentCreation = (data) => {
        mutation.mutate({
            accountId: account.id,
            event: {
                format: state.formDatas.typeSelection.format,
                isTest: state.formDatas.typeSelection.isTest,
                name: data.name,
                start: data.start,
            },
        });
    };

    const handleTestSettingsStepContentPrevious = () => {
        dispatchState({ type: eventCreationDialogStateMachine.Action.PREVIOUS });
    };

    const handleRealSettingsStepContentNext = (data) => {
        dispatchState({
            type: eventCreationDialogStateMachine.Action.NEXT,
            formData: data,
        });
    };

    const handleRealSettingsStepContentPrevious = () => {
        dispatchState({ type: eventCreationDialogStateMachine.Action.PREVIOUS });
    };

    const handlePaymentSettingsStepContentCreation = (data) => {
        mutation.mutate({
            accountId: account.id,
            event: {
                format: state.formDatas.typeSelection.format,
                isTest: state.formDatas.typeSelection.isTest,
                name: state.formDatas.settings.real.name,
                start: state.formDatas.settings.real.start,
                limits: { participant: state.formDatas.settings.real.limits.participant },
            },
            ...(data.discountCode !== "" && { discountCode: data.discountCode }),
            paymentType: data.type,
        });
    };

    const handlePaymentSettingsStepContentPrevious = () => {
        dispatchState({ type: eventCreationDialogStateMachine.Action.PREVIOUS });
    };

    return (
        <ThemeProvider theme={accountTheme}>
            <Dialog onClose={handleClose} open={isOpen}>
                <DialogTitle>Veranstaltung erstellen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Stepper activeStep={displayStep} orientation="vertical">
                        <Step>
                            <StepLabel>Typ auswählen</StepLabel>
                            <StepContent>
                                <EventCreationDialog__TypeSettingsStepContent
                                    defaultFormData={state.formDatas.typeSelection}
                                    onNext={handleTypeSelectionStepContentNext}
                                />
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Einstellen</StepLabel>
                            <StepContent>
                                <Typography>
                                    <b>Format: </b>
                                    {state.formDatas.typeSelection.format !== null &&
                                        formatEventFormat(state.formDatas.typeSelection.format)}
                                </Typography>
                                <Typography>
                                    <b>Typ: </b>
                                    {state.formDatas.typeSelection.isTest
                                        ? "Test-Veranstaltung"
                                        : "Echte-Veranstaltung"}
                                </Typography>
                                <Box mt={2}>
                                    {state.formDatas.typeSelection.isTest ? (
                                        <EventCreationDialog__TestSettingsStepContent
                                            defaultFormData={state.formDatas.settings.test}
                                            mutation={mutation}
                                            onCreation={handleTestSettingsStepContentCreation}
                                            onPrevious={handleTestSettingsStepContentPrevious}
                                        />
                                    ) : (
                                        <EventCreationDialog__RealSettingsStepContent
                                            defaultFormData={state.formDatas.settings.real}
                                            onNext={handleRealSettingsStepContentNext}
                                            onPrevious={handleRealSettingsStepContentPrevious}
                                        />
                                    )}
                                </Box>
                            </StepContent>
                        </Step>
                        {state.step !== eventCreationDialogStateMachine.Step.TYPE_SELECTION &&
                            !state.formDatas.typeSelection.isTest && (
                                <Step>
                                    <StepLabel>Bezahlung</StepLabel>
                                    <StepContent>
                                        <EventCreationDialog__PaymentSettingsStepContent
                                            defaultFormData={state.formDatas.paymentSettings}
                                            event={state.formDatas.settings.real}
                                            mutation={mutation}
                                            onCreation={handlePaymentSettingsStepContentCreation}
                                            onPrevious={handlePaymentSettingsStepContentPrevious}
                                        />
                                    </StepContent>
                                </Step>
                            )}
                    </Stepper>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};

export { EventCreationDialog };
