import { Box, Typography } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

import { Checkbox } from "@/base";

import { CertTemplateUpdateDialog__PositionOffsetSettings } from "./cert-template-update-dialog__position-offset-settings";

const CertTemplateUpdateDialog__ElementSettings = ({
    fieldName,
    isEnabled = true,
    isStandard,
    label,
}) => {
    const formController = useFormControllerContext();

    const isVisibleFieldValue = useWatch({
        control: formController.control,
        name: `${fieldName}.isVisible`,
    });

    return (
        <Box>
            <Typography fontWeight="bold">{label}</Typography>
            <Box alignItems="center" display="flex" mt={1}>
                {!isStandard && (
                    <Box mr={2}>
                        <Checkbox
                            fieldName={`${fieldName}.isVisible`}
                            isEnabled={isEnabled}
                            isFullWidth={false}
                        />
                    </Box>
                )}
                <Box flex={1}>
                    <CertTemplateUpdateDialog__PositionOffsetSettings
                        fieldName={`${fieldName}.positionOffset`}
                        isEnabled={isVisibleFieldValue && isEnabled}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export { CertTemplateUpdateDialog__ElementSettings };
