import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";

import { formatAddress, formatSchoolType } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { useAccountContext } from "@/contexts";

import { AccountUpdateDialog } from "./account-update-dialog";

const AccountPage__BasicsCard = () => {
    const account = useAccountContext();

    const updateDialogDisplayer = useDialogDisplayer(AccountUpdateDialog);

    const handleUpdateButtonClick = () => {
        updateDialogDisplayer.open({ account });
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4">{account.name}</Typography>
                    <Typography mt={2}>
                        <b>Schulart:</b> {formatSchoolType(account.type)}
                    </Typography>
                    {/* Backwards compatibility */}
                    <Typography mt={2}>
                        <b>Adresse:</b>{" "}
                        {account.address === undefined
                            ? "Keine Adresse hinterlegt"
                            : formatAddress(account.address)}
                    </Typography>
                    <Box mt={2}>
                        <Button
                            onClick={handleUpdateButtonClick}
                            size="small"
                            startIcon={<EditIcon />}
                            variant="outlined"
                        >
                            Bearbeiten
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            {updateDialogDisplayer.element}
        </>
    );
};

export { AccountPage__BasicsCard };
