import Papa from "papaparse";
import React from "react";
import { useController as useFieldController } from "react-hook-form";
import FileUpload from "react-material-file-upload";

import { config, transformToMinimalWhiteSpaceString } from "@bujus/common";
import { useDialogDisplayer, useFormControllerContext } from "@bujus/common-frontend";

import { ErrorDialog } from "../../error-dialog";

const parseCsvFileAsync = (file) =>
    new Promise((resolve) => {
        Papa.parse(file, {
            complete: (results) => {
                resolve(results.data);
            },
            skipEmptyLines: true,
        });
    });

const CsvFileUpload = ({ fieldName, isEnabled = true, label, onChange }) => {
    const formController = useFormControllerContext();

    const errorDialog = useDialogDisplayer(ErrorDialog);

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const handleChangeAsync = async (files) => {
        const file = files[0];
        if (file === undefined) {
            return;
        }
        if (!file.name.endsWith(".csv")) {
            errorDialog.open({
                title: "Die CSV-Datei ist ungültig",
                description:
                    "Die Datei ist keine CSV-Datei oder ihr Name endet zumindest nicht mit .csv. Stellen Sie sicher, dass Sie die Datei aus ihrem Tabellenkalkulationsprogramm richtig als CSV-Datei exportiert haben.",
            });
            return;
        }
        let data;
        try {
            data = await parseCsvFileAsync(file);
            if (data.length === 0) {
                errorDialog.open({
                    title: "Die CSV-Datei ist ungültig",
                    description: "Die ausgewählte Datei enthält keine Daten",
                });
                return;
            }
            const rowLimit = config.event.alltimeLimits.participant + 1;
            if (data.length > rowLimit) {
                errorDialog.open({
                    title: "Die CSV-Datei ist ungültig",
                    description: `Die Datei darf maximal ${rowLimit} Zeilen enthalten.`,
                });
                return;
            }
            const coloumnsCount = data[0].length;
            let isColoumnsCountInvalid = false;
            data.every((row, rowIndex) => {
                if (row.length === coloumnsCount) {
                    return true;
                }
                isColoumnsCountInvalid = true;
                errorDialog.open({
                    title: "Die CSV-Datei ist ungültig",
                    description: `Alle Zeilen der Tabelle müssen gleich viele Spalten enthalten. Zeile 1 enthält aktuell ${coloumnsCount} Spalten und Zeile ${
                        rowIndex + 1
                    } enthält ${row.length} Spalte${row.length === 1 ? "" : "n"}.`,
                });
                return false;
            });
            if (isColoumnsCountInvalid) {
                return;
            }
            data.forEach((row, rowIndex) => {
                row.forEach((value, colIndex) => {
                    data[rowIndex][colIndex] = transformToMinimalWhiteSpaceString(value);
                });
            });
            fieldController.field.onChange(data);
            onChange();
        } catch (error) {
            errorDialog.open({
                title: "Die CSV-Datei ist ungültig",
                description:
                    "Die Datei ist keine gültige CSV-Datei. Stellen Sie sicher, dass Sie die Datei aus ihrem Tabellenkalkulationsprogramm richtig als CSV-Datei exportiert haben.",
            });
        }
    };

    return (
        <>
            <FileUpload
                buttonText="Datei auswählen"
                maxFiles={!isEnabled ? 0 : 1}
                onChange={handleChangeAsync}
                sx={{
                    "& .MuiButtonBase-root": { mb: 0 },
                    "& .MuiFormHelperText-root": { display: "none" },
                    "& ul": { display: "none" },
                }}
                title={label}
            />
            {errorDialog.element}
        </>
    );
};

export { CsvFileUpload };
