import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";

const DialogClosingButton = ({ isDisabled, onClick }) => {
    const theme = useTheme();

    const handleClick = () => {
        onClick();
    };

    return (
        <Box position="absolute" right={theme.spacing(1)} top={theme.spacing(1)}>
            <IconButton color="secondary" disabled={isDisabled} onClick={handleClick}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export { DialogClosingButton };
