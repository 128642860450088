import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import * as dateFns from "date-fns";
import React from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import { useAccountContext } from "@/contexts";

import { AccountPasswordChangeCancellationAlert } from "./account-password-change-cancellation-alert";
import { AccountPasswordChangeRequestDialog } from "./account-password-change-request-dialog";

const AccountPage__PasswordCard = () => {
    const account = useAccountContext();

    const changeRequestDialogDisplayer = useDialogDisplayer(AccountPasswordChangeRequestDialog);

    const handleChangeRequestButtonClick = () => {
        changeRequestDialogDisplayer.open();
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6">Passwort</Typography>
                    <AccountPasswordChangeCancellationAlert containerProps={{ mt: 2 }} />
                    {(account.passwordChangeIntent === undefined ||
                        dateFns.isBefore(account.passwordChangeIntent.expires, new Date())) && (
                        <Box mt={2}>
                            <Button
                                onClick={handleChangeRequestButtonClick}
                                size="small"
                                startIcon={<EditIcon />}
                                variant="outlined"
                            >
                                Passwort ändern
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
            {changeRequestDialogDisplayer.element}
        </>
    );
};

export { AccountPage__PasswordCard };
