import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { Typography } from "@mui/material";
import * as dateFns from "date-fns";
import React from "react";

import { formatDate } from "@bujus/common";

import { config } from "@/config";

const EventScheduleTimeline = ({ event = undefined, start = undefined }) => {
    if (event === undefined && start === undefined) {
        return undefined;
    }
    return (
        <Timeline
            sx={{
                m: 0,
                p: 0,
                [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2 },
            }}
        >
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">Test Start</TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {formatDate(
                        event === undefined
                            ? dateFns.subMilliseconds(start, config.event.schedule.warmUpTime)
                            : event.warmUpStart,
                        "P",
                    )}
                    <Typography color="secondary">
                        Ab hier können Ergebnisse eingetragen werden
                    </Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">Start</TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {formatDate(event === undefined ? start : event.start, "P")}
                    <Typography color="secondary">
                        Dieses Datum wird zur Berechnung der Altersgruppe der Teilnehmer verwendet.
                    </Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">Ende</TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    {formatDate(
                        event === undefined
                            ? dateFns.addMilliseconds(start, config.event.schedule.expireTime)
                            : event.end,
                        "P",
                    )}
                    <Typography color="secondary">
                        Ab hier können keine Ergebnisse mehr eingetragen werden.
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
};

export { EventScheduleTimeline };
