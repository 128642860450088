const downloadCsvFile = (name, string) => {
    const file = new File([string], name, { type: "text/csv;charset=utf8;" });
    const objectUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = objectUrl;
    link.download = file.name;
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectUrl);
};

export { downloadCsvFile };
