const generateHydratedCerts = (participants, certs) =>
    certs.map((x) => ({
        ...x,
        participant: participants.find((y) => y.id === x.participant.id),
    }));

const generateHydratedCertsResult = (participants, certs) => {
    const hydratedCerts = generateHydratedCerts(participants, certs);
    return {
        notGeneratedCerts: hydratedCerts.filter((x) => !x.isGenerated),
        generatedCerts: hydratedCerts.filter((x) => x.isGenerated),
    };
};

export { generateHydratedCerts, generateHydratedCertsResult };
