import React, { useMemo, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { schoolAppRoutes } from "@bujus/common";

import { CenterLayout, UnauthorizedRoute } from "@/base";
import {
    AccountDashboardMailAddressStateProvider,
    AccountDashboardTitleStateProvider,
} from "@/contexts";

import { AccountLoginPage } from "./account-login-page";
import { AccountMailAddressVerificationPage } from "./account-mail-address-verification-page/account-mail-address-verification-page";
import { AccountPasswordChangePage } from "./account-password-change-page";
import { AccountPasswordForgottenPage } from "./account-password-forgotten-page";
import { AccountRemovalPage } from "./account-removal-page";
import { AccountSignUpPage } from "./account-sign-up-page";
import { EventRemovalPage } from "./event-removal-page/event-removal-page";

const AccountDashboard = () => {
    const [title, setTitle] = useState("");
    const [mailAddress, setMailAddress] = useState("");

    const titleStateProviderValue = useMemo(() => [title, setTitle], [title, setTitle]);
    const mailAddressStateProviderValue = useMemo(
        () => [mailAddress, setMailAddress],
        [mailAddress, setMailAddress],
    );

    return (
        <AccountDashboardTitleStateProvider value={titleStateProviderValue}>
            <AccountDashboardMailAddressStateProvider value={mailAddressStateProviderValue}>
                <CenterLayout title={title}>
                    <Routes>
                        <Route
                            element={
                                <UnauthorizedRoute>
                                    <AccountSignUpPage />
                                </UnauthorizedRoute>
                            }
                            path={schoolAppRoutes().account(true).signUp().build()}
                        />
                        <Route
                            element={
                                <UnauthorizedRoute>
                                    <AccountLoginPage />
                                </UnauthorizedRoute>
                            }
                            path={schoolAppRoutes().account(true).login().build()}
                        />
                        <Route
                            element={<AccountMailAddressVerificationPage />}
                            path={schoolAppRoutes().account(true).mailAddressVerification().build()}
                        />
                        <Route
                            element={
                                <UnauthorizedRoute>
                                    <AccountPasswordForgottenPage />
                                </UnauthorizedRoute>
                            }
                            path={schoolAppRoutes().account(true).passwordForgotten().build()}
                        />
                        <Route
                            element={<AccountPasswordChangePage />}
                            path={schoolAppRoutes().account(true).passwordChange().build()}
                        />
                        <Route
                            element={<EventRemovalPage />}
                            path={schoolAppRoutes().account(true).eventRemoval().build()}
                        />
                        <Route
                            element={<AccountRemovalPage />}
                            path={schoolAppRoutes().account(true).removal().build()}
                        />
                        <Route
                            element={
                                <Navigate to={schoolAppRoutes(true).account().login().build()} />
                            }
                            path="*"
                        />
                    </Routes>
                </CenterLayout>
            </AccountDashboardMailAddressStateProvider>
        </AccountDashboardTitleStateProvider>
    );
};

export { AccountDashboard };
