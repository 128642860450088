import {
    MenuBook as MenuBookIcon,
    Message as MessageIcon,
    Support as SupportIcon,
} from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";

import { config } from "@/config";
import { useDashboardSideBarContext, useOpenInstructionsDrawerContext } from "@/contexts";

const SideBar__HelpLink = () => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const { isDashboardSideBarOpen } = useDashboardSideBarContext();

    const [isOpen, setIsOpen] = useState(false);

    const menuAnchorElementRef = useRef();

    const handleListItemButtonClick = () => {
        setIsOpen(true);
    };

    const handleMenuClose = () => {
        setIsOpen(false);
    };

    const handleLiveChatMenuItemClick = () => {
        window.open(config.urls.liveChat, "_blank");
        setIsOpen(false);
    };

    const handleInstructionsMenuItemClick = () => {
        openInstructionsDrawer();
        setIsOpen(false);
    };

    const handleOnboardingMenuItemClick = () => {
        window.open(config.urls.onboarding, "_blank");
        setIsOpen(false);
    };

    return (
        <>
            <Tooltip placement="right" title={isDashboardSideBarOpen ? "" : "Hilfe"}>
                <ListItemButton ref={menuAnchorElementRef} onClick={handleListItemButtonClick}>
                    <ListItemIcon>
                        <SupportIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hilfe" />
                </ListItemButton>
            </Tooltip>
            <Menu anchorEl={menuAnchorElementRef.current} onClose={handleMenuClose} open={isOpen}>
                <MenuItem onClick={handleLiveChatMenuItemClick}>
                    <ListItemIcon>
                        <MessageIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Live-Chat</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleInstructionsMenuItemClick}>
                    <ListItemIcon>
                        <MenuBookIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Anleitung</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleOnboardingMenuItemClick}>
                    <ListItemIcon>
                        <SupportIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>1:1 Onboarding</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

export { SideBar__HelpLink };
