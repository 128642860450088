import { joiResolver } from "@hookform/resolvers/joi";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { formatAddress, schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, TextField } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { accountTheme } from "@/styles";

const AccountDpaRequestDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.dpaRequest.body, validationOptions),
        defaultValues: {
            signer: {
                name: "",
                mailAddress: "",
            },
        },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.requestDpaAsync(variables.accountId, variables.signer),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (data, variables) => {
                snackbarsController.enqueueSuccessMessage("Auftragsverarbeitungsvertrag angefragt");
                snackbarsController.enqueuePopUpWarning();
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
                close();
                window.open(data.dataPrivacy.dpa.eSignaturesContract.signaturePageUrl, "_blank");
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        if (account.address === undefined) {
            snackbarsController.enqueueErrorMessage("Schuladresse fehlt");
            return;
        }
        mutation.mutate({
            accountId: account.id,
            signer: {
                name: data.signer.name,
                mailAddress: data.signer.mailAddress,
            },
        });
    };

    return (
        <ThemeProvider theme={accountTheme}>
            <Dialog onClose={handleClose} open={isOpen}>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <DialogTitle>Auftragsverarbeitungsvertrag anfragen</DialogTitle>
                    <DialogClosingButton
                        isDisabled={mutation.isLoading}
                        onClick={handleClosingButtonClick}
                    />
                    <DialogContent>
                        <DialogContentText>
                            Wir schicken dem Schulleiter eine Email, mit einem Link zur
                            Unterzeichnung eines digitalen Vertrags.
                            <Typography mt={2}>Sind die folgenden Angaben korrekt?</Typography>
                            <Typography variant="dialogContentTextLong">
                                <b>Schulname:</b> {account.name}
                            </Typography>
                            <Typography variant="dialogContentTextLong">
                                <b>Schuladresse:</b>{" "}
                                {account.address === undefined
                                    ? "Keine Adresse hinterlegt"
                                    : formatAddress(account.address)}
                            </Typography>
                        </DialogContentText>
                        <Box display="flex" flexDirection="column" gap={2} mt={2}>
                            <TextField
                                fieldName="signer.name"
                                isAutofocusing
                                isEnabled={!mutation.isLoading}
                                label="Schulleiter Name *"
                            />
                            <TextField
                                fieldName="signer.mailAddress"
                                isEnabled={!mutation.isLoading}
                                label="Schulleiter Email-Adresse *"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={mutation.isLoading}
                            startIcon={<DescriptionIcon />}
                            type="submit"
                        >
                            Angaben sind korrekt und Vertrag anfragen
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Dialog>
        </ThemeProvider>
    );
};

export { AccountDpaRequestDialog };
