import { useLocation } from "react-router-dom";

const useTabsValue = (...tabRoutes) => {
    const { pathname: fullRoute } = useLocation();

    const tabsValue = tabRoutes.findIndex((tabRoute) => fullRoute.startsWith(tabRoute));
    return tabsValue === -1 ? 0 : tabsValue;
};

export { useTabsValue };
