const generateDevelopmentConfig = () => ({
    urls: {
        importHelpers: {
            helpers:
                "https://storage.googleapis.com/files.school-app.bujus.de/import-helpers/Bujus%20-%20Helfer%20-%20Importhilfe%20-%20v3.xlsx",
            eventFormatToParticipantsMap: {
                CONTEST:
                    "https://storage.googleapis.com/files.school-app.bujus.de/import-helpers/Bujus%20-%20Wettbewerb%20Veranstaltung%20-%20Teilnehmer%20-%20Importhilfe%20-%20v2.xlsx",
                COMPETITION:
                    "https://storage.googleapis.com/files.school-app.bujus.de/import-helpers/Bujus%20-%20Wettkampf%20Veranstaltung%20-%20Teilnehmer%20-%20Importhilfe%20-%20v4.xlsx",
            },
        },
    },
    dataMasterPageSizeOptions: [1, 2, 3, 4, 5, 10, 20, 30, 50, 75, 100],
    bugsnag: { publicApiKey: "0e7cc5ad8440614637b33b7a9f264ca0" },
    googleMaps: { publicApiKey: "AIzaSyAh6dZQwV5AIc70g6OUQ9terxFUU2DNBso" },
    stripe: {
        publicApiKey:
            "pk_test_51IR9EkLTZHLl3HxJq8HPCFZs1hsXrDgU0VmWnf6MSS96nuXvGPfHYHfJzUPVq6mIPioCYfmvVuw04BuBVc1m7KPq00UBmvwNrY",
    },
});

export { generateDevelopmentConfig };
