import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";

import {
    EventFormat,
    MeasuringType,
    checkIsMovingDisciplineCategory,
    formatMeasuringType,
} from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { Collapse, RadioSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

import { BigDisciplineSelect, ResultAttempts, ResultValueInput } from "../../common";

const BigResultCreationDialog__OtherDataInput = ({ participant }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormControllerContext();
    const formDisciplineCategory = useWatch({
        control: formController.control,
        name: "disciplineCategory",
    });
    const formDiscipline = useWatch({
        control: formController.control,
        name: "discipline",
    });

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography variant="h6">3. Eintragen</Typography>
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                    <BigDisciplineSelect
                        disciplineCategoryFieldName="disciplineCategory"
                        fieldName="discipline"
                        isEnabled={participant !== undefined}
                        label="Disziplin *"
                        participant={participant}
                    />
                    {selectedEvent.format === EventFormat.COMPETITION && (
                        <Collapse
                            isOpen={
                                formDisciplineCategory !== undefined &&
                                checkIsMovingDisciplineCategory(formDisciplineCategory.id)
                            }
                            overwritingTopMargin={2}
                        >
                            <RadioSelect
                                fieldName="measuringType"
                                formatItem={formatMeasuringType}
                                items={Object.values(MeasuringType)}
                                label="Messtyp"
                            />
                        </Collapse>
                    )}
                    <ResultValueInput
                        discipline={formDiscipline === null ? undefined : formDiscipline}
                        fieldName="value"
                        isEnabled={formDiscipline !== undefined}
                        isFailedAttemptFieldName="isFailedAttempt"
                        label="Wert *"
                    />
                    <ResultAttempts
                        discipline={formDiscipline === null ? undefined : formDiscipline}
                        participant={participant}
                    />
                    <Button startIcon={<AddIcon />} type="submit" variant="contained">
                        Eintragen
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export { BigResultCreationDialog__OtherDataInput };
