import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { AppErrorType, schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext, useLogoutLocalContext } from "@bujus/common-frontend";

import {
    Form,
    HorizontalCenterErrorMessage,
    LoginButton,
    PasswordField,
} from "@/base";
import { useSnackbarsController } from "@/hooks";

const AccountRemovalPage__Logic = ({ token }) => {
    const snackbarsController = useSnackbarsController();

    const logoutLocal = useLogoutLocalContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.removal.body, validationOptions),
        defaultValues: { password: "" },
    });

    const mutation = useMutation(
        async (variables) => apiService.schools.removeAsync(variables.token, variables.password),
        {
            onError: (error) => {
                if (error.type === AppErrorType.INVALID.REMOVAL_TOKEN) {
                    return;
                }
                snackbarsController.enqueueError(error);
            },
            onSuccess: (account) => {
                logoutLocal();
                snackbarsController.enqueueSuccessMessage(`${account.name} entfernt`);
            },
        },
    );

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            token,
            password: data.password,
        });
    };

    if (mutation.isError && mutation.error.type === AppErrorType.INVALID.REMOVAL_TOKEN) {
        return (
            <Box>
                <HorizontalCenterErrorMessage error={mutation.error} />
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </Box>
        );
    }
    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <Box>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <PasswordField
                            autoComplete="current-password"
                            fieldName="password"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Passwort *"
                        />

                        <LoadingButton
                            fullWidth
                            loading={mutation.isLoading}
                            startIcon={<DeleteIcon />}
                            type="submit"
                            variant="contained"
                        >
                            Account löschen
                        </LoadingButton>
                    </Box>
                </Form>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </Box>
        );
    }
    if (mutation.isSuccess) {
        return (
            <Box>
                <Typography align="center">
                    Ihr Account und alle damit verbundenen Daten wurden gelöscht.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </Box>
        );
    }
    return undefined;
};

export { AccountRemovalPage__Logic };
