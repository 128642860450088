import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { formatResultValue, generateFullName, getDiscipline } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const ResultRemovalDialog = ({ close, result, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) => apiService.results.removeAsync(variables.id),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (removedResult) => {
                snackbarsController.enqueueSuccessMessage(
                    `${generateFullName(
                        removedResult.participant.name.first,
                        removedResult.participant.name.last,
                    )}: ${getDiscipline(selectedEvent.format, removedResult.disciplineId).name}: ${formatResultValue(
                        selectedEvent.format,
                        removedResult.disciplineId,
                        removedResult.isFailedAttempt,
                        removedResult.value,
                    )} entfernt`,
                );
                queryClient.invalidateQueries(["results"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ id: result.id });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Ergebnis entfernen</DialogTitle>
            <DialogClosingButton isDisabled={mutation.isLoading} onClick={handleClosingButtonClick} />
            <DialogContent>
                <DialogContentText>
                    {result.helper?.name !== undefined && (
                        <Typography variant="dialogContentTextLong">
                            <b>Helfer Name:</b> {result.helper.name}
                        </Typography>
                    )}
                    {result.helper?.username !== undefined && (
                        <Typography variant="dialogContentTextLong">
                            <b>Helfer Benutzername:</b> {result.helper.username}
                        </Typography>
                    )}
                    <Typography variant="dialogContentTextLong">
                        <b>Teilnehmer Name:</b>{" "}
                        {generateFullName(
                            result.participant.name.first,
                            result.participant.name.last,
                        )}
                    </Typography>
                    {result.participant.schoolInternalId !== undefined && (
                        <Typography variant="dialogContentTextLong">
                            <b>Teilnehmer Schulinterne-ID:</b> {result.participant.schoolInternalId}
                        </Typography>
                    )}
                    <Typography variant="dialogContentTextLong">
                        <b>Disziplin:</b>{" "}
                        {getDiscipline(selectedEvent.format, result.disciplineId).name}
                    </Typography>
                    <Typography variant="dialogContentTextLong">
                        <b>Wert:</b>{" "}
                        {formatResultValue(
                            selectedEvent.format,
                            result.disciplineId,
                            result.isFailedAttempt,
                            result.value,
                        )}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={mutation.isLoading}
                    onClick={handleButtonClick}
                    startIcon={<DeleteIcon />}
                >
                    Entfernen
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { ResultRemovalDialog };
