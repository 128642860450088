const Step = {
    ACCOUNT_SETTINGS: "ACCOUNT_SETTINGS",
    SCHOOL_SETTINGS: "SCHOOL_SETTINGS",
    ADDRESS_SETTINGS: "ADDRESS_SETTINGS",
};

const DisplayStep = {
    ACCOUNT_SETTINGS: 0,
    SCHOOL_SETTINGS: 1,
    ADDRESS_SETTINGS: 2,
};

const convertStepToDisplayStep = (step) => {
    switch (step) {
        case Step.ACCOUNT_SETTINGS:
            return DisplayStep.ACCOUNT_SETTINGS;
        case Step.SCHOOL_SETTINGS:
            return DisplayStep.SCHOOL_SETTINGS;
        case Step.ADDRESS_SETTINGS:
            return DisplayStep.ADDRESS_SETTINGS;
        default:
            throw new Error();
    }
};

const Action = {
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
};

const generateInitialState = (mailAddress) => ({
    step: Step.ACCOUNT_SETTINGS,
    formDatas: {
        accountSettings: {
            verifiableMailAddress: mailAddress,
            password: "",
            passwordConfirmation: "",
        },
        schoolSettings: {
            name: "",
            type: null,
        },
        addressSettings: {
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            state: null,
        },
    },
});

const reducer = (state, action) => {
    switch (action.type) {
        case Action.NEXT: {
            switch (state.step) {
                case Step.ACCOUNT_SETTINGS: {
                    return {
                        ...state,
                        step: Step.SCHOOL_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            accountSettings: action.formData,
                        },
                    };
                }
                case Step.SCHOOL_SETTINGS: {
                    if (state.formDatas.accountSettings.isTest) {
                        throw new Error();
                    }
                    return {
                        ...state,
                        step: Step.ADDRESS_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            schoolSettings: action.formData,
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        case Action.PREVIOUS: {
            switch (state.step) {
                case Step.SCHOOL_SETTINGS: {
                    return {
                        ...state,
                        step: Step.ACCOUNT_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            // accountSettings: state.formDatas.accountSettings,
                        },
                    };
                }
                case Step.ADDRESS_SETTINGS: {
                    return {
                        ...state,
                        step: Step.SCHOOL_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            // accountSettings: state.formDatas.accountSettings,
                            // schoolSettings: state.formDatas.schoolSettings,
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        default:
            throw new Error();
    }
};

const accountSignUpPage__StateMachine = {
    Step,
    DisplayStep,
    convertStepToDisplayStep,
    Action,
    generateInitialState,
    reducer,
};

export { accountSignUpPage__StateMachine };
