import {
    Checkbox as MuiCheckbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const Checkbox = ({ fieldName, isEnabled = true, isFullWidth = true, label }) => {
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const checkbox = (
        <MuiCheckbox
            checked={fieldController.field.value}
            onChange={fieldController.field.onChange}
        />
    );

    return (
        <FormControl
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth={isFullWidth}
        >
            {label === undefined ? checkbox : <FormControlLabel control={checkbox} label={label} />}
            {fieldController.fieldState.error !== undefined && (
                <FormHelperText>{fieldController.fieldState.error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

export { Checkbox };
