import {
    FilterList as FilterListIcon,
    FilterListOff as FilterListOffIcon,
    Info as InfoIcon,
} from "@mui/icons-material";
import { Box, Checkbox, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useController as useFieldController } from "react-hook-form";

import {
    Order,
    checkIsDisciplinePreselected,
    defaultDisciplineSorting,
    eventFormatToAllDisciplinesMap,
    getAllowedDisciplines,
} from "@bujus/common";
import {
    listItemsWithFuse,
    useDialogDisplayer,
    useFormControllerContext,
} from "@bujus/common-frontend";

import { AutocompleteSelect } from "@/base";
import { useDisciplinePreselectionContext, useSelectedEventStateContext } from "@/contexts";

import { DisciplineInformationDialog } from "../discipline-information-dialog";

const DisciplineSelect = ({ fieldName, isEnabled = true, label, participant }) => {
    const [selectedEvent] = useSelectedEventStateContext();
    const disciplinePreselection = useDisciplinePreselectionContext();
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const [isFilter, setIsFilter] = useState(
        participant === undefined ||
            fieldController.field.value === null ||
            getAllowedDisciplines(selectedEvent, participant).find(
                (discipline) => discipline.id === fieldController.field.value.id,
            ) !== undefined,
    );

    const informationDialogDisplayer = useDialogDisplayer(DisciplineInformationDialog);

    const searchableDisciplines = (() => {
        if (participant === undefined) {
            return [];
        }
        if (!isFilter) {
            return eventFormatToAllDisciplinesMap[selectedEvent.format];
        }
        return getAllowedDisciplines(selectedEvent, participant);
    })();

    const handleAutocompleteSelectListItemsAsync = async (listingModifiers) =>
        listItemsWithFuse(
            searchableDisciplines,
            [
                {
                    name: "name",
                    getFn: (discipline) => discipline.name,
                },
            ],
            defaultDisciplineSorting,
            listingModifiers,
        );

    const handleFilterCheckboxChange = (event) => {
        setIsFilter(event.target.checked);
    };

    const handleInformationButtonClick = () => {
        informationDialogDisplayer.open({ discipline: fieldController.field.value });
    };

    useEffect(() => {
        if (fieldController.field.value !== null) {
            let disciplines = [];
            if (participant === undefined || !isFilter) {
                disciplines = eventFormatToAllDisciplinesMap[selectedEvent.format];
            } else {
                disciplines = getAllowedDisciplines(selectedEvent, participant);
            }
            if (
                disciplines.find(
                    (discipline) => discipline.id === fieldController.field.value.id,
                ) !== undefined
            ) {
                return;
            }
        }
        formController.setValue(fieldName, null);
    }, [participant, isFilter]);

    return (
        <>
            <Box display="flex">
                <Box flex={1}>
                    <AutocompleteSelect
                        baseQueryKey={["disciplines", "list"]}
                        fieldName={fieldName}
                        fieldSorting={{
                            path: "name",
                            order: Order.ASCENDING,
                        }}
                        formatItem={(discipline) =>
                            `${
                                participant === undefined ||
                                !checkIsDisciplinePreselected(
                                    selectedEvent,
                                    disciplinePreselection,
                                    participant,
                                    discipline.id,
                                )
                                    ? ""
                                    : "★   "
                            }${discipline.name}`
                        }
                        getKeyFromItem={(discipline) => discipline.id}
                        isEnabled={isEnabled}
                        isLocalData
                        label={label}
                        listItemsAsync={handleAutocompleteSelectListItemsAsync}
                        pageSize={searchableDisciplines.length}
                    />
                </Box>
                <Box alignItems="center" display="flex" gap={1} height={56} ml={1}>
                    <Tooltip title="Informationen zur ausgewählten Disziplin">
                        <Box>
                            <IconButton
                                disabled={fieldController.field.value === null}
                                onClick={handleInformationButtonClick}
                            >
                                <InfoIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <Tooltip title="Disziplinen für ausgewählten Teilnehmer filtern">
                        <Checkbox
                            checked={isFilter}
                            checkedIcon={<FilterListIcon />}
                            disabled={!isEnabled}
                            icon={<FilterListOffIcon />}
                            onChange={handleFilterCheckboxChange}
                            tabIndex={-1}
                        />
                    </Tooltip>
                </Box>
            </Box>
            {informationDialogDisplayer.element}
        </>
    );
};

export { DisciplineSelect };
