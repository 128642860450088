import { Box, Typography } from "@mui/material";
import React from "react";

const AllGroupsUpdateDialog__DataGridNoResultsOverlay = () => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
    >
        <Typography textAlign="center">Keine Teilnehmer gefunden.</Typography>
    </Box>
);

export { AllGroupsUpdateDialog__DataGridNoResultsOverlay };
