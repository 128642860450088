import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { helperValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Checkbox, DialogClosingButton, Form, PasswordField, TextField } from "@/base";
import { useSnackbarsController } from "@/hooks";

const HelperUpdateDialog = ({ close, helper, isOpen, removalDialogDisplayer }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(helperValidationSchemas.update.form, validationOptions),
        defaultValues: {
            name: helper.name === undefined ? "" : helper.name,
            username: helper.username,
            password: helper.password,
            isActive: helper.isActive,
        },
    });

    const mutation = useMutation(
        async (variables) => apiService.helpers.updateAsync(variables.id, variables.helper),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (updatedHelper) => {
                snackbarsController.enqueueSuccessMessage(`${updatedHelper.username} geändert`);
                queryClient.invalidateQueries(["helpers"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleRemovalButtonClick = () => {
        if (!tryClose()) {
            return;
        }
        removalDialogDisplayer.open({ helper });
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            id: helper.id,
            helper: {
                ...(data.name !== "" && { name: data.name }),
                username: data.username,
                password: data.password,
                isActive: data.isActive,
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Helfer bearbeiten</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            fieldName="name"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Name *"
                        />
                        <TextField
                            fieldName="username"
                            isEnabled={!mutation.isLoading}
                            label="Benutzername *"
                        />
                        <PasswordField
                            fieldName="password"
                            isEnabled={!mutation.isLoading}
                            label="Passwort *"
                        />
                        <Checkbox
                            fieldName="isActive"
                            isEnabled={!mutation.isLoading}
                            label="Aktiv"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={mutation.isLoading}
                        onClick={handleRemovalButtonClick}
                        startIcon={<DeleteIcon />}
                    >
                        Entfernen
                    </Button>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<EditIcon />}
                        type="submit"
                    >
                        Ändern
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { HelperUpdateDialog };
