import { Description as DescriptionIcon } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    Chip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";

import { formatDate } from "@bujus/common";
import { formatInvoiceStatus, useApiServiceContext } from "@bujus/common-frontend";

import { HorizontalCenterCircularProgress, QueryError } from "@/base";
import { useAccountContext, useDashboardTitleStateContext } from "@/contexts";
import { accountTheme } from "@/styles";
import { formatMoneyAmount } from "@/utilities";

const PaymentsPage = () => {
    const theme = useTheme();

    const account = useAccountContext();
    const apiService = useApiServiceContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const query = useQuery(["schools", "getAllPayments", account.id], async ({ queryKey: key }) =>
        apiService.schools.getAllPaymentsAsync(key[2]),
    );

    const handleInvoiceOpenButtonClick = (payment) => {
        window.open(payment.url, "_blank");
    };

    useEffect(() => {
        setDashboardTitle("Zahlungen");
    }, []);

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    } else if (query.isError) {
        content = <QueryError query={query} />;
    } else if (query.isSuccess) {
        if (query.data.length === 0) {
            content = <Typography align="center">Noch keine Zahlungen getätigt.</Typography>;
        } else {
            content = (
                <Table>
                    <colgroup>
                        <col width="125px" />
                        <col width="100px" />
                        <col width="125px" />
                        <col width="275px" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>Datum</TableCell>
                            <TableCell align="right" sx={{ fontWeight: "bold" }}>
                                Betrag
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                Rechnung
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {query.data.map((x) => (
                            <TableRow key={x.created}>
                                <TableCell>{formatDate(x.created, "P")}</TableCell>
                                <TableCell align="right">{formatMoneyAmount(x.amount)}</TableCell>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleInvoiceOpenButtonClick(x)}>
                                        <DescriptionIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        color={x.status !== "paid" ? "warning" : "success"}
                                        label={formatInvoiceStatus(x)}
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        }
    }
    if (content !== undefined) {
        return (
            <ThemeProvider theme={accountTheme}>
                <Box maxWidth={theme.breakpoints.values.md}>
                    <Card>
                        <CardContent>{content}</CardContent>
                    </Card>
                </Box>
            </ThemeProvider>
        );
    }
    return undefined;
};

export { PaymentsPage };
