import { VerticalAlignCenter as VerticalAlignCenterIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useWatch } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

import { CertTemplateUpdateDialog__NumberInput } from "./cert-template-update-dialog__number-input";

const CertTemplateUpdateDialog__PositionOffsetSettings = ({ fieldName, isEnabled = true }) => {
    const formController = useFormControllerContext();

    const maxFieldValue = useWatch({
        control: formController.control,
        name: `${fieldName}.max`,
    });
    const stepFieldValue = useWatch({
        control: formController.control,
        name: `${fieldName}.step`,
    });

    return (
        <Box alignItems="center" display="flex">
            <CertTemplateUpdateDialog__NumberInput
                fieldName={`${fieldName}.x`}
                icon={
                    <VerticalAlignCenterIcon
                        color="secondary"
                        sx={{ transform: "rotate(90deg)" }}
                    />
                }
                isEnabled={isEnabled}
                isHorizontal
                max={maxFieldValue}
                step={stepFieldValue}
            />
            <Box flex={1} ml={4}>
                <CertTemplateUpdateDialog__NumberInput
                    fieldName={`${fieldName}.y`}
                    icon={<VerticalAlignCenterIcon color="secondary" />}
                    isEnabled={isEnabled}
                    isFlipped
                    isHorizontal={false}
                    max={maxFieldValue}
                    step={stepFieldValue}
                />
            </Box>
        </Box>
    );
};

export { CertTemplateUpdateDialog__PositionOffsetSettings };
