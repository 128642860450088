import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { helperValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Checkbox, DialogClosingButton, Form } from "@/base";
import { useSnackbarsController } from "@/hooks";

const HelperRemovalDialog = ({ close, helper, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(helperValidationSchemas.removal.body, validationOptions),
        defaultValues: { isResultsKeepingEnabled: true },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.helpers.removeAsync(variables.id, variables.isResultsKeepingEnabled),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (removedHelper) => {
                snackbarsController.enqueueSuccessMessage(`${removedHelper.username} entfernt`);
                queryClient.invalidateQueries(["helpers"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            id: helper.id,
            isResultsKeepingEnabled: data.isResultsKeepingEnabled,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Helfer entfernen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <DialogContentText>
                        {helper.name !== undefined && (
                            <Typography variant="dialogContentTextLong">
                                <b>Name:</b> {helper.name}
                            </Typography>
                        )}
                        <Typography variant="dialogContentTextLong">
                            <b>Benutzername:</b> {helper.username}
                        </Typography>
                    </DialogContentText>
                    <Box mt={2}>
                        <Checkbox
                            fieldName="isResultsKeepingEnabled"
                            isEnabled={!mutation.isLoading}
                            label="Ergebnisse behalten"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<DeleteIcon />}
                        type="submit"
                    >
                        Entfernen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { HelperRemovalDialog };
