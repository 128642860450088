import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ThemeProvider,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { accountTheme } from "@/styles";

const AccountPasswordChangeRequestDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const mutation = useMutation(
        async (variables) => apiService.schools.requestPasswordChangeAsync(variables.accountId),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account Passwort Änderung angefragt");
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleButtonClick = () => {
        mutation.mutate({ accountId: account.id });
    };

    return (
        <ThemeProvider theme={accountTheme}>
            <Dialog onClose={handleClose} open={isOpen}>
                <DialogTitle>Account Passwort ändern</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <DialogContentText>
                        Um Ihr Passwort zu ändern, schicken wir Ihnen eine Email, mit einem Link zur
                        Änderung.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        disabled={mutation.isLoading}
                        onClick={handleButtonClick}
                        startIcon={<EditIcon />}
                    >
                        Änderung anfragen
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export { AccountPasswordChangeRequestDialog };
