import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const IsActiveOfAllHelpersUpdateDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const mutation = useMutation(
        async (variables) =>
            apiService.helpers.updateIsActiveOfAllAsync(variables.eventId, variables.isActive),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage(
                    `Alle Helfer ${!variables.isActive ? "deaktiviert" : "aktiviert"}`,
                );
                queryClient.invalidateQueries(["helpers"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const handleDeactivateButtonClick = () => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            isActive: false,
        });
    };

    const handleActivateButtonClick = () => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            isActive: true,
        });
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Helfer aktivieren oder deaktivieren</DialogTitle>
            <DialogClosingButton disabled={mutation.isLoading} onClick={handleClosingButtonClick} />
            <DialogActions>
                <LoadingButton
                    disabled={mutation.isLoading}
                    loading={mutation.isLoading && !mutation.variables.isActive}
                    onClick={handleDeactivateButtonClick}
                    startIcon={<CloseIcon />}
                >
                    Alle deaktivieren
                </LoadingButton>
                <LoadingButton
                    disabled={mutation.isLoading}
                    loading={mutation.isLoading && mutation.variables.isActive}
                    onClick={handleActivateButtonClick}
                    startIcon={<CheckIcon />}
                >
                    Alle aktivieren
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export { IsActiveOfAllHelpersUpdateDialog };
