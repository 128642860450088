import { TextField as MuiTextField } from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext } from "@bujus/common-frontend";

const TextField = ({
    cyId = undefined,
    fieldName,
    isAutofocusing = false,
    isEnabled = true,
    label,
    type,
}) => {
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    return (
        <MuiTextField
            autoFocus={isAutofocusing}
            {...(cyId !== undefined && { cyId })}
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
            {...(fieldController.fieldState.error !== undefined && {
                helperText: fieldController.fieldState.error.message,
            })}
            label={label}
            onChange={fieldController.field.onChange}
            {...(type !== undefined && { type })}
            value={fieldController.field.value}
        />
    );
};

export { TextField };
