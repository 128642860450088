import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    Gender,
    calculateAgeGroup,
    formatGender,
    generateFullName,
    participantValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { Collapse, DialogClosingButton, Form, MaskedInput, RadioSelect, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";
import { birthYearMask } from "@/utilities";

import { GroupSelect } from "../../common";
import { GroupCreationDialog } from "../../common/group-creation-dialog";

const ParticipantCreationDialog = ({ close, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const [latestAgeGroup, setLatestAgeGroup] = useState();

    const groupCreationDialogDisplayer = useDialogDisplayer(GroupCreationDialog);

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(participantValidationSchemas.creation.form, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            schoolInternalId: "",
            name: {
                first: "",
                last: "",
            },
            gender: Gender.MALE,
            birthYear: birthYearMask.defaultValue,
            class: "",
            group: null,
        },
    });
    const birthYearFieldValue = useWatch({
        control: formController.control,
        name: "birthYear",
    });

    const mutation = useMutation(
        async (variables) => apiService.participants.createAsync(variables.participant),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (participant) => {
                snackbarsController.enqueueSuccessMessage(
                    `${generateFullName(
                        participant.name.first,
                        participant.name.last,
                    )} hinzugefügt`,
                );
                queryClient.invalidateQueries(["participants"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            participant: {
                eventId: selectedEvent.id,
                ...(data.schoolInternalId !== "" && { schoolInternalId: data.schoolInternalId }),
                name: {
                    first: data.name.first,
                    last: data.name.last,
                },
                gender: data.gender,
                birthYear: Number(data.birthYear),
                ...(data.class !== "" && { class: data.class }),
                ...(data.group !== null && { groupId: data.group.id }),
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    useEffect(() => {
        // HACK Joi does not throw an error, when the value was full 4 characters and then the user deletes one character
        if (
            birthYearFieldValue.replace("_", "").length !== 4 ||
            formController.formState.errors.birthYear !== undefined
        ) {
            return;
        }
        setLatestAgeGroup(calculateAgeGroup(selectedEvent.start, Number(birthYearFieldValue)));
    }, [formController.formState.errors.birthYear, birthYearFieldValue]);

    return (
        <>
            <Dialog onClose={handleClose} open={isOpen}>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <DialogTitle>Teilnehmer hinzufügen</DialogTitle>
                    <DialogClosingButton
                        isDisabled={mutation.isLoading}
                        onClick={handleClosingButtonClick}
                    />
                    <DialogContent>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <TextField
                                fieldName="schoolInternalId"
                                isAutofocusing
                                isEnabled={!mutation.isLoading}
                                label="Schulinterne-ID"
                            />
                            <TextField
                                fieldName="name.first"
                                isEnabled={!mutation.isLoading}
                                label="Vorname *"
                            />
                            <TextField
                                fieldName="name.last"
                                isEnabled={!mutation.isLoading}
                                label="Nachname *"
                            />
                            <RadioSelect
                                fieldName="gender"
                                formatItem={formatGender}
                                isEnabled={!mutation.isLoading}
                                items={Object.values(Gender)}
                                label="Geschlecht *"
                            />
                            <MaskedInput
                                fieldName="birthYear"
                                isEnabled={!mutation.isLoading}
                                label="Geburtsjahr *"
                                mask={birthYearMask}
                            />
                            <Collapse
                                isOpen={
                                    birthYearFieldValue.replace("_", "").length === 4 &&
                                    formController.formState.errors.birthYear === undefined
                                }
                                overwritingTopMargin={2}
                            >
                                <Typography>
                                    <b>Altersgruppe:</b> {latestAgeGroup}
                                </Typography>
                            </Collapse>
                            <TextField
                                fieldName="class"
                                isEnabled={!mutation.isLoading}
                                label="Klasse"
                            />
                            <GroupSelect
                                creationDialogDisplayer={groupCreationDialogDisplayer}
                                fieldName="group"
                                isEnabled={!mutation.isLoading}
                                label="Riege"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            loading={mutation.isLoading}
                            startIcon={<AddIcon />}
                            type="submit"
                        >
                            Hinzufügen
                        </LoadingButton>
                    </DialogActions>
                </Form>
            </Dialog>
            {groupCreationDialogDisplayer.element}
        </>
    );
};

export { ParticipantCreationDialog };
