import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

const ClosingConfirmationDialog = ({ close, isOpen, onConfirmation }) => {
    const handleClose = () => {
        close();
    };

    const handleConfirmationButtonClick = () => {
        onConfirmation();
        close();
    };

    const handleCancelButtonClick = () => {
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Änderungen verwerfen?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Wenn Sie den Dialog schließen, werden alle Änderungen verworfen.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleConfirmationButtonClick}>
                    Änderungen verwerfen
                </Button>
                <Button onClick={handleCancelButtonClick}>Abbrechen</Button>
            </DialogActions>
        </Dialog>
    );
};

export { ClosingConfirmationDialog };
