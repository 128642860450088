import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useDashboardSideBarContext } from "@/contexts";

const SideBar__Link = ({ cyId = undefined, isEnabled = true, icon, route, text }) => {
    const fullRoute = useLocation().pathname;
    const navigate = useNavigate();

    const { isDashboardSideBarOpen } = useDashboardSideBarContext();

    const handleClick = () => {
        // closeDashboardSideBar();
        navigate(route);
    };

    return (
        <Tooltip placement="right" title={isDashboardSideBarOpen ? "" : text}>
            <ListItemButton
                {...(cyId !== undefined && { cyId })}
                disabled={!isEnabled}
                onClick={handleClick}
                selected={fullRoute === route || fullRoute.startsWith(`${route}/`)}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>
        </Tooltip>
    );
};

export { SideBar__Link };
