import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    ClassLevelRange,
    EventFormat,
    formatClassLevelRange,
    groupValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, RadioSelect, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const GroupCreationDialog = ({ close, isOpen, onCreated }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(groupValidationSchemas.creation.form, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            name: "",
            ...(selectedEvent.format === EventFormat.CONTEST && {
                classLevelRange: ClassLevelRange.N_1_AND_2,
            }),
        },
    });

    const mutation = useMutation(
        async (variables) => apiService.groups.createAsync(variables.group),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (group) => {
                snackbarsController.enqueueSuccessMessage(`${group.name} hinzugefügt`);
                queryClient.invalidateQueries(["groups"]);
                close();
                onCreated(group);
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            group: {
                eventId: selectedEvent.id,
                name: data.name,
                ...(selectedEvent.format === EventFormat.CONTEST && {
                    classLevelRange: data.classLevelRange,
                }),
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Riege hinzufügen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField
                            fieldName="name"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Name *"
                        />
                        {selectedEvent.format === EventFormat.CONTEST && (
                            <RadioSelect
                                fieldName="classLevelRange"
                                formatItem={formatClassLevelRange}
                                isEnabled={!mutation.isLoading}
                                items={Object.values(ClassLevelRange)}
                                label="Klassenstufe *"
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<AddIcon />}
                        type="submit"
                    >
                        Hinzufügen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { GroupCreationDialog };
