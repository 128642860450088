import React from "react";

import { Dashboard__Layout } from "./dashboard__layout";
import { Dashboard__Loader } from "./dashboard__loader";
import { Dashboard__Router } from "./dashboard__router";

const Dashboard = () => (
    <Dashboard__Loader>
        <Dashboard__Layout>
            <Dashboard__Router />
        </Dashboard__Layout>
    </Dashboard__Loader>
);

export { Dashboard };
