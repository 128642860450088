import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";

import {
    DisciplineCategoryId,
    formatCertGenerationErrorType,
    formatCertType,
    formatResultValue,
    getDiscipline,
    getDisciplineCategory,
} from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";
import { getResultColor } from "@/utilities";

const CertDetailsDialog__ContestEventEvaluationCard = ({ cert }) => {
    const [selectedEvent] = useSelectedEventStateContext();
    console.log(cert);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Details</Typography>
                <Box mt={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{ fontWeight: "bold" }}>Disziplin</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Wert</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Rang</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Fehler</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Gefälscht</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(DisciplineCategoryId).map((x) =>
                                [
                                    undefined,
                                    ...cert.disciplineCategoryIdToDisciplineCategoryEvaluationMap[x]
                                        .bestResults,
                                ].map((y, yIndex) => (
                                    <TableRow
                                        key={`${x}.${yIndex}`}
                                        {...(yIndex === 0
                                            ? {}
                                            : {
                                                  sx: {
                                                      color: getResultColor(
                                                          selectedEvent.format,
                                                          cert.disciplineCategoryIdToDisciplineCategoryEvaluationMap,
                                                          cert
                                                              .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                              x
                                                          ],
                                                      ),
                                                  },
                                              })}
                                    >
                                        {/* Disziplinkategorie */}
                                        <TableCell
                                            sx={{
                                                fontWeight: "bold",
                                                color: "unset",
                                            }}
                                        >
                                            {yIndex === 0 &&
                                                getDisciplineCategory(selectedEvent.format, x).name}
                                        </TableCell>
                                        {/* Disziplin */}
                                        <TableCell sx={{ color: "unset" }}>
                                            {yIndex > 0 &&
                                                getDiscipline(selectedEvent.format, y.disciplineId)
                                                    .name}
                                        </TableCell>
                                        {/* Wert */}
                                        <TableCell sx={{ color: "unset" }}>
                                            {yIndex > 0 &&
                                                formatResultValue(
                                                    selectedEvent.format,
                                                    y.disciplineId,
                                                    false,
                                                    y.value,
                                                )}
                                        </TableCell>
                                        {/* Rang */}
                                        <TableCell sx={{ color: "unset" }}>
                                            {yIndex === 0 &&
                                                cert
                                                    .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                    x
                                                ].rank}
                                        </TableCell>
                                        {/* Fehler */}
                                        <TableCell sx={{ color: "unset" }}>
                                            {yIndex === 0 &&
                                                !cert
                                                    .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                    x
                                                ].isValid &&
                                                formatCertGenerationErrorType(
                                                    cert
                                                        .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                        x
                                                    ].errorType,
                                                )}
                                        </TableCell>
                                        {/* Gefälscht */}
                                        <TableCell sx={{ color: "unset" }}>
                                            {yIndex === 0 ? undefined : !y.isFake ? (
                                                <CloseIcon
                                                    sx={{
                                                        color: "inherit",
                                                        height: "0.875em",
                                                    }}
                                                />
                                            ) : (
                                                <CheckIcon
                                                    sx={{
                                                        color: "inherit",
                                                        height: "0.875em",
                                                    }}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )),
                            )}
                        </TableBody>
                    </Table>
                    {!cert.isGenerated ? (
                        <Typography mt={2}>
                            <b>Fehler:</b> {formatCertGenerationErrorType(cert.errorType)}
                        </Typography>
                    ) : (
                        <>
                            <Typography mt={2}>
                                <b>Rangsumme:</b> {cert.rankSum}
                            </Typography>
                            <Typography mt={2}>
                                <b>Rang:</b> {cert.points} von {cert.groupParticipantCount}
                            </Typography>
                            <Typography mt={2}>
                                <b>Typ:</b> {formatCertType(cert.type)}
                            </Typography>
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export { CertDetailsDialog__ContestEventEvaluationCard };
