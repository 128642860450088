import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { AppErrorType, eventValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import {
    Form,
    HorizontalCenterErrorMessage,
    LoginButton,
    PasswordField,
} from "@/base";
import { useSnackbarsController } from "@/hooks";

const EventRemovalPage__Logic = ({ token }) => {
    const snackbarsController = useSnackbarsController();

    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(eventValidationSchemas.removal.body, validationOptions),
        defaultValues: { password: "" },
    });

    const mutation = useMutation(
        async (variables) => {
            console.log("MUTATING");
            return apiService.events.removeAsync(
                variables.token,
                variables.password,
                variables.passwordConfirmation,
            );
        },
        {
            onError: (error) => {
                if (error.type === AppErrorType.INVALID.REMOVAL_TOKEN) {
                    return;
                }
                snackbarsController.enqueueError(error);
            },
            onSuccess: (event) => {
                snackbarsController.enqueueSuccessMessage(`${event.name} entfernt`);
            },
        },
    );
    console.log(mutation);

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            token,
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
        });
    };

    if (mutation.isError && mutation.error.type === AppErrorType.INVALID.REMOVAL_TOKEN) {
        return (
            <>
                <HorizontalCenterErrorMessage error={mutation.error} />
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <PasswordField
                            autoComplete="current-password"
                            fieldName="password"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Passwort *"
                        />
                        <LoadingButton
                            fullWidth
                            loading={mutation.isLoading}
                            startIcon={<DeleteIcon />}
                            type="submit"
                            variant="contained"
                        >
                            Veranstaltung löschen
                        </LoadingButton>
                    </Box>
                </Form>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </>
        );
    }
    if (mutation.isSuccess) {
        return (
            <>
                <Typography align="center">
                    Die Veranstaltung und alle damit verbundenen Daten wurden gelöscht.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </>
        );
    }
    return undefined;
};

export { EventRemovalPage__Logic };
