import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { eventValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, PasswordField } from "@/base";
import { useSnackbarsController } from "@/hooks";

const EventRemovalRequestDialog = ({ close, event, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(eventValidationSchemas.removalRequest.body, validationOptions),
        defaultValues: { password: "" },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.events.requestRemovalAsync(variables.eventId, variables.password),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Veranstaltungs Löschung angefragt");
                queryClient.invalidateQueries(["events"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: event.id,
            password: data.password,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Veranstaltung löschen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <DialogContentText>
                        Um die Veranstaltung zu löschen, schicken wir Ihnen eine Email, mit einem
                        Link zur Löschung.
                        <Typography variant="dialogContentTextLong">
                            <b>Name:</b> {event.name}
                        </Typography>
                        <Typography variant="dialogContentTextLong">
                            <b>3-Wort-ID:</b> {event.n3WordId}
                        </Typography>
                    </DialogContentText>
                    <Box mt={2}>
                        <PasswordField
                            autoComplete="current-password"
                            fieldName="password"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Passwort *"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<DeleteIcon />}
                        type="submit"
                    >
                        Löschung anfragen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { EventRemovalRequestDialog };
