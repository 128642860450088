import { Print as PrintIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import React from "react";

import { generateFullName } from "@bujus/common";
import { EventFormatComponent } from "@bujus/common-frontend";

import { DialogClosingButton } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

import { CertDetailsDialog__CompetitionEventEvaluationCard } from "./cert-details-dialog__competition-event-evaluation-card";
import { CertDetailsDialog__ContestEventEvaluationCard } from "./cert-details-dialog__contest-event-evaluation-card";

const CertDetailsDialog = ({ cert, close, isOpen, printingDialogDisplayer = undefined }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const handleClose = () => {
        close();
    };

    const handleClosingButtonClick = () => {
        close();
    };

    const handlePrintingButtonClick = () => {
        close();
        printingDialogDisplayer.open({ cert });
    };

    return (
        <Dialog maxWidth="md" onClose={handleClose} open={isOpen}>
            <DialogTitle>Urkunde-Details</DialogTitle>
            <DialogClosingButton onClick={handleClosingButtonClick} />
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={1}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Teilnehmer</Typography>
                            {cert.participant.schoolInternalId !== undefined && (
                                <Typography mt={2}>
                                    <b>Schulinterne-ID:</b> {cert.participant.schoolInternalId}
                                </Typography>
                            )}
                            <Typography mt={2}>
                                <b>Name:</b>{" "}
                                {generateFullName(
                                    cert.participant.name.first,
                                    cert.participant.name.last,
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                    <EventFormatComponent
                        cert={cert}
                        competitionEventComponent={
                            CertDetailsDialog__CompetitionEventEvaluationCard
                        }
                        contestEventComponent={CertDetailsDialog__ContestEventEvaluationCard}
                        eventFormat={selectedEvent.format}
                    />
                </Box>
            </DialogContent>
            {printingDialogDisplayer !== undefined && (
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={handlePrintingButtonClick}
                        startIcon={<PrintIcon />}
                    >
                        Drucken
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export { CertDetailsDialog };
