import {
    ArrowOutward as ArrowOutwardIcon,
    Clear as ClearIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Groups3 as Groups3Icon,
    Search as SearchIcon,
} from "@mui/icons-material";
import { Box, Button, InputAdornment, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import {
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { EventFormat, SpecialGroupId, validationTemplates } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { ButtonHotkeyIcon } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

import { AllGroupsUpdateDialog__GroupsAutoDistributionDialog } from "./all-groups-update-dialog__groups-auto-distribution-dialog";

const AllGroupsUpdateDialog__DataMasterToolbar = ({
    groups,
    onAutoDistributionByClass,
    onAutoDistributionByParticipantGroup,
    onSelectedGroupRemoval,
    onSelectedGroupUpdate,
    onSelectedParticipantsTransfer,
    onSelectedParticipantsUnallocation,
    selectedGroup,
    selectedParticipantIds,
}) => {
    const [isAutoDistributionMenuOpen, setIsAutoDistributionMenuOpen] = useState(false);
    const [isSelectedParticipantTransferMenuOpen, setIsSelectedParticipantTransferMenuOpen] =
        useState(false);

    const autoDistributionMenuAnchorElementRef = useRef();
    const selectedParticipantTransferMenuAnchorElementRef = useRef();

    const autoDistributionSettingsDialogDisplayer = useDialogDisplayer(
        AllGroupsUpdateDialog__GroupsAutoDistributionDialog,
    );

    useHotkeys("e", (event) => {
        event.preventDefault();
        if (selectedGroup.id === SpecialGroupId.UNALLOCATED) {
            return;
        }
        onSelectedParticipantsUnallocation();
    });

    useHotkeys("del, backspace", (event) => {
        event.preventDefault();
        if (selectedGroup.id === SpecialGroupId.UNALLOCATED) {
            return;
        }
        onSelectedGroupRemoval();
    });

    const handleUpdateButtonClick = () => {
        onSelectedGroupUpdate();
    };

    const handleSelectedParticipantsTransferButtonClick = () => {
        setIsSelectedParticipantTransferMenuOpen(true);
    };

    const handleAutoDistributionMenuOpenButtonClick = () => {
        setIsAutoDistributionMenuOpen(true);
    };

    const handleAutoDistributionMenuClose = () => {
        setIsAutoDistributionMenuOpen(false);
    };

    const handleAutoDistributionByParticipantGroupMenuItemClick = () => {
        autoDistributionSettingsDialogDisplayer.open({
            title: "Automatische Verteilung nach Geschlecht und Altersgruppe",
            onDone: (participantLimitPerGroup) => {
                onAutoDistributionByParticipantGroup(participantLimitPerGroup);
            },
        });
        handleAutoDistributionMenuClose();
    };

    const handleAutoDistributionByClassMenuItemClick = () => {
        autoDistributionSettingsDialogDisplayer.open({
            title: "Automatische Verteilung nach Klasse",
            onDone: (participantLimitPerGroup) => {
                onAutoDistributionByClass(participantLimitPerGroup);
            },
        });
        handleAutoDistributionMenuClose();
    };

    const handleSelectedParticipantsTransferMenuClose = () => {
        setIsSelectedParticipantTransferMenuOpen(false);
    };

    const handleSelectedParticipantsTransferMenuGroupMenuItemClick = (targetId) => {
        onSelectedParticipantsTransfer(targetId);
    };

    const handleSelectedParticipantsUnallocationButtonClick = () => {
        onSelectedParticipantsUnallocation();
    };

    const handleGroupRemovalButtonClick = () => {
        onSelectedGroupRemoval();
    };

    const selectedParticipantsTransferGroupOptions = groups.filter(
        (x) => x.id !== selectedGroup.id && x.id !== SpecialGroupId.UNALLOCATED,
    );

    return (
        <>
            <Box mb={1}>
                <Box
                    sx={{
                        flexWrap: "wrap",
                        display: "flex",
                        gap: 1,
                    }}
                >
                    <Box alignItems="center" display="flex" gap={1}>
                        <GridToolbarColumnsButton size="medium" />
                        <GridToolbarFilterButton size="medium" />
                        <Tooltip title="Durchsucht Schulinterne-ID, Vorname, Nachname, Geschlecht, Geburtsjahr, Altersgruppe, Klasse">
                            <GridToolbarQuickFilter
                                debounceMs={750}
                                inputProps={{ maxLength: validationTemplates.searchValueMaxLength }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Schulinterne-ID, Vorname, Nachname, ..."
                                size="small"
                                variant="outlined"
                            />
                        </Tooltip>
                    </Box>
                    <Box alignItems="center" display="flex" gap={1}>
                        {selectedGroup.id !== SpecialGroupId.UNALLOCATED && (
                            <Button onClick={handleUpdateButtonClick} startIcon={<EditIcon />}>
                                Bearbeiten
                            </Button>
                        )}
                        <Button
                            ref={selectedParticipantTransferMenuAnchorElementRef}
                            disabled={selectedParticipantIds.length === 0}
                            onClick={handleSelectedParticipantsTransferButtonClick}
                            startIcon={<ArrowOutwardIcon />}
                        >
                            Verschieben
                        </Button>
                        {selectedGroup.id === SpecialGroupId.UNALLOCATED && (
                            <>
                                <Button
                                    ref={autoDistributionMenuAnchorElementRef}
                                    disabled={selectedGroup.participants.length === 0}
                                    onClick={handleAutoDistributionMenuOpenButtonClick}
                                    startIcon={<Groups3Icon />}
                                >
                                    Alle Automatisch Verteilen
                                </Button>
                                <Menu
                                    anchorEl={autoDistributionMenuAnchorElementRef.current}
                                    onClose={handleAutoDistributionMenuClose}
                                    open={isAutoDistributionMenuOpen}
                                >
                                    <MenuItem
                                        onClick={
                                            handleAutoDistributionByParticipantGroupMenuItemClick
                                        }
                                    >
                                        <ListItemText>
                                            Nach Geschlecht und Altersgruppe
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={handleAutoDistributionByClassMenuItemClick}>
                                        <ListItemText>Nach Klasse</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                        <Menu
                            anchorEl={selectedParticipantTransferMenuAnchorElementRef.current}
                            onClose={handleSelectedParticipantsTransferMenuClose}
                            open={isSelectedParticipantTransferMenuOpen}
                        >
                            {selectedParticipantsTransferGroupOptions.length === 0 ? (
                                <MenuItem disabled>Keine Optionen</MenuItem>
                            ) : (
                                selectedParticipantsTransferGroupOptions.map((x) => (
                                    <MenuItem
                                        key={x.id}
                                        onClick={() =>
                                            handleSelectedParticipantsTransferMenuGroupMenuItemClick(
                                                x.id,
                                            )
                                        }
                                    >
                                        <ListItemText>{x.name}</ListItemText>
                                    </MenuItem>
                                ))
                            )}
                        </Menu>
                        {selectedGroup.id !== SpecialGroupId.UNALLOCATED && (
                            <>
                                <Button
                                    disabled={selectedParticipantIds.length === 0}
                                    endIcon={<ButtonHotkeyIcon>E</ButtonHotkeyIcon>}
                                    onClick={handleSelectedParticipantsUnallocationButtonClick}
                                    startIcon={<ClearIcon />}
                                >
                                    Aus Riege Entfernen
                                </Button>
                                <Button
                                    endIcon={<ButtonHotkeyIcon>DEL</ButtonHotkeyIcon>}
                                    onClick={handleGroupRemovalButtonClick}
                                    startIcon={<DeleteIcon />}
                                >
                                    Riege Entfernen
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            {autoDistributionSettingsDialogDisplayer.element}
        </>
    );
};

export { AllGroupsUpdateDialog__DataMasterToolbar };
