import React, { StrictMode } from "react";
import ReactDomClient from "react-dom/client";

import { App } from "@/app";

ReactDomClient.createRoot(document.getElementById("root")).render(
    <StrictMode>
        <App />
    </StrictMode>,
);
