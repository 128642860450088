import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { nanoid } from "nanoid";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    ClassLevelRange,
    EventFormat,
    formatClassLevelRange,
    groupValidationSchemas,
    validationOptions,
} from "@bujus/common";

import { DialogClosingButton, Form, RadioSelect, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AllGroupsUpdateDialog__GroupCreationDialog = ({
    close,
    isOpen,
    controller,
    participantIds,
}) => {
    const snackbarsController = useSnackbarsController();

    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(groupValidationSchemas.creation.form, validationOptions),
        defaultValues: {
            name: "",
            ...(selectedEvent.format === EventFormat.CONTEST && {
                classLevelRange: ClassLevelRange.N_1_AND_2,
            }),
        },
    });

    const handleClose = () => {
        close();
    };

    const handleFormSubmit = (data2) => {
        try {
            controller.create({
                id: nanoid(),
                name: data2.name,
                ...(selectedEvent.format === EventFormat.CONTEST && {
                    classLevelRange: data2.classLevelRange,
                }),
                participants: controller.groups
                    .flatMap((x) => x.participants)
                    .filter((x) => participantIds.includes(x.id)),
            });
            close();
        } catch (error) {
            snackbarsController.enqueueError(error);
        }
    };

    const handleClosingButtonClick = () => {
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Riege hinzufügen</DialogTitle>
                <DialogClosingButton onClick={handleClosingButtonClick} />
                <DialogContent>
                    {participantIds.length !== 0 && (
                        <Box mb={2}>
                            <DialogContentText>
                                mit {participantIds.length} Teilnehmer
                                {participantIds.length > 1 && "n"}
                            </DialogContentText>
                        </Box>
                    )}
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextField fieldName="name" isAutofocusing label="Name *" />
                        {selectedEvent.format === EventFormat.CONTEST && (
                            <RadioSelect
                                fieldName="classLevelRange"
                                formatItem={formatClassLevelRange}
                                items={Object.values(ClassLevelRange)}
                                label="Klassenstufe *"
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<AddIcon />} type="submit">
                        Hinzufügen
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { AllGroupsUpdateDialog__GroupCreationDialog };
