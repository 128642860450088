import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    EventFormat,
    MeasuringType,
    checkIsMovingDisciplineCategory,
    formatMeasuringType,
    formatResultValue,
    formatResultValueInputValue,
    generateFullName,
    getDiscipline,
    getDisciplineCategoryOfDiscipline,
    parseResultValue,
    resultValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Collapse, DialogClosingButton, Form, RadioSelect } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import {
    DisciplineSelect,
    HelperSelect,
    ParticipantSelect,
    ResultAttempts,
    ResultValueInput,
} from "../../common";

const ResultUpdateDialog = ({ close, isOpen, removalDialogDisplayer, result }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(resultValidationSchemas.update.schoolForm, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            helper: result.helper === undefined ? null : result.helper,
            participant: result.participant,
            discipline: getDiscipline(selectedEvent.format, result.disciplineId),
            ...(selectedEvent.format === EventFormat.COMPETITION && {
                measuringType: !checkIsMovingDisciplineCategory(
                    getDisciplineCategoryOfDiscipline(selectedEvent.format, result.disciplineId).id,
                )
                    ? MeasuringType.MANUALLY
                    : result.measuringType,
            }),
            isFailedAttempt: result.isFailedAttempt,
            value: formatResultValueInputValue(
                selectedEvent.format,
                result.disciplineId,
                result.isFailedAttempt,
                result.value,
            ),
        },
    });
    const participantFieldValue = useWatch({
        control: formController.control,
        name: "participant",
    });
    const disciplineFieldValue = useWatch({
        control: formController.control,
        name: "discipline",
    });

    const mutation = useMutation(
        async (variables) => apiService.results.updateAsync(variables.id, variables.result),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: (updatedResult) => {
                snackbarsController.enqueueSuccessMessage(
                    `${generateFullName(
                        updatedResult.participant.name.first,
                        updatedResult.participant.name.last,
                    )}: ${getDiscipline(selectedEvent.format, updatedResult.disciplineId).name}: ${formatResultValue(
                        selectedEvent.format,
                        updatedResult.disciplineId,
                        updatedResult.isFailedAttempt,
                        updatedResult.value,
                    )} geändert`,
                );
                queryClient.invalidateQueries(["results"]);
                close();
            },
        },
    );

    const selectedDisciplineCategory =
        disciplineFieldValue === null
            ? undefined
            : getDisciplineCategoryOfDiscipline(selectedEvent.format, disciplineFieldValue.id);

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleRemovalButtonClick = () => {
        if (!tryClose()) {
            return;
        }
        removalDialogDisplayer.open({ result });
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            id: result.id,
            result: {
                ...(data.helper !== null && { helperId: data.helper.id }),
                participantId: data.participant.id,
                disciplineId: data.discipline.id,
                ...(selectedEvent.format === EventFormat.COMPETITION &&
                    checkIsMovingDisciplineCategory(
                        getDisciplineCategoryOfDiscipline(selectedEvent.format, data.discipline.id)
                            .id,
                    ) && { measuringType: data.measuringType }),
                isFailedAttempt: data.isFailedAttempt,
                ...(!data.isFailedAttempt && {
                    value: parseResultValue(selectedEvent.format, data.discipline.id, data.value),
                }),
            },
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Ergebnis bearbeiten</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <HelperSelect
                            fieldName="helper"
                            isEnabled={!mutation.isLoading}
                            label="Helfer"
                        />
                        <ParticipantSelect
                            fieldName="participant"
                            isEnabled={!mutation.isLoading}
                            label="Teilnehmer *"
                        />
                        <DisciplineSelect
                            fieldName="discipline"
                            isEnabled={participantFieldValue !== null && !mutation.isLoading}
                            label="Disziplin *"
                            participant={
                                participantFieldValue === null ? undefined : participantFieldValue
                            }
                        />
                        {selectedEvent.format === EventFormat.COMPETITION && (
                            <Collapse
                                isOpen={
                                    selectedDisciplineCategory !== undefined &&
                                    checkIsMovingDisciplineCategory(selectedDisciplineCategory.id)
                                }
                                overwritingTopMargin={2}
                            >
                                <RadioSelect
                                    fieldName="measuringType"
                                    formatItem={formatMeasuringType}
                                    isEnabled={!mutation.isLoading}
                                    items={Object.values(MeasuringType)}
                                    label="Messtyp"
                                />
                            </Collapse>
                        )}
                        <ResultValueInput
                            discipline={
                                disciplineFieldValue === null ? undefined : disciplineFieldValue
                            }
                            fieldName="value"
                            isEnabled={
                                selectedDisciplineCategory !== undefined && !mutation.isLoading
                            }
                            isFailedAttemptFieldName="isFailedAttempt"
                            label="Wert *"
                        />
                        <ResultAttempts
                            currentResultId={result.id}
                            discipline={
                                disciplineFieldValue === null ? undefined : disciplineFieldValue
                            }
                            participant={
                                participantFieldValue === null ? undefined : participantFieldValue
                            }
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={mutation.isLoading}
                        onClick={handleRemovalButtonClick}
                        startIcon={<DeleteIcon />}
                    >
                        Entfernen
                    </Button>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<EditIcon />}
                        type="submit"
                    >
                        Ändern
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { ResultUpdateDialog };
