import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Box, Card, CardContent, IconButton, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";

import { CertType } from "@bujus/common";
import { useApiServiceContext, useDialogDisplayer } from "@bujus/common-frontend";

import { HorizontalCenterCircularProgress, QueryError } from "@/base";
import {
    useDashboardTitleStateContext,
    useOpenInstructionsDrawerContext,
    useSelectedEventStateContext,
} from "@/contexts";

import { CertTemplateSetImageOverlayUpdateDialog } from "./cert-template-set-image-overlay-update-dialog";
import { CertTemplateSetImageOverlayUpdateDialogMode } from "./cert-template-set-image-overlay-update-dialog/cert-template-set-image-overlay-update-dialog-mode";
import { CertTemplateSetPage__CertTemplateInfoCard } from "./cert-template-set-page__cert-template-info-card";
import { CertTemplateSetPage__ImageOverlayCard } from "./cert-template-set-page__image-overlay-card";
import { CertTemplateUpdateDialog } from "./cert-template-update-dialog";
import { TestCertPrintingDialog } from "./test-cert-printing-dialog";

const CertTemplateSetPage = () => {
    const theme = useTheme();

    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const testPrintingDialog = useDialogDisplayer(TestCertPrintingDialog);
    const updateDialogDisplayer = useDialogDisplayer(CertTemplateUpdateDialog);
    const rubberStampUpdateDialogDisplayer = useDialogDisplayer(
        CertTemplateSetImageOverlayUpdateDialog,
    );

    const query = useQuery(
        ["certTemplateSets", "get", selectedEvent.id],
        async ({ queryKey: key }) => apiService.certTemplateSets.getAsync(key[2]),
    );

    const handleInstructionsButtonClick = () => {
        openInstructionsDrawer("/auswertung/urkunden-ausrichten");
    };

    useEffect(() => {
        setDashboardTitle("Urkunden ausrichten");
    }, []);

    let content;
    if (query.isLoading) {
        content = <HorizontalCenterCircularProgress />;
    } else if (query.isError) {
        content = <QueryError query={query} />;
    }
    if (content !== undefined) {
        return (
            <Box maxWidth={300}>
                <Card>
                    <CardContent>{content}</CardContent>
                </Card>
            </Box>
        );
    }
    if (query.isSuccess) {
        return (
            <>
                <Box>
                    <Box display="flex" flexWrap="wrap" gap={1}>
                        {Object.values(CertType).map((x) => (
                            <CertTemplateSetPage__CertTemplateInfoCard
                                key={x}
                                certTemplateSet={query.data}
                                certType={x}
                                testPrintingDialog={testPrintingDialog}
                                updateDialogDisplayer={updateDialogDisplayer}
                            />
                        ))}
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
                        <CertTemplateSetPage__ImageOverlayCard
                            certTemplateSet={query.data}
                            mode={CertTemplateSetImageOverlayUpdateDialogMode.RUBBER_STAMP}
                        />
                        <CertTemplateSetPage__ImageOverlayCard
                            certTemplateSet={query.data}
                            mode={CertTemplateSetImageOverlayUpdateDialogMode.SIGNATURE}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "fixed",
                        bottom: theme.spacing(1),
                        right: theme.spacing(1),
                    }}
                >
                    <IconButton onClick={handleInstructionsButtonClick}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Box>
                {testPrintingDialog.element}
                {updateDialogDisplayer.element}
                {rubberStampUpdateDialogDisplayer.element}
            </>
        );
    }
    return undefined;
};

export { CertTemplateSetPage };
