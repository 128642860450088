import { joiResolver } from "@hookform/resolvers/joi";
import { Box } from "@mui/system";
import React from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    DisciplineCategoryId,
    EventFormat,
    MeasuringType,
    checkIsMovingDisciplineCategory,
    getDisciplineCategory,
    getDisciplineCategoryOfDiscipline,
    parseResultValue,
    resultValidationSchemas,
    unitToResultValueMaskMap,
    validationOptions,
} from "@bujus/common";

import { Form } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

import { BigResultCreationDialog__GroupSelect } from "./big-result-creation-dialog__group-select";
import { BigResultCreationDialog__History } from "./big-result-creation-dialog__history";
import { BigResultCreationDialog__OtherDataInput } from "./big-result-creation-dialog__other-data-input";
import { BigResultCreationDialog__ParticipantSelect } from "./big-result-creation-dialog__participant-select";

const BigResultCreationDialog__LoadedContent = ({
    participants,
    groups,
    hydratedHistory,
    mutation,
}) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(resultValidationSchemas.creation.forms.bigSchool, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            groups: [],
            participant: null,
            disciplineCategory: getDisciplineCategory(
                selectedEvent.format,
                DisciplineCategoryId.SPRINTING,
            ),
            discipline: getDisciplineCategory(selectedEvent.format, DisciplineCategoryId.SPRINTING)
                .disciplines[0],
            ...(selectedEvent.format === EventFormat.COMPETITION && {
                measuringType: MeasuringType.MANUALLY,
            }),
            isFailedAttempt: false,
            value: unitToResultValueMaskMap[
                getDisciplineCategory(selectedEvent.format, DisciplineCategoryId.SPRINTING)
                    .disciplines[0].inputUnit
            ].defaultValue,
        },
    });
    const participantFieldValue = useWatch({
        control: formController.control,
        name: "participant",
    });

    const handleFormSubmit = (data2) => {
        mutation.mutateAsync({
            result: {
                participantId: data2.participant.id,
                disciplineId: data2.discipline.id,
                ...(selectedEvent.format === EventFormat.COMPETITION &&
                    checkIsMovingDisciplineCategory(
                        getDisciplineCategoryOfDiscipline(selectedEvent.format, data2.discipline.id)
                            .id,
                    ) && { measuringType: data2.measuringType }),
                isFailedAttempt: data2.isFailedAttempt,
                ...(!data2.isFailedAttempt && {
                    value: parseResultValue(selectedEvent.format, data2.discipline.id, data2.value),
                }),
            },
        });
        formController.setValue("isFailedAttempt", false);
        formController.setValue(
            "value",
            unitToResultValueMaskMap[data2.discipline.inputUnit].defaultValue,
        );
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flex={1} gap={1}>
                <Box display="flex" flex="none" flexDirection="column" width={320}>
                    <BigResultCreationDialog__GroupSelect
                        groups={groups}
                        participants={participants}
                    />
                </Box>
                <Box display="flex" flex="none" flexDirection="column" width={320}>
                    <BigResultCreationDialog__ParticipantSelect />
                </Box>
                <Box display="flex" flex="none" flexDirection="column" width={480}>
                    <BigResultCreationDialog__OtherDataInput
                        participant={
                            participantFieldValue === null ? undefined : participantFieldValue
                        }
                    />
                    <Box display="flex" flex={1} flexDirection="column" mt={1}>
                        <BigResultCreationDialog__History hydratedHistory={hydratedHistory} />
                    </Box>
                </Box>
            </Box>
        </Form>
    );
};

export { BigResultCreationDialog__LoadedContent };
