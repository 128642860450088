import { Box, Typography } from "@mui/material";
import React from "react";

const AllGroupsUpdateDialog__DataGridNoRowsOverlay = () => (
    <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
    >
        <Typography textAlign="center">Noch keine Teilnehmer hinzugefügt.</Typography>
    </Box>
);

export { AllGroupsUpdateDialog__DataGridNoRowsOverlay };
