import deepmerge from "deepmerge";

import { colors } from "@bujus/common";

import { theme } from "./theme";

const accountTheme = deepmerge(theme, {
    palette: {
        primary: {
            light: colors.mulberryShades[400],
            main: colors.mulberryShades[500],
            dark: colors.mulberryShades[700],
            contrastText: colors.white,
        },
    },
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.mulberryShades[50],
                    color: colors.mulberryShades[600],
                },
            },
        },
    },
});

export { accountTheme };
