import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import * as dateFns from "date-fns";
import React from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

import { EventRemovalRequestDialog } from "../common";

import { EventRemovalCancellationAlert } from "./event-removal-cancellation-alert";

const EventPage__RemovalCard = () => {
    const [selectedEvent] = useSelectedEventStateContext();

    const requestDialogDisplayer = useDialogDisplayer(EventRemovalRequestDialog);

    const handleRequestButtonClick = () => {
        requestDialogDisplayer.open({ event: selectedEvent });
    };

    return (
        <>
            <Card sx={{ borderColor: "error.main" }}>
                <CardContent>
                    <Typography color="error" variant="h6">
                        Danger Zone
                    </Typography>
                    <EventRemovalCancellationAlert containerProps={{ mt: 2 }} />
                    {(selectedEvent.removalIntent === undefined ||
                        dateFns.isBefore(selectedEvent.removalIntent.expires, new Date())) && (
                        <Box mt={2}>
                            <Button
                                color="error"
                                onClick={handleRequestButtonClick}
                                size="small"
                                startIcon={<DeleteIcon />}
                                variant="outlined"
                            >
                                Veranstaltung löschen
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
            {requestDialogDisplayer.element}
        </>
    );
};

export { EventPage__RemovalCard };
