import { joiResolver } from "@hookform/resolvers/joi";
import { Add as AddIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { PaymentType, eventValidationSchemas, formatDate, validationOptions } from "@bujus/common";

import applePayIcon from "@/assets/apple-pay-icon.svg";
import bankTransferIcon from "@/assets/bank-transfer-icon.svg";
import creditCardIcon from "@/assets/credit-card-icon.svg";
import giropayIcon from "@/assets/giropay-icon.svg";
import googlePayIcon from "@/assets/google-pay-icon.svg";
import klarnaIcon from "@/assets/klarna-icon.svg";
import paypalIcon from "@/assets/paypal-icon.svg";
import sofortIcon from "@/assets/sofort-icon.svg";
import { CardRadioSelect, Form, PaymentMark, TextField } from "@/base";

import { EventCreationDialog__Pricing } from "./event-creation-dialog__pricing";

const EventCreationDialog__PaymentSettingsStepContent = ({
    defaultFormData,
    event,
    mutation,
    onCreation,
    onPrevious,
}) => {
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            eventValidationSchemas.creation.forms.paymentSettings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        onCreation(data);
    };

    const handlePreviousButtonClick = () => {
        onPrevious();
    };

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <Typography>
                    <b>Name: </b>
                    {event.name}
                    <br />
                    <b>Start: </b>
                    {formatDate(event.start, "P")}
                    <br />
                    <b>Max. Teilnehmeranzahl: </b>
                    {event.limits.participant}
                    <br />
                    <EventCreationDialog__Pricing participantLimit={event.limits.participant} />
                </Typography>
                <TextField fieldName="discountCode" label="Discount-Code" />
                <CardRadioSelect
                    fieldName="type"
                    formatItem={(paymentType) => {
                        switch (paymentType) {
                            case PaymentType.ONLINE:
                                return (
                                    <>
                                        <Typography fontWeight="bold">Online</Typography>
                                        <Typography color="text.secondary" mt={2}>
                                            Zahlen Sie mit Ihrem präferierten Zahlungsmittel. Danach
                                            bekommen Sie einen Zahlungsbeleg.
                                        </Typography>
                                        <Box display="flex" gap={1} mt={2}>
                                            <PaymentMark src={creditCardIcon} />
                                            <PaymentMark src={klarnaIcon} />
                                            <PaymentMark src={sofortIcon} />
                                            <PaymentMark src={giropayIcon} />
                                            <PaymentMark src={paypalIcon} />
                                            <PaymentMark src={applePayIcon} />
                                            <PaymentMark src={googlePayIcon} />
                                        </Box>
                                    </>
                                );
                            case PaymentType.INVOICE:
                                return (
                                    <>
                                        <Typography fontWeight="bold">Rechnung</Typography>
                                        <Typography color="text.secondary" mt={2}>
                                            Kaufen Sie auf Rechnung und bezahlen Sie dann innerhalb
                                            von 30 Tagen.
                                        </Typography>
                                        <Box display="flex" gap={1} mt={2}>
                                            <PaymentMark src={bankTransferIcon} />
                                            <PaymentMark src={creditCardIcon} />
                                            <PaymentMark src={klarnaIcon} />
                                            <PaymentMark src={giropayIcon} />
                                            <PaymentMark src={paypalIcon} />
                                            <PaymentMark src={applePayIcon} />
                                            <PaymentMark src={googlePayIcon} />
                                        </Box>
                                    </>
                                );
                            default:
                                return undefined;
                        }
                    }}
                    items={Object.values(PaymentType)}
                    label="Zahlungsmethode"
                />
                <Typography>
                    <b>Geld-zurück-Garantie</b> bei Ausfall der Veranstalung
                </Typography>
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    color="secondary"
                    disabled={mutation.isLoading}
                    onClick={handlePreviousButtonClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Zurück
                </Button>
                <LoadingButton loading={mutation.isLoading} startIcon={<AddIcon />} type="submit">
                    Bezahlen und erstellen
                </LoadingButton>
            </Box>
        </Form>
    );
};

export { EventCreationDialog__PaymentSettingsStepContent };
