import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";

import { SpecialGroupId } from "@bujus/common";

import { AllGroupsUpdateDialog__CreationCard } from "./all-groups-update-dialog__creation-card";
import { AllGroupsUpdateDialog__SelectOption } from "./all-groups-update-dialog__select-option";

const AllGroupsUpdateDialog__Select = ({
    groups,
    isDragging,
    onCreationRequest,
    onGroupSelection,
    selectedGroup,
    selectedParticipantIds,
}) => {
    const handlCreationCardRequest = () => {
        onCreationRequest();
    };

    const handleOptionViewSelection = (id) => {
        onGroupSelection(id);
    };

    return (
        <Card
            sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <AllGroupsUpdateDialog__CreationCard
                    isDropzone={isDragging}
                    onRequest={handlCreationCardRequest}
                    selectedParticipantIds={selectedParticipantIds}
                />
                <Box mt={1}>
                    <AllGroupsUpdateDialog__SelectOption
                        group={groups.find((x) => x.id === SpecialGroupId.UNALLOCATED)}
                        isDropzone={isDragging}
                        isSelected={selectedGroup.id === SpecialGroupId.UNALLOCATED}
                        onSelection={() => handleOptionViewSelection(SpecialGroupId.UNALLOCATED)}
                    />
                </Box>
                <Box flex={1} mt={2} position="relative">
                    <Box
                        bottom={0}
                        left={0}
                        position="absolute"
                        right={0}
                        sx={{ overflowY: "auto" }}
                        top={0}
                    >
                        <Grid container spacing={1}>
                            {groups
                                .filter((x) => x.id !== SpecialGroupId.UNALLOCATED)
                                .map((x) => (
                                    <Grid
                                        key={`${x.id}`}
                                        display="flex"
                                        flexDirection="column"
                                        item
                                        xs={6}
                                    >
                                        <AllGroupsUpdateDialog__SelectOption
                                            group={x}
                                            isDropzone={isDragging}
                                            isSelected={selectedGroup.id === x.id}
                                            onSelection={handleOptionViewSelection}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export { AllGroupsUpdateDialog__Select };
