import {
    FilterList as FilterListIcon,
    FilterListOff as FilterListOffIcon,
    Info as InfoIcon,
} from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Chip,
    IconButton,
    InputLabel,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AutoAnimateHeight from "react-auto-animate-height";
import { useController as useFieldController } from "react-hook-form";

import {
    checkIsDisciplinePreselected,
    eventFormatToDisciplineCategoriesMap,
    getAllowedDisciplines,
    getDisciplineCategoryOfDiscipline,
    getPreselectedDiscipline,
} from "@bujus/common";
import {
    generateSelectOptions,
    useDialogDisplayer,
    useFormControllerContext,
} from "@bujus/common-frontend";

import { useDisciplinePreselectionContext, useSelectedEventStateContext } from "@/contexts";

import { DisciplineInformationDialog } from "../discipline-information-dialog";

const BigDisciplineSelect = ({
    disciplineCategoryFieldName,
    fieldName,
    isEnabled = true,
    label,
    participant,
}) => {
    const [selectedEvent] = useSelectedEventStateContext();
    const disciplinePreselection = useDisciplinePreselectionContext();
    const formController = useFormControllerContext();

    const disciplineFieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    const [isFilter, setIsFilter] = useState(
        participant === undefined ||
            disciplineFieldController.field.value === null ||
            getAllowedDisciplines(selectedEvent, participant).find(
                (discipline) => discipline.id === disciplineFieldController.field.value.id,
            ) !== undefined,
    );

    const informationDialogDisplayer = useDialogDisplayer(DisciplineInformationDialog);

    const disciplineCategoryFieldController = useFieldController({
        control: formController.control,
        name: disciplineCategoryFieldName, // HACK
    });

    const disciplineCategoryOptions = useMemo(
        () =>
            generateSelectOptions(
                eventFormatToDisciplineCategoriesMap[selectedEvent.format],
                (disciplineCategory) => disciplineCategory.id,
                (disciplineCategory) => disciplineCategory.name,
            ),
        [],
    );

    const allowedDisciplineOptions = useMemo(() => {
        let disciplines;
        if (participant === undefined || !isFilter) {
            disciplines = disciplineCategoryFieldController.field.value.disciplines;
        } else {
            disciplines = getAllowedDisciplines(selectedEvent, participant).filter(
                (x) =>
                    getDisciplineCategoryOfDiscipline(selectedEvent.format, x.id).id ===
                    disciplineCategoryFieldController.field.value.id,
            );
        }
        return generateSelectOptions(
            disciplines,
            (discipline) => discipline.id,
            (discipline) =>
                `${
                    participant === undefined ||
                    !checkIsDisciplinePreselected(
                        selectedEvent,
                        disciplinePreselection,
                        participant,
                        discipline.id,
                    )
                        ? ""
                        : "★ "
                }${discipline.shortName}`,
        );
    }, [
        disciplineCategoryFieldController.field.value,
        disciplinePreselection,
        isFilter,
        participant,
        selectedEvent,
    ]);

    const handleDisciplineCategoryToggleButtonGroupChange = (event) => {
        disciplineCategoryFieldController.field.onChange(
            disciplineCategoryOptions.find((x) => x.key === event.target.value).value,
        );
    };

    const handleFilterCheckboxChange = (event2) => {
        setIsFilter(event2.target.checked);
    };

    const handleInformationButtonClick = () => {
        informationDialogDisplayer.open({ discipline: disciplineFieldController.field.value });
    };

    const handleDisciplineChipClick = (disciplineId) => {
        disciplineFieldController.field.onChange(
            allowedDisciplineOptions.find((x) => x.key === disciplineId).value,
        );
    };

    useEffect(() => {
        disciplineFieldController.field.onChange(
            participant === undefined
                ? disciplineCategoryFieldController.field.value.disciplines[0]
                : getPreselectedDiscipline(
                      selectedEvent,
                      disciplinePreselection,
                      participant,
                      disciplineCategoryFieldController.field.value.id,
                  ),
        );
    }, [participant, isFilter, disciplineCategoryFieldController.field.value]);

    return (
        <>
            <Box>
                <InputLabel>{label}</InputLabel>
                <Box display="flex" mt={1}>
                    <Box flex={1}>
                        <Box>
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                onChange={handleDisciplineCategoryToggleButtonGroupChange}
                                value={disciplineCategoryFieldController.field.value.id}
                            >
                                {disciplineCategoryOptions.map((x) => (
                                    <ToggleButton key={x.key} value={x.key} variant="outlined">
                                        {x.text}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Box alignItems="center" display="flex" gap={1} ml={1}>
                        <Tooltip title="Informationen zur ausgewählten Disziplin">
                            <Box>
                                <IconButton
                                    disabled={disciplineFieldController.field.value === null}
                                    onClick={handleInformationButtonClick}
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <Tooltip title="Disziplinen für ausgewählten Teilnehmer filtern">
                            <Checkbox
                                checked={isFilter}
                                checkedIcon={<FilterListIcon />}
                                disabled={!isEnabled}
                                icon={<FilterListOffIcon />}
                                onChange={handleFilterCheckboxChange}
                                tabIndex={-1}
                            />
                        </Tooltip>
                    </Box>
                </Box>
                <Box mt={2}>
                    <AutoAnimateHeight duration="200ms">
                        <Box alignContent="flex-start" display="flex" flexWrap="wrap" gap={1}>
                            {allowedDisciplineOptions.map((x) => (
                                <Chip
                                    key={x.key}
                                    {...(disciplineFieldController.field.value === null ||
                                        (disciplineFieldController.field.value.id === x.key && {
                                            color: "primary",
                                        }))}
                                    label={x.text}
                                    onClick={() => handleDisciplineChipClick(x.key)}
                                    sx={{
                                        transition:
                                            "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
                                    }}
                                />
                            ))}
                        </Box>
                    </AutoAnimateHeight>
                </Box>
            </Box>
            {informationDialogDisplayer.element}
        </>
    );
};

export { BigDisciplineSelect };
