import { useQuery } from "@tanstack/react-query";
import { jwtDecode } from "jwt-decode";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AppErrorType, apiRoutes } from "@bujus/common";
import {
    ApiServiceProvider,
    AuthorizationJwtStateProvider,
    LogoutLocalProvider,
    checkIsErrorLogoutReason,
    useApiService,
} from "@bujus/common-frontend";

import { CenterLayout, HorizontalCenterCircularProgress, QueryError } from "@/base";
import { AccountProvider } from "@/contexts";
import { usePageSizeCookie, useResetDataQueries, useSelectedEventIdCookie } from "@/hooks";
import { mixpanel } from "@/services";

import { OutdatedVersionSnackbar } from "./outdated-version-snackbar";

let authorizationJwt;

const App__Loader = ({ children }) => {
    const [, , removeSelectedEventIdCookie] = useSelectedEventIdCookie();
    const [, , removePageSizeCookie] = usePageSizeCookie();

    const resetDataQueries = useResetDataQueries();

    const [authorizationJwtMirror, _setAuthorizationJwtMirror] = useState(authorizationJwt);
    const setAuthorizationJwt = useCallback(
        (newAuthorizationJwt) => {
            authorizationJwt = newAuthorizationJwt;
            _setAuthorizationJwtMirror(newAuthorizationJwt);
        },
        [_setAuthorizationJwtMirror],
    );
    const [apiVersion, setApiVersion] = useState("0.0.0");

    const accountId = useMemo(
        () =>
            authorizationJwtMirror === undefined ? undefined : jwtDecode(authorizationJwtMirror).id,
        [authorizationJwtMirror],
    );

    const logoutLocal = useCallback(() => {
        setAuthorizationJwt(undefined);
        removeSelectedEventIdCookie();
        removePageSizeCookie();
        resetDataQueries();
        mixpanel.reset();
    }, [setAuthorizationJwt, removeSelectedEventIdCookie, removePageSizeCookie, resetDataQueries]);

    const apiService = useApiService(
        apiRoutes,
        () => authorizationJwt,
        authorizationJwtMirror,
        setAuthorizationJwt,
        (error) => {
            if (!checkIsErrorLogoutReason(error)) {
                return;
            }
            logoutLocal();
        },
        apiRoutes().schools().refreshAuthorization().build(),
        () => apiService.schools.refreshAuthorizationAsync(),
        (newApiVersion) => {
            setApiVersion(newApiVersion);
        },
    );

    const authorizationRefreshQuery = useQuery(
        ["_schools", "refresh-authorization"],
        apiService.schools.refreshAuthorizationAsync,
    );

    const accountQuery = useQuery(
        ["schools", "get", accountId],
        ({ queryKey: key }) => apiService.schools.getAsync(key[2]),
        { enabled: accountId !== undefined },
    );

    const authorizationJwtStateProviderValue = useMemo(
        () => [authorizationJwtMirror, setAuthorizationJwt],
        [authorizationJwtMirror, setAuthorizationJwt],
    );

    useEffect(() => {
        if (accountId === undefined) {
            return;
        }
        mixpanel.identify(accountId);
    }, [accountId]);

    let content;
    if (
        authorizationRefreshQuery.isLoading ||
        (accountId !== undefined && accountQuery.isLoading)
    ) {
        content = <HorizontalCenterCircularProgress />;
    } else if (
        authorizationRefreshQuery.isError &&
        (authorizationRefreshQuery.error.type === AppErrorType.NETWORK ||
            authorizationRefreshQuery.error.type === AppErrorType.INTERNAL.MAINTENANCE_MODE)
    ) {
        content = <QueryError query={authorizationRefreshQuery} />;
    } else if (accountId !== undefined && accountQuery.isError) {
        content = <QueryError query={accountQuery} />;
    }
    if (content !== undefined) {
        return <CenterLayout isVerticalCenter>{content}</CenterLayout>;
    }
    if (accountId === undefined || (accountId !== undefined && accountQuery.isSuccess)) {
        return (
            <AuthorizationJwtStateProvider value={authorizationJwtStateProviderValue}>
                <LogoutLocalProvider value={logoutLocal}>
                    <AccountProvider
                        value={accountId === undefined ? undefined : accountQuery.data}
                    >
                        <ApiServiceProvider value={apiService}>
                            <OutdatedVersionSnackbar apiVersion={apiVersion} />
                            {children}
                        </ApiServiceProvider>
                    </AccountProvider>
                </LogoutLocalProvider>
            </AuthorizationJwtStateProvider>
        );
    }
    return undefined;
};

export { App__Loader };
