import { Block, Check } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

import { checkIsEventActive } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

import { EventScheduleTimeline } from "../common";

const EventPage__ScheduleCard = () => {
    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Zeitlicher Ablauf</Typography>
                <Box mt={2}>
                    <EventScheduleTimeline event={selectedEvent} />
                </Box>
                <Box
                    alignItems="center"
                    color={!checkIsEventActive(selectedEvent) ? "error.main" : "success.main"}
                    display="flex"
                    mt={2}
                >
                    {!checkIsEventActive(selectedEvent) ? <Block /> : <Check />}
                    <Typography ml={1}>
                        <b>
                            {!checkIsEventActive(selectedEvent)
                                ? "Es können keine Ergebnisse eintragen werden."
                                : "Es können Ergebnisse eintragen werden."}
                        </b>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export { EventPage__ScheduleCard };
