import { Box, useTheme } from "@mui/material";
import React from "react";

import { HorizontalCenterCircularProgress } from "../horizontal-center-circular-progress";

const SuspenseFallback = () => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection="column"
            height={theme.spacing(20)}
            justifyContent="center"
        >
            <HorizontalCenterCircularProgress />
        </Box>
    );
};

export { SuspenseFallback };
