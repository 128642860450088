import {
    Box,
    Card,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
} from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import {
    Gender,
    eventFormatToAgeGroupsMap,
    eventFormatToDisciplineCategoriesMap,
    formatGender,
} from "@bujus/common";
import { useFormControllerContext } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

const generateStripedTableStyle = (disciplineCategoryIndex) =>
    disciplineCategoryIndex % 2 !== 0 && {
        backgroundColor: (theme2) => `${theme2.palette.background.default} !important`,
    };

const generateDividerTableStyle = (aIndex, aLength, bIndex = undefined, bLength = undefined) =>
    aIndex !== aLength - 1 ||
    (bIndex !== undefined &&
        bIndex !== bLength - 1 && {
            borderRight: 1,
            borderRightColor: "divider",
        });

const leftHeaderCellStyle = {
    borderRight: 1,
    borderRightColor: "primary.main",
    boxShadow: "0 0 6px 0px rgba(0,0,0,0.4)",
    clipPath: "inset(0px -15px 0px 0px)",
    left: 0,
    position: "sticky",
    whiteSpace: "nowrap",
};

const DisciplinePreselectionPage__CompetitionEventView = ({ FormActions, mutation }) => {
    const theme = useTheme();

    const [selectedEvent] = useSelectedEventStateContext();
    const formController = useFormControllerContext();

    const valueFieldController = useFieldController({
        control: formController.control,
        name: "value",
    });

    const handleRadioChange = (gender, ageGroup, disciplineCategoryId, disciplineId) => {
        valueFieldController.field.onChange({
            ...valueFieldController.field.value,
            [gender]: {
                ...valueFieldController.field.value[gender],
                [ageGroup]: {
                    ...valueFieldController.field.value[gender][ageGroup],
                    [disciplineCategoryId]: disciplineId,
                },
            },
        });
    };

    return (
        <Box display="flex" flex={1} flexDirection="column" maxWidth={theme.breakpoints.values.xxl}>
            <Card
                sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                <Box display="flex" flex={1} flexDirection="column">
                    <Box flex={1} position="relative">
                        <Box
                            sx={{
                                bottom: 0,
                                display: "flex",
                                left: 0,
                                overflow: "hidden",
                                position: "absolute",
                                right: 0,
                                top: 0,
                            }}
                        >
                            <TableContainer sx={{ height: "100%" }}>
                                <Table
                                    size="small"
                                    stickyHeader
                                    sx={{
                                        overflow: "clip",
                                        "& th": { backgroundColor: "background.paper" },
                                        "& td": {
                                            backgroundColor: "background.paper",
                                            position: "relative",
                                        },
                                        "& td:hover::before": {
                                            backgroundColor: "primary.main",
                                            content: '""',
                                            height: "100%",
                                            left: "-1000vw",
                                            opacity: 0.1,
                                            pointerEvents: "none",
                                            position: "absolute",
                                            top: 0,
                                            width: "2000vw",
                                            zIndex: 4,
                                        },
                                        "& td:hover::after": {
                                            backgroundColor: "primary.main",
                                            content: '""',
                                            height: "2000vh",
                                            left: 0,
                                            opacity: 0.1,
                                            pointerEvents: "none",
                                            position: "absolute",
                                            top: "-1000vh",
                                            width: "100%",
                                            zIndex: 4,
                                        },
                                    }}
                                >
                                    <TableHead
                                        sx={{
                                            boxShadow: "0 0 6px 0px rgba(0,0,0,0.4)",
                                            clipPath: "inset(0px 0px -15px 0px)",
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 3,
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    ...leftHeaderCellStyle,
                                                    zIndex: 20,
                                                }}
                                            />
                                            {eventFormatToDisciplineCategoriesMap[
                                                selectedEvent.format
                                            ].map((x, xIndex) => (
                                                <TableCell
                                                    key={x.id}
                                                    align="center"
                                                    colSpan={x.disciplines.length}
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                        ...generateStripedTableStyle(xIndex),
                                                        ...generateDividerTableStyle(
                                                            xIndex,
                                                            eventFormatToDisciplineCategoriesMap[
                                                                selectedEvent.format
                                                            ].length,
                                                        ),
                                                    }}
                                                >
                                                    {x.name}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow
                                            sx={{ "& th": { borderBottomColor: "primary.main" } }}
                                        >
                                            <TableCell
                                                sx={{
                                                    ...leftHeaderCellStyle,
                                                    zIndex: 20,
                                                }}
                                            >
                                                Gruppe
                                            </TableCell>
                                            {eventFormatToDisciplineCategoriesMap[
                                                selectedEvent.format
                                            ].map((x, xIndex) =>
                                                x.disciplines.map((y, yIndex) => (
                                                    <TableCell
                                                        key={`${x.id}.${y.id}`}
                                                        align="center"
                                                        sx={{
                                                            whiteSpace: "nowrap",
                                                            ...generateStripedTableStyle(xIndex),
                                                            ...generateDividerTableStyle(
                                                                yIndex,
                                                                x.disciplines.length,
                                                                xIndex,
                                                                eventFormatToDisciplineCategoriesMap[
                                                                    selectedEvent.format
                                                                ].length,
                                                            ),
                                                        }}
                                                    >
                                                        {y.shortName}
                                                    </TableCell>
                                                )),
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(Gender).map((x) =>
                                            eventFormatToAgeGroupsMap[selectedEvent.format].map(
                                                (y) => (
                                                    <TableRow key={`${x}.${y}`}>
                                                        <TableCell
                                                            component="th"
                                                            sx={{
                                                                ...leftHeaderCellStyle,
                                                                zIndex: 2,
                                                            }}
                                                        >
                                                            {formatGender(x)} {y}
                                                        </TableCell>
                                                        {eventFormatToDisciplineCategoriesMap[
                                                            selectedEvent.format
                                                        ].map((z, zIndex) =>
                                                            z.disciplines.map((a, aIndex) => (
                                                                <TableCell
                                                                    key={`${x}.${y}.${z.id}.${a.id}`}
                                                                    align="center"
                                                                    sx={{
                                                                        whiteSpace: "nowrap",
                                                                        ...generateStripedTableStyle(
                                                                            zIndex,
                                                                        ),
                                                                        ...generateDividerTableStyle(
                                                                            aIndex,
                                                                            z.disciplines.length,
                                                                            zIndex,
                                                                            eventFormatToDisciplineCategoriesMap[
                                                                                selectedEvent.format
                                                                            ].length,
                                                                        ),
                                                                    }}
                                                                >
                                                                    {a.allowedParticipantGroups[
                                                                        x
                                                                    ] !== undefined &&
                                                                        a.allowedParticipantGroups[
                                                                            x
                                                                        ].some((b) => b === y) && (
                                                                            <Radio
                                                                                checked={(() =>
                                                                                    valueFieldController
                                                                                        .field
                                                                                        .value[x][
                                                                                        y
                                                                                    ][z.id] ===
                                                                                    a.id)()}
                                                                                disabled={
                                                                                    mutation.isLoading
                                                                                }
                                                                                onChange={() =>
                                                                                    handleRadioChange(
                                                                                        x,
                                                                                        y,
                                                                                        z.id,
                                                                                        a.id,
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                </TableCell>
                                                            )),
                                                        )}
                                                    </TableRow>
                                                ),
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    {FormActions}
                </Box>
            </Card>
        </Box>
    );
};

export { DisciplinePreselectionPage__CompetitionEventView };
