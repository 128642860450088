import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";

import {
    DisciplineCategoryId,
    checkIsCountingResult,
    formatCertGenerationErrorType,
    formatCertType,
    formatResultValue,
    getDiscipline,
    getDisciplineCategory,
} from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";
import { getResultColor } from "@/utilities";

const CertDetailsDialog__CompetitionEventEvaluationCard = ({ cert }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Details</Typography>
                <Box mt={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{ fontWeight: "bold" }}>Disziplin</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Wert</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Punkte</TableCell>
                                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                    Zulässig
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                    In Wertung
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Fehler</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Gefälscht</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(DisciplineCategoryId).map((x) => (
                                <TableRow
                                    key={x}
                                    sx={{
                                        color: getResultColor(
                                            selectedEvent.format,
                                            cert.disciplineCategoryIdToDisciplineCategoryEvaluationMap,
                                            cert
                                                .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                x
                                            ],
                                        ),
                                    }}
                                >
                                    {/* Disziplinkategorie */}
                                    <TableCell
                                        sx={{
                                            fontWeight: "bold",
                                            color: "unset",
                                        }}
                                    >
                                        {getDisciplineCategory(selectedEvent.format, x).name}
                                    </TableCell>
                                    {/* Disziplin */}
                                    <TableCell sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid
                                            ? undefined
                                            : getDiscipline(
                                                  selectedEvent.format,
                                                  cert
                                                      .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                      x
                                                  ].bestResult.disciplineId,
                                              ).name}
                                    </TableCell>
                                    {/* Wert */}
                                    <TableCell sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid
                                            ? undefined
                                            : formatResultValue(
                                                  selectedEvent.format,
                                                  cert
                                                      .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                      x
                                                  ].bestResult.disciplineId,
                                                  false,
                                                  cert
                                                      .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                      x
                                                  ].bestResult.value,
                                              )}
                                    </TableCell>
                                    {/* Punkte */}
                                    <TableCell sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid
                                            ? undefined
                                            : cert
                                                  .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                  x
                                              ].points}
                                    </TableCell>
                                    {/* Zulässig */}
                                    <TableCell align="center" sx={{ color: "unset" }}>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid ? (
                                            <CloseIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        ) : (
                                            <CheckIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    {/* In Wertung */}
                                    <TableCell align="center" sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid ||
                                        !checkIsCountingResult(
                                            cert.disciplineCategoryIdToDisciplineCategoryEvaluationMap,
                                            cert
                                                .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                x
                                            ],
                                        ) ? (
                                            <CloseIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        ) : (
                                            <CheckIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    {/* Fehler */}
                                    <TableCell sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid &&
                                            formatCertGenerationErrorType(
                                                cert
                                                    .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                                    x
                                                ].errorType,
                                            )}
                                    </TableCell>
                                    {/* Gefälscht */}
                                    <TableCell sx={{ color: "unset" }}>
                                        {!cert
                                            .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                            x
                                        ].isValid ? undefined : !cert
                                              .disciplineCategoryIdToDisciplineCategoryEvaluationMap[
                                              x
                                          ].bestResult.isFake ? (
                                            <CloseIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        ) : (
                                            <CheckIcon
                                                sx={{
                                                    color: "inherit",
                                                    height: "0.875em",
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!cert.isGenerated ? (
                        <Typography mt={2}>
                            <b>Fehler:</b> {formatCertGenerationErrorType(cert.errorType)}
                        </Typography>
                    ) : (
                        <>
                            <Typography mt={2}>
                                <b>Punkte:</b> {cert.points}
                            </Typography>
                            <Typography mt={2}>
                                <b>Typ:</b> {formatCertType(cert.type)}
                            </Typography>
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export { CertDetailsDialog__CompetitionEventEvaluationCard };
