import { Box, Card, CardContent, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

import { calculateAgeGroup, formatGender } from "@bujus/common";

import { useSelectedEventStateContext } from "@/contexts";

import { AllGroupsUpdateDialog__DataGridNoResultsOverlay } from "./all-groups-update-dialog__data-grid-no-results-overlay";
import { AllGroupsUpdateDialog__DataGridNoRowsOverlay } from "./all-groups-update-dialog__data-grid-no-rows-overlay";
import { AllGroupsUpdateDialog__DataMasterRow } from "./all-groups-update-dialog__data-master-row";
import { AllGroupsUpdateDialog__DataMasterToolbar } from "./all-groups-update-dialog__data-master-toolbar";

const AllGroupsUpdateDialog__Details = ({
    groups,
    onAutoDistributionByClass,
    onAutoDistributionByParticipantGroup,
    onSelectedGroupRemoval,
    onSelectedGroupUpdate,
    onSelectedParticipantsChange,
    onSelectedParticipantsTransfer,
    onSelectedParticipantsUnallocation,
    selectedGroup,
    selectedParticipantIds,
}) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        document.activeElement.blur();
        onSelectedParticipantsChange(() => newRowSelectionModel);
    };

    const handleToolbarAutoDistributeByClass = (participantLimitPerGroup) => {
        onAutoDistributionByClass(participantLimitPerGroup);
    };

    const handleToolbarAutoDistributeByParticipantGroup = (participantLimitPerGroup) => {
        onAutoDistributionByParticipantGroup(participantLimitPerGroup);
    };

    const handleToolbarSelectedGroupRemoval = () => {
        onSelectedGroupRemoval();
    };

    const handleToolbarSelectedGroupUpdate = () => {
        onSelectedGroupUpdate();
    };

    const handleToolbarSelectedParticipantsTransfer = (targetId) => {
        onSelectedParticipantsTransfer(targetId);
    };

    const handleToolbarSelectedParticipantsUnallocation = () => {
        onSelectedParticipantsUnallocation();
    };

    return (
        <Card
            sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardContent
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box flex={1} position="relative">
                    <Box bottom={0} left={0} position="absolute" right={0} top={0}>
                        <DataGrid
                            checkboxSelection
                            columns={[
                                {
                                    field: "schoolInternalId",
                                    type: "string",
                                    headerName: "Schulinterne-ID",
                                    width: 150,
                                },
                                {
                                    field: "name.first",
                                    valueGetter: ({ row }) => row.name.first,
                                    type: "string",
                                    headerName: "Vorname",
                                    width: 200,
                                },
                                {
                                    field: "name.last",
                                    valueGetter: ({ row }) => row.name.last,
                                    type: "string",
                                    headerName: "Nachname",
                                    width: 200,
                                },
                                {
                                    field: "gender",
                                    type: "string",
                                    valueFormatter: ({ value }) => formatGender(value),
                                    headerName: "Geschlecht",
                                    width: 100,
                                },
                                {
                                    field: "birthYear",
                                    type: "number",
                                    valueFormatter: ({ value }) => value.toString(),
                                    headerName: "Geburtsjahr",
                                    width: 100,
                                },
                                {
                                    field: "ageGroup",
                                    valueGetter: ({ row }) =>
                                        calculateAgeGroup(selectedEvent.start, row.birthYear),
                                    type: "number",
                                    headerName: "Altersgruppe",
                                    sortable: false,
                                    width: 125,
                                },
                                {
                                    field: "class",
                                    type: "string",
                                    headerName: "Klasse",
                                    width: 100,
                                },
                            ]}
                            density="compact"
                            filterMode="client"
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        gender: false,
                                        birthYear: false,
                                        ageGroup: false,
                                    },
                                },
                            }}
                            onRowSelectionModelChange={handleRowSelectionModelChange}
                            pageSizeOptions={[100]}
                            rows={selectedGroup.participants}
                            rowSelectionModel={selectedParticipantIds}
                            slotProps={{
                                toolbar: {
                                    groups,
                                    onAutoDistributionByClass: handleToolbarAutoDistributeByClass,
                                    onAutoDistributionByParticipantGroup:
                                        handleToolbarAutoDistributeByParticipantGroup,
                                    onSelectedGroupRemoval: handleToolbarSelectedGroupRemoval,
                                    onSelectedGroupUpdate: handleToolbarSelectedGroupUpdate,
                                    onSelectedParticipantsTransfer:
                                        handleToolbarSelectedParticipantsTransfer,
                                    onSelectedParticipantsUnallocation:
                                        handleToolbarSelectedParticipantsUnallocation,
                                    selectedGroup,
                                    selectedParticipantIds,
                                },
                            }}
                            slots={{
                                loadingOverlay: LinearProgress,
                                noRowsOverlay: AllGroupsUpdateDialog__DataGridNoRowsOverlay,
                                row: AllGroupsUpdateDialog__DataMasterRow,
                                noResultsOverlay: AllGroupsUpdateDialog__DataGridNoResultsOverlay,
                                toolbar: AllGroupsUpdateDialog__DataMasterToolbar,
                            }}
                            sx={{
                                borderWidth: 0,
                                flex: 1,
                                userSelect: "none",
                                "& .MuiDataGrid-cell:focus": { outline: "none" },
                                "& .MuiDataGrid-cell:focus-within": { outline: "none" },
                            }}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export { AllGroupsUpdateDialog__Details };
