import { Box, Toolbar } from "@mui/material";
import React, { useMemo, useState } from "react";

import { WindowSizeLayout } from "@/base";
import { DashboardSideBarProvider, DashboardTitleStateProvider } from "@/contexts";

import { DataPrivacyAlert } from "./data-privacy-alert";
import { SideBar } from "./side-bar";
import { TopBar } from "./top-bar/top-bar";

const Dashboard__Layout = ({ children }) => {
    const [title, setTitle] = useState("");
    const [isSideBarOpen, setisSideBarOpen] = useState(false);

    const titleStateProviderValue = useMemo(() => [title, setTitle], [title, setTitle]);

    const sideBarProviderValue = useMemo(
        () => ({
            isDashboardSideBarOpen: isSideBarOpen,
            setIsDashboardSideBarOpen: setisSideBarOpen,
            toggleDashboardSideBar: () => {
                setisSideBarOpen(!isSideBarOpen);
            },
            closeDashboardSideBar: () => {
                setisSideBarOpen(false);
            },
        }),
        [isSideBarOpen, setisSideBarOpen],
    );

    return (
        <WindowSizeLayout>
            <DashboardTitleStateProvider value={titleStateProviderValue}>
                <DashboardSideBarProvider value={sideBarProviderValue}>
                    <TopBar />
                    <Box display="flex" flex={1}>
                        <SideBar />
                        <Box display="flex" flex={1} flexDirection="column">
                            <Toolbar />
                            <DataPrivacyAlert
                                containerProps={{
                                    m: 1,
                                    mb: 0,
                                }}
                            />
                            <Box flex={1} overflow="auto" position="relative">
                                <Box
                                    bottom={0}
                                    display="flex"
                                    flexDirection="column"
                                    left={0}
                                    position="absolute"
                                    right={0}
                                    top={0}
                                >
                                    <Box display="flex" flex={1} flexDirection="column" p={1}>
                                        {children}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DashboardSideBarProvider>
            </DashboardTitleStateProvider>
        </WindowSizeLayout>
    );
};

export { Dashboard__Layout };
