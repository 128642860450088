import { TextField as MuiTextField } from "@mui/material";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext, useFormValidationRule } from "@bujus/common-frontend";

const NumberInput = ({
    fieldName,
    isAutofocusing = false,
    isEnabled = true,
    label,
    size = undefined,
    validationSchema,
}) => {
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    // HACK
    const minNumber = useFormValidationRule({ validationSchema }, fieldName, "min")?.args?.limit;
    const maxNumber = useFormValidationRule({ validationSchema }, fieldName, "max")?.args?.limit;

    const handleChange = (newValue) => {
        fieldController.field.onChange(newValue);
    };

    return (
        <MuiTextField
            autoFocus={isAutofocusing}
            disabled={!isEnabled}
            error={fieldController.fieldState.error !== undefined}
            fullWidth
            {...(fieldController.fieldState.error !== undefined && {
                helperText: fieldController.fieldState.error.message,
            })}
            InputProps={{
                inputProps: {
                    min: minNumber,
                    max: maxNumber,
                },
            }}
            label={label}
            onChange={handleChange}
            type="number"
            {...(size !== undefined && { size })}
            value={fieldController.field.value}
        />
    );
};

export { NumberInput };
