import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import { schoolValidationSchemas, validationOptions } from "@bujus/common";

import { Form, PasswordField, TextField } from "@/base";
import { useAccountDashboardMailAddressStateContext } from "@/contexts";

const AccountSignUpPage__AccountSettingsStepContent = ({ defaultFormData, onNext }) => {
    const [, setAccountDashboardMailAddress] = useAccountDashboardMailAddressStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            schoolValidationSchemas.signUp.forms.accountSettings,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });

    const verifiableMailAddressFieldValue = useWatch({
        control: formController.control,
        name: "verifiableMailAddress",
    });

    const handleFormSubmit = (data) => {
        onNext(data);
    };

    useEffect(() => {
        setAccountDashboardMailAddress(verifiableMailAddressFieldValue);
    }, [verifiableMailAddressFieldValue]);

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                    autoComplete="email"
                    cyId="AccountSignUpPage__AccountSettingsStepContent__verifiable-mail-address-field"
                    fieldName="verifiableMailAddress"
                    label="Email-Adresse *"
                    type="email"
                />
                <PasswordField
                    autoComplete="new-password"
                    cyId="AccountSignUpPage__AccountSettingsStepContent__password-field"
                    fieldName="password"
                    label="Passwort *"
                />
                <PasswordField
                    autoComplete="new-password"
                    cyId="AccountSignUpPage__AccountSettingsStepContent__password-confirmation-field"
                    fieldName="passwordConfirmation"
                    label="Passwort bestätigen *"
                />
            </Box>
            <Box display="flex" gap={1} justifyContent="end" mt={2}>
                <Button
                    cyId="AccountSignUpPage__AccountSettingsStepContent__next-button"
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                >
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { AccountSignUpPage__AccountSettingsStepContent };
