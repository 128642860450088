import { joiResolver } from "@hookform/resolvers/joi";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash.isequal";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    EventFormat,
    callEventFormatFunction,
    disciplinePreselectionValidationSchemas,
    getDiscipline,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Form } from "@/base";
import {
    useDashboardTitleStateContext,
    useDisciplinePreselectionContext,
    useSelectedEventStateContext,
} from "@/contexts";
import { useSnackbarsController } from "@/hooks";

import { DisciplinePreselectionPage__CompetitionEventView } from "./discipline-preselection-page__competition-event-view";
import { DisciplinePreselectionPage__ContestEventView } from "./discipline-preselection-page__contest-event-view";

const convertDisciplinePreselectionToFieldValue = (eventFormat, disciplinePreselection) =>
    callEventFormatFunction(
        () =>
            Object.fromEntries(
                Object.entries(disciplinePreselection).map(([xKey, xValue]) => [
                    xKey,
                    Object.fromEntries(
                        Object.entries(xValue).map(([yKey, yValue]) => [
                            yKey,
                            getDiscipline(eventFormat, yValue),
                        ]),
                    ),
                ]),
            ),
        () => disciplinePreselection,
        eventFormat,
    );

const convertFieldValueToDisciplinePreselection = (eventFormat, fieldValue) =>
    callEventFormatFunction(
        () =>
            Object.fromEntries(
                Object.entries(fieldValue).map(([xKey, xValue]) => [
                    xKey,
                    Object.fromEntries(
                        Object.entries(xValue).map(([yKey, yValue]) => [yKey, yValue.id]),
                    ),
                ]),
            ),
        () => fieldValue,
        eventFormat,
    );

const DisciplinePreselectionPage = () => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();
    const disciplinePreselection = useDisciplinePreselectionContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            disciplinePreselectionValidationSchemas.update.form,
            validationOptions,
        ),
        context: { event: selectedEvent },
        defaultValues: {
            value: convertDisciplinePreselectionToFieldValue(
                selectedEvent.format,
                disciplinePreselection,
            ),
        },
    });
    const fieldValue = useWatch({
        control: formController.control,
        name: "value",
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.disciplinePreselections.updateAsync(
                variables.eventId,
                variables.disciplinePreselection,
            ),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Disziplinvorauswahl geändert");
                formController.reset();
                queryClient.invalidateQueries(["disciplinePreselections"]);
            },
        },
    );

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: selectedEvent.id,
            disciplinePreselection: convertFieldValueToDisciplinePreselection(
                selectedEvent.format,
                data.value,
            ),
        });
    };

    const handleResetButtonClick = () => {
        formController.reset();
    };

    // const isDirtyToDefault = useMemo(
    //     () =>
    //         !isEqual(
    //             convertFieldValueToDisciplinePreselection(
    //                 selectedEvent.format,
    //                 valueFieldController.field.value,
    //             ),
    //             eventFormatToDefaultDisciplinePreselectionMap[selectedEvent.format],
    //         ),
    //     [valueFieldController.field.value],
    // );

    // const handleDefaultResetButtonClick = () => {
    //     console.log("DEFAULTING");
    //     formController.setValue(
    //         "value",
    //         convertDisciplinePreselectionToFieldValue(
    //             selectedEvent.format,
    //             eventFormatToDefaultDisciplinePreselectionMap[selectedEvent.format],
    //         ),
    //     );
    //     // valueFieldController.field.onChange(
    //     //     ,
    //     // );
    // };

    useEffect(() => {
        // This is only for exceptions, where the new updated discipline preselection is not the same as the one we get back from the server. It is kinda an optimistic update with the form reset in the mutation on success handler
        if (
            isEqual(
                convertDisciplinePreselectionToFieldValue(
                    selectedEvent.format,
                    disciplinePreselection,
                ),
                fieldValue,
            )
        ) {
            return;
        }
        formController.reset({
            value: convertDisciplinePreselectionToFieldValue(
                selectedEvent.format,
                disciplinePreselection,
            ),
        });
    }, [disciplinePreselection]);

    useEffect(() => {
        setDashboardTitle("Disziplinvorauswahl");
    }, []);

    const FormActions = (
        <DialogActions
            sx={{
                borderColor: "divider",
                borderTopStyle: "solid",
                borderTopWidth: 1,
            }}
        >
            {formController.formState.isDirty && (
                <Button disabled={mutation.isLoading} onClick={handleResetButtonClick}>
                    Änderungen Abbrechen
                </Button>
            )}
            {/* {isDirtyToDefault && (
                <Button disabled={mutation.isLoading} onClick={handleDefaultResetButtonClick}>
                    Auf Standard Zurücksetzen
                </Button>
            )} */}
            <Box flex={1}>
                <LoadingButton
                    disabled={!formController.formState.isDirty}
                    fullWidth
                    loading={mutation.isLoading}
                    type="submit"
                    variant="contained"
                >
                    Speichern
                </LoadingButton>
            </Box>
        </DialogActions>
    );
    // HACK The component does not get unmounted on event change, when the event has been loaded before. This reset function is to slow to update the form values in time, so the ui tries to render the old values which could be for another event format. This way it crashes. That is because in the rendering of this component has to check if the needed values are present on render
    // if (
    //     (selectedEvent.format === EventFormat.CONTEST &&
    //         fieldValue[ClassLevelRange.N_1_AND_2] === undefined) ||
    //     (selectedEvent.format === EventFormat.COMPETITION && fieldValue[Gender.MALE] === undefined)
    // ) {
    //     return undefined;
    // }
    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            {callEventFormatFunction(
                () => (
                    <DisciplinePreselectionPage__ContestEventView
                        FormActions={FormActions}
                        mutation={mutation}
                    />
                ),
                () => (
                    <DisciplinePreselectionPage__CompetitionEventView
                        FormActions={FormActions}
                        mutation={mutation}
                    />
                ),
                selectedEvent.format,
            )}
        </Form>
    );
};

export { DisciplinePreselectionPage };
