import { createContext, useContext } from "react";

const AccountDashboardTitleStateContext = createContext();
const AccountDashboardTitleStateProvider = AccountDashboardTitleStateContext.Provider;
const useAccountDashboardTitleStateContext = () => useContext(AccountDashboardTitleStateContext);

export {
    AccountDashboardTitleStateContext,
    AccountDashboardTitleStateProvider,
    useAccountDashboardTitleStateContext,
};
