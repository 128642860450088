import { joiResolver } from "@hookform/resolvers/joi";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useForm as useFormController } from "react-hook-form";

import { EventFormat, certValidationSchemas, validationOptions } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { CardCollapse, Checkbox, Form } from "@/base";
import { useDashboardTitleStateContext, useSelectedEventStateContext } from "@/contexts";

import { StatisticsDialog } from "./statistics-dialog";

const StatisticsPage = () => {
    const theme = useTheme();

    const [selectedEvent] = useSelectedEventStateContext();
    const [, setDashboardTitle] = useDashboardTitleStateContext();

    const dialogDisplayer = useDialogDisplayer(StatisticsDialog);

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(certValidationSchemas.allGeneration.body, validationOptions),
        context: { event: selectedEvent },
        defaultValues: {
            options: {
                isOnlyUsingAllowedDisciplines: false,
                isRescuingNotGeneratingCerts: false,
            },
        },
    });

    const handleFormSubmit = (data) => {
        dialogDisplayer.open({ options: data.options });
    };

    useEffect(() => {
        setDashboardTitle("Statistiken");
    }, []);

    return (
        <>
            <Box
                alignItems="center"
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
            >
                <Box maxWidth={theme.breakpoints.values.sm}>
                    <Form controller={formController} onSubmit={handleFormSubmit}>
                        <Box alignItems="center" display="flex" flexDirection="column">
                            <CardCollapse title="Erweiterte Optionen">
                                <Checkbox
                                    fieldName="options.isOnlyUsingAllowedDisciplines"
                                    label="Verbiete Teilnehmern das Ausführen von Disziplinen, die sie nach Altersgruppe und Geschlecht nicht ausführen dürften. Beispiel: Männlich 14 darf keine 50m laufen, sondern nur 75m."
                                />
                                <Checkbox
                                    fieldName="options.isRescuingNotGeneratingCerts"
                                    label="Rette Urkunden, welche eigentlich nicht generiert werden können."
                                />
                            </CardCollapse>
                            <Box mt={2}>
                                <Button startIcon={<VisibilityIcon />} type="submit">
                                    Ansehen
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                </Box>
            </Box>
            {dialogDisplayer.element}
        </>
    );
};

export { StatisticsPage };
