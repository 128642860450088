import { Box, Collapse as MuiCollapse } from "@mui/material";
import React, { useEffect, useState } from "react";

const Collapse = ({
    isOpen,
    isAnimatingOnEnter = false,
    overwritingTopMargin = undefined,
    children,
}) => {
    const [finalIsOpen, setFinalIsOpen] = useState(isAnimatingOnEnter ? false : isOpen);

    useEffect(() => {
        setFinalIsOpen(isOpen);
    }, [isOpen]);

    return (
        <MuiCollapse
            in={finalIsOpen}
            {...(overwritingTopMargin !== undefined && { sx: { mt: -overwritingTopMargin } })}
        >
            <Box mt={overwritingTopMargin}>{children}</Box>
        </MuiCollapse>
    );
};

export { Collapse };
