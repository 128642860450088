import { Description as DescriptionIcon, Support as SupportIcon } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import React from "react";

import howToOpenInstructionsImageSource from "@/assets/how-to-open-instructions.webp";
import { DialogClosingButton } from "@/base";
import { useAccountContext, useOpenInstructionsDrawerContext } from "@/contexts";

const EventCreatedInformationDialog = ({ close, event, stripeInvoiceUrl = undefined, isOpen }) => {
    const openInstructionsDrawer = useOpenInstructionsDrawerContext();
    const account = useAccountContext();

    const handleClose = () => {
        openInstructionsDrawer("/vorbereitung/helfer-hinzufügen");
        close();
    };

    const handleClosingButtonClick = () => {
        openInstructionsDrawer("/vorbereitung/helfer-hinzufügen");
        close();
    };

    const handleInvoiceOpenButtonClick = () => {
        window.open(stripeInvoiceUrl, "_blank");
    };

    const handleStartButtonClick = () => {
        openInstructionsDrawer("/vorbereitung/helfer-hinzufügen");
        close();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>{event.name} erstellt</DialogTitle>
            <DialogClosingButton onClick={handleClosingButtonClick} />
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        Die Veranstaltung wurde erstellt und auch direkt ausgewählt.
                    </Typography>
                    {stripeInvoiceUrl !== undefined && (
                        <>
                            <br />
                            <Typography>
                                Die Rechnung wurde an {account.mailAddress} geschickt.
                            </Typography>
                        </>
                    )}
                    <br />
                    <Typography>
                        Wenn Sie Bujus vorher noch nicht verwendet haben, werfen Sie gerne einen
                        Blick in die Anleitung. Die Anleitung zeigt Ihnen, wie eine Veranstaltung
                        mit Bujus abläuft und wie alle Funktionen zu benutzen sind. Sie finden die
                        Anleitung auch immer unten links im Dashboard.
                    </Typography>
                </DialogContentText>
                <Box mt={2}>
                    <Card>
                        <img
                            alt=""
                            src={howToOpenInstructionsImageSource}
                            style={{
                                display: "block",
                                width: "100%",
                            }}
                        />
                    </Card>
                </Box>
            </DialogContent>
            <DialogActions>
                {stripeInvoiceUrl !== undefined && (
                    <Button onClick={handleInvoiceOpenButtonClick} startIcon={<DescriptionIcon />}>
                        Rechnung öffnen
                    </Button>
                )}
                <Button onClick={handleStartButtonClick} startIcon={<SupportIcon />}>
                    Los gehts!
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { EventCreatedInformationDialog };
