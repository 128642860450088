import { Edit as EditIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";

import { formatEventFormat } from "@bujus/common";
import { useDialogDisplayer } from "@bujus/common-frontend";

import { useSelectedEventStateContext } from "@/contexts";

import { EventNameUpdateDialog } from "./event-name-update-dialog";

const EventPage__BasicsCard = () => {
    const [selectedEvent] = useSelectedEventStateContext();

    const updateDialogDisplayer = useDialogDisplayer(EventNameUpdateDialog);

    const handleUpdateButtonClick = () => {
        updateDialogDisplayer.open({ event: selectedEvent });
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography>
                        <b>3-Wort-ID:</b> {selectedEvent.n3WordId}
                    </Typography>
                    <Box display="flex" mt={2}>
                        <Box>
                            <Typography display="inline" variant="h5">
                                {selectedEvent.name}
                            </Typography>
                            <Tooltip title="Bearbeiten">
                                <Box display="inline">
                                    <IconButton onClick={handleUpdateButtonClick} size="small">
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box ml={1}>
                            <Chip color="primary" label={formatEventFormat(selectedEvent.format)} />
                        </Box>
                        {selectedEvent.isTest && (
                            <Box ml={1}>
                                <Chip color="info" label="Test" />
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
            {updateDialogDisplayer.element}
        </>
    );
};

export { EventPage__BasicsCard };
