const generateDataTableDataProps = (twoDimensionalArray, isFirstRowContainingHeadings) => ({
    columns: twoDimensionalArray[0].map((x, xIndex) => ({
        fieldName: xIndex,
        heading: `Spalte ${xIndex + 1}`,
        getValue: (row) => row[xIndex],
        width: 1,
    })),
    items: twoDimensionalArray.slice(!isFirstRowContainingHeadings ? 0 : 1).map((x) =>
        x.reduce((yAccumulator, y, yIndex) => {
            yAccumulator[yIndex] = y;
            return yAccumulator;
        }, {}),
    ),
});

export { generateDataTableDataProps };
