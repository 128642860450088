import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { AppErrorType, schoolValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { Form, HorizontalCenterErrorMessage, LoginButton, PasswordField } from "@/base";
import { useAccountContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const AccountPasswordChangePage__Logic = ({ token }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const account = useAccountContext();
    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(schoolValidationSchemas.passwordChange.form, validationOptions),
        defaultValues: {
            password: "",
            passwordConfirmation: "",
        },
    });

    const mutation = useMutation(
        async (variables) =>
            apiService.schools.changePasswordAsync(variables.token, variables.password),
        {
            onError: (error) => {
                if (error.type === AppErrorType.INVALID.PASSWORD_CHANGE_TOKEN) {
                    return;
                }
                snackbarsController.enqueueError(error);
            },
            onSuccess: (_, variables) => {
                snackbarsController.enqueueSuccessMessage("Account Passwort geändert");
                if (variables.accountId === undefined) {
                    return;
                }
                queryClient.invalidateQueries(["schools", "get", variables.accountId]);
            },
        },
    );

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            ...(account !== undefined && { accountId: account.id }),
            token,
            password: data.password,
        });
    };

    if (mutation.isError && mutation.error.type === AppErrorType.INVALID.PASSWORD_CHANGE_TOKEN) {
        return (
            <Box>
                <HorizontalCenterErrorMessage error={mutation.error} />
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </Box>
        );
    }
    if (mutation.isIdle || mutation.isLoading || mutation.isError) {
        return (
            <Box>
                <Form controller={formController} onSubmit={handleFormSubmit}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <PasswordField
                            autoComplete="current-password"
                            fieldName="password"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label="Passwort *"
                        />
                        <PasswordField
                            autoComplete="current-password"
                            fieldName="passwordConfirmation"
                            isEnabled={!mutation.isLoading}
                            label="Passwort bestätigen *"
                        />
                        <LoadingButton
                            fullWidth
                            loading={mutation.isLoading}
                            startIcon={<EditIcon />}
                            type="submit"
                            variant="contained"
                        >
                            Passwort ändern
                        </LoadingButton>
                    </Box>
                </Form>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton color="secondary" />
                </Box>
            </Box>
        );
    }
    if (mutation.isSuccess) {
        return (
            <Box>
                <Typography align="center">
                    Sie könnne sich jetzt mit Ihrem neuen Passwort anmelden.
                </Typography>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LoginButton />
                </Box>
            </Box>
        );
    }
    return undefined;
};

export { AccountPasswordChangePage__Logic };
