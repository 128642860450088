import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

const minHeight = 480;

const WindowSizeLayout = ({ children }) => {
    const theme = useTheme();
    const isWideEnough = useMediaQuery(theme.breakpoints.up("md"));

    const [height, setHeight] = useState(window.innerHeight);

    const isHighEnough = height > minHeight;

    const handleWindowResize = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <>
            <Box display="flex" flex={1} flexDirection="column" sx={{ overflowX: "hidden" }}>
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    minHeight={minHeight}
                    minWidth={theme.breakpoints.values.md}
                >
                    {children}
                </Box>
            </Box>
            {(!isWideEnough || !isHighEnough) && (
                <Box
                    bottom={0}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    left={0}
                    position="fixed"
                    right={0}
                    sx={{
                        backgroundColor: "background.darkOverlay",
                        zIndex: theme.zIndex.overlay,
                    }}
                    top={0}
                >
                    <Container maxWidth="xs">
                        <Typography color="error" textAlign="center" variant="h4">
                            Das Fenster ist zu klein
                        </Typography>
                        <Typography color="text.onDark" mt={2} textAlign="center">
                            Bitte vergrößern Sie das Fenster oder öffnen Sie die App auf einem
                            anderen Gerät.
                        </Typography>
                    </Container>
                </Box>
            )}
        </>
    );
};

export { WindowSizeLayout };
