import * as dateFns from "date-fns";

const Step = {
    TYPE_SELECTION: "TYPE_SELECTION",
    SETTINGS: "SETTINGS",
    PAYMENT_SETTINGS: "PAYMENT_SETTINGS",
};

const DisplayStep = {
    TYPE_SELECTION: 0,
    SETTINGS: 1,
    PAYMENT_SETTINGS: 2,
};

const convertStepToDisplayStep = (step) => {
    switch (step) {
        case Step.TYPE_SELECTION:
            return DisplayStep.TYPE_SELECTION;
        case Step.SETTINGS:
            return DisplayStep.SETTINGS;
        case Step.PAYMENT_SETTINGS:
            return DisplayStep.PAYMENT_SETTINGS;
        default:
            throw new Error();
    }
};

const Action = {
    NEXT: "NEXT",
    PREVIOUS: "PREVIOUS",
};

const initialState = {
    step: Step.TYPE_SELECTION,
    formDatas: {
        typeSelection: {
            format: null,
            isTest: null,
        },
        settings: {
            test: {
                name: "",
                start: dateFns.startOfDay(new Date()),
            },
            real: {
                name: "",
                start: dateFns.startOfDay(new Date()),
                limits: { participant: null },
            },
        },
        paymentSettings: {
            discountCode: "",
            type: "",
        },
    },
};

// Should not reset steps when going back
const reducer = (state, action) => {
    switch (action.type) {
        case Action.NEXT: {
            switch (state.step) {
                case Step.TYPE_SELECTION: {
                    return {
                        ...state,
                        step: Step.SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            typeSelection: action.formData,
                            settings: {
                                ...state.formDatas.settings,
                                test: {
                                    ...state.formDatas.settings.test,
                                    start: dateFns.startOfDay(new Date()),
                                },
                                real: {
                                    ...state.formDatas.settings.real,
                                    start: dateFns.startOfDay(new Date()),
                                },
                            },
                        },
                    };
                }
                case Step.SETTINGS: {
                    if (state.formDatas.typeSelection.isTest) {
                        throw new Error();
                    }
                    return {
                        ...state,
                        step: Step.PAYMENT_SETTINGS,
                        formDatas: {
                            ...state.formDatas,
                            settings: {
                                ...state.formDatas.settings,
                                real: action.formData,
                            },
                        },
                    };
                }
                default:
                    throw new Error();
            }
        }
        case Action.PREVIOUS: {
            switch (state.step) {
                case Step.SETTINGS: {
                    return {
                        ...state,
                        step: Step.TYPE_SELECTION,
                    };
                }
                case Step.PAYMENT_SETTINGS: {
                    return {
                        ...state,
                        step: Step.SETTINGS,
                    };
                }
                default:
                    throw new Error();
            }
        }
        default:
            throw new Error();
    }
};

const eventCreationDialogStateMachine = {
    Step,
    DisplayStep,
    convertStepToDisplayStep,
    Action,
    initialState,
    reducer,
};

export { eventCreationDialogStateMachine };
