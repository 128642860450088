import {
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import * as dateFns from "date-fns";
import React, { useState } from "react";

import { useDialogDisplayer } from "@bujus/common-frontend";

import { useAccountContext } from "@/contexts";

import { AccountRemovalCancellationAlert } from "./account-removal-cancellation-alert";
import { AccountRemovalRequestDialog } from "./account-removal-request-dialog";

const AccountPage__RemovalCard = () => {
    const account = useAccountContext();

    const [isCreatedRevealed, setIsCreatedRevealed] = useState(false);

    const requestDialogDisplayer = useDialogDisplayer(AccountRemovalRequestDialog);

    const handleRequestButtonClick = () => {
        requestDialogDisplayer.open();
    };

    const handleCreatedRevealingToggleButtonClick = () => {
        setIsCreatedRevealed(!isCreatedRevealed);
    };

    return (
        <>
            <Card sx={{ borderColor: "error.main" }}>
                <CardContent>
                    <Typography color="error" variant="h6">
                        Danger Zone
                    </Typography>
                    <AccountRemovalCancellationAlert containerProps={{ mt: 2 }} />
                    {(account.removalIntent === undefined ||
                        dateFns.isBefore(account.removalIntent.expires, new Date())) && (
                        <Box mt={2}>
                            <Button
                                color="error"
                                onClick={handleRequestButtonClick}
                                size="small"
                                startIcon={<DeleteIcon />}
                                variant="outlined"
                            >
                                Account löschen
                            </Button>
                        </Box>
                    )}
                    <Box alignItems="center" display="flex" mt={2}>
                        <Typography>
                            <b>Erstellt:</b>{" "}
                            <Typography
                                sx={{
                                    display: "inline",
                                    ...(!isCreatedRevealed && { filter: "blur(4px)" }),
                                    transition: "filter 0.2s ease-in-out",
                                }}
                            >
                                {" "}
                                {account.created.toISOString()}
                                {/* TODO Change when https://github.com/date-fns/date-fns/pull/2971 is fixed */}
                            </Typography>
                        </Typography>
                        <Box ml={1}>
                            <IconButton
                                color="secondary"
                                edge="end"
                                onClick={handleCreatedRevealingToggleButtonClick}
                            >
                                {isCreatedRevealed ? (
                                    <VisibilityIcon fontSize="small" />
                                ) : (
                                    <VisibilityOffIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            {requestDialogDisplayer.element}
        </>
    );
};

export { AccountPage__RemovalCard };
