import { joiResolver } from "@hookform/resolvers/joi";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import {
    formatResultValue,
    generateFullName,
    getDiscipline,
    resultValidationSchemas,
    validationOptions,
} from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DataTable, DialogClosingButton, Form, TextField } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";
import { useSnackbarsController } from "@/hooks";

const ManyResultsRemovalDialog = ({ close, results, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();
    const [selectedEvent] = useSelectedEventStateContext();

    const confirmation = `${results.length}`;
    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            resultValidationSchemas.manyRemoval.generateForm(confirmation),
            validationOptions,
        ),
        defaultValues: { confirmation: "" },
    });

    const mutation = useMutation(
        async (variables) => apiService.results.removeManyAsync(variables.ids),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage(
                    `${results.length} Ergebnis${results.length === 1 ? "" : "se"} entfernt`,
                );
                queryClient.invalidateQueries(["results"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = () => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({ ids: results.map((x) => x.id) });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    const dataTableColumns = [
        {
            fieldName: "participant",
            heading: "Teilnehmer",
            getValue: (result) =>
                generateFullName(result.participant.name.first, result.participant.name.last),
            width: 1,
        },
        {
            fieldName: "discipline",
            heading: "Disziplin",
            getValue: (result) => getDiscipline(selectedEvent.format, result.disciplineId).name,
            width: 1,
        },
        {
            fieldName: "value",
            heading: "Wert",
            getValue: (result) =>
                formatResultValue(
                    selectedEvent.format,
                    result.disciplineId,
                    result.isFailedAttempt,
                    result.value,
                ),
            width: 1,
        },
    ];

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>{results.length} Ergebnis entfernen</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <DataTable columns={dataTableColumns} items={results} />
                    <Box mt={2}>
                        <TextField
                            fieldName="confirmation"
                            isAutofocusing
                            isEnabled={!mutation.isLoading}
                            label={`Tippen Sie "${confirmation}" ein um zu bestätigen`}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        disabled={!formController.formState.isValid}
                        icon={<DeleteIcon />}
                        loading={mutation.isLoading}
                        type="submit"
                    >
                        Entfernen
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { ManyResultsRemovalDialog };
