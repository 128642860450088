import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import { useDashboardTitleStateContext } from "@/contexts";

import { EventPage__BasicsCard } from "./event-page__basics-card";
import { EventPage__LimitsCard } from "./event-page__limits-card";
import { EventPage__RemovalCard } from "./event-page__removal-card";
import { EventPage__ReportGenerationCard } from "./event-page__report-generation-card";
import { EventPage__ScheduleCard } from "./event-page__schedule-card";

const EventPage = () => {
    const theme = useTheme();

    const [, setDashboardTitle] = useDashboardTitleStateContext();

    useEffect(() => {
        setDashboardTitle("Veranstaltung");
    }, []);

    return (
        <Box display="flex" flexDirection="column" gap={1} maxWidth={theme.breakpoints.values.sm}>
            <EventPage__BasicsCard />
            <EventPage__ScheduleCard />
            <EventPage__LimitsCard />
            {/* <EventPage__ReportGenerationCard /> */}
            <EventPage__RemovalCard />
        </Box>
    );
};

export { EventPage };
