import { createContext, useContext } from "react";

const OpenInstructionsDrawerContext = createContext();
const OpenInstructionsDrawerProvider = OpenInstructionsDrawerContext.Provider;
const useOpenInstructionsDrawerContext = () => useContext(OpenInstructionsDrawerContext);

export {
    OpenInstructionsDrawerContext,
    OpenInstructionsDrawerProvider,
    useOpenInstructionsDrawerContext,
};
