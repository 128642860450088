import { EventFormat, callEventFormatFunction, checkIsCountingResult } from "@bujus/common";

const getResultColor = (
    eventFormat,
    disciplineCategoryIdToDisciplineCategoryEvaluationMap,
    disciplineCategoryEvaluation,
) => {
    if (!disciplineCategoryEvaluation.isValid) {
        return "error.main";
    }
    if (
        eventFormat === EventFormat.COMPETITION &&
        !checkIsCountingResult(
            disciplineCategoryIdToDisciplineCategoryEvaluationMap,
            disciplineCategoryEvaluation,
        )
    ) {
        return "error.main";
    }
    if (
        callEventFormatFunction(
            () => disciplineCategoryEvaluation.bestResults[0].isFake,
            () => disciplineCategoryEvaluation.bestResult.isFake,
            eventFormat,
        )
    ) {
        return "warning.main";
    }
    return "success.main";
};

export { getResultColor };
