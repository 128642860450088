import { joiResolver } from "@hookform/resolvers/joi";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useForm as useFormController, useWatch } from "react-hook-form";

import {
    certTemplateSetValidationSchemas,
    eventFormatToCertTypeToCertTemplatePresets,
    formatCertType,
    formatEventFormat,
    validationOptions,
} from "@bujus/common";

import { Form, Select } from "@/base";
import { useSelectedEventStateContext } from "@/contexts";

const CertTemplateUpdateDialog__PresetStepContent = ({ defaultFormData, onLiveChange, onNext }) => {
    const [selectedEvent] = useSelectedEventStateContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(
            certTemplateSetValidationSchemas.update.forms.presetSelection,
            validationOptions,
        ),
        defaultValues: defaultFormData,
    });
    const presetFieldValue = useWatch({
        control: formController.control,
        name: "preset",
    });

    const handleFormSubmit = (data) => {
        onNext(data);
    };

    useEffect(() => {
        onLiveChange(presetFieldValue.certTemplate);
    }, [presetFieldValue]);

    return (
        <Form controller={formController} onSubmit={handleFormSubmit}>
            <Select
                fieldName="preset"
                formatItem={(preset) =>
                    `${formatEventFormat(selectedEvent.format)} ${formatCertType(preset.certType)} ${preset.year}`
                }
                getKeyFromItem={(preset) => preset.id}
                items={
                    eventFormatToCertTypeToCertTemplatePresets[selectedEvent.format][
                        presetFieldValue.certType
                    ]
                }
                label="Jahr *"
            />
            <Box display="flex" justifyContent="end" mt={2}>
                <Button endIcon={<ArrowForwardIcon />} type="submit">
                    Weiter
                </Button>
            </Box>
        </Form>
    );
};

export { CertTemplateUpdateDialog__PresetStepContent };
