import { Box, Typography } from "@mui/material";
import React from "react";

const ButtonHotkeyIcon = ({ children }) => (
    <Box
        px={0.8}
        py={0.2}
        sx={{
            backgroundColor: "background.primary",
            borderRadius: 1,
        }}
    >
        <Typography fontSize="0.7em">{children}</Typography>
    </Box>
);

export { ButtonHotkeyIcon };
