import { joiResolver } from "@hookform/resolvers/joi";
import { Edit as EditIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm as useFormController } from "react-hook-form";

import { eventValidationSchemas, validationOptions } from "@bujus/common";
import { useApiServiceContext } from "@bujus/common-frontend";

import { DialogClosingButton, Form, TextField } from "@/base";
import { useSnackbarsController } from "@/hooks";

const EventNameUpdateDialog = ({ close, event, isOpen }) => {
    const snackbarsController = useSnackbarsController();

    const queryClient = useQueryClient();

    const apiService = useApiServiceContext();

    const formController = useFormController({
        mode: "onChange",
        resolver: joiResolver(eventValidationSchemas.nameUpdate.body, validationOptions),
        defaultValues: { name: event.name },
    });

    const mutation = useMutation(
        async (variables) => apiService.events.updateNameAsync(variables.eventId, variables.name),
        {
            onError: (error) => {
                snackbarsController.enqueueError(error);
            },
            onSuccess: () => {
                snackbarsController.enqueueSuccessMessage("Veranstaltungs Name geändert");
                queryClient.invalidateQueries(["events"]);
                close();
            },
        },
    );

    const tryClose = () => {
        if (mutation.isLoading) {
            return false;
        }
        close();
        return true;
    };

    const handleClose = () => {
        tryClose();
    };

    const handleFormSubmit = (data) => {
        if (mutation.isLoading) {
            return;
        }
        mutation.mutate({
            eventId: event.id,
            name: data.name,
        });
    };

    const handleClosingButtonClick = () => {
        tryClose();
    };

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <Form controller={formController} onSubmit={handleFormSubmit}>
                <DialogTitle>Veranstaltungs Name ändern</DialogTitle>
                <DialogClosingButton
                    isDisabled={mutation.isLoading}
                    onClick={handleClosingButtonClick}
                />
                <DialogContent>
                    <TextField
                        fieldName="name"
                        isAutofocusing
                        isEnabled={!mutation.isLoading}
                        label="Name *"
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loading={mutation.isLoading}
                        startIcon={<EditIcon />}
                        type="submit"
                    >
                        Ändern
                    </LoadingButton>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

export { EventNameUpdateDialog };
