import React from "react";

import { schoolAppRoutes } from "@bujus/common";

import { useAccountContext } from "@/contexts";

import { RestrictedRoute } from "../restricted-route";

const AuthenticatedRoute = ({ children }) => {
    const account = useAccountContext();

    return (
        <RestrictedRoute
            condition={account !== undefined}
            redirectUrl={schoolAppRoutes(true).account().login().build()}
        >
            {children}
        </RestrictedRoute>
    );
};

export { AuthenticatedRoute };
