import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React from "react";
import { useController as useFieldController } from "react-hook-form";

import { useFormControllerContext, useFormValidationRule } from "@bujus/common-frontend";

const DatePicker = ({ fieldName, isEnabled = true, label, validationSchema }) => {
    const formController = useFormControllerContext();

    const fieldController = useFieldController({
        control: formController.control,
        name: fieldName,
    });

    // HACK
    const min = useFormValidationRule(
        { validationSchema },
        fieldName,
        "myDynamicMin",
    )?.args?.generateDate();
    const max = useFormValidationRule(
        { validationSchema },
        fieldName,
        "myDynamicMax",
    )?.args?.generateDate();

    const handleChange = (value) => {
        fieldController.field.onChange(value);
    };

    return (
        <DesktopDatePicker
            disabled={!isEnabled}
            label={label}
            maxDate={max}
            minDate={min}
            onChange={handleChange}
            renderInput={(props) => (
                <TextField
                    {...props}
                    error={fieldController.fieldState.error !== undefined}
                    fullWidth
                    helperText={
                        fieldController.fieldState.error === undefined
                            ? undefined
                            : fieldController.fieldState.error.message
                    }
                />
            )}
            value={fieldController.field.value}
        />
    );
};

export { DatePicker };
